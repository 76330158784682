import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Modal,
	Badge,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { AuditLog } from '../../Objects/AuditLog';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isValidated    : false,
			isSuccess      : false,
			isShowModal    : false,
			isLockForm     : false,
			formObj        : null,
			ErrMsg         : '',
			zaiko_flag     : '',
			flag_1         : '',
			flag_2         : '',
			flag_3         : '',
			flag_4         : '',
			flag_5         : '',
			flag_6         : '',
			flag_7         : '',
			flag_8         : '',
			flag_9         : '',
			en_kbn         : '',
		}
		this.handleReload     = this.handleReload.bind(this);
		this.handleSubmit     = this.handleSubmit.bind(this);
		this.handleShow       = this.handleShow.bind(this);
		this.handleClose      = this.handleClose.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
		this.lockFormClose    = this.lockFormClose.bind(this);
	}
	handleReload() {

		//Formオブジェクトの取得
		const formObj = this.state.formObj;

		//フォームのリセット
		formObj.reset();

		//データの初期化
		this.setState({
			isShowModal    : false,
			isSuccess      : false,
			isValidated    : false,
			isLockForm     : false,
			ErrMsg         : '',
			zaiko_flag     : '',
			flag_1         : '',
			flag_2         : '',
			flag_3         : '',
			flag_4         : '',
			flag_5         : '',
			flag_6         : '',
			flag_7         : '',
			flag_8         : '',
			flag_9         : '',
			en_kbn         : '',
		});

	}
	async handleSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal : false,
			isValidated : true,
			isLockForm  : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//Formから直接取得する更新項目
			const code          = formObj.code.value;
			const name          = formObj.name.value;
			const m_code        = formObj.m_code.value;
			const m_name        = formObj.m_name.value;
			const price         = formObj.price.value;
			const price_notax   = formObj.price_notax.value;
			const piece_type    = formObj.piece_type.value;
			const title         = formObj.title.value;
			const text_1        = formObj.text_1.value;
			const text_2        = formObj.text_2.value;
			const text_3        = formObj.text_3.value;
			const text_4        = formObj.text_4.value;
			const text_5        = 'null';
			const select_1      = formObj.select_1.value === '' ? 'null' : formObj.select_1.value;
			const select_2      = formObj.select_2.value === '' ? 'null' : formObj.select_2.value;
			const select_3      = 'null';
			const zaiko         = formObj.zaiko.value ? formObj.zaiko.value : 0;

			//Stateから取得する更新項目
			const zaiko_flag    = this.state.zaiko_flag  ? true :false;
			const flag_1        = this.state.flag_1      ? true :false;
			const flag_2        = this.state.flag_2      ? true :false;
			const flag_3        = this.state.flag_3      ? true :false;
			const flag_4        = this.state.flag_4      ? true :false;
			const flag_5        = this.state.flag_5      ? true :false;
			const flag_6        = this.state.flag_6      ? true :false;
			const flag_7        = this.state.flag_7      ? true :false;
			const flag_8        = this.state.flag_8      ? true :false;
			const flag_9        = this.state.flag_9      ? true :false;
			const en_kbn        = this.state.en_kbn      ? true :false;
			
			//日付処理
		        const date           = new Date();
		        const isoDate        = date.toISOString();

			//商品コードの予約語チェック（主に管理画面側のURIと重ならないかのみのチェック）
			if(
				code === 'New' ||
				code === 'Category'
			){
				this.setState({
					ErrMsg     : '※商品コードに使用できない文字を指定されました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//プロフィールの登録
			const createQuery = `mutation createItem($data: ItemInput!) {
				createItem(input: $data) {
					code
				}
			}`;
			const createData = {
				data : {
					code        : code,
					name        : name,
					m_code      : m_code,
					m_name      : m_name,
					price       : price,
					price_notax : price_notax,
					piece_type  : piece_type,
					title       : title,
					text_1      : text_1,
					text_2      : text_2,
					text_3      : text_3,
					text_4      : text_4,
					text_5      : text_5,
					select_1    : select_1,
					select_2    : select_2,
					select_3    : select_3,
					zaiko       : zaiko,
					zaiko_flag  : zaiko_flag,
					zaiko_memo  : '登録',
					flag_1      : flag_1,
					flag_2      : flag_2,
					flag_3      : flag_3,
					flag_4      : flag_4,
					flag_5      : flag_5,
					flag_6      : flag_6,
					flag_7      : flag_7,
					flag_8      : flag_8,
					flag_9      : flag_9,
					en_kbn      : en_kbn,
					date_add    : isoDate,
					date_edit   : isoDate,
				}
			};
			await API.graphql(graphqlOperation(createQuery, createData));

			//鑑査ログ
			await AuditLog('item', 'create', code, createData, '商品情報を登録しました。');

			this.setState({
				code : code,
			});
		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors[0].errorType === 'DynamoDB:ConditionalCheckFailedException'){
				err_msg = '指定された商品コードはすでに登録されています。<br/>';
			}
			else if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			});
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	handleClose() {
		this.setState({
			isShowModal    : false,
			isSuccess      : false,
			isValidated    : false,
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleShow(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowModal: true,
			formObj    : form,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this.setState({ isLoading: false });
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>
							登録しました。<br/>
							続けて登録しますか？
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={()=>{ this.props.history.push('/Item/' + this.state.code + '/'); }}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.handleReload}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowModal} onHide={this.handleClose} centered>
						<Modal.Body>本当に登録しますか？</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.handleSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleShow(e)}>
					<Row>
						<Col sm={12}>
							<p><big>商品情報の新規登録</big></p>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							商品コード　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="code">
							        <Form.Control name="code" type="text" placeholder="商品コード" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「商品コード」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							商品名　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="name">
							        <Form.Control name="name" type="text" placeholder="商品名" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「商品名」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メーカーコード　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="m_code">
							        <Form.Control name="m_code" type="text" placeholder="メーカーコード" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「メーカーコード」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メーカー名　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="m_name">
							        <Form.Control name="m_name" type="text" placeholder="メーカー名" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「メーカー名」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							販売価格　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={3}>
							<Form.Group controlId="price">
							        <Form.Control name="price" type="text" placeholder="販売価格" size="lg" required pattern="\d{1,20}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「販売価格」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<h5 style={{ marginTop: 20 }}>円</h5>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							税抜価格　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={3}>
							<Form.Group controlId="price_notax">
							        <Form.Control name="price_notax" type="text" placeholder="税抜価格" size="lg" required pattern="\d{1,20}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「税抜価格」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<h5 style={{ marginTop: 20 }}>円</h5>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							個数名称　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="piece_type">
							        <Form.Control name="piece_type" type="text" placeholder="個数名称" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「個数名称」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							見出しキャッチ　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="title">
							        <Form.Control name="title" type="text" placeholder="見出しキャッチ" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「見出しキャッチ」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							商品説明　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="text_1">
							        <Form.Control name="text_1" as="textarea" rows="3" placeholder="商品説明" size="lg" required pattern=".{1,1000}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「商品説明」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							カラー説明　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="text_2">
							        <Form.Control name="text_2" as="textarea" rows="3" placeholder="カラー説明" size="lg" required pattern=".{1,1000}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「カラー説明」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							サイズ説明　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="text_3">
							        <Form.Control name="text_3" as="textarea" rows="3" placeholder="サイズ説明" size="lg" required pattern=".{1,1000}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「サイズ説明」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							付属品その他　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="text_4">
							        <Form.Control name="text_4" as="textarea" rows="3" placeholder="付属品その他" size="lg" required pattern=".{1,1000}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「付属品その他」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							色選択
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="select_1">
							        <Form.Control name="select_1" as="textarea" rows="3" placeholder="色選択" size="lg" pattern=".{1,1000}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「色選択」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							サイズ選択
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="select_2">
							        <Form.Control name="select_2" as="textarea" rows="3" placeholder="サイズ選択" size="lg" pattern=".{1,1000}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「サイズ選択」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							在庫数　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="zaiko">
							        <Form.Control name="zaiko" type="text" placeholder="在庫数" size="lg" required pattern="\d{1,20}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「在庫数」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							在庫数無制限フラグ
						</Form.Label>
						<Col sm={2}>
							<Button name="zaiko_flag" variant={this.state.zaiko_flag ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ zaiko_flag: this.state.zaiko_flag ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.zaiko_flag ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							属性アイコン（NEW）
						</Form.Label>
						<Col sm={2}>
							<Button name="flag_1" variant={this.state.flag_1 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ flag_1: this.state.flag_1 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.flag_1 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							属性アイコン（PICKUP）
						</Form.Label>
						<Col sm={2}>
							<Button name="flag_2" variant={this.state.flag_2 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ flag_2: this.state.flag_2 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.flag_2 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							属性アイコン（SALE）
						</Form.Label>
						<Col sm={2}>
							<Button name="flag_3" variant={this.state.flag_3 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ flag_3: this.state.flag_3 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.flag_3 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							属性アイコン（DM便可）
						</Form.Label>
						<Col sm={2}>
							<Button name="flag_4" variant={this.state.flag_4 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ flag_4: this.state.flag_4 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.flag_4 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							属性アイコン（宅急便）
						</Form.Label>
						<Col sm={2}>
							<Button name="flag_5" variant={this.state.flag_5 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ flag_5: this.state.flag_5 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.flag_5 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							属性アイコン（DM10）
						</Form.Label>
						<Col sm={2}>
							<Button name="flag_6" variant={this.state.flag_6 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ flag_6: this.state.flag_6 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.flag_6 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							属性アイコン（送料無料）
						</Form.Label>
						<Col sm={2}>
							<Button name="flag_8" variant={this.state.flag_8 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ flag_8: this.state.flag_8 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.flag_8 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							有効フラグ
						</Form.Label>
						<Col sm={2}>
							<Button name="en_kbn" variant={this.state.en_kbn ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ en_kbn: this.state.en_kbn ? false : true }); }} disabled={this.state.isLockForm || this.state.not_email_kbn} >
								{this.state.en_kbn ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >登録する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props.history.push('/User/'); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					</Form>
				</Container>
			);
		}
	}
}

export default withRouter(Default);


