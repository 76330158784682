import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Alert,
	Modal,
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from "react-csv";


class MyTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectIDs   : [],
		}
		this._selectAllRows          = this._selectAllRows.bind(this);
		this._selectRows             = this._selectRows.bind(this);
		this._dataFormatDefaultField = this._dataFormatDefaultField.bind(this);
		this._dataFormatLabel        = this._dataFormatLabel.bind(this);
		this._dataFormatDate         = this._dataFormatDate.bind(this);
		this._dataFormatButton       = this._dataFormatButton.bind(this);
	}
	_selectRows(row, isSelect, rowIndex, e) {
		
		//新しく選択した内容
		const selectID  = row.selectid;
		
		//今まで選択した内容
		let selectIDs = this.state.selectIDs;

		//選択したかどうか
		if(isSelect){
			//新しく選択した内容を追加
			selectIDs.push(selectID);
		
			//重複を削除
			selectIDs = selectIDs.filter((x, i, self) => self.indexOf(x) === i);
		}
		else{
			//対応する要素を削除
			selectIDs = selectIDs.filter(n => n !== selectID);
		}
		
		//今まで選択した内容を更新
		this.setState({ selectIDs: selectIDs});
		
		//親に数値を渡す
		this.props.setSelectIDs(selectIDs);

	}
	_selectAllRows(isSelect, rows, e) {

		//今まで選択した内容
		let selectIDs = this.state.selectIDs;

		//ループ処理
		rows.some(row => {
		
			//新しく選択した内容
			const selectID  = row.selectid;
			
			//選択したかどうか
			if(isSelect){
				//新しく選択した内容を追加
				selectIDs.push(selectID);
			
				//重複を削除
				selectIDs = selectIDs.filter((x, i, self) => self.indexOf(x) === i);
			}
			else{
				//対応する要素を削除
				selectIDs = selectIDs.filter(n => n !== selectID);
			}
			
			return false;
		
		});

		//今まで選択した内容を更新
		this.setState({ selectIDs: selectIDs});
		
		//親に数値を渡す
		this.props.setSelectIDs(selectIDs);

	}
	_dataFormatDefaultField(cell, row) {
			return (<p style={{margin: "10px"}}>{cell}</p>);
	
	}
	_dataFormatLabel(cell, row) {
		return (
			<div className="text-center" style={{margin: "10px"}}>
				<Badge variant={cell ? 'success' : 'secondary'}>
					{cell ? '有効' : '無効'}
				</Badge>　
			</div>
		);
	}
	_dataFormatDate(cell, row) {

		//日付の整形
		const dateObj     = new Date(cell);
		const year        = dateObj.getFullYear().toString();
		const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day         = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second      = ('00' + dateObj.getSeconds().toString()).slice(-2);

		//出力
		return (
			<div>
				{year}年{month}月{day}日{hour}時{minute}分{second}秒
			</div>
		);
	}
	_dataFormatButton(cell, row) {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" href={'/User/' + this.props.cognitoid + '/Address/' + row.addressid + '/'} target="_blank" >{/*onClick={() => this.props.onClickDetails(row)}*/}
					詳細
				</Button>
			</div>
		);
	}
	render() {
		if(this.props.data.length === 0){
			return (
				<Alert variant="danger">
					対象のデータが見つかりません。
				</Alert>
			);
		}
		else{
		
			//表示用に整形
			let TableData = [];
			for(let item of this.props.data){
				const addressid   = item.addressid;
				const name        = item.o_name_1 + ' ' + item.o_name_2;
				const zip         = item.o_zip_1 + '-' + item.o_zip_2;
				const pref        = item.o_pref;
				const addr        = item.o_addr_1 + item.o_addr_2 + item.o_addr_3;
				const tel         = item.o_tel_1 + '-' + item.o_tel_2 + '-' + item.o_tel_3;
				const o_com_name  = item.o_com_name;
				const date_add    = item.date_add;
				const RowData = {
					selectid     : addressid,
					addressid    : addressid,
					name         : name,
					zip          : zip,
					pref         : pref,
					addr         : addr,
					com_name     : o_com_name,
					tel          : tel,
					date_add     : date_add,
				};
				TableData.push(RowData);
			}

			const windowSize = window.innerWidth;

			const columns = [
				{
					dataField: 'selectid',
					text: '詳細',
					sort: false,
					formatter: this._dataFormatButton,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'name',
					text: '名前',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'zip',
					text: '郵便番号',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'pref',
					text: '都道府県',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'addr',
					text: '住所',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'com_name',
					text: '会社名',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'tel',
					text: '電話番号',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'date_add',
					text: '作成日',
					sort: true,
					formatter: this._dataFormatDate,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
			];

			const defaultSorted = [
				{
					dataField: 'date_add',
					order: 'desc'
				}
			];
			
			const selectRow = {
				mode         : 'checkbox',
				clickToSelect: false,
				onSelect     : this._selectRows,
				onSelectAll  : this._selectAllRows,
			};
			
			const options = {
				sizePerPageList: [
					{ text: '10', value: 10},
					{ text: '50', value: 50},
					{ text: '100', value: 100},
					{ text: 'All', value: TableData.length},
				],
			};
			
			return (
				<BootstrapTable bootstrap4 keyField='addressid' data={ TableData } columns={ columns } bordered={ false } defaultSorted={ defaultSorted } selectRow={ selectRow } pagination={ paginationFactory(options) } />
			);

		}
	}
}

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading   : true,
			ErrMsg      : '',
			viewData    : [],
			csvData     : [],
			defaultData : [],
			selectIDs   : [],
			cognitoid   : '',
			name        : '',
		}
		this._reload              = this._reload.bind(this);
		this._getData             = this._getData.bind(this);
		this.onClickDetails       = this.onClickDetails.bind(this);
		this.handleDismiss        = this.handleDismiss.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleSearch         = this.handleSearch.bind(this);
		this.setSelectIDs         = this.setSelectIDs.bind(this);
	}
	_reload() {
		//子ウィンドウからの呼び出し用
		this.handleReload();
	}
	async _getData() {
		
		//処理
		try{

			//IDの取得
			const cognitoid = this.props.match.params.cognitoid;
			if(!cognitoid){
					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
			}

			//データ取得
			const ProfileQuery = `query getProfile($cognitoid: String!) {
				getProfile(cognitoid: $cognitoid) {
					name_1
					name_2
				}
			}`;
			const ProfileData = { cognitoid: cognitoid };
			const refProfile = await API.graphql(graphqlOperation(ProfileQuery, ProfileData));
			//console.log(ref);

			//データが空かどうか
			if(refProfile.data.getProfile === null){
				this.setState({
					isLoading   : false,
					isNotFound  : true,
				});
				return(0);
			}
			
			//名前取得
			const name = refProfile.data.getProfile.name_1 + ' ' + refProfile.data.getProfile.name_2;
			
			//ユーザ情報の取得
			const Query = `query queryAddress($cognitoid: String!) {
				queryAddress(cognitoid: $cognitoid) {
					items {
						cognitoid
						addressid
						o_name_1
						o_name_2
						o_name_furi_1
						o_name_furi_2
						o_zip_1
						o_zip_2
						o_pref
						o_addr_1
						o_addr_2
						o_addr_3
						o_tel_1
						o_tel_2
						o_tel_3
						o_com_name
						date_add
						date_edit
					}
					nextToken
				}
			}`;
			const Data = {
				cognitoid: cognitoid,
			};
			let ref = await API.graphql(graphqlOperation(Query, Data));
			let Items = ref.data.queryAddress.items;
			
			
			//ユーザ情報の取得（未取得の続きデータがあった場合の処理）
			while (ref.data.queryAddress.nextToken) {
				const Query = `query queryAddress($cognitoid: String!, $nextToken: String) {
					queryAddress(cognitoid: $cognitoid, nextToken: $nextToken) {
						items {
							cognitoid
							addressid
							o_name_1
							o_name_2
							o_name_furi_1
							o_name_furi_2
							o_zip_1
							o_zip_2
							o_pref
							o_addr_1
							o_addr_2
							o_addr_3
							o_tel_1
							o_tel_2
							o_tel_3
							o_com_name
							date_add
							date_edit
						}
						nextToken
					}
				}`;
				const Data = {
					nextToken: ref.data.queryAddress.nextToken,
				};
				//console.log(ref.data.queryAddress.nextToken);
				ref = await API.graphql(graphqlOperation(Query, Data));
				Items = Items.concat(ref.data.queryAddress.items);
			}

			//データの判別
			if(Items.length === 0){
				//データがなかった時
				this.setState({
					isLoading : false,
					ErrMsg    : 'データがありません。',
				});
				return(0);
			}

			//'null'文字がある場合は''に変換
			Items.some((item, index) => Object.keys(item).some(key => { if(item[key] === 'null'){ Items[index][key] = '' } return false; }));
			
			//データコピー
			const viewData = [];
			for(let row of Items){
				viewData.push(row);
			}
			
			//データコピー
			const defaultData = [];
			for(let row of Items){
				defaultData.push(row);
			}
			
			//console.log(viewData);
			
			//データの保存
			this.setState({
				isLoading   : false,
				viewData    : viewData,
				defaultData : defaultData,
				cognitoid   : cognitoid,
				name        : name,
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				isLoading  : false,
				ErrMsg     : err_msg,
			});
		}
	}
	onClickDetails(row){
		if(row.addressid){
			this.props.history.push('/User/' + this.state.cognitoid + '/Address/' + row.addressid + '/');
		}
	}
	handleDismiss() {
		this.setState({ ErrMsg: '' });
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
			viewData    : [],
			csvData     : [],
			defaultData : [],
		});
		this._getData();
	}
	handleSearch(e){

		//Submitをキャンセル
		e.preventDefault();
		e.stopPropagation();
	
		//検索文字列と対象データを取得
		const key   = e.target.value;
		const defaultData = this.state.defaultData;
		
		//検索文字及び対象データがない場合はリセット
		if(key === '' || defaultData.length === 0){
			this.setState({ viewData: defaultData});
			return(0);
		}
		
		//検索文字オブジェクト
		const matchString = new RegExp(key);
		
		//検索
		const viewData = defaultData.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		//表示データ更新
		this.setState({ viewData: viewData});
	}
	setSelectIDs (selectIDs){
	
		//デフォルトのデータを取得
		const defaultData = this.state.defaultData;

		//デフォルトのデータ内から選択されているIDのデータのみ抽出
		const csvData = defaultData.filter(item => selectIDs.indexOf(item.addressid) !== -1);
		
		//選択しているIDとCSV用データを保存
		this.setState({
			selectIDs: selectIDs,
			csvData  : csvData,
		});
	}
	async componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleDismiss} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleDismiss}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Form onSubmit={e => this.handleSearch(e)}>
						<Row style={{ marginTop: 20 }}>
							<Col sm={4}>
								<Form.Group controlId="search">
								        <Form.Control name="search" type="text" placeholder="検索" size="lg" onChange={e => this.handleSearch(e)}/>
								</Form.Group>
							</Col>
							<Col sm={4}>
								<Badge pill variant="secondary">
									<h5>　{ this.state.viewData.length.toLocaleString() }件　</h5>
								</Badge>
							</Col>
						</Row>
					</Form>

					<Row style={{ marginBottom: 20 }}>
						<Col sm={12} className="text-right">
							<Button variant="danger" onClick={()=>{ this.props.history.push('/User/' + this.state.cognitoid + '/Address/New/'); }}>
								新規登録
							</Button>　
							<Button variant="dark" onClick={ this.handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<MyTable data={this.state.viewData} setSelectIDs={this.setSelectIDs} onClickDetails={this.onClickDetails} {...this.state}/>
						</Col>
					</Row>
					<hr/>
					<CSVLink data={this.state.csvData} filename={"user.csv"} className="btn btn-secondary">CSVのダウンロード</CSVLink>
				</Container>
			);
		}
	}
}

export default withRouter(Default);

