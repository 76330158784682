import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Alert,
	Modal,
	Container,
	Row,
	Col,
	Button,
	Image,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';


class MyTable extends React.Component {
	constructor(props) {
		super(props);
		this._dataFormatLabel        = this._dataFormatLabel.bind(this);
		this._dataFormatDate         = this._dataFormatDate.bind(this);
	}
	_dataFormatLabel(cell, row) {
		return (
			<div className="text-center" style={{margin: "10px"}}>
				<Badge variant={cell ? 'danger' : 'success'}>
					{cell ? '失敗' : '成功'}
				</Badge>　
			</div>
		);
	}
	_dataFormatDate(cell, row) {

		//日付の整形
		const dateObj     = new Date(cell);
		const year        = dateObj.getFullYear().toString();
		const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day         = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second      = ('00' + dateObj.getSeconds().toString()).slice(-2);

		//出力
		return (
			<div>
				{year}年{month}月{day}日{hour}時{minute}分{second}秒
			</div>
		);
	}
	render() {
		if(this.props.data.length === 0){
			return (
				<Alert variant="danger">
					対象のデータが見つかりません。
				</Alert>
			);
		}
		else{
		
			//表示用に整形
			let TableData = [];
			for(let item of this.props.data){

				const date_add   = item.date_add;
				const csv_falg   = item.csv_falg === 'order' ? '売上データ' : item.csv_falg;
				const csv_file   = item.csv_file;
				const csv_num    = item.csv_num;
				const log_text   = item.log_text;
				const error_flag = item.error_flag;
				const error_text = item.error_text;
				const RowData = {
					date_add   : date_add,
					csv_falg   : csv_falg,
					csv_file   : csv_file,
					csv_num    : csv_num.toLocaleString() + '件',
					log_text   : log_text,
					error_flag : error_flag,
					error_text : error_text,
				};
				
				TableData.push(RowData);
			}
			
			const columns = [
				{
					dataField: 'date_add',
					text: '日時',
					sort: true,
					formatter: this._dataFormatDate,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'error_flag',
					text: '判定',
					sort: true,
					formatter: this._dataFormatLabel,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'csv_falg',
					text: 'CSV種別',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'csv_file',
					text: '取り込みファイル名',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'csv_num',
					text: '行数',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					align: 'right',
				},
				{
					dataField: 'log_text',
					text: 'ログ内容',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'error_text',
					text: 'エラー内容',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
			];

			const defaultSorted = [
				{
					dataField: 'date_add',
					order: 'desc'
				}
			];
			
			const options = {
				sizePerPageList: [
					{ text: '10', value: 10},
					{ text: '50', value: 50},
					{ text: '100', value: 100},
					{ text: 'All', value: TableData.length},
				],
			};
			
			return (
				<BootstrapTable bootstrap4 keyField='date_add' data={ TableData } columns={ columns } bordered={ false } defaultSorted={ defaultSorted } pagination={ paginationFactory(options) } />
			);

		}
	}
}

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading   : true,
			ErrMsg      : '',
			dateTime    : '',
			viewData    : [],
		}
		this._reload              = this._reload.bind(this);
		this._getData             = this._getData.bind(this);
		this.handleDismiss        = this.handleDismiss.bind(this);
		this.handleReload         = this.handleReload.bind(this);
	}
	_reload() {
		//子ウィンドウからの呼び出し用
		this.handleReload();
	}
	async _getData() {
		
		//処理
		try{

			//商品情報の取得
			const Query = `query scanCSVLog {
				scanCSVLog {
					items {
						date_add
						csv_falg
						csv_file
						csv_num
						log_text
						error_flag
						error_text
					}
					nextToken
				}
			}`;
			let ref = await API.graphql(graphqlOperation(Query));
			let Items = ref.data.scanCSVLog.items;
			console.log(Items);
			
			//商品情報の取得（未取得の続きデータがあった場合の処理）
			while (ref.data.scanCSVLog.nextToken) {
				const Query = `query scanCSVLog($nextToken: String) {
					scanCSVLog(nextToken: $nextToken) {
						items {
							date_add
							csv_falg
							csv_file
							csv_num
							log_text
							error_flag
							error_text
						}
						nextToken
					}
				}`;
				const Data = {
					nextToken: ref.data.scanCSVLog.nextToken,
				};
				//console.log(ref.data.scanCSVLog.nextToken);
				ref = await API.graphql(graphqlOperation(Query, Data));
				Items = Items.concat(ref.data.scanCSVLog.items);
			}

			//データの判別
			if(Items.length === 0){
				//データがなかった時
				this.setState({
					isLoading : false,
					ErrMsg    : 'データがありません。',
				});
			}
			else{

				//'null'文字がある場合は''に変換
				//Items.some((item, index) => Object.keys(item).some(key => { if(item[key] === 'null'){ Items[index][key] = '' } return false; }));
				
				//データコピー
				const viewData = [];
				for(let row of Items){
					viewData.push(row);
				}
				console.log(1);
				//データの保存
				this.setState({
					isLoading   : false,
					viewData    : viewData,
				});
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				isLoading  : false,
				ErrMsg     : err_msg,
			});
		}
	}
	handleDismiss() {
		this.setState({ ErrMsg: '' });
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
			viewData    : [],
		});
		this._getData();
	}
	async componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleDismiss} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleDismiss}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					<Row style={{ marginBottom: 20 }}>
						<Col sm={6}>
						        <Button variant="secondary" type="button" onClick={()=>{ this.props.history.push('/CSVUpload/'); }} >戻る</Button>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ this.handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<MyTable data={this.state.viewData}  />
						</Col>
					</Row>
				</Container>
			);
		}
	}
}

export default withRouter(Default);

