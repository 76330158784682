import React from 'react';
import Encoding from 'encoding-japanese';


class CSVDownload extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			href    : '',
		};
		this._click = this._click.bind(this);
	}
	_click() {
		if (typeof this.props.onClick === 'function') {
			this.props.onClick();
		}
	}
	componentWillReceiveProps(nextProps){
		
		try{
			const data = nextProps.data;
			const skipHeader = nextProps.skipHeader ? true : false;
			if(data.length !== 0){
				
				//ヘッダーの作成
				let header = [];
				for (const key in data[0]) {
					header.push(key);
				}
				
				//データ作成
				let csvText = '';
				let i = 0;
				for(const hash of data){
					i++;
					
					//ヘッダー作成
					if(i===1 && skipHeader === false){
						for (const key of header) {
							csvText = csvText + key + ',';
						}
						csvText = csvText.slice(0, -1) + '\r\n';
					}

					//内容作成
					for (const key of header) {
						const value = hash[key].toString().replace(/\n/g,'').replace(/\r/g,'');
						csvText = csvText + value + ',';
					}
					csvText = csvText.slice(0, -1) + '\r\n';
				}
				//console.log(csvText);
				const unicodeList = [];
				for (let i = 0; i < csvText.length; i += 1) {
					unicodeList.push(csvText.charCodeAt(i));
				}
				const shiftJisCodeList = Encoding.convert(unicodeList, 'sjis', 'unicode');
				const uInt8List = new Uint8Array(shiftJisCodeList);
				const blob = new Blob([ uInt8List ], { "type" : "text/plain" });
				this.setState({ href : window.URL.createObjectURL(blob) });
			}
		}
		catch(err){
			console.log(err);
		}
	}
	render() {
		return (
			<a
				target="_self"
				className={this.props.className}
				download={this.props.filename}
				href={this.state.href}
				onClick={this._click}
			>
				{this.props.children}
			</a>
		);
	}
}

export default CSVDownload;

