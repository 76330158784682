import {
	Alert,
	Container,
	Row,
	Col,
} from 'react-bootstrap';
import React from 'react';

class NotFound extends React.Component {
	render() {
		return (
			<Container style={{ marginTop: 100, marginBottom: 200 }}>
				<Row>
				<Col sm={{ span: 8, offset: 2 }}>
					<div>Not Found Error</div>
					<Alert variant="danger">
						ページが見つかりません。<br/>
					</Alert>
				</Col>
				</Row>
			</Container>
		);
	}
}

export default NotFound;

