import React from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Modal,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { AuditLog } from '../../Objects/AuditLog';

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isSuccess      : false,
			isExecuteESFix : false,
			isLockESFix    : false,
			isLockForm     : false,
			formObj        : null,
			ErrMsg         : '',
		}
		this.handleReload         = this.handleReload.bind(this);
		this.handleSbumit         = this.handleSbumit.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
		this.updateCSV            = this.updateCSV.bind(this);
		this.excuteESFix          = this.excuteESFix.bind(this);
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
	}
	handleSbumit(event) {
		event.preventDefault();
		event.stopPropagation();

	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleClose() {
		this.setState({
			isSuccess      : false,
			isExecuteESFix : false,
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	lockFormClose(){
		//
	}
	//画像ファイルのアップロード
	async updateCSV(fileFlag, event) {
		console.log(event);
		try{
			//画面ロックの有効化
			this.setState({
				isLockForm : true,
			});
			
			//画像データ
			const file = event.target.files[0];
			
			//チェック
			if(!file){
				this.setState({
					ErrMsg     : '※ファイルが見つかりません。<br/>',
					isLockForm : false,
				 });
				return(0);
			}
			
			//ファイル名の生成
			const dateObj     = new Date();
			const year        = dateObj.getFullYear().toString();
			const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day         = ('00' + dateObj.getDate().toString()).slice(-2);
			var fromObj       = new Date(year + '/' + month + '/' + day + ' 00:00:00');
			var time          = dateObj.getTime() - fromObj.getTime();
			var sec           = ('00000' + Math.floor(time / 1000).toString()).slice(-5);
			var ms            = ('000' + time.toString()).slice(-3);
			const fileName    = fileFlag + '-' + year + month + day + '-' + sec + ms + '.csv';
			
			//ストレージを定義
			Storage.configure({ AWSS3: { bucket: 'mindwavestore.admin', region: 'ap-northeast-1', level: 'public' } });

			//ファイルをアップロード
			await Storage.put('CSVUpload/' + fileName, file);
			
			//フォームリセット
			document.form.reset();

			//鑑査ログ
			await AuditLog('csvupload', 'create', fileName, null, 'CSVをアップロードしました。');

			//更新
			this.setState({
				isSuccess  : true,
				isLockForm : false,
			});

		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}
	}
	async excuteESFix() {
		try{

			this.setState({
				isExecuteESFix : false,
				isLockESFix    : true,
			 });

			const ref = await API.graphql(graphqlOperation(`mutation executeESFix {
				executeESFix {
					result
					error
				}
			}`));
			console.log(ref);

		}
		catch(err){
			console.log(err);
		}
	
	}
	componentDidMount () {
		this.setState({
			isLoading   : false,
		});
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>
							アップロードしました。<br/>
							データ取り込みの進捗及び結果は、ログ一覧からご確認ください。
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isExecuteESFix} onHide={this.handleClose} centered>
						<Modal.Body>
							処理を行いますか？<br/>
							処理完了までに最大15分ほどかかります。<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.excuteESFix}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データをアップロード中です。
						</Modal.Body>
					</Modal>
					
					<Row>
						<Col sm={12}>
							<h4>CSVアップロード</h4>　
						</Col>
					</Row>

					<Form onSubmit={e => this.handleSbumit(e)} name="form">
					<Row style={{ marginTop: 20 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="danger" type="button" block size="lg" onClick={()=>{ this.props.history.push('/CSVUpload/Log/'); }} disabled={this.state.isLockForm} >ログを見る</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 20 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button disabled={this.state.isLockForm || this.state.isLockESFix} onClick={()=>{ this.setState({ isExecuteESFix : true }); }}  variant="primary" size="lg" block>
							        商品情報のデータベース間の同期を行う（15分ぐらいかかります）
						        </Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 30 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <label htmlFor="order_upload" className="btn btn-dark btn-lg btn-block">
						        売上データをアップロードする
						        <Form.Control type="file" accept=".csv" disabled={this.state.isLockForm} onChange={(e) => this.updateCSV('order', e)} id="order_upload" style={{display:'none'}}/>
						        </label>
						</Col>
					</Row>
					<Row style={{ marginTop: 20 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <label htmlFor="delivery_upload" className="btn btn-dark btn-lg btn-block">
						        伝票番号をアップロードする
						        <Form.Control type="file" accept=".csv" disabled={this.state.isLockForm} onChange={(e) => this.updateCSV('delivery', e)} id="delivery_upload" style={{display:'none'}}/>
						        </label>
						</Col>
					</Row>
					<Row style={{ marginTop: 20 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <label htmlFor="item_upload" className="btn btn-dark btn-lg btn-block">
						        商品情報をアップロードする
						        <Form.Control type="file" accept=".csv" disabled={this.state.isLockForm} onChange={(e) => this.updateCSV('item', e)} id="item_upload" style={{display:'none'}}/>
						        </label>
						</Col>
					</Row>
					<Row style={{ marginTop: 20 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <label htmlFor="user_upload" className="btn btn-dark btn-lg btn-block">
						        会員情報をアップロードする
						        <Form.Control type="file" accept=".csv" disabled={this.state.isLockForm} onChange={(e) => this.updateCSV('user', e)} id="user_upload" style={{display:'none'}}/>
						        </label>
						</Col>
					</Row>
					<Row style={{ marginTop: 20 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <label htmlFor="category_upload" className="btn btn-dark btn-lg btn-block">
						        カテゴリ情報をアップロードする
						        <Form.Control type="file" accept=".csv" disabled={this.state.isLockForm} onChange={(e) => this.updateCSV('category', e)} id="category_upload" style={{display:'none'}}/>
						        </label>
						</Col>
					</Row>
					</Form>
					

				</Container>
			);
		}
	}
}

export default withRouter(Default);


