import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Modal,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import NotFound from '../../Objects/NotFound';
import { AuditLog } from '../../Objects/AuditLog';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isNotFound     : false,
			isValidated    : false,
			isSuccess      : false,
			isDeleted      : false,
			isShowUpdate   : false,
			isShowDelete   : false,
			isLockForm     : false,
			formObj        : null,
			ErrMsg         : '',
			tmpl_name      : '',
			tmpl_sort      : '',
			tmpl_from      : '',
			tmpl_cc        : '',
			tmpl_bcc       : '',
			tmpl_subject   : '',
			tmpl_msg       : '',
			date_add       : '',
			date_edit      : '',
		}
		this.handleReload     = this.handleReload.bind(this);
		this.updateSubmit     = this.updateSubmit.bind(this);
		this.deleteSubmit     = this.deleteSubmit.bind(this);
		this.handleUpdate     = this.handleUpdate.bind(this);
		this.handleDelete     = this.handleDelete.bind(this);
		this.handleClose      = this.handleClose.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
		this.lockFormClose    = this.lockFormClose.bind(this);
		this._getData         = this._getData.bind(this);
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	async _getData() {

		try{
			//IDの取得
			const tmpl_name = this.props.match.params.tmpl_name;
			if(!tmpl_name){
					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
			}

			//データ取得
			const Query = `query getMailTmpl($tmpl_name: String!) {
				getMailTmpl(tmpl_name: $tmpl_name) {
					tmpl_sort
					tmpl_from
					tmpl_cc
					tmpl_bcc
					tmpl_subject
					tmpl_msg
					date_add
					date_edit

				}
			}`;
			const Data = {
				tmpl_name: tmpl_name,
			};
			const ref = await API.graphql(graphqlOperation(Query, Data));
			//console.log(ref);
			
			//データが空かどうか
			if(ref.data.getMailTmpl === null){
				this.setState({
					isLoading   : false,
					isNotFound  : true,
				});
				return(0);
			}
			
			const tmpl_sort       = ref.data.getMailTmpl.tmpl_sort;
			const tmpl_from       = ref.data.getMailTmpl.tmpl_from;
			const tmpl_cc         = ref.data.getMailTmpl.tmpl_cc  === 'null' ? '' : ref.data.getMailTmpl.tmpl_cc;
			const tmpl_bcc        = ref.data.getMailTmpl.tmpl_bcc === 'null' ? '' : ref.data.getMailTmpl.tmpl_bcc;
			const tmpl_subject    = ref.data.getMailTmpl.tmpl_subject;
			const tmpl_msg        = ref.data.getMailTmpl.tmpl_msg;
			
			//日付
			const date_add         = new Date(ref.data.getMailTmpl.date_add);
			const date_add_year    = date_add.getFullYear().toString();
			const date_add_month   = ('00' + (date_add.getMonth()+1).toString()).slice(-2);
			const date_add_day     = ('00' + date_add.getDate().toString()).slice(-2);
			const date_add_hour    = ('00' + date_add.getHours().toString()).slice(-2);
			const date_add_minute  = ('00' + date_add.getMinutes().toString()).slice(-2);
			const date_add_second  = ('00' + date_add.getSeconds().toString()).slice(-2);
			const date_edit        = new Date(ref.data.getMailTmpl.date_edit);
			const date_edit_year   = date_edit.getFullYear().toString();
			const date_edit_month  = ('00' + (date_edit.getMonth()+1).toString()).slice(-2);
			const date_edit_day    = ('00' + date_edit.getDate().toString()).slice(-2);
			const date_edit_hour   = ('00' + date_edit.getHours().toString()).slice(-2);
			const date_edit_minute = ('00' + date_edit.getMinutes().toString()).slice(-2);
			const date_edit_second = ('00' + date_edit.getSeconds().toString()).slice(-2);
			
			this.setState({
				isLoading     : false,
				tmpl_name     : tmpl_name,
				tmpl_sort     : tmpl_sort,
				tmpl_from     : tmpl_from,
				tmpl_cc       : tmpl_cc,
				tmpl_bcc      : tmpl_bcc,
				tmpl_subject  : tmpl_subject,
				tmpl_msg      : tmpl_msg,
				date_add      : date_add_year + '年' + date_add_month + '月' + date_add_day + '日' + date_add_hour + '時' + date_add_minute + '分' + date_add_second + '秒',
				date_edit     : date_edit_year + '年' + date_edit_month + '月' + date_edit_day + '日' + date_edit_hour + '時' + date_edit_minute + '分' + date_edit_second + '秒',
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLoading  : false,
				isLockForm : false,
			 });
			return(0);
		}
	}
	async updateSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowUpdate : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Stateから取得する更新項目
			const tmpl_name    = this.state.tmpl_name;
			
			//Formから直接取得する更新項目
			const tmpl_sort    = formObj.tmpl_sort.value;
			const tmpl_from    = formObj.tmpl_from.value;
			const tmpl_cc      = formObj.tmpl_cc.value;
			const tmpl_bcc     = formObj.tmpl_bcc.value;
			const tmpl_subject = formObj.tmpl_subject.value;
			const tmpl_msg     = formObj.tmpl_msg.value;

			//日付処理
		        const date          = new Date();
		        const isoDate       = date.toISOString();

			//更新登録
			const Query = `mutation updateMailTmpl($data: MailTmplInput!) {
				updateMailTmpl(input: $data) {
					tmpl_name
				}
			}`;
			const Data = {
				data : {
					tmpl_name     : tmpl_name,
					tmpl_sort     : tmpl_sort ? tmpl_sort : 0,
					tmpl_from     : tmpl_from,
					tmpl_cc       : tmpl_cc  ? tmpl_cc : 'null',
					tmpl_bcc      : tmpl_bcc ? tmpl_bcc : 'null',
					tmpl_subject  : tmpl_subject,
					tmpl_msg      : tmpl_msg,
					date_edit     : isoDate,
				}
			};
			await API.graphql(graphqlOperation(Query, Data));

			//鑑査ログ
			await AuditLog('mailtmpl', 'update', tmpl_name, Data, 'メールテンプレートを変更しました。');
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	async deleteSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowDelete : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Stateから取得する更新項目
			const tmpl_name = this.state.tmpl_name;
			
			//プロフィールの更新登録
			const Query = `mutation deleteMailTmpl($data: MailTmplInput!) {
				deleteMailTmpl(input: $data) {
					tmpl_name
				}
			}`;
			const Data = {
				data : {
					tmpl_name   : tmpl_name,
				}
			};
			await API.graphql(graphqlOperation(Query, Data));

			//鑑査ログ
			await AuditLog('mailtmpl', 'delete', tmpl_name, Data, 'メールテンプレートを削除しました。');
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isDeleted   : true,
			isLockForm  : false,
		});
	}
	handleClose() {
		this.setState({
			isShowUpdate : false,
			isShowDelete : false,
			isSuccess    : false,
			isValidated  : false,
			isLockForm   : false,
			ErrMsg     : '',
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleUpdate(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowUpdate : true,
			formObj      : form,
		});
	}
	handleDelete(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowDelete : true,
			formObj      : form,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else if(this.state.isNotFound){
			return (
				<NotFound />
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>更新しました。</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isDeleted} onHide={()=>{ this.props.history.push('/MailTmpl/'); }} centered>
						<Modal.Body>削除しました。</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={()=>{ this.props.history.push('/MailTmpl/'); }}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowUpdate} onHide={this.handleClose} centered>
						<Modal.Body>
							本当に更新しますか？<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.updateSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowDelete} onHide={this.handleClose} centered>
						<Modal.Body>本当に削除しますか？</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.deleteSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleUpdate(e)}>
					<Row>
						<Col sm={4}>
							<p><big>メール送信テンプレート情報</big></p>
						</Col>
						<Col sm={7} className="text-right">
							<Button variant="dark" onClick={ this.handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							登録日時
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="date_add">
							        <Form.Control name="date_add" type="text" defaultValue={this.state.date_add} size="lg" readOnly />
								<Form.Control.Feedback />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							更新日時
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="date_edit">
							        <Form.Control name="date_edit" type="text" defaultValue={this.state.date_edit} size="lg" readOnly />
								<Form.Control.Feedback />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							テンプレート名　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="tmpl_name">
							        <Form.Control name="tmpl_name" type="text" defaultValue={this.state.tmpl_name} placeholder="テンプレート名" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} readOnly />
								<Form.Control.Feedback type="invalid">
									「テンプレート名」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							並び順 順位
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="tmpl_sort">
							        <Form.Control name="tmpl_sort" type="text" defaultValue={this.state.tmpl_sort} placeholder="並び順 順位" size="lg" pattern="\d{1,3}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「並び順 順位」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							差出人アドレス　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="tmpl_from">
							        <Form.Control name="tmpl_from" type="email" defaultValue={this.state.tmpl_from} placeholder="差出人アドレス" size="lg" required disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「差出人アドレス」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							CCアドレス
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="tmpl_cc">
							        <Form.Control name="tmpl_cc" type="email" defaultValue={this.state.tmpl_cc} placeholder="CCアドレス" size="lg" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「CCアドレス」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							BCCアドレス
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="tmpl_bcc">
							        <Form.Control name="tmpl_bcc" type="email" defaultValue={this.state.tmpl_bcc} placeholder="BCCアドレス" size="lg" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「BCCアドレス」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メール件名　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="tmpl_subject">
							        <Form.Control name="tmpl_subject" type="text" defaultValue={this.state.tmpl_subject} placeholder="メール件名" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「メール件名」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メール本文　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="tmpl_msg">
							        <Form.Control name="tmpl_msg" as="textarea" defaultValue={this.state.tmpl_msg} rows="15" placeholder="" size="lg" required pattern=".{1,1000}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「メール本文」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props.history.push('/MailTmpl/'); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					</Form>

					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleDelete(e)}>
					<Row style={{ marginTop: 100 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="danger" type="submit" block size="lg" disabled={this.state.isLockForm} >削除する</Button>
						</Col>
					</Row>
					</Form>

				</Container>
			);
		}
	}
}

export default withRouter(Default);


