import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Modal,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import NotFound from '../../Objects/NotFound';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { AuditLog } from '../../Objects/AuditLog';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isNotFound     : false,
			isValidated    : false,
			isDeleted      : false,
			isShowUpdate   : false,
			isShowDelete   : false,
			isLockForm     : false,
			showAddress    : false,
			formObj        : null,
			ErrMsg         : '',
			magazineno     : '',
			send_count     : '',
			messageid_text : '',
			from           : '',
			subject        : '',
			msg_text       : '',
			msg_html       : '',
			status         : '',
			date_add       : '',
			date_edit      : '',
			status_variant : '',
		}
		this.handleReload     = this.handleReload.bind(this);
		this.deleteSubmit     = this.deleteSubmit.bind(this);
		this.handleDelete     = this.handleDelete.bind(this);
		this.handleClose      = this.handleClose.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
		this.lockFormClose    = this.lockFormClose.bind(this);
		this._getData         = this._getData.bind(this);
		this._getAddress      = this._getAddress.bind(this);
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	async _getData() {

		try{
			//IDの取得
			const magazineno = this.props.match.params.magazineno;
			if(!magazineno){
					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
			}

			//データ取得
			const Query = `query getMailMagazine($magazineno: String!) {
				getMailMagazine(magazineno: $magazineno) {
					reserv_date
					send_count
					messageid
					from
					subject
					msg_text
					msg_html
					status
					date_add
					date_edit

				}
			}`;
			const Data = {
				magazineno: magazineno,
			};
			const ref = await API.graphql(graphqlOperation(Query, Data));
			//console.log(ref);
			
			//データが空かどうか
			if(ref.data.getMailMagazine === null){
				this.setState({
					isLoading   : false,
					isNotFound  : true,
				});
				return(0);
			}
			
			const send_count  = ref.data.getMailMagazine.send_count;
			const messageid   = ref.data.getMailMagazine.messageid;
			const from        = ref.data.getMailMagazine.from;
			const subject     = ref.data.getMailMagazine.subject;
			const msg_text    = ref.data.getMailMagazine.msg_text;
			const msg_html    = ref.data.getMailMagazine.msg_html === 'null' ? '' : ref.data.getMailMagazine.msg_html;
			const status      = ref.data.getMailMagazine.status;
			
			
			//配列
			let messageid_text = '';
			if(messageid){
				messageid_text = messageid.join('\n');
			}
			
			//ステータス
			let status_variant = 'secondary';
			
			if(status === 'delivered'){
				status_variant = 'success';
			}
			else if(status === 'runnig'){
				status_variant = 'primary';
			}
			else if(status === 'reserved'){
				status_variant = 'info';
			}
			else if(status === 'failed'){
				status_variant = 'danger';
			}
			
			//日付
			const reserv_date        = new Date(ref.data.getMailMagazine.reserv_date);
			const reserv_date_year   = reserv_date.getFullYear().toString();
			const reserv_date_month  = ('00' + (reserv_date.getMonth()+1).toString()).slice(-2);
			const reserv_date_day    = ('00' + reserv_date.getDate().toString()).slice(-2);
			const reserv_date_hour   = ('00' + reserv_date.getHours().toString()).slice(-2);
			const reserv_date_minute = ('00' + reserv_date.getMinutes().toString()).slice(-2);
			const reserv_date_second = ('00' + reserv_date.getSeconds().toString()).slice(-2);
			const date_add         = new Date(ref.data.getMailMagazine.date_add);
			const date_add_year    = date_add.getFullYear().toString();
			const date_add_month   = ('00' + (date_add.getMonth()+1).toString()).slice(-2);
			const date_add_day     = ('00' + date_add.getDate().toString()).slice(-2);
			const date_add_hour    = ('00' + date_add.getHours().toString()).slice(-2);
			const date_add_minute  = ('00' + date_add.getMinutes().toString()).slice(-2);
			const date_add_second  = ('00' + date_add.getSeconds().toString()).slice(-2);
			const date_edit        = new Date(ref.data.getMailMagazine.date_edit);
			const date_edit_year   = date_edit.getFullYear().toString();
			const date_edit_month  = ('00' + (date_edit.getMonth()+1).toString()).slice(-2);
			const date_edit_day    = ('00' + date_edit.getDate().toString()).slice(-2);
			const date_edit_hour   = ('00' + date_edit.getHours().toString()).slice(-2);
			const date_edit_minute = ('00' + date_edit.getMinutes().toString()).slice(-2);
			const date_edit_second = ('00' + date_edit.getSeconds().toString()).slice(-2);
			
			this.setState({
				isLoading      : false,
				magazineno     : magazineno,
				send_count     : send_count,
				messageid_text : messageid_text,
				from           : from,
				subject        : subject,
				msg_text       : msg_text,
				msg_html       : msg_html,
				status         : status,
				status_variant : status_variant,
				reserv_date    : reserv_date_year + '年' + reserv_date_month + '月' + reserv_date_day + '日' + reserv_date_hour + '時' + reserv_date_minute + '分' + reserv_date_second + '秒',
				date_add       : date_add_year + '年' + date_add_month + '月' + date_add_day + '日' + date_add_hour + '時' + date_add_minute + '分' + date_add_second + '秒',
				date_edit      : date_edit_year + '年' + date_edit_month + '月' + date_edit_day + '日' + date_edit_hour + '時' + date_edit_minute + '分' + date_edit_second + '秒',
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLoading  : false,
				isLockForm : false,
			 });
			return(0);
		}
	}
	async _getAddress() {

		try{
			//データの再取得
			this.setState({
				isLoading   : true,
			});
			
			const magazineno = this.state.magazineno;
			
			//console.log(999);
			//ユーザ件数の取得
			const CountQuery = `query queryMailMagazineAddressCount($magazineno: String!) {
				queryMailMagazineAddressCount(magazineno: $magazineno) {
					scannedCount
					nextToken
				}
			}`;
			const CountData = {
				magazineno : magazineno,
			};
			let refCount     = await API.graphql(graphqlOperation(CountQuery, CountData));
			let AddressCount = refCount.data.queryMailMagazineAddressCount.scannedCount;
			
			//進捗情報
			let loadingPer   = 1;
			if(refCount.data.queryMailMagazineAddressCount.nextToken){
				this.setState({
					loadingPer : loadingPer,
				});
			}

			//ユーザ件数の取得（未取得の続きデータがあった場合の処理）
			while (refCount.data.queryMailMagazineAddressCount.nextToken) {
				const CountQuery = `query queryMailMagazineAddressCount($magazineno: String!, $nextToken: String) {
					queryMailMagazineAddressCount(magazineno: $magazineno, nextToken: $nextToken) {
						scannedCount
						nextToken
					}
				}`;
				const CountData = {
					magazineno : magazineno,
					nextToken: refCount.data.queryMailMagazineAddressCount.nextToken,
				};
				refCount = await API.graphql(graphqlOperation(CountQuery, CountData));
				AddressCount = AddressCount + refCount.data.queryMailMagazineAddressCount.scannedCount;
			}
			 
			//ユーザ情報の取得
			const AddressQuery = `query queryMailMagazineAddress($magazineno: String!) {
				queryMailMagazineAddress(magazineno: $magazineno) {
					items {
						email
						status
						date_edit
					}
					nextToken
				}
			}`;
			const QueryData = {
				magazineno : magazineno,
			};
			let refAddress = await API.graphql(graphqlOperation(AddressQuery, QueryData));
			let AddressData = refAddress.data.queryMailMagazineAddress.items;
			
			//進捗情報
			if(refAddress.data.queryMailMagazineAddress.nextToken){
				const perNow = Math.floor(AddressData.length / AddressCount * 100);
				if(loadingPer < perNow){
					this.setState({
						loadingPer : perNow,
					});
				}
			}
			
			//ユーザ情報の取得（未取得の続きデータがあった場合の処理）
			while (refAddress.data.queryMailMagazineAddress.nextToken) {
				const AddressQuery = `query queryMailMagazineAddress($magazineno: String!, $nextToken: String) {
					queryMailMagazineAddress(magazineno: $magazineno, nextToken: $nextToken) {
						items {
							email
							status
							date_edit
						}
						nextToken
					}
				}`;
				const QueryData = {
					magazineno : magazineno,
					nextToken: refAddress.data.queryMailMagazineAddress.nextToken,
				};
				//console.log(refAddress.data.queryMailMagazineAddress.nextToken);
				refAddress = await API.graphql(graphqlOperation(AddressQuery, QueryData));
				AddressData = AddressData.concat(refAddress.data.queryMailMagazineAddress.items);
				//進捗情報
				if(AddressData.length){
					const perNow = Math.floor(AddressData.length / AddressCount * 100);
					if(loadingPer < perNow){
						this.setState({
							loadingPer : perNow,
						});
					}
				}
			}
			
			//'null'文字がある場合は''に変換
			AddressData.some((item, index) => Object.keys(item).some(key => { if(item[key] === 'null'){ AddressData[index][key] = '' } return false; }));
			
			//メールアドレスを確認
			AddressData = AddressData.filter(item => item.email !== '');
			
			//データコピー
			const magazineData = [];
			for(let row of AddressData){
				magazineData.push(row);
			}
			
			//データの判別
			if(magazineData.length === 0){
				//データがなかった時
				this.setState({
					isLoading : false,
					ErrMsg    : '対象となるデータがありません。<br/>',
				});
				return(0);
			}
			
			//console.log(magazineData);
			this.setState({
				isLoading       : false,
				magazineData    : magazineData,
				showAddress     : true,
			});
			
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLoading  : false,
				isLockForm : false,
			 });
			return(0);
		}
	}
	async deleteSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowDelete : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Stateから取得する更新項目
			const magazineno = this.state.magazineno;
			const status     = this.state.status;
			
			if(status !== 'reserved'){
				this.setState({
					ErrMsg     : '※キャンセルできないステータスです。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//プロフィールの更新登録
			const Query = `mutation updateMailMagazine($data: MailMagazineInput!) {
				updateMailMagazine(input: $data) {
					magazineno
				}
			}`;
			const Data = {
				data : {
					magazineno   : magazineno,
					status       : 'canceled',
				}
			};
			await API.graphql(graphqlOperation(Query, Data));

			//鑑査ログ
			await AuditLog('mailmagazine', 'update', magazineno, Data, 'メールマガジン配信予約をキャンセルしました。');
			
			//ストレージを定義
			//Storage.configure({ AWSS3: { bucket: 'mindwavestore.admin', region: 'ap-northeast-1', level: 'public' } });
			
			//S3に保存する
			//await Storage.remove('MailMagazine/_reserved/' + magazineno + '.json');

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isDeleted   : true,
			isLockForm  : false,
		});
	}
	handleClose() {
		this.setState({
			isShowDelete : false,
			isValidated  : false,
			isLockForm   : false,
			ErrMsg     : '',
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleDelete(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowDelete : true,
			formObj      : form,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else if(this.state.isNotFound){
			return (
				<NotFound />
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isDeleted} onHide={()=>{ this.props.history.push('/MailMagazine/'); }} centered>
						<Modal.Body>キャンセルしました。</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={()=>{ this.props.history.push('/MailMagazine/'); }}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowDelete} onHide={this.handleClose} centered>
						<Modal.Body>本当にキャンセルしますか？</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.deleteSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.showAddress} onHide={()=>{ this.setState({ showAddress: false }); }} centered size="lg">
						<Modal.Body className="text-center">
							<BootstrapTable bootstrap4 keyField='email' data={ this.state.magazineData } columns={ [ { dataField: 'email', text: 'メールアドレス', sort: true, }, { dataField: 'status', text: 'ステータス', sort: true, }, { dataField: 'date_edit', text: '配信日時', sort: true, }, ] } bordered={ false } defaultSorted={ [{ dataField: 'email', order: 'asc'}] } pagination={ paginationFactory() } />
						</Modal.Body>
					</Modal>
					
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Form noValidate validated={this.state.isValidated}>
					<Row>
						<Col sm={4}>
							<p><big>メールマガジン予約情報</big></p>
						</Col>
						<Col sm={7} className="text-right">
							<Button variant="dark" onClick={ this.handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メールマガジン番号
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="magazineno">
							        <Form.Control name="magazineno" type="text" defaultValue={this.state.magazineno} size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							ステータス
						</Form.Label>
						<Col sm={2}>
							<Badge variant={this.state.status_variant}>
								{this.state.status}
							</Badge>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							登録日時
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="date_add">
							        <Form.Control name="date_add" type="text" defaultValue={this.state.date_add} size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							配信日時
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="date_edit">
							        <Form.Control name="date_edit" type="text" defaultValue={this.state.reserv_date} size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							配信数
						</Form.Label>
						<Col sm={7}>
							<Form.Group controlId="tmpl_sort">
							        <Form.Control name="tmpl_sort" type="text" defaultValue={this.state.send_count.toLocaleString() + '件'} size="lg" readOnly />
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Button variant="dark" onClick={ this._getAddress }>
								確認
							</Button>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							差出人アドレス
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="tmpl_from">
							        <Form.Control name="tmpl_from" type="email" defaultValue={this.state.from} size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メール件名
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="tmpl_subject">
							        <Form.Control name="tmpl_subject" type="text" defaultValue={this.state.subject} size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メール本文（TEXT）
						</Form.Label>
						<Col sm={8}>
						<pre style={{ border: 'solid 1px gray', padding: '2em', marginTop: 20 }}>
							{this.state.msg_text }
						</pre>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メール本文（HTML）
						</Form.Label>
						<Col sm={8}>
						<pre style={{ border: 'solid 1px gray', padding: '2em', marginTop: 20 }}>
							{this.state.msg_html }
						</pre>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メッセージID
						</Form.Label>
						<Col sm={8}>
						<pre style={{ border: 'solid 1px gray', padding: '2em', marginTop: 20 }}>
							{this.state.messageid_text }
						</pre>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props.history.push('/MailMagazine/'); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					</Form>

					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleDelete(e)}>
					<Row style={{ marginTop: 100 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="danger" type="submit" block size="lg" disabled={this.state.isLockForm} >キャンセルする</Button>
						</Col>
					</Row>
					</Form>

				</Container>
			);
		}
	}
}

export default withRouter(Default);


