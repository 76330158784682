import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Alert,
	Modal,
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Badge,
	ProgressBar,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import CSVDownload from '../../Objects/CSVDownload';

class MyTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			orderno      : '',
			status       : '',
			selectIDs    : [],
			ErrMsg       : '',
		}
		this.handleSubmit            = this.handleSubmit.bind(this);
		this.handleClickDetails      = this.handleClickDetails.bind(this);
		this.handleClickMailSend     = this.handleClickMailSend.bind(this);
		this.handleClose             = this.handleClose.bind(this);
		this._selectAllRows          = this._selectAllRows.bind(this);
		this._selectRows             = this._selectRows.bind(this);
		this._dataFormatOverlay      = this._dataFormatOverlay.bind(this);
		this._dataFormatOverlay2      = this._dataFormatOverlay2.bind(this);//追加 220203
		this._dataFormatLabel        = this._dataFormatLabel.bind(this);
		this._dataFormatIcon         = this._dataFormatIcon.bind(this);//追加 220203
		this._dataFormatDate         = this._dataFormatDate.bind(this);
		this._dataFormatDate2         = this._dataFormatDate2.bind(this);//追加 220203
		this._dataFormatOdate         = this._dataFormatOdate.bind(this);//追加 220203
		this._dataFormatButton       = this._dataFormatButton.bind(this);
		this._dataFormatMail         = this._dataFormatMail.bind(this);
	}
	
	handleSubmit(e) {
		//Submitをキャンセル
		e.preventDefault();
		e.stopPropagation();
	}
	handleClickDetails(row){
		if(row.orderno){
			this.props.history.push('/Order/' + row.orderno + '/');
		}
	}
	handleClickMailSend(row){
		if(row.orderno){
			this.props.history.push('/Order/' + row.orderno + '/MailSend/');
		}
	}
	handleClose() {
		this.setState({
			ErrMsg       : '',
		});
	}
	_selectRows(row, isSelect, rowIndex, e) {
		
		//新しく選択した内容
		const selectID  = row.selectid;
		
		//今まで選択した内容
		let selectIDs = this.state.selectIDs;

		//選択したかどうか
		if(isSelect){
			//新しく選択した内容を追加
			selectIDs.push(selectID);
		
			//重複を削除
			selectIDs = selectIDs.filter((x, i, self) => self.indexOf(x) === i);
		}
		else{
			//対応する要素を削除
			selectIDs = selectIDs.filter(n => n !== selectID);
		}
		
		//今まで選択した内容を更新
		this.setState({ selectIDs: selectIDs});
		
		//親に数値を渡す
		this.props.setSelectIDs(selectIDs);

	}
	_selectAllRows(isSelect, rows, e) {

		//今まで選択した内容
		let selectIDs = this.state.selectIDs;

		//ループ処理
		rows.some(row => {
		
			//新しく選択した内容
			const selectID  = row.selectid;
			
			//選択したかどうか
			if(isSelect){
				//新しく選択した内容を追加
				selectIDs.push(selectID);
			
				//重複を削除
				selectIDs = selectIDs.filter((x, i, self) => self.indexOf(x) === i);
			}
			else{
				//対応する要素を削除
				selectIDs = selectIDs.filter(n => n !== selectID);
			}
			
			return false;
		
		});

		//今まで選択した内容を更新
		this.setState({ selectIDs: selectIDs});
		
		//親に数値を渡す
		this.props.setSelectIDs(selectIDs);

	}
	_dataFormatLabel(cell, row) {//margin: "10px" ■220203 変更
		return (
			<div className="text-center" style={{margin: "3px"}}>
				<Button variant={cell ? 'success' : 'secondary'} href={'/Analysis/User/' + row.cognitoid + '/'} target="_blank" disabled={!cell}>
					{cell ? '○' : '×'}
				</Button>　
			</div>
		);
	}
	_dataFormatIcon(cell, row) {// ■220203 追加
		return (
			<div className="text-center" style={{margin: "3px"}}>
				<Badge variant="primary" className="p-2" style={cell==='stationery' ? {backgroundColor: "#00B7EB"} : cell==='cosme' ? {backgroundColor: "pink"} :  {backgroundColor: "gray"}}>
					{cell==='stationery' ? 'S' : cell==='cosme' ? 'C' : cell==='post' ? 'P' :' '}
				</Badge>
			</div>
		);
	}
	_dataFormatOverlay(cell, row) {// ■220203 font-size追加
		return (
			<OverlayTrigger placement="bottom" overlay={<Tooltip>{row.memo_full}</Tooltip>}>
				<div style={{fontSize: "0.9em"}}>{cell}</div>
			</OverlayTrigger>
		);
	}

	_dataFormatOverlay2(cell, row) {// ■220203 追加
		return (
			<OverlayTrigger placement="bottom" overlay={<Tooltip>{row.order_memo_full}</Tooltip>}>
				<div style={{fontSize: "0.9em"}}>{cell}</div>
			</OverlayTrigger>
		);
	}
	_dataFormatDate(cell, row) {

		//日付の整形
		const dateObj     = new Date(cell);
		const year        = dateObj.getFullYear().toString();
		const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day         = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second      = ('00' + dateObj.getSeconds().toString()).slice(-2);

		//出力
		return (
			<div>
				{year}年{month}月{day}日{hour}時{minute}分{second}秒
			</div>
		);
	}
	_dataFormatDate2(cell, row) {// ■220203 追加

		//日付の整形
		const dateObj     = new Date(cell);
		const year        = dateObj.getFullYear().toString();
		const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day         = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second      = ('00' + dateObj.getSeconds().toString()).slice(-2);

		//出力
		return (
			<div className="text-center" style={{margin: "2px"}}>
				{year}-{month}-{day}<br/>{hour}:{minute}:{second}
			</div>
		);
	}
	_dataFormatOdate(cell, row) {// ■220203 追加

		//日付の整形
		cell = cell.replace(/：/g,':');
		const o_date     =  cell.split(' ');

		//出力
		return (
			<div className="text-center" style={{margin: "2px"}}>
				{o_date[0]}<br/>{o_date[1]}
			</div>
		);
	}
	_dataFormatButton(cell, row) {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" href={'/Order/' + row.orderno + '/'} target="_blank" >{/*onClick={() => this.handleClickDetails(row)}*/}
					詳細
				</Button>
			</div>
		);
	}
	_dataFormatMail(cell, row) {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" href={'/Order/' + row.orderno + '/MailSend/'} target="_blank" >{/*onClick={() => this.handleClickMailSend(row)}*/}
					メール
				</Button>
			</div>
		);
	}
	render() {
		if(this.props.data.length === 0){
			return (
				<Alert variant="danger">
					対象のデータが見つかりません。
				</Alert>
			);
		}
		else{
		
			//表示用に整形
			let TableData = [];
			for(let item of this.props.data){
				const orderno       = item.orderno;
				const cognitoid     = item.cognitoid;
				const name_1        = item.name_1;
				const name_2        = item.name_2;
				const payment_type  = item.payment_type;
				const payment_price = item.payment_price;
				const o_pref        = item.o_pref;
				const memo          = item.memo;
				const order_memo    = item.order_memo; //追加 220203
				const o_date_type   = item.o_date_type;
				const o_date_h      = item.o_date_h;
				const delivery_date = item.delivery_date;//追加 220203
				const not_ec_user_kbn = item.not_ec_user_kbn;
				const flag          = item.flag;
				const carriage_type = item.carriage_type;
				const order_history_flag = item.order_history_flag;
				const status        = item.status;
				const date_add      = item.date_add;
				const date_edit     = item.date_edit;
				const RowData = {
					selectid        : orderno,
					orderno         : orderno,
					cognitoid       : cognitoid,
					name            : name_1 + ' ' +name_2,
					ec_user_kbn     : not_ec_user_kbn ? '非会員' : '会員',
					payment_type    : payment_type,
					payment_price   : payment_price ? payment_price.toLocaleString() + ' 円' : '0 円',
					o_pref          : o_pref,
					memo            : memo ? (memo.length > 10 ? memo.substr(0,10) + '...' : memo) : '',
					memo_full       : memo,
					order_memo      : order_memo ? (order_memo.length > 10 ? order_memo.substr(0,10) + '...' : order_memo) : '',//追加 220203
					order_memo_full : order_memo,//追加 220203
					o_date          : o_date_type + ' ' + o_date_h,
					delivery_date   : delivery_date ? delivery_date : '未発送',//追加 220203
					flag            : flag,
					carriage_type   : carriage_type,
					order_history_flag : order_history_flag ? true : false,
					status          : status,
					date_add        : date_add,
					date_edit       : date_edit,
				};
				
				TableData.push(RowData);
			}
			
			const windowSize = window.innerWidth;

			const columns = [
				{
					dataField: 'selectid',
					text: '詳細',
					sort: false,
					formatter: this._dataFormatButton,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'orderno',
					text: '受注番号',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'date_add',
					text: '受注日',
					sort: true,
					formatter: this._dataFormatDate2,//this._dataFormatDate ■220203更新
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'name',
					text: '注文者名',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'ec_user_kbn',
					text: '会員',//会員種別 ■220203更新
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'payment_type',
					text: '決済方法',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'payment_price',
					text: '決済金額',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
					align: 'right',
				},
				{
					dataField: 'o_pref',
					text: 'お届け先',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'memo',
					text: 'メモ',
					sort: true,
					formatter: this._dataFormatOverlay,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{//220203 追加
					dataField: 'order_memo',
					text: '連絡事項',
					sort: true,
					formatter: this._dataFormatOverlay2,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'o_date',
					text: '配送日時指定',
					sort: true,
					formatter: this._dataFormatOdate,//220203追加
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{//220203追加
					dataField: 'delivery_date',
					text: '発送日',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'flag',
					text: 'フラグ',
					sort: true,
					formatter: this._dataFormatIcon,//220203 追加
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'carriage_type',
					text: 'お届け方法',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'status',
					text: '受注状態',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'dummy1',
					isDummyField: true,
					text: 'メール',
					sort: false,
					formatter: this._dataFormatMail,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
			];

			const defaultSorted = [
				{
					dataField: 'date_add',
					order: 'desc'
				}
			];
			
			const selectRow = {
				mode         : 'checkbox',
				clickToSelect: false,
				onSelect     : this._selectRows,
				onSelectAll  : this._selectAllRows,
			};
			
			const options = {
				sizePerPageList: [
					{ text: '100', value: 100},
					{ text: '10', value: 10},
					{ text: '50', value: 50},
					{ text: 'All', value: TableData.length},
				],
			};
			
			return (
				<Form onSubmit={e => this.handleSubmit(e)} >
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<BootstrapTable bootstrap4 keyField='orderno' data={ TableData } columns={ columns } bordered={ false } defaultSorted={ defaultSorted } selectRow={ selectRow } pagination={ paginationFactory(options) } />
				</Form>
			);

		}
	}
}

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading    : true,
			loadingPer   : 0,
			ErrMsg       : '',
			viewData     : [],
			defaultData  : [],
			selectIDs    : [],
			dateTime     : '',
			startDate    : '',
			lastDate     : '',
			cognitoid    : '',
			status       : '',
			csvData      : [],
		}
		this._reload              = this._reload.bind(this);
		this._formatDate          = this._formatDate.bind(this);
		this._getData             = this._getData.bind(this);
		this.handleDismiss        = this.handleDismiss.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleSearch         = this.handleSearch.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.setSelectIDs         = this.setSelectIDs.bind(this);
		this.createCSV            = this.createCSV.bind(this);
	}
	_reload() {
		//子ウィンドウからの呼び出し用
		this.handleReload();
	}
	_formatDate(dateObj) {

		//日付の整形
		const year        = dateObj.getFullYear().toString();
		const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day         = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second      = ('00' + dateObj.getSeconds().toString()).slice(-2);

		const text = year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分' + second + '秒';

		return (text);
	}
	async _getData() {
		
		//処理
		try{
		
			//IDの取得
			const cognitoid = this.props.match.params.cognitoid;
			if(!cognitoid){
					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
			}

			//ユーザ件数の取得
			const CountQuery = `query queryOrderUserPartCount($cognitoid: String!) {
				queryOrderUserPartCount(cognitoid: $cognitoid) {
					scannedCount
					nextToken
				}
			}`;
			const CountData = {
				cognitoid: cognitoid,
			};
			let countRef  = await API.graphql(graphqlOperation(CountQuery, CountData));
			let itemCount = countRef.data.queryOrderUserPartCount.scannedCount;
			while (countRef.data.queryOrderUserPartCount.nextToken) {
				const Query = `query queryOrderUserPartCount($cognitoid: String!, $nextToken: String) {
					queryOrderUserPartCount(cognitoid: $cognitoid, nextToken: $nextToken) {
						scannedCount
						nextToken
					}
				}`;
				const Data = {
					cognitoid: cognitoid,
					nextToken: countRef.data.queryOrderUserPartCount.nextToken,
				};
				countRef  = await API.graphql(graphqlOperation(Query, Data));
				itemCount = itemCount + countRef.data.queryOrderUserPartCount.scannedCount;
			}
			
			//ユーザ件数の取得（古いもの）
			const OldCountQuery = `query queryOldOrderUserPartCount($cognitoid: String!) {
				queryOldOrderUserPartCount(cognitoid: $cognitoid) {
					scannedCount
					nextToken
				}
			}`;
			countRef  = await API.graphql(graphqlOperation(OldCountQuery, CountData));
			itemCount = itemCount + countRef.data.queryOldOrderUserPartCount.scannedCount;
			while (countRef.data.queryOldOrderUserPartCount.nextToken) {
				const Query = `query queryOldOrderUserPartCount($cognitoid: String!, $nextToken: String) {
					queryOldOrderUserPartCount(cognitoid: $cognitoid, nextToken: $nextToken) {
						scannedCount
						nextToken
					}
				}`;
				const Data = {
					cognitoid: cognitoid,
					nextToken: countRef.data.queryOldOrderUserPartCount.nextToken,
				};
				countRef  = await API.graphql(graphqlOperation(Query, Data));
				itemCount = itemCount + countRef.data.queryOldOrderUserPartCount.scannedCount;
			}


			//進捗情報
			let loadingPer   = 1;
			
			//ユーザ情報の取得
			const Query = `query queryOrderUserPart($cognitoid: String!) {
				queryOrderUserPart(cognitoid: $cognitoid) {
					items {
						orderno
						cognitoid
						total_price
						payment_type
						payment_price
						carriage_type
						carriage
						cashdeli
						wrapping_type
						wrapping
						coupon_type
						coupon
						email
						name_1
						name_2
						name_furi_1
						name_furi_2
						zip_1
						zip_2
						pref
						addr_1
						addr_2
						addr_3
						tel_1
						tel_2
						tel_3
						o_name_1
						o_name_2
						o_name_furi_1
						o_name_furi_2
						o_zip_1
						o_zip_2
						o_pref
						o_addr_1
						o_addr_2
						o_addr_3
						o_tel_1
						o_tel_2
						o_tel_3
						o_com_name
						o_date_type
						o_date_h
						order_memo
						order_history_flag
						delivery_no
						delivery_date
						status
						memo
						flag
						not_ec_user_kbn
						date_add
						date_edit
						old_order_id
						order_items{
							orderno
							code
							name
							price
							sub_price
							piece
							piece_type
							date_add
							date_edit
						}
					}
					nextToken
				}
			}`;
			const Data = {
				cognitoid: cognitoid,
			};
			let ref = await API.graphql(graphqlOperation(Query, Data));
			let Items = ref.data.queryOrderUserPart.items;
			
			//ユーザ情報の取得（未取得の続きデータがあった場合の処理）
			while (ref.data.queryOrderUserPart.nextToken) {
				const Query = `query queryOrderUserPart($cognitoid: String!, $nextToken: String) {
					queryOrderUserPart(cognitoid: $cognitoid, nextToken: $nextToken) {
						items {
							orderno
							cognitoid
							total_price
							payment_type
							payment_price
							carriage_type
							carriage
							cashdeli
							wrapping_type
							wrapping
							coupon_type
							coupon
							email
							name_1
							name_2
							name_furi_1
							name_furi_2
							zip_1
							zip_2
							pref
							addr_1
							addr_2
							addr_3
							tel_1
							tel_2
							tel_3
							o_name_1
							o_name_2
							o_name_furi_1
							o_name_furi_2
							o_zip_1
							o_zip_2
							o_pref
							o_addr_1
							o_addr_2
							o_addr_3
							o_tel_1
							o_tel_2
							o_tel_3
							o_com_name
							o_date_type
							o_date_h
							order_memo
							order_history_flag
							delivery_no
							delivery_date
							status
							memo
							flag
							not_ec_user_kbn
							date_add
							date_edit
							old_order_id
							order_items{
								orderno
								code
								name
								price
								sub_price
								piece
								piece_type
								date_add
								date_edit
							}
						}
						nextToken
					}
				}`;
				const Data = {
					cognitoid: cognitoid,
					nextToken: ref.data.queryOrderUserPart.nextToken,
				};
				//console.log(ref.data.queryOrderUserPart.nextToken);
				ref = await API.graphql(graphqlOperation(Query, Data));
				Items = Items.concat(ref.data.queryOrderUserPart.items);
				//進捗情報
				if(Items.length){
					loadingPer = Math.floor(Items.length / itemCount * 100);
					this.setState({
						loadingPer : loadingPer,
					});
				}
			}

			//ユーザ情報の取得
			const OldQuery = `query queryOldOrderUserPart($cognitoid: String!) {
				queryOldOrderUserPart(cognitoid: $cognitoid) {
					items {
						orderno
						cognitoid
						total_price
						payment_type
						payment_price
						carriage_type
						carriage
						cashdeli
						wrapping_type
						wrapping
						coupon_type
						coupon
						email
						name_1
						name_2
						name_furi_1
						name_furi_2
						zip_1
						zip_2
						pref
						addr_1
						addr_2
						addr_3
						tel_1
						tel_2
						tel_3
						o_name_1
						o_name_2
						o_name_furi_1
						o_name_furi_2
						o_zip_1
						o_zip_2
						o_pref
						o_addr_1
						o_addr_2
						o_addr_3
						o_tel_1
						o_tel_2
						o_tel_3
						o_com_name
						o_date_type
						o_date_h
						order_memo
						order_history_flag
						delivery_no
						delivery_date
						status
						memo
						flag
						not_ec_user_kbn
						date_add
						date_edit
						old_order_id
						order_items{
							orderno
							code
							name
							price
							sub_price
							piece
							piece_type
							date_add
							date_edit
						}
					}
					nextToken
				}
			}`;
			ref = await API.graphql(graphqlOperation(OldQuery, Data));
			Items = Items.concat(ref.data.queryOldOrderUserPart.items);
			
			//ユーザ情報の取得（未取得の続きデータがあった場合の処理）
			while (ref.data.queryOldOrderUserPart.nextToken) {
				const Query = `query queryOldOrderUserPart($cognitoid: String!, $nextToken: String) {
					queryOldOrderUserPart(cognitoid: $cognitoid, nextToken: $nextToken) {
						items {
							orderno
							cognitoid
							total_price
							payment_type
							payment_price
							carriage_type
							carriage
							cashdeli
							wrapping_type
							wrapping
							coupon_type
							coupon
							email
							name_1
							name_2
							name_furi_1
							name_furi_2
							zip_1
							zip_2
							pref
							addr_1
							addr_2
							addr_3
							tel_1
							tel_2
							tel_3
							o_name_1
							o_name_2
							o_name_furi_1
							o_name_furi_2
							o_zip_1
							o_zip_2
							o_pref
							o_addr_1
							o_addr_2
							o_addr_3
							o_tel_1
							o_tel_2
							o_tel_3
							o_com_name
							o_date_type
							o_date_h
							order_memo
							order_history_flag
							delivery_no
							delivery_date
							status
							memo
							flag
							not_ec_user_kbn
							date_add
							date_edit
							old_order_id
							order_items{
								orderno
								code
								name
								price
								sub_price
								piece
								piece_type
								date_add
								date_edit
							}
						}
						nextToken
					}
				}`;
				const Data = {
					cognitoid: cognitoid,
					nextToken: ref.data.queryOldOrderUserPart.nextToken,
				};
				//console.log(ref.data.queryOldOrderUserPart.nextToken);
				ref = await API.graphql(graphqlOperation(Query, Data));
				Items = Items.concat(ref.data.queryOldOrderUserPart.items);
				//進捗情報
				if(Items.length){
					loadingPer = Math.floor(Items.length / itemCount * 100);
					this.setState({
						loadingPer : loadingPer,
					});
				}
			}

			//データの判別
			if(Items.length === 0){
				//データがなかった時
				this.setState({
					isLoading : false,
					ErrMsg    : 'データがありません。',
				});
			}
			else{

				//'null'文字がある場合は''に変換
				Items.some((item, index) => Object.keys(item).some(key => { if(item[key] === 'null'){ Items[index][key] = '' } return false; }));
				
				//nullがある場合は''に変換
				Items.some((item, index) => Object.keys(item).some(key => { if(item[key] === null){ Items[index][key] = '' } return false; }));
				
				//データコピー
				const viewData = [];
				for(let row of Items){
					row.name_full = row.name_1 + ' ' + row.name_2;
					row.name_furi_full = row.name_furi_1 + ' ' + row.name_furi_2;
					let order_item_name = '';
					let order_item_code = '';
					for(const item of row.order_items){
						order_item_name = order_item_name + ' ' + item.name;
						order_item_code = order_item_code + ' ' + item.code;
					}
					row.order_item_name = order_item_name;
					row.order_item_code = order_item_code;
					viewData.push(row);
				}
				
				//データコピー
				const defaultData = [];
				for(let row of Items){
					row.name_full = row.name_1 + ' ' + row.name_2;
					row.name_furi_full = row.name_furi_1 + ' ' + row.name_furi_2;
					let order_item_name = '';
					let order_item_code = '';
					for(const item of row.order_items){
						order_item_name = order_item_name + ' ' + item.name;
						order_item_code = order_item_code + ' ' + item.code;
					}
					row.order_item_name = order_item_name;
					row.order_item_code = order_item_code;
					defaultData.push(row);
				}

				//ソート　受注日時順に出力
				defaultData.sort(
					(a, b) => {
						if (a.date_add > b.date_add) return -1;
						if (a.date_add < b.date_add) return 1;
						return 0;
					}
				);
				
				//最初の配列
				const startDateObj   = new Date(defaultData.slice(-1)[0].date_add);
				const startDateYear  = startDateObj.getFullYear().toString();
				const startDateMonth = ('00' + (startDateObj.getMonth()+1).toString()).slice(-2);
				const startDate      = startDateYear + '-' + startDateMonth + '-01';
				
				//最後の配列
				const lastDateObj   = new Date(defaultData[0].date_add);
				const lastDateYear  = lastDateObj.getFullYear().toString();
				const lastDateMonth = ('00' + (lastDateObj.getMonth()+1).toString()).slice(-2);
				const lastDateDay   = (new Date(lastDateYear, lastDateMonth, 0)).getDate();
				const lastDate      = lastDateYear + '-' + lastDateMonth + '-' + lastDateDay;

				//現在時刻の取得
				const dateObj = new Date();
				
				//データの保存
				this.setState({
					isLoading   : false,
					loadingPer  : 0,
					viewData    : viewData,
					defaultData : defaultData,
					dateTime    : this._formatDate(dateObj),
					startDate   : startDate,
					lastDate    : lastDate,
					cognitoid   : cognitoid,
				});
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				isLoading  : false,
				loadingPer : 0,
				ErrMsg     : err_msg,
			});
		}
	}
	handleDismiss() {
		this.setState({ ErrMsg: '' });
	}
	handleReload() {
		
		//データの再取得
		this.setState({
			isLoading   : true,
			viewData    : [],
			defaultData : [],
			selectIDs   : [],
			dateTime    : '',
		});
		
		//表示データ更新
		this._getData();
	}
	handleSearch(e){

		//Submitをキャンセル
		e.preventDefault();
		e.stopPropagation();

		//Formオブジェクトの確認
		const formObj     = e.currentTarget;
		if(!formObj){
			this.setState({
				ErrMsg     : '※不正なエラーが発生しました。<br/>',
			 });
			return(0);
		}

		//Formオブジェクトの確認
		if(formObj.checkValidity() === false){
			this.setState({
				ErrMsg     : '※入力項目にエラーがありました。<br/>',
			});
			return(0);
		}
		
		//対象データを取得
		let viewData = this.state.defaultData;
		
		//Formから直接取得する更新項目
		const search_start_date    = formObj.search_start_date.value;
		const search_last_date     = formObj.search_last_date.value;
		const search_ec_user_kbn   = formObj.search_ec_user_kbn.value;
		const search_payment_type  = formObj.search_payment_type.value;
		const search_carriage_type = formObj.search_carriage_type.value;
		const search_status        = formObj.search_status.value;
		const search_flag          = formObj.search_flag.value;
		const search_key           = formObj.search_key.value;
		
		//日付オブジェクトに変換
		const startDate = new Date(search_start_date);
		const lastDate  = new Date(search_last_date);
		
		//TIME形式に変換
		const startTime = startDate.getTime();
		const lastTime  = lastDate.getTime() + (86400 * 1000);
		
		//検索日付のチェック
		if(startTime > lastTime){
			this.setState({
				ErrMsg     : '※検索日付の指定に誤りがあります。<br/>',
			});
			return(0);
		}
		
		//日付検索
		viewData = viewData.filter((item) => 
			{
				const dateObj  = new Date(item.date_add);
				const dateTime = dateObj.getTime() + (9 * 60 * 60 * 1000);
				
				if(dateTime >= startTime && dateTime < lastTime){
					return true;
				}
				else{
					return false;
				}
			}
		);
		
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		viewData = viewData.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		//search_ec_user_kbn
		if(search_ec_user_kbn === "会員"){
			viewData = viewData.filter(item => item.not_ec_user_kbn === false );
		}
		else if(search_ec_user_kbn === "非会員"){
			viewData = viewData.filter(item => item.not_ec_user_kbn === true );
		}
		
		//search_payment_type
		if(search_payment_type){
			viewData = viewData.filter(item => item.payment_type === search_payment_type );
		}
		
		//search_carriage_type
		if(search_carriage_type){
			viewData = viewData.filter(item => item.carriage_type === search_carriage_type );
		}
		
		//search_status
		if(search_status){
			viewData = viewData.filter(item => item.status === search_status );
		}
		
		//search_flag
		if(search_flag === "stationery"){
			viewData = viewData.filter(item => item.flag === 'stationery' );
		}
		else if(search_flag === "post"){
			viewData = viewData.filter(item => item.flag === 'post' );
		}
		else if(search_flag === "cosme"){
			viewData = viewData.filter(item => item.flag === 'cosme' );
		}

		//表示データ更新
		this.setState({ viewData: viewData});
	
	}
	handleClose() {
		this.setState({
			ErrMsg       : '',
		});
	}
	setSelectIDs (selectIDs){
		
		//選択しているIDとCSV用データを保存
		this.setState({
			selectIDs : selectIDs,
		});
		
	}
	createCSV(flag){

		//デフォルトのデータ内から選択されているIDのデータのみ抽出
		const defaultData = this.state.defaultData.filter(item => this.state.selectIDs.indexOf(item.orderno) !== -1);
		
		//ソート
		defaultData.sort(
			(a, b) => {
				if (a.orderno < b.orderno) return -1;
				if (a.orderno > b.orderno) return 1;
				if (a.code < b.code) return -1;
				if (a.code > b.code) return 1;
				return 0;
			}
		);
		
		//CSV1データの作成
		const csvData = [];
		
		//売上データ
		if(flag === 1){

			//ソート　受注日時順に出力
			defaultData.sort(
				(a, b) => {
					if (a.date_add > b.date_add) return -1;
					if (a.date_add < b.date_add) return 1;
					return 0;
				}
			);

			for(let row of defaultData){
				if(row.order_items === null) {continue;}
				for(let item of row.order_items){
					csvData.push(
						{
							orderno         : row.orderno         ,
							cognitoid       : row.cognitoid       ,
							total_price     : row.total_price     ,
							payment_type    : row.payment_type    ,
							payment_price   : row.payment_price   ,
							carriage_type   : row.carriage_type   ,
							carriage        : row.carriage        ,
							cashdeli        : row.cashdeli        ,
							wrapping_type   : row.wrapping_type   ,
							wrapping        : row.wrapping        ,
							coupon_type     : row.coupon_type     ,
							coupon          : row.coupon          ,
							email           : row.email           ,
							name_1          : row.name_1          ,
							name_2          : row.name_2          ,
							name_furi_1     : row.name_furi_1     ,
							name_furi_2     : row.name_furi_2     ,
							zip_1           : row.zip_1           ,
							zip_2           : row.zip_2           ,
							pref            : row.pref            ,
							addr_1          : row.addr_1          ,
							addr_2          : row.addr_2          ,
							addr_3          : row.addr_3          ,
							tel_1           : row.tel_1           ,
							tel_2           : row.tel_2           ,
							tel_3           : row.tel_3           ,
							o_name_1        : row.o_name_1        ,
							o_name_2        : row.o_name_2        ,
							o_name_furi_1   : row.o_name_furi_1   ,
							o_name_furi_2   : row.o_name_furi_2   ,
							o_zip_1         : row.o_zip_1         ,
							o_zip_2         : row.o_zip_2         ,
							o_pref          : row.o_pref          ,
							o_addr_1        : row.o_addr_1        ,
							o_addr_2        : row.o_addr_2        ,
							o_addr_3        : row.o_addr_3        ,
							o_tel_1         : row.o_tel_1         ,
							o_tel_2         : row.o_tel_2         ,
							o_tel_3         : row.o_tel_3         ,
							o_com_name      : row.o_com_name      ,
							o_date_type     : row.o_date_type     ,
							o_date_h        : row.o_date_h        ,
							order_memo      : row.order_memo      ,
							order_history_flag : row.order_history_flag,
							delivery_no     : row.delivery_no     ,
							delivery_date   : row.delivery_date   ,
							status          : row.status          ,
							memo            : row.memo            ,
							flag            : row.flag            ,
							not_ec_user_kbn : row.not_ec_user_kbn ,
							code            : item.code           ,
							name            : item.name           ,
							price           : item.price          ,
							piece           : item.piece          ,
							piece_type      : item.piece_type     ,
							sub_price       : item.sub_price      ,
							date_add        : row.date_add        ,
							date_edit       : row.date_edit       ,
							old_order_id    : row.old_order_id    ,
						}
					);
				}
			}
		}
		
		//データ更新
		this.setState({
			csvData : csvData,
		});

	}
	async componentDidMount () {
		//データ読み出し
		this._getData();
	}
	render() {
		if(this.state.isLoading && this.state.loadingPer){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={{ span: 6, offset: 3 }}>
						<ProgressBar now={this.state.loadingPer} label={this.state.loadingPer + `%`} animated />
					</Col>
				</Row>
			);
		}
		else if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleDismiss} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleDismiss}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					<Row>
						<Col sm={12}>
						<Form onSubmit={e => this.handleSearch(e)}>
						<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap float-left">
							<Row style={{ marginTop: 10 }}>
								<Col sm={3}>
									検索対象：{this.state.cognitoid}
								</Col>
							</Row>
							<Row style={{ marginTop: 20 }}>
								<Col sm={3}>
									<Form.Group controlId="search_start_date">
									        <Form.Control name="search_start_date" type="date" defaultValue={this.state.startDate} min={this.state.startDate} max={this.state.lastDate} />
									</Form.Group>
								</Col>
								<Col sm={3}>
									<Form.Group controlId="search_last_date">
									        <Form.Control name="search_last_date" type="date" defaultValue={this.state.lastDate}  min={this.state.startDate} max={this.state.lastDate} />
									</Form.Group>
								</Col>
							</Row>
							<Row style={{ marginTop: 10 }}>
								<Col sm={2}>
									<Form.Group controlId="search_ec_user_kbn">
										<Form.Label>会員・非会員</Form.Label>
										<Form.Control as="select" name="search_ec_user_kbn" defaultValue="">
										<option value="">すべて</option>
										<option value="会員">会員</option>
										<option value="非会員">非会員</option>
										</Form.Control>
									</Form.Group>
								</Col>
								<Col sm={2}>
									<Form.Group controlId="search_payment_type">
										<Form.Label>決済方法</Form.Label>
										<Form.Control as="select" name="search_payment_type" defaultValue="">
										<option value="">すべて</option>
										<option value="銀行振込">銀行振込</option>
										<option value="クレジットカード決済">クレジットカード決済</option>
										<option value="代金引換">代金引換</option>
										<option value="後払い決済">後払い決済</option>
										<option value="後払い">後払い</option>
										<option value="未定義">未定義</option>
										</Form.Control>
									</Form.Group>
								</Col>
								<Col sm={2}>
									<Form.Group controlId="search_carriage_type">
										<Form.Label>配送方法</Form.Label>
										<Form.Control as="select" name="search_carriage_type" defaultValue="">
										<option value="">すべて</option>
										<option value="ゆうパケット">ゆうパケット</option>
										<option value="宅急便">宅急便</option>
										<option value="未定義">未定義</option>
										</Form.Control>
									</Form.Group>
								</Col>
								<Col sm={2}>
									<Form.Group controlId="search_status">
										<Form.Label>ステータス</Form.Label>
										<Form.Control as="select" name="search_status" defaultValue="">
										<option value="">すべて</option>
										<option value="仮受注">仮受注</option>
										<option value="未処理">未処理</option>
										<option value="出荷済み">出荷済み</option>
										<option value="予約・入荷待ち">予約・入荷待ち</option>
										<option value="決済処理待ち">決済処理待ち</option>
										<option value="返信待ち">返信待ち</option>
										<option value="商品手配中">商品手配中</option>
										<option value="後払い与信中">後払い与信中</option>
										<option value="キャンセル">キャンセル</option>
										<option value="未定義">未定義</option>
										</Form.Control>
									</Form.Group>
								</Col>
								<Col sm={2}>
									<Form.Group controlId="search_flag">
										<Form.Label>サイト区分</Form.Label>
										<Form.Control as="select" name="search_flag" defaultValue="">
										<option value="">すべて</option>
										<option value="stationery">stationery</option>
										<option value="post">post</option>
										<option value="cosme">cosme</option>
										</Form.Control>
									</Form.Group>
								</Col>
							</Row>
							<Row style={{ marginTop: 10 }}>
								<Col sm={8}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="検索" />
									</Form.Group>
								</Col>
								<Col sm={2}>
									<Button variant="dark" type="submit" block >検索</Button>
								</Col>
							</Row>
						</Container>
						</Form>
						</Col>
					</Row>


					<Row style={{ marginTop: 20, marginBottom: 20 }}>
						<Col sm={6}>
							<Badge variant="secondary">
								<h6>　データ取得時間：{ this.state.dateTime ? this.state.dateTime : 'データなし' }　</h6>
							</Badge>　
							<Badge pill variant="secondary">
								<h6>　{ this.state.viewData.length ? this.state.viewData.length.toLocaleString() : '0' }件　</h6>
							</Badge>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ this.handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<MyTable data={this.state.viewData} setSelectIDs={this.setSelectIDs} {...this.props} />
						</Col>
					</Row>
					
					<Form>
						<div  style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
							<Row>
								<Col sm={12}>
									
									
									<CSVDownload data={this.state.csvData} filename={"order1.csv"} className={'btn btn-secondary ' + (this.state.selectIDs.length === 0 ? 'disabled' : '')} onClick={() => { this.createCSV(1); }}>売上データを出力する</CSVDownload>　
								</Col>
							</Row>
						</div>
					</Form>

				</Container>
			);
		}
	}
}

export default withRouter(Default);

