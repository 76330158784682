import React from 'react';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';

export class SignOut extends React.Component {
	constructor (props) {
		super(props);
		this._signOut = this._signOut.bind(this);
	}
	_signOut() {
		Auth.signOut()
			.then(data => {
				this.props.history.push('/');
				this.props.reload();
			})
			.catch(err => console.log(err));
	}
	componentDidMount () {
		this._signOut();
	}
	render() {
		return 	<div/>
	}
}

export default withRouter(SignOut);
