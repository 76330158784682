import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Modal,
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import { CSVLink } from "react-csv";
import { AuditLog } from '../../Objects/AuditLog';


class MyList extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isValidated    : false,
			isSuccess      : false,
			isShowUpdate   : false,
			isShowDelete   : false,
			isShowFlag     : false,
			isLockForm     : false,
			formObj        : null,
			categoryid     : '',
			view_flag      : '',
			ErrMsg         : '',
		}
		this.updateSubmit         = this.updateSubmit.bind(this);
		this.deleteSubmit         = this.deleteSubmit.bind(this);
		this.flagSubmit           = this.flagSubmit.bind(this);
		this.handleUpdate         = this.handleUpdate.bind(this);
		this.handleDelete         = this.handleDelete.bind(this);
		this.handleViewFlag       = this.handleViewFlag.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
		this.handleReload         = this.handleReload.bind(this);

	}
	async updateSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowUpdate : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//データ
			const data = this.props.defaultData;

			//Formから直接取得する更新項目
			const categoryid    = formObj.categoryid.value;
			const category_a    = formObj.category_a.value;
			const category_b    = formObj.category_b.value;
			const category_c    = formObj.category_c.value;
			const category_d    = formObj.category_d.value;

			//カテゴリの飛び空欄がないかチェック
			if(category_d !== '' && (category_b === '' || category_c === '')){
				this.setState({
					ErrMsg     : '※カテゴリデータが不正です。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			else if(category_c !== '' && category_b === ''){
				this.setState({
					ErrMsg     : '※カテゴリデータが不正です。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//同じ内容があるか検索
			if(data.filter((x, i) => { if(x.categoryid !== categoryid && x.category_a === category_a && x.category_b === category_b && x.category_c === category_c && x.category_d === category_d){ return true; }else{ return false;} }).length !== 0){
				this.setState({
					ErrMsg     : '※すでに同じ内容が登録されています。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//プロフィールの更新登録
			const Query = `mutation updateCategory($data: CategoryInput!) {
				updateCategory(input: $data) {
					categoryid
				}
			}`;
			const Data = {
				data : {
					categoryid : categoryid,
					category_a : category_a ? category_a : 'null',
					category_b : category_b ? category_b : 'null',
					category_c : category_c ? category_c : 'null',
					category_d : category_d ? category_d : 'null',
				}
			};
			await API.graphql(graphqlOperation(Query, Data));

			//鑑査ログ
			await AuditLog('category', 'create', categoryid, Data, 'カテゴリを登録しました。');

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	async deleteSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowDelete : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{

			//Stateから取得する更新項目
			const categoryid = this.state.categoryid;

			/*
			//カテゴリ登録済みのデータを取得
			const SelectQuery = `query queryCategoryItem($categoryid: String!) {
				queryCategoryItem(categoryid: $categoryid) {
					items {
						code
					}
					nextToken
				}
			}`;
			const SelectData = {
				categoryid: categoryid,
			};
			let refSelect = await API.graphql(graphqlOperation(SelectQuery, SelectData));
			let SelectItems = refSelect.data.queryCategoryItem.items;

			//選択済みカテゴリ情報の取得（未取得の続きデータがあった場合の処理）
			while (refSelect.data.queryCategoryItem.nextToken) {
				const SelectQuery = `query queryCategoryItem($categoryid: String!, $nextToken: String) {
					queryCategoryItem(categoryid: $categoryid, nextToken: $nextToken) {
						items {
							code
						}
						nextToken
					}
				}`;
				const SelectData = {
					categoryid: categoryid,
					nextToken: refSelect.data.queryCategoryItem.nextToken,
				};
				//console.log(refSelect.data.queryCategoryItem.nextToken);
				refSelect = await API.graphql(graphqlOperation(SelectQuery, SelectData));
				SelectItems = SelectItems.concat(refSelect.data.queryCategoryItem.items);
			}
			//console.log(SelectItems.length);

			//カテゴリに登録されている商品があるか確認する。
			if(SelectItems.length !== 0){
				this.setState({
					ErrMsg     : '※このカテゴリには商品が登録されています。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			*/

			//カテゴリの削除
			const Query = `mutation deleteCategory($data: CategoryInput!) {
				deleteCategory(input: $data) {
					categoryid
				}
			}`;
			const Data = {
				data : {
					categoryid   : categoryid,
				}
			};
			await API.graphql(graphqlOperation(Query, Data));

			//鑑査ログ
			await AuditLog('category', 'delete', categoryid, Data, 'カテゴリを削除しました。');

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	async flagSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowFlag   : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{

			//Stateから取得する更新項目
			const categoryid = this.state.categoryid;
			const view_flag  = this.state.view_flag;

			//プロフィールの更新登録
			const Query = `mutation updateCategory($data: CategoryInput!) {
				updateCategory(input: $data) {
					categoryid
				}
			}`;
			const Data = {
				data : {
					categoryid : categoryid,
					view_flag  : view_flag  ? true       : false,
				}
			};
			await API.graphql(graphqlOperation(Query, Data));

			//鑑査ログ
			await AuditLog('category', 'update', categoryid, Data, 'カテゴリの表示を変更しました。');

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	handleClose() {
		this.setState({
			isShowCreate   : false,
			isShowUpdate   : false,
			isShowDelete   : false,
			isShowFlag     : false,
			isSuccess      : false,
			isValidated    : false,
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleUpdate(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowUpdate : true,
			formObj      : form,
		});
	}
	handleDelete(categoryid) {
		//console.log(categoryid);
		this.setState({
			isShowDelete : true,
			categoryid   : categoryid,
		});
	}
	handleViewFlag(categoryid, view_flag) {
		//console.log(categoryid);
		this.setState({
			isShowFlag   : true,
			categoryid   : categoryid,
			view_flag    : view_flag,
		});
	}
	lockFormClose(){
		//
	}
	handleReload() {

		//モーダル閉じる
		this.handleClose();

		//データの再取得
		this.props.handleReload();
	}
	render() {

		//データ
		const data = this.props.viewData;

		//0件の場合
		if(data.length === 0){
			return (
				<div/>
			);
		}
		else{

			//データをソート
			data.sort(
				(a, b) => {
					if (a.category_a < b.category_a) return -1;
					if (a.category_a > b.category_a) return 1;
					if (a.category_b < b.category_b) return -1;
					if (a.category_b > b.category_b) return 1;
					if (a.category_c < b.category_c) return -1;
					if (a.category_c > b.category_c) return 1;
					if (a.category_d < b.category_d) return -1;
					if (a.category_d > b.category_d) return 1;
					return 0;
				}
			);

			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleReload} centered>
						<Modal.Body>
							更新しました。<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleReload}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowUpdate} onHide={this.handleClose} centered>
						<Modal.Body>
							本当に更新しますか？
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.updateSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowFlag} onHide={this.handleClose} centered>
						<Modal.Body>
							本当に更新しますか？
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.flagSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowDelete} onHide={this.handleClose} centered>
						<Modal.Body>本当に削除しますか？</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.deleteSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					 { data.map(
					 	(value, index) => {
							const categoryid = value.categoryid;
							const category_a  = value.category_a;
							const category_b  = value.category_b;
							const category_c  = value.category_c;
							const category_d  = value.category_d;
							const view_flag   = value.view_flag ? true : false;

							let evenstyle;//rowにstripedを適用
							if((index % 2) !== 0){
								evenstyle ={backgroundColor:'rgba(0,0,0,.05)',paddingTop:'.7rem',paddingBottom:'.7rem'};
							}else{
								evenstyle ={paddingTop:'.7rem',paddingBottom:'.7rem'};
							}


							return (
								<div key={index} className="text-nowrap" style={evenstyle}>
									<Form noValidate validated={this.props.isValidated} onSubmit={e => this.handleUpdate(e)} >
								        <Form.Control name="categoryid" type="hidden" defaultValue={categoryid} />
									<Row>
										<Col sm={1}>
										        <Button variant="secondary" type="button" block size="sm" onClick={()=>{ this.props.handleCopy(category_a, category_b, category_c, category_d); }} disabled={this.state.isLockForm} >コピー</Button>
										</Col>
										<Col sm={2}>
											<Form.Group controlId="category_a" style={{marginBottom:0}}>
											        <Form.Control name="category_a" type="text" placeholder="" size="sm" defaultValue={category_a} required pattern=".{1,255}" disabled={this.state.isLockForm} />
												<Form.Control.Feedback type="invalid">
													「カテゴリA」を正しく入力してください。
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
										<Col sm={2}>
											<Form.Group controlId="category_b" style={{marginBottom:0}}>
											        <Form.Control name="category_b" type="text" placeholder="" size="sm" defaultValue={category_b} pattern=".{1,255}" disabled={this.state.isLockForm} />
												<Form.Control.Feedback type="invalid">
													「カテゴリB」を正しく入力してください。
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
										<Col sm={2}>
											<Form.Group controlId="category_c" style={{marginBottom:0}}>
											        <Form.Control name="category_c" type="text" placeholder="" size="sm" defaultValue={category_c} pattern=".{1,255}" disabled={this.state.isLockForm} />
												<Form.Control.Feedback type="invalid">
													「カテゴリC」を正しく入力してください。
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
										<Col sm={2}>
											<Form.Group controlId="category_d" style={{marginBottom:0}}>
											        <Form.Control name="category_d" type="text" placeholder="" size="sm" defaultValue={category_d} pattern=".{1,255}" disabled={this.state.isLockForm} />
												<Form.Control.Feedback type="invalid">
													「カテゴリD」を正しく入力してください。
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
										<Col sm={2}>
											<Row>
												<Col sm={4}>
												        <Button variant={view_flag ? 'primary' : 'secondary'} type="button" block size="sm" disabled={this.state.isLockForm} onClick={()=>{ this.handleViewFlag(categoryid, view_flag ? false : true); }} >
												        	{view_flag ? '表示中' : '非表示'}
												        </Button>
												</Col>
												<Col sm={4}>
												        <Button variant="dark" type="submit" block size="sm" disabled={this.state.isLockForm} >更新</Button>
												</Col>
												<Col sm={4}>
												        <Button variant="danger" type="button" block size="sm" onClick={()=>{ this.handleDelete(categoryid); }} disabled={this.state.isLockForm} >削除</Button>
												</Col>
											</Row>
										</Col>
									</Row>
									</Form>
								</div>
							);
						}
					 )}

				</div>
			);

		}
	}
}

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isValidated    : false,
			isSuccess      : false,
			isShowCreate   : false,
			isLockForm     : false,
			formObj        : null,
			ErrMsg         : '',
			viewData       : [],
			defaultData    : [],
			category_a     : '',
			category_b     : '',
			category_c     : '',
			category_d     : '',
		}
		this._getData             = this._getData.bind(this);
		this.createSubmit         = this.createSubmit.bind(this);
		this.handleCreate         = this.handleCreate.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleCopy           = this.handleCopy.bind(this);
		this.handleReset          = this.handleReset.bind(this);
		this.handleSearch         = this.handleSearch.bind(this);
	}
	async _getData() {

		//処理
		try{
			//カテゴリの取得
			const Query = `query scanCategory {
				scanCategory {
					items {
						categoryid
						category_a
						category_b
						category_c
						category_d
						view_flag
					}
					nextToken
				}
			}`;
			let ref = await API.graphql(graphqlOperation(Query));
			let Items = ref.data.scanCategory.items;

			//カテゴリ情報の取得（未取得の続きデータがあった場合の処理）
			while (ref.data.scanCategory.nextToken) {
				const Query = `query scanCategory($nextToken: String) {
					scanCategory(nextToken: $nextToken) {
						items {
							categoryid
							category_a
							category_b
							category_c
							category_d
							view_flag
						}
						nextToken
					}
				}`;
				const Data = {
					nextToken: ref.data.scanCategory.nextToken,
				};
				//console.log(ref.data.scanCategory.nextToken);
				ref = await API.graphql(graphqlOperation(Query, Data));
				Items = Items.concat(ref.data.scanCategory.items);
			}

			//'null'文字がある場合は''に変換
			Items.some((item, index) => Object.keys(item).some(key => { if(item[key] === 'null'){ Items[index][key] = '' } return false; }));

			//データの保存
			this.setState({
				isLoading   : false,
				viewData    : Items,
				defaultData : Items,
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				isLoading  : false,
				ErrMsg     : err_msg,
			});
		}
	}
	async createSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowCreate : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formから直接取得する更新項目
			const data          = this.state.defaultData;
			const category_a    = formObj.category_a.value;
			const category_b    = formObj.category_b.value;
			const category_c    = formObj.category_c.value;
			const category_d    = formObj.category_d.value;

			//カテゴリの飛び空欄がないかチェック
			if(category_d !== '' && (category_b === '' || category_c === '')){
				this.setState({
					ErrMsg     : '※カテゴリデータが不正です。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			else if(category_c !== '' && category_b === ''){
				this.setState({
					ErrMsg     : '※カテゴリデータが不正です。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//同じ内容があるか検索
			if(data.filter((x, i) => { if(x.category_a === category_a && x.category_b === category_b && x.category_c === category_c && x.category_d === category_d){ return true; }else{ return false;} }).length !== 0){
				this.setState({
					ErrMsg     : '※すでに同じ内容が登録されています。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//カテゴリの登録
			const createQuery = `mutation createCategory($data: CategoryInput!) {
				createCategory(input: $data) {
					categoryid
				}
			}`;
			const createData = {
				data : {
					category_a  : category_a ? category_a : 'null',
					category_b  : category_b ? category_b : 'null',
					category_c  : category_c ? category_c : 'null',
					category_d  : category_d ? category_d : 'null',
					view_flag   : true,
				}
			};
			const ref = await API.graphql(graphqlOperation(createQuery, createData));

			//鑑査ログ
			await AuditLog('category', 'create', ref, createData, 'カテゴリの作成しました。');
		}
		catch(error) {
			//console.log(error);
			let err_msg = '';
			if(error.errors[0].errorType === 'DynamoDB:ConditionalCheckFailedException'){
				err_msg = '指定されたカテゴリIDはすでに登録されています。<br/>';
			}
			else if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			});
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	handleClose() {
		this.setState({
			isShowCreate   : false,
			isSuccess      : false,
			isValidated    : false,
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleCreate(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowCreate : true,
			formObj      : form,
		});
	}
	lockFormClose(){
		//
	}
	handleReload() {

		//Formオブジェクトの取得
		const formObj = this.state.formObj;

		//フォームのリセット
		if(formObj){
			formObj.reset();
		}

		//データの再取得
		this.setState({
			isLoading    : true,
			isShowCreate : false,
			isSuccess    : false,
			isValidated  : false,
			isLockForm   : false,
			ErrMsg       : '',
			viewData     : [],
			defaultData  : [],
		});
		this._getData();
	}
	handleCopy(category_a, category_b, category_c, category_d) {

		//検索文字列と対象データを取得
		const defaultData = this.state.defaultData;

		//検索文字オブジェクト
		const matchString_a = new RegExp(category_a);
		const matchString_b = new RegExp(category_b);
		const matchString_c = new RegExp(category_c);
		const matchString_d = new RegExp(category_d);

		//検索
		const viewData= defaultData.filter(item => (item.category_a.match(matchString_a) && item.category_b.match(matchString_b) && item.category_c.match(matchString_c) && item.category_d.match(matchString_d)) ? true :  false );

		//表示データ更新
		this.setState({
			category_a : category_a,
			category_b : category_b,
			category_c : category_c,
			category_d : category_d,
			viewData   : viewData,
		});
	}
	handleReset() {
		this.setState({
			category_a : '',
			category_b : '',
			category_c : '',
			category_d : '',
			viewData   : this.state.defaultData,
		});
	}
	handleSearch(e){

		//Submitをキャンセル
		e.preventDefault();
		e.stopPropagation();

		//検索文字列と対象データを取得
		const name  = e.target.name;
		const value = e.target.value;
		const defaultData = this.state.defaultData;

		//現在までの検索内容
		const category_a  = name === 'category_a' ? value : this.state.category_a;
		const category_b  = name === 'category_b' ? value : this.state.category_b;
		const category_c  = name === 'category_c' ? value : this.state.category_c;
		const category_d  = name === 'category_d' ? value : this.state.category_d;

		//検索文字オブジェクト
		const matchString_a = new RegExp(category_a);
		const matchString_b = new RegExp(category_b);
		const matchString_c = new RegExp(category_c);
		const matchString_d = new RegExp(category_d);

		//検索
		const viewData= defaultData.filter(item => (item.category_a.match(matchString_a) && item.category_b.match(matchString_b) && item.category_c.match(matchString_c) && item.category_d.match(matchString_d)) ? true :  false );

		//表示データ更新
		this.setState({
			viewData: viewData,
			[name]  : value,
		});
	}
	setSelectIDs (selectIDs){

		//デフォルトのデータを取得
		const defaultData = this.state.defaultData;

		//デフォルトのデータ内から選択されているIDのデータのみ抽出
		const csvData = defaultData.filter(item => selectIDs.indexOf(item.cognitoid) !== -1);

		//選択しているIDとCSV用データを保存
		this.setState({
			selectIDs: selectIDs,
			csvData  : csvData,
		});
	}
	async componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>
							更新しました。<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleReload}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowCreate} onHide={this.handleClose} centered>
						<Modal.Body>本当に登録しますか？</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.createSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleCreate(e)}>
					<Row>
						<Col sm={6}>
							<p><big>カテゴリ</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ this.handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 20 }} className="text-nowrap">
						<Col sm={1}>
						        <Button variant="secondary" type="reset" block size="" onClick={this.handleReset} disabled={this.state.isLockForm} >クリア</Button>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="category_a">
							        <Form.Control name="category_a" type="text" placeholder="カテゴリA" size="" required pattern=".{1,255}" defaultValue={this.state.category_a} onChange={e => this.handleSearch(e)} disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「カテゴリA」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="category_b">
							        <Form.Control name="category_b" type="text" placeholder="カテゴリB" size="" pattern=".{1,255}" defaultValue={this.state.category_b} onChange={e => this.handleSearch(e)} disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「カテゴリB」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="category_c">
							        <Form.Control name="category_c" type="text" placeholder="カテゴリC" size="" pattern=".{1,255}" defaultValue={this.state.category_c} onChange={e => this.handleSearch(e)} disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「カテゴリC」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="category_d">
							        <Form.Control name="category_d" type="text" placeholder="カテゴリD" size="" pattern=".{1,255}" defaultValue={this.state.category_d} onChange={e => this.handleSearch(e)} disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「カテゴリD」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
						        <Button variant="dark" type="submit" block size="" disabled={this.state.isLockForm} >追加</Button>
						</Col>
					</Row>
					</Form>
					<hr/>
					<MyList viewData={this.state.viewData} defaultData={this.state.defaultData} handleReload={this.handleReload} handleCopy={this.handleCopy} />
					<hr/>
					<CSVLink data={this.state.viewData} filename={"category.csv"} className="btn btn-secondary">CSVのダウンロード</CSVLink>
				</Container>
			);
		}
	}
}

export default withRouter(Default);
