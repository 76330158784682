import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Modal,
	Badge,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { AuditLog } from '../../Objects/AuditLog';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isValidated    : false,
			isSuccess      : false,
			isShowModal    : false,
			isLockForm     : false,
			formObj        : null,
			ErrMsg         : '',
			tmpl_name      : '',
		}
		this.handleReload     = this.handleReload.bind(this);
		this.handleSubmit     = this.handleSubmit.bind(this);
		this.handleShow       = this.handleShow.bind(this);
		this.handleClose      = this.handleClose.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
		this.lockFormClose    = this.lockFormClose.bind(this);
	}
	handleReload() {

		//Formオブジェクトの取得
		const formObj = this.state.formObj;

		//フォームのリセット
		formObj.reset();

		//データの初期化
		this.setState({
			isShowModal    : false,
			isSuccess      : false,
			isValidated    : false,
			isLockForm     : false,
			ErrMsg         : '',
			cognitoid      : '',
			mg_kbn_1       : '',
			mg_kbn_2       : '',
			mg_kbn_3       : '',
			en_kbn         : '',
			not_email_kbn  : '',
		});

	}
	async handleSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal : false,
			isValidated : true,
			isLockForm  : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//Formから直接取得する更新項目
			const tmpl_name    = formObj.tmpl_name.value;
			const tmpl_sort    = formObj.tmpl_sort.value;
			const tmpl_from    = formObj.tmpl_from.value;
			const tmpl_cc      = formObj.tmpl_cc.value;
			const tmpl_bcc     = formObj.tmpl_bcc.value;
			const tmpl_subject = formObj.tmpl_subject.value;
			const tmpl_msg     = formObj.tmpl_msg.value;
			
			//日付処理
		        const date           = new Date();
		        const isoDate        = date.toISOString();
		        
			//取得
			const Query = `query getMailTmpl($tmpl_name: String!) {
				getMailTmpl(tmpl_name: $tmpl_name) {
					tmpl_name
				}
			}`;
			const Data = {
				tmpl_name: tmpl_name,
			};
			const ref = await API.graphql(graphqlOperation(Query, Data));
			//console.log(ref);
			if(ref.data.getMailTmpl !== null){
				this.setState({
					ErrMsg     : '※すでに同じテンプレート名があります。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//プロフィールの登録
			const createQuery = `mutation createMailTmpl($data: MailTmplInput!) {
				createMailTmpl(input: $data) {
					tmpl_name
				}
			}`;
			const createData = {
				data : {
					tmpl_name     : tmpl_name,
					tmpl_sort     : tmpl_sort ? tmpl_sort : 0,
					tmpl_from     : tmpl_from,
					tmpl_cc       : tmpl_cc ? tmpl_cc : 'null',
					tmpl_bcc      : tmpl_bcc ? tmpl_bcc : 'null',
					tmpl_subject  : tmpl_subject,
					tmpl_msg      : tmpl_msg,
					date_add      : isoDate,
					date_edit     : isoDate,
				}
			};
			await API.graphql(graphqlOperation(createQuery, createData));

			//鑑査ログ
			await AuditLog('mailtmpl', 'create', tmpl_name, createData, 'メールテンプレートを登録しました。');

			this.setState({
				tmpl_name : tmpl_name,
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			});
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	handleClose() {
		this.setState({
			isShowModal    : false,
			isSuccess      : false,
			isValidated    : false,
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleShow(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowModal: true,
			formObj    : form,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this.setState({
			isLoading  : false,
		});
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>
							登録しました。<br/>
							続けて登録しますか？
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={()=>{ this.props.history.push('/MailTmpl/' + this.state.tmpl_name + '/'); }}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.handleReload}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowModal} onHide={this.handleClose} centered>
						<Modal.Body>本当に登録しますか？</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.handleSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleShow(e)}>
					<Row>
						<Col sm={8}>
							<p><big>メール送信テンプレートの新規登録</big></p>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							テンプレート名　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="tmpl_name">
							        <Form.Control name="tmpl_name" type="text" placeholder="テンプレート名" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「テンプレート名」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							並び順 順位
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="tmpl_sort">
							        <Form.Control name="tmpl_sort" type="text" placeholder="並び順 順位" size="lg" pattern="\d{1,3}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「並び順 順位」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							差出人アドレス　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="tmpl_from">
							        <Form.Control name="tmpl_from" type="email" placeholder="差出人アドレス" size="lg" required disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「差出人アドレス」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							CCアドレス
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="tmpl_cc">
							        <Form.Control name="tmpl_cc" type="email" placeholder="CCアドレス" size="lg" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「CCアドレス」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							BCCアドレス
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="tmpl_bcc">
							        <Form.Control name="tmpl_bcc" type="email" placeholder="BCCアドレス" size="lg" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「BCCアドレス」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メール件名　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="tmpl_subject">
							        <Form.Control name="tmpl_subject" type="text" placeholder="メール件名" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「メール件名」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メール本文　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="tmpl_msg">
							        <Form.Control name="tmpl_msg" as="textarea" rows="15" placeholder="" size="lg" required pattern=".{1,1000}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「メール本文」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >登録する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props.history.push('/MailTmpl/'); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					</Form>
				</Container>
			);
		}
	}
}

export default withRouter(Default);


