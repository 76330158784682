import React from 'react';
import { Auth } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	ButtonGroup,
	Button,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			cognitoid : '',
			email : '',
		}
		this._reload       = this._reload.bind(this);
		this._getData      = this._getData.bind(this);
	}
	_reload() {
		this._getData();
	}
	async _getData() {
		//Cognito IDの取得
		const user      = await Auth.currentAuthenticatedUser();
		//console.log(user);
		const cognitoid = user.username;
		const email     = user.attributes.email;
		this.setState({ cognitoid: cognitoid });
		this.setState({ email: email });
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		return (
			<Container style={{ marginTop: 100, marginBottom: 200 }}>
				<Form>
					<Form.Group as={Row} controlId="formUserName" size="lg">
						<Col sm={{ span: 2, offset: 2 }}>
							<Form.Label>アカウントID</Form.Label>
						</Col>
						<Col sm={6}>
							<Form.Control plaintext readOnly defaultValue={this.state.cognitoid} />
						</Col>
					</Form.Group>
					<Form.Group as={Row} controlId="formEmail" size="lg">
						<Col sm={{ span: 2, offset: 2 }}>
							<Form.Label>メールアドレス</Form.Label>
						</Col>
						<Col sm={6}>
							<Form.Control plaintext readOnly defaultValue={this.state.email} />
						</Col>
					</Form.Group>
					<Form.Group as={Row} controlId="formPassword" size="lg">
						<Col sm={{ span: 2, offset: 2 }}>
							<Form.Label>パスワード</Form.Label>
						</Col>
						<Col sm={6}>
							<Form.Control plaintext readOnly defaultValue="*****" />
						</Col>
					</Form.Group>
					<Row style={{marginTop: "40px"}}>
						<Col sm={{ span: 8, offset: 2 }}>
							<ButtonGroup>
								<Button variant="dark" onClick={() => this.props.history.push('/Login/Email/')}>メールアドレスを変更する</Button>
								<Button variant="dark" onClick={() => this.props.history.push('/Login/Password/')}>パスワードを変更する</Button>
							</ButtonGroup>
						</Col>
					</Row>
				</Form>
			</Container>
		);
	}
}

export default withRouter(Default);


