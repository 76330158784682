import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Modal,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import NotFound from '../../Objects/NotFound';
import { AuditLog } from '../../Objects/AuditLog';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading       : true,
			isNotFound      : false,
			isValidated     : false,
			isSuccess       : false,
			isShowUpdate    : false,
			isLockForm      : false,
			formObj         : null,
			formFlag        : '',
			ErrMsg          : '',
			notSelectedData : [],
			SelectedData    : [],
			AllData         : [],
			code            : '',
			name            : '',
			categoryids     : [],
		}
		this.handleReload      = this.handleReload.bind(this);
		this.updateSubmit      = this.updateSubmit.bind(this);
		this.handleDismiss     = this.handleDismiss.bind(this);
		this.handleUpdate      = this.handleUpdate.bind(this);
		this.handleClose       = this.handleClose.bind(this);
		this.lockFormClose     = this.lockFormClose.bind(this);
		this._getData          = this._getData.bind(this);
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	async _getData() {
		
		//処理
		try{

			//商品コードの取得
			const code = this.props.match.params.code;
			if(!code){
					this.setState({
						isLoading   : false,
						ErrMsg  : 'ユーザIDが読み取れません。<br/>',
					});
					return(0);
			}

			//商品データの取得
			const ItemQuery = `query getItem($code: String!) {
				getItem(code: $code) {
					code
					name
					categoryids
				}
			}`;
			const ItemData = { code: code };
			const refItem = await API.graphql(graphqlOperation(ItemQuery, ItemData));
			//console.log(refItem);
		
			//商品データが見当たらない場合
			if(refItem.data.getItem === null){
				this.setState({
					isLoading   : false,
					isNotFound  : true,
				});
				return(0);
			}

			//商品名の取得
			const name = refItem.data.getItem.name;

			//選択済みカテゴリの取得
			const categoryids = refItem.data.getItem.categoryids ? refItem.data.getItem.categoryids : [];

			//カテゴリの取得
			const CategoryQuery = `query scanCategory {
				scanCategory {
					items {
						categoryid
						category_a
						category_b
						category_c
						category_d
					}
					nextToken
				}
			}`;
			let refCategory = await API.graphql(graphqlOperation(CategoryQuery));
			let CategoryItems = refCategory.data.scanCategory.items;
			
			//カテゴリ情報の取得（未取得の続きデータがあった場合の処理）
			while (refCategory.data.scanCategory.nextToken) {
				const CategoryQuery = `query scanCategory($nextToken: String) {
					scanCategory(nextToken: $nextToken) {
						items {
							categoryid
							category_a
							category_b
							category_c
							category_d
						}
						nextToken
					}
				}`;
				const CategoryData = {
					nextToken: refCategory.data.scanCategory.nextToken,
				};
				//console.log(refCategory.data.scanCategory.nextToken);
				refCategory = await API.graphql(graphqlOperation(CategoryQuery, CategoryData));
				CategoryItems = CategoryItems.concat(refCategory.data.scanCategory.items);
			}
			
			//'null'文字がある場合は''に変換
			CategoryItems.some((item, index) => Object.keys(item).some(key => { if(item[key] === 'null'){ CategoryItems[index][key] = '' } return false; }));

			//データをソート
			CategoryItems.sort(
				(a, b) => {
					if (a.category_a < b.category_a) return -1;
					if (a.category_a > b.category_a) return 1;
					if (a.category_b < b.category_b) return -1;
					if (a.category_b > b.category_b) return 1;
					if (a.category_c < b.category_c) return -1;
					if (a.category_c > b.category_c) return 1;
					if (a.category_d < b.category_d) return -1;
					if (a.category_d > b.category_d) return 1;
					return 0;
				}
			);

			//選択済みカテゴリ情報にその他項目を追加する
			const SelectedData = CategoryItems.filter(x => categoryids.findIndex(categoryid => categoryid === x.categoryid) === -1 ? false : true );

			//選んでいるものを一覧から外す
			const notSelectedData = CategoryItems.filter(x => categoryids.findIndex(categoryid => categoryid === x.categoryid) === -1 ? true : false );
			
			//データの保存
			this.setState({
				isLoading       : false,
				notSelectedData : notSelectedData,
				SelectedData    : SelectedData,
				AllData         : CategoryItems,
				code            : code,
				name            : name,
				categoryids     : categoryids,
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				isLoading  : false,
				ErrMsg     : err_msg,
			});
		}
	}
	async updateSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowUpdate : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//Formオブジェクトの確認
		const formObj     = this.state.formObj;
		if(!formObj){
			this.setState({
				ErrMsg     : '※不正なエラーが発生しました。<br/>',
				isLockForm : false,
			 });
			return(0);
		}

		//Formオブジェクトの確認
		if(formObj.checkValidity() === false){
			this.setState({
				ErrMsg     : '※入力項目にエラーがありました。<br/>',
				isLockForm : false,
			});
			return(0);
		}

		//Stateから取得する更新項目
		const code        = this.state.code;
		const categoryids = this.state.categoryids;
		const AllData     = this.state.AllData;

		//どちらの処理か
		const formFlag     = this.state.formFlag;

		//更新処理
		try{
			if(formFlag === 'insert'){
				
				//Formから直接取得する更新項目
				const selected = [].slice.call(formObj.notSelected.selectedOptions).map(o => o.value);
				
				//結合
				const data = categoryids.concat(selected);
				
				//重複を削除
				const newData = data.filter((x, i, self) => self.indexOf(x) === i);
				
				//フラグ判定
				let cosme_flag = false;
				let stationery_flag = false;
				
				//フラグ判定用データ
				const flagData = AllData.filter(x => newData.findIndex(categoryid => categoryid === x.categoryid) === -1 ? false : true );

				//判定
				const cosmeRef = flagData.filter(x => x.category_a === 'コスメ');
				const stationeryRef = flagData.filter(x => x.category_a === '文具');
				
				//フラグ付け
				if(cosmeRef.length !== 0){ cosme_flag = true; }
				if(stationeryRef.length !== 0){ stationery_flag = true; }
				
				console.log(cosme_flag);
				console.log(stationery_flag);

				//商品の更新登録
				const Query = `mutation updateItem($data: ItemInput!) {
					updateItem(input: $data) {
						code
					}
				}`;
				const Data = {
					data : {
						code        : code,
						categoryids : newData,
						cosme_flag      : cosme_flag,
						stationery_flag : stationery_flag,
					}
				};
				await API.graphql(graphqlOperation(Query, Data));

				//鑑査ログ
				await AuditLog('item', 'update', code, Data, 'カテゴリ情報を追加変更しました。');
			}
			else if(formFlag === 'drop'){

				//Formから直接取得する更新項目
				const selected = [].slice.call(formObj.Selected.selectedOptions).map(o => o.value);

				//選んでいるものを一覧から外す
				const data = categoryids.filter(x => selected.findIndex(categoryid => categoryid === x) === -1 ? true : false );

				//重複を削除
				const newData = data.filter((x, i, self) => self.indexOf(x) === i);

				//フラグ判定
				let cosme_flag = false;
				let stationery_flag = false;
				
				//フラグ判定用データ
				const flagData = AllData.filter(x => newData.findIndex(categoryid => categoryid === x.categoryid) === -1 ? false : true );

				//判定
				const cosmeRef = flagData.filter(x => x.category_a === 'コスメ');
				const stationeryRef = flagData.filter(x => x.category_a === '文具');
				
				//フラグ付け
				if(cosmeRef.length !== 0){ cosme_flag = true; }
				if(stationeryRef.length !== 0){ stationery_flag = true; }

				//商品の更新登録
				const Query = `mutation updateItem($data: ItemInput!) {
					updateItem(input: $data) {
						code
					}
				}`;
				const Data = {
					data : {
						code        : code,
						categoryids : newData,
						cosme_flag      : cosme_flag,
						stationery_flag : stationery_flag,
					}
				};
				await API.graphql(graphqlOperation(Query, Data));

				//鑑査ログ
				await AuditLog('item', 'update', code, Data, 'カテゴリ情報を削除変更しました。');
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isValidated : false,
			isLockForm  : false,
		});
		
		//選択リセット
		formObj.reset();

		//データ更新
		this._getData();
	}
	handleDismiss() {
		this.setState({
			ErrMsg     : '',
			isLockForm : false,
		});
	}
	handleClose() {
		this.setState({
			isShowUpdate : false,
			isSuccess    : false,
			isValidated  : false,
			isLockForm   : false,
		});
	}
	handleUpdate(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowUpdate : true,
			formObj      : form,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else if(this.state.isNotFound){
			return (
				<NotFound />
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleDismiss} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleDismiss}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>更新しました。</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowUpdate} onHide={this.handleClose} centered>
						<Modal.Body>
							本当に更新しますか？<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.updateSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Form>
					<Row>
						<Col sm={6}>
							<p><big>商品情報へのカテゴリ登録</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ this.handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							商品コード
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="code">
							        <Form.Control name="code" type="text" defaultValue={this.state.code} size="lg" readOnly style={{ marginTop: 20 }} />
								<Form.Control.Feedback />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							商品名
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="name">
							        <Form.Control name="name" type="text" defaultValue={this.state.name} size="lg" readOnly />
								<Form.Control.Feedback/>
							</Form.Group>
						</Col>
					</Row>
					</Form>
					<hr/>
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleUpdate(e)}>
					<Row>
						<Col sm={12}>
							<Form.Group controlId="category">
								<Form.Label>
									カテゴリ一覧
								</Form.Label>
								<select multiple size="20" name="notSelected" className="form-control" required={this.state.formFlag === 'insert'}>
									{ this.state.notSelectedData.map(
									 	(item, index) => {
											const id = item.categoryid;
											let value = item.category_a;
											if(item.category_b){
												value = value + '->' + item.category_b;
												if(item.category_c){
													value = value + '->' + item.category_c;
													if(item.category_d){
														value = value + '->' + item.category_d;
													}
												}
											}
											return (
												<option key={index} value={id}>{value}</option>
											);
										}
									) }
								</select>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={6} className="text-right">
						        <Button variant="secondary" type="submit" size="lg" disabled={this.state.isLockForm} onClick={()=>{ this.setState({ formFlag: 'insert' }); }} >↓</Button>
						</Col>
						<Col sm={6}>
						        <Button variant="secondary" type="submit" size="lg" disabled={this.state.isLockForm} onClick={()=>{ this.setState({ formFlag: 'drop' }); }} >↑</Button>
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<Form.Group controlId="category">
								<Form.Label>
									登録済みカテゴリ
								</Form.Label>
								<select multiple size="20" name="Selected"className="form-control" required={this.state.formFlag === 'drop'}>
									{ this.state.SelectedData.map(
									 	(item, index) => {
											const id = item.categoryid;
											let value = item.category_a;
											if(item.category_b){
												value = value + '->' + item.category_b;
												if(item.category_c){
													value = value + '->' + item.category_c;
													if(item.category_d){
														value = value + '->' + item.category_d;
													}
												}
											}
											return (
												<option key={index} value={id}>{value}</option>
											);
										}
									) }
								</select>
							</Form.Group>
						</Col>
					</Row>
					</Form>
					<hr/>
				</Container>
			);
		}
	}
}

export default withRouter(Default);


