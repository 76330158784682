import React from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Button,
	Image,
	Modal,
	ProgressBar,
	Table,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import NotFound from '../../Objects/NotFound';


class FormA extends React.Component {
	render() {

		//日付
		const dateObj         = new Date(this.props.data.date_add);
		const dateObj_year    = dateObj.getFullYear().toString();
		const dateObj_month   = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const dateObj_day     = ('00' + dateObj.getDate().toString()).slice(-2);
		const dateObj_hour    = ('00' + dateObj.getHours().toString()).slice(-2);
		const dateObj_minute  = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const dateObj_second  = ('00' + dateObj.getSeconds().toString()).slice(-2);
		const date_add        = dateObj_year + '年' + dateObj_month + '月' + dateObj_day + '日' + dateObj_hour + '時' + dateObj_minute + '分' + dateObj_second + '秒';

		return (

			<div id="pagebody">
				<h1 className="formstyle">マインドウェイブ オンラインストア 受注伝票</h1>
						{/*<div className="wdata">受注ID：{ this.props.data.orderno }  / 注文日：{ date_add }</div>*/}
						<Table bordered id="" className="denpyo mt-3"></Table>
		<Table bordered id="" className="denpyo mt-3">
  <tbody>
    <tr>
      <th scope="row">受注ID</th>
      <td>{ this.props.data.orderno }</td>
      <th>サイト区分</th>
      <td>{ this.props.data.flag }</td>
      <td rowspan="2" className="txt_ll shipping">{ this.props.data.carriage_type }</td>
    </tr>
    <tr>
      <th scope="row">注文日</th>
      <td>{ date_add }</td>
      <th>会員区分</th>
      <td className={ (Number(this.props.data.total_price) >= 3000) ? !this.props.data.not_ec_user_kbn ? 'kaiin' : {}: {}} >
		  <div style={(Number(this.props.data.total_price) >= 3000) ? !this.props.data.not_ec_user_kbn ? {color:'white'} : {}: {}}>{ this.props.data.not_ec_user_kbn ? '非会員' : '会員' }</div>
	  </td>
      </tr>
  </tbody>
</Table>

				<Table bordered id="add" className="denpyo mt-3">
				<tbody>
					<tr>
						<td>
							<dl>
							<dt>【ご注文者】</dt>
							<dd className="names">{ this.props.data.name_1 } { this.props.data.name_2 }&nbsp;様</dd>
							<dd>
							〒{ this.props.data.zip_1 }-{ this.props.data.zip_2 }<br />
						{ this.props.data.pref }{ this.props.data.addr_1 }{ this.props.data.addr_2 }<br />
					{ this.props.data.addr_3 === 'null' ? '' : this.props.data.addr_3 }</dd>
				<dd>TEL：{ this.props.data.tel_1 }-{ this.props.data.tel_2 }-{ this.props.data.tel_3 }</dd>
							</dl>
						</td>
						<td>
							<dl>
							<dt>【お届け先】</dt>
							<dd className="names">{ this.props.data.o_name_1 } { this.props.data.o_name_2 }&nbsp;様</dd>
							<dd>
							〒{ this.props.data.o_zip_1 }-{ this.props.data.o_zip_2 }<br />
							{ this.props.data.o_pref }{ this.props.data.o_addr_1 }{ this.props.data.o_addr_2 }<br />
							{ this.props.data.o_addr_3 === 'null' ? '' : this.props.data.o_addr_3 }
							{ this.props.data.o_com_name }
							</dd>
							<dd>TEL：{ this.props.data.o_tel_1 }-{ this.props.data.o_tel_2 }-{ this.props.data.o_tel_3 }</dd>
							</dl>
						</td>
					</tr>
				</tbody>
				</Table>
				<h3 className="orderlistas">ご注文内容</h3>
				<Table border="0" cellSpacing="0" cellPadding="0" id="items" className="denpyo">
				<tbody>
					<tr>
						<th className="lb1">商品コード</th>
						<th>商品名</th>
						<th>単価</th>
						<th>個数</th>
						<th>金額</th>
					</tr>
					 { this.props.data.order_items.map(
					 	(data, index) => {
					 		if(data === null){
					 			return (
									<tr key={index}><td className="cside" colSpan="6">（受注データがありません。）</td></tr>
								);
							}
							else{
								return (
									<tr key={index}>
										<td className="lb2">{ data.code }</td>
										<td>{ data.name }</td>
										<td nowrap="nowrap" className="text-right">{ data.price ? data.price.toLocaleString() : 0 }円</td>
										<td nowrap="nowrap" className="cside">{ data.piece ? data.piece.toLocaleString() : 0 }{ data.piece_type }</td>
										<td nowrap="nowrap" className="text-right">{ data.sub_price ? data.sub_price.toLocaleString() : 0 }円</td>
									</tr>
								);
							}
						}
					 )}
				</tbody>
				</Table>

				<div id="prices">
					<Table className="denpyo">
					<tbody>
						<tr>
							<th className="text-right" colSpan="3">商品合計</th>
							<td className="text-right txt_bold">{ this.props.data.total_price ? this.props.data.total_price.toLocaleString() : 0 }円</td>
						</tr>
						<tr>
						<th>お届け方法</th>
						<td className="ltx">{ this.props.data.carriage_type }</td>
						<th className="text-right">送料</th>
						<td className="text-right">{ this.props.data.carriage ? this.props.data.carriage.toLocaleString() : 0 }円</td>
						</tr>
						<tr>
							<th>支払方法</th>
							<td>{ this.props.data.payment_type }</td>
							<th className="text-right">代引・後払い手数料</th>
							<td className="text-right">{ this.props.data.cashdeli ? this.props.data.cashdeli.toLocaleString() : 0 }円</td>
						</tr>
						<tr>
						<th>ギフトラッピング</th>
						<td className="ltx">{ this.props.data.wrapping_type ? this.props.data.wrapping_type : '指定なし' }</td>
							<th className="text-right">ギフト手数料</th>
							<td className="text-right">{ this.props.data.wrapping ? this.props.data.wrapping.toLocaleString() : 0 }円</td>
						</tr>
						<tr>
						<th>クーポン</th>
						<td className="ltx">{ this.props.data.coupon_type ? this.props.data.coupon_type : '指定なし' }</td>
							<th className="text-right">クーポン</th>
							<td className="text-right">{ this.props.data.coupon ? this.props.data.coupon.toLocaleString() : 0 }円</td>
						</tr>
						<tr>
							<th className="text-right" colSpan="3">総合計金額</th>
							<td className="text-right"><span className="totalp">{ this.props.data.payment_price ? this.props.data.payment_price.toLocaleString() : 0 }円</span></td>
						</tr>
					</tbody>
					</Table>
				</div>
				<br clear="all"/>
				{/*<Table border="0" cellSpacing="0" cellPadding="0" id="option" className="denpyo">
				<tbody>
					<tr>
						<th>サイト区分</th>
						<td className="ltx">{ this.props.data.flag }</td>
						<th>会員区分</th>
						<td>{ this.props.data.not_ec_user_kbn ? '非会員' : '会員' }</td>
					</tr>
					<tr>
						<th>配達希望日</th>
						<td className="ltx">
							{ this.props.data.o_date_type ? this.props.data.o_date_type : '指定なし' }
						</td>
						<th>時間指定</th>
						<td className="ltx">{ this.props.data.o_date_h ? this.props.data.o_date_h : '指定なし' }</td>
					</tr>
					<tr>
						<th>備考</th>
						<td colSpan="3" >{ this.props.data.order_memo }&nbsp;</td>
					</tr>
					<tr>
					</tr>
					<tr>
					</tr>
				</tbody>
				</Table>*/}
				<Table border="0" cellSpacing="0" cellPadding="5" id="option" className="denpyo">
				<tbody>
					<tr>
						<th>配達希望日</th>
						<td className="ltx txt_l">{ this.props.data.o_date_type ? this.props.data.o_date_type : '指定なし' }</td>
						<th>ギフトラッピング</th>
						<td className="txt_l txt_bold">{ this.props.data.wrapping_type ? this.props.data.wrapping_type : '指定なし' }</td>{/*.txt_bold追加220203*/}
					</tr>
					<tr>
						<th>時間指定</th>
						<td className="ltx txt_l">
							{ this.props.data.o_date_h ? this.props.data.o_date_h : '指定なし' }
						</td>
						<th>クーポン</th>
						<td className="ltx txt_l">{ this.props.data.coupon_type ? this.props.data.coupon_type : '指定なし' }</td>
					</tr>
					<tr>
						<th>備考</th>
						<td colSpan="3" className="txt_l">{ this.props.data.order_memo }&nbsp;</td>
					</tr>
				</tbody>
				</Table>
				<Table border="0" cellPadding="5" cellSpacing="0" id="delivery_message" className="denpyo">
				<tbody>
					<tr>
						<th className="">出荷指示</th>
						<td className="txt_l txt_bold">{ this.props.data.delivery_message }</td>
					</tr>
				</tbody>
				</Table>
				{/*<Table border="0" cellPadding="0" cellSpacing="0" id="delivery">
				<tbody>
					<tr>
						<th>送り状NO.</th>
						<td>{ this.props.data.delivery_no }</td>
						<th>出荷日</th>
						<td className="w120">{ this.props.data.delivery_date }</td>
					</tr>
				</tbody>
				</Table>*/}
					<hr className="kaigyo" />
			</div>

		);
	}
}

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			loadingPer     : 0,
			isNotFound     : false,
			ErrMsg         : '',
			viewData       : [],
		}
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this._getData             = this._getData.bind(this);
	}
	async _getData() {

		try{
			let selectIDs = [];

			if(this.props.match.params.selectIDs){

				//IDの取得
				selectIDs = JSON.parse(this.props.match.params.selectIDs);
				if(selectIDs.length === 0){
						this.setState({
							isLoading : false,
							ErrMsg    : '※データがありません。<br/>',
						});
						return(0);
				}
			}
			else{

				try{
					//ストレージを定義
					Storage.configure({ AWSS3: { bucket: 'mindwavestore.admin', region: 'ap-northeast-1', level: 'private' } });

					//S3からファイルを取得
					const result   = await Storage.get('formA.json', { download: true });

					//データ
					const jsonText = await new Promise(resolve =>
					{
						const reader = new FileReader();
						reader.onload = () => resolve(reader.result);
						reader.readAsText(result.Body);
					});

					//JSON形式を変換
					const jsonData = JSON.parse(jsonText);

					//データ取得
					selectIDs = jsonData.selectIDs;
				}
				catch(err){
					//console.log(err);
				}
			}

			//console.log(selectIDs);

			//データ
			const viewData = [];

			//カウント
			let i = 0;

			//取得
			for(let orderno of selectIDs){

				//データ取得
				const Query = `query getOrder($orderno: String!) {
					getOrder(orderno: $orderno) {
						orderno
						cognitoid
						total_price
						payment_type
						payment_price
						carriage_type
						carriage
						cashdeli
						wrapping_type
						wrapping
						coupon_type
						coupon
						email
						name_1
						name_2
						name_furi_1
						name_furi_2
						zip_1
						zip_2
						pref
						addr_1
						addr_2
						addr_3
						tel_1
						tel_2
						tel_3
						o_name_1
						o_name_2
						o_name_furi_1
						o_name_furi_2
						o_zip_1
						o_zip_2
						o_pref
						o_addr_1
						o_addr_2
						o_addr_3
						o_tel_1
						o_tel_2
						o_tel_3
						o_com_name
						o_date_type
						o_date_h
						order_memo
						delivery_no
						delivery_date
						status
						memo
						delivery_message
						flag
						not_ec_user_kbn
						date_add
						date_edit
						order_items{
							orderno
							code
							name
							price
							sub_price
							piece
							piece_type
							date_add
							date_edit
						}
					}
				}`;
				const Data = { orderno: orderno };
				const ref = await API.graphql(graphqlOperation(Query, Data));
				let item = ref.data.getOrder;

				//データが空かどうか
				if(item === null){

					//データ取得
					const Query = `query getOldOrder($orderno: String!) {
						getOldOrder(orderno: $orderno) {
							orderno
							cognitoid
							total_price
							payment_type
							payment_price
							carriage_type
							carriage
							cashdeli
							wrapping_type
							wrapping
							coupon_type
							coupon
							email
							name_1
							name_2
							name_furi_1
							name_furi_2
							zip_1
							zip_2
							pref
							addr_1
							addr_2
							addr_3
							tel_1
							tel_2
							tel_3
							o_name_1
							o_name_2
							o_name_furi_1
							o_name_furi_2
							o_zip_1
							o_zip_2
							o_pref
							o_addr_1
							o_addr_2
							o_addr_3
							o_tel_1
							o_tel_2
							o_tel_3
							o_com_name
							o_date_type
							o_date_h
							order_memo
							delivery_no
							delivery_date
							status
							memo
							delivery_message
							flag
							not_ec_user_kbn
							date_add
							date_edit
							order_items{
								orderno
								code
								name
								price
								sub_price
								piece
								piece_type
								date_add
								date_edit
							}
						}
					}`;
					const Data = { orderno: orderno };
					const ref = await API.graphql(graphqlOperation(Query, Data));
					item = ref.data.getOldOrder;

					//データが空かどうか
					if(item === null){
						continue;
					}
				}

				//'null'を空文字に
				if(item.coupon_type   === 'null'){ item.coupon_type = ''; }
				if(item.addr_3        === 'null'){ item.addr_3 = ''; }
				if(item.o_addr_3      === 'null'){ item.o_addr_3 = ''; }
				if(item.o_com_name    === 'null'){ item.o_com_name = ''; }
				if(item.order_memo    === 'null'){ item.order_memo = ''; }
				if(item.delivery_no   === 'null'){ item.delivery_no = ''; }
				if(item.delivery_date === 'null'){ item.delivery_date = ''; }
				if(item.memo          === 'null'){ item.memo = ''; }
				if(item.delivery_message === 'null'){ item.delivery_message = ''; }

				//'null'を空文字に
				if(item.coupon_type   === null){ item.coupon_type = ''; }
				if(item.addr_3        === null){ item.addr_3 = ''; }
				if(item.o_addr_3      === null){ item.o_addr_3 = ''; }
				if(item.o_com_name    === null){ item.o_com_name = ''; }
				if(item.order_memo    === null){ item.order_memo = ''; }
				if(item.delivery_no   === null){ item.delivery_no = ''; }
				if(item.delivery_date === null){ item.delivery_date = ''; }
				if(item.memo          === null){ item.memo = ''; }
				if(item.delivery_message === null){ item.delivery_message = ''; }

				//データ格納
				viewData.push(item);

				//進捗情報
				this.setState({
					loadingPer : Math.floor(i++ / selectIDs.length * 100),
				});
			}

			//データが空かどうか
			if(viewData.length === 0){
			this.setState({
				ErrMsg     : '※データがありません。<br/>',
				isLoading  : false,
			 });
			return(0);
			}

			this.setState({
				isLoading : false,
				viewData  : viewData,
			});
		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLoading  : false,
			 });
			return(0);
		}
	}
	handleErrorClose() {
		this.setState({
			ErrMsg         : '',
		});
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading && this.state.loadingPer){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={{ span: 6, offset: 3 }}>
						<ProgressBar now={this.state.loadingPer} label={this.state.loadingPer + `%`} animated />
					</Col>
				</Row>
			);
		}
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else if(this.state.isNotFound){
			return (
				<NotFound />
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					 { this.state.viewData.map(
					 	(data, index) => {
							return (
								<Container key={index} style={{ marginTop: 20 }} >
									<FormA data={data}/>
								</Container>
							);
						}
					 )}


				</div>

			);
		}
	}
}

export default withRouter(Default);
