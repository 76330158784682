import React from 'react';
import {
	Container,
	Row,
	Col,
	Image,
	Table,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import NotFound from './NotFound';


class Default extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading      : true,
			permit_flag    : false,
		}
		this._click = this._click.bind(this);
	}
	_click(uri) {
		if(uri){
			this.props.history.push(uri);
		}
	}
	async componentDidMount () {
		this.setState({
			isLoading   : false,
		});
	}
	render() {
		if(this.state.permit_flag){
			return (
				<NotFound/>
			);
		}
		else if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
					<Table bordered>
					<thead>
						<tr>
							<th>Version</th>
							<th>Date</th>
							<th>Update</th>
							<th>Author</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>ver.2.1010</td>
							<td>2020.06.09</td>
							<td>
								・ファイル共有開始
							</td>
							<td></td>
						</tr>
						<tr>
							<td>ver.2.1011</td>
							<td>2020.06.10</td>
							<td>
								・orderテーブルの見た目の変更（.table-striped,.table-sm, .table-hover）<br/>
								・style.css変更（html|fontSize:12px, .table > tbody > tr > td | vertical-align: middle）<br/>
								・表示件数オプション [200件]追加
							</td>
							<td>大塚</td>
						</tr>
						<tr>
							<td>ver.2.1012</td>
							<td>2020.06.10</td>
							<td>
								・詳細ボタン、メールボタン、リピーターボタン（.btn-sm追加）<br/>
								・ステータスselect（form-control-sm追加 form-groupのmarginBottom:0）<br/>
								・「取得時間」「表示件数」のbadgeのスタイル修正
							</td>
							<td>大塚</td>
						</tr>
						<tr>
							<td>ver.2.1013</td>
							<td>2020.06.11</td>
							<td>
								・カテゴリーページ ボタン、フォームのサイズダウン
							</td>
							<td>大塚</td>
						</tr>
						<tr>
							<td>ver.2.1014</td>
							<td>2020.06.11</td>
							<td>
								・AWS amplify APIのバージョンアップで、Storage.getが仕様変更。BLOB形式を読むように改修。
								対象は商品一覧、ユーザ一覧、受注伝票2種類。<br/>
							</td>
							<td>斧山</td>
						</tr>
						<tr>
							<td>ver.2.1015</td>
							<td>2020.06.16</td>
							<td>
								・受注伝票、明細書のレイアウト修正（FormA.js、FormB.js、style.css 43行目以下追加）<br/>
							</td>
							<td>芦田</td>
						</tr>
						<tr>
							<td>ver.2.1016</td>
							<td>2020.06.19</td>
							<td>
								Order/Main.js<br/>
								・ORDERテーブルの表示件数とページネーションを上下に追加(PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone)<br/>
								・「選択件数：◯件」を表示するように、2536行に追加
							</td>
							<td>大塚</td>
						</tr>
						<tr>
							<td>ver.2.1017</td>
							<td>2020.07.03</td>
							<td>
							Order/Details.js<br/>
								・発送先情報の電話番号2の引用に誤りあり修正（1724行目、tel_2をo_tel_2に修正）<br/>
								・表示順を変更（注文内容と発送情報を最下部に移動）
							</td>
							<td>芦田</td>
						</tr>
						<tr>
							<td>ver.2.1018</td>
							<td>2020.07.09</td>
							<td>
							App.js<br/>
								・誤ってMailTmplDetailsのリンク部分を変更していたようなので修正
							</td>
							<td>大塚</td>
						</tr>
						<tr>
							<td>ver.2.1019</td>
							<td>2020.07.30</td>
							<td>
								・商品マスタのデータをDynamoDBからElasticSearchに手動で同期が取れるよう機能ボタンの追加
							</td>
							<td>斧山</td>
						</tr>
						<tr>
							<td>ver.2.1020</td>
							<td>2020.08.28</td>
							<td>
								・一括メール送信機能を実装
							</td>
							<td>斧山</td>
						</tr>
						<tr>
							<td>ver.2.1021</td>
							<td>2020.09.02</td>
							<td>
								・メール送信機能のデフォルト送信先をユーザ情報のメールアドレス優先から受注データのメールアドレス優先に変更
							</td>
							<td>斧山</td>
						</tr>
						<tr>
							<td>ver.2.1022</td>
							<td>2020.10.01</td>
							<td>
								・取引登録CSVデータにおいて、ギフトラッピング手数料の明細行が出力されていななかったものを修正
							</td>
							<td>斧山</td>
						</tr>
						<tr>
							<td>ver.2.1023</td>
							<td>2021.01.07</td>
							<td>
								・商品在庫の履歴管理のためのデバッグコードを追加
							</td>
							<td>斧山</td>
						</tr>
						<tr>
							<td>ver.2.1024</td>
							<td>2021.06.18</td>
							<td>
							・明細書の文章・レイアウト修正（FormB.js、style.css）
							</td>
							<td>芦田</td>
						</tr>
						<tr>
							<td>ver.2.1025</td>
							<td>2022.02.03</td>
							<td>
								Order/Main.js<br/>
								・ORDERテーブルのレイアウト及び表示フォーマット変更（受注日, 会員, リピ, メモ, フラグ, [_dataFormatDate2], [_dataFormatOdate], [_dataFormatIcon]）<br/>
								・ORDERテーブルの表示項目追加（order_memo, delivery_date, [_dataFormatOverlay2]）
							</td>
							<td>大塚</td>
						</tr>
						<tr>
							<td>ver.2.1026</td>
							<td>2022.02.03</td>
							<td>
							Analysis/User.js<br/>
								・ORDERテーブルのレイアウト及び表示フォーマット変更（受注日, 会員, メモ, フラグ, [_dataFormatDate2], [_dataFormatOdate], [_dataFormatIcon]）<br/>
								・ORDERテーブルの表示項目追加（order_memo, delivery_date, [_dataFormatOverlay2]）<br/>
								Order/FormA.js 一部font-weight変更
							</td>
							<td>芦田</td>
						</tr>
						<tr>
							<td>ver.2.1027</td>
							<td>2022.02.17</td>
							<td>
								・受注一覧画面においてデータ取得時間が不適切なケースがあったものを修正<br/>
								・受注一覧画面においてのCSV出力でorder1.csv、order2.csvのみ項目をダブルクォテーション囲みに変更<br/>
							</td>
							<td>斧山</td>
						</tr>
						<tr>
							<td>ver.2.1028</td>
							<td>2022.02.18</td>
							<td>
								・ステータスに「処理中」「確認中」を追加<br/>
								・受注データに出荷指示を追加<br/>
								・受注伝票に出荷指示を追加<br/>
							</td>
							<td>斧山</td>
						</tr>
						<tr>
							<td>ver.2.1029</td>
							<td>2022.02.19</td>
							<td>
								・受注一覧画面において注文者がバウンス及びブロック対象のメールアドレスの場合、氏名を赤字表示にするように変更<br/>
							</td>
							<td>斧山</td>
						</tr>
						<tr>
							<td>ver.2.1030</td>
							<td>2022.02.25</td>
							<td>
								・Order/Main.js　検索部分（search_status）と一括ステータス変更のステータスに「処理中」「確認中」を追加<br/>
								・Order/Item.js、order/Details.js　ステータスに「処理中」「確認中」を追加<br/>
								・Order/FormA.js　会員区分の「且つ、会員の場合」条件追加、class追加、sytle.css調整(.kaiin・#delivery_message)
							</td>
							<td>芦田</td>
						</tr>
						<tr>
							<td>ver.2.1031</td>
							<td>2022.03.04</td>
							<td>
								・Order/Details.js　出荷指示をテキストエリアにして、sytle.cssで改行調整
							</td>
							<td>芦田</td>
						</tr>
					</tbody>
					</Table>
				</Container>
			);
		}
	}
}

export default withRouter(Default);
