import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	InputGroup,
	Button,
	Image,
	Modal,
	Badge,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { AuditLog } from '../../Objects/AuditLog';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isValidated    : false,
			isSuccess      : false,
			isShowModal    : false,
			isLockForm     : false,
			formObj        : null,
			ErrMsg         : '',
			cognitoid      : '',
			mg_kbn_1       : '',
			mg_kbn_2       : '',
			mg_kbn_3       : '',
			en_kbn         : '',
			not_email_kbn  : '',
		}
		this.handleReload     = this.handleReload.bind(this);
		this.handleSubmit     = this.handleSubmit.bind(this);
		this.handleShow       = this.handleShow.bind(this);
		this.handleClose      = this.handleClose.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
		this.lockFormClose    = this.lockFormClose.bind(this);
	}
	handleReload() {

		//Formオブジェクトの取得
		const formObj = this.state.formObj;

		//フォームのリセット
		formObj.reset();

		//データの初期化
		this.setState({
			isShowModal    : false,
			isSuccess      : false,
			isValidated    : false,
			isLockForm     : false,
			ErrMsg         : '',
			cognitoid      : '',
			mg_kbn_1       : '',
			mg_kbn_2       : '',
			mg_kbn_3       : '',
			en_kbn         : '',
			not_email_kbn  : '',
		});

	}
	async handleSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal : false,
			isValidated : true,
			isLockForm  : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//Formから直接取得する更新項目
			const email         = formObj.email.value;
			const name_1        = formObj.name_1.value;
			const name_2        = formObj.name_2.value;
			const name_furi_1   = formObj.name_furi_1.value;
			const name_furi_2   = formObj.name_furi_2.value;
			const zip_1         = formObj.zip_1.value;
			const zip_2         = formObj.zip_2.value;
			const pref          = formObj.pref.value;
			const addr_1        = formObj.addr_1.value;
			const addr_2        = formObj.addr_2.value;
			const addr_3        = formObj.addr_3.value;
			const tel_1         = formObj.tel_1.value;
			const tel_2         = formObj.tel_2.value;
			const tel_3         = formObj.tel_3.value;
			const birth_y       = formObj.birth_y.value;
			const birth_m       = formObj.birth_m.value;
			const birth_d       = formObj.birth_d.value;
			const sex           = formObj.sex.value;

			//Stateから取得する更新項目
			const not_email_kbn = this.state.not_email_kbn ? true :false;
			const mg_kbn_1      = this.state.mg_kbn_1      ? true :false;
			const mg_kbn_2      = this.state.mg_kbn_2      ? true :false;
			const mg_kbn_3      = this.state.mg_kbn_3      ? true :false;
			
			//日付処理
		        const date           = new Date();
		        const isoDate        = date.toISOString();

			//ユーザIDの登録
			const Query = `mutation executeCognitoAdmin($email: String!, $flag: String!) {
				executeCognitoAdmin(email: $email, flag: $flag) {
					cognitoid
					error
				}
			}`;
			const Data = {
				email: email,
				flag : not_email_kbn ? 'NotEmailCreateUser' : 'CreateUser',
			};
			const ref       = await API.graphql(graphqlOperation(Query, Data));
			const cognitoid = ref.data.executeCognitoAdmin.cognitoid
			const error     = ref.data.executeCognitoAdmin.error
			if(error){
				this.setState({
					ErrMsg     : error + '<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//プロフィールの登録
			const createQuery = `mutation createProfile($data: ProfileInput!) {
				createProfile(input: $data) {
					cognitoid
				}
			}`;
			const createData = {
				data : {
					cognitoid      : cognitoid,
					email          : not_email_kbn ? 'null' : email,
					name_1         : name_1,
					name_2         : name_2,
					name_furi_1    : name_furi_1,
					name_furi_2    : name_furi_2,
					zip_1          : zip_1,
					zip_2          : zip_2,
					pref           : pref,
					addr_1         : addr_1,
					addr_2         : addr_2,
					addr_3         : addr_3 ? addr_3 : 'null',
					tel_1          : tel_1,
					tel_2          : tel_2,
					tel_3          : tel_3,
					birth_y        : birth_y,
					birth_m        : birth_m,
					birth_d        : birth_d,
					sex            : sex,
					mg_kbn_1       : mg_kbn_1,
					mg_kbn_2       : mg_kbn_2,
					mg_kbn_3       : mg_kbn_3,
					en_kbn         : true,
					not_email_kbn  : not_email_kbn,
					date_add       : isoDate,
				}
			};
			await API.graphql(graphqlOperation(createQuery, createData));

			//鑑査ログ
			await AuditLog('user', 'create', cognitoid, createData, '会員情報を登録しました。');

			this.setState({
				cognitoid : cognitoid,
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			});
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	handleClose() {
		this.setState({
			isShowModal    : false,
			isSuccess      : false,
			isValidated    : false,
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleShow(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowModal: true,
			formObj    : form,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this.setState({ isLoading: false });
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>
							登録しました。<br/>
							続けて登録しますか？
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={()=>{ this.props.history.push('/User/' + this.state.cognitoid + '/'); }}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.handleReload}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowModal} onHide={this.handleClose} centered>
						<Modal.Body>本当に登録しますか？</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.handleSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleShow(e)}>
					<Row>
						<Col sm={8}>
							<p><big>ユーザ情報の新規登録</big></p>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メールアドレス　<Badge variant="danger" style={{ display: this.state.not_email_kbn ? 'none' : '' }} >必須</Badge>
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="email">
							        <Form.Control name="email" type="email" placeholder="メールアドレス" size="lg" pattern=".{1,255}" required={!this.state.not_email_kbn} disabled={this.state.isLockForm || this.state.not_email_kbn} />
								<Form.Control.Feedback type="invalid">
									「メールアドレス」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 6, offset: 3 }}>
							<Form.Group controlId="not_email_kbn">
								<Form.Check name="not_email_kbn" type="checkbox" label="このユーザを非メール会員として登録する"  onClick={()=>{ this.setState({ not_email_kbn: this.state.not_email_kbn ? false : true }); }} disabled={this.state.isLockForm} />
								<Form.Control.Feedback/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							お名前　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={3}>
							<Form.Group controlId="name_1">
							        <Form.Control name="name_1" type="text" placeholder="お名前（名字）" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「お名前（名字）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={3}>
							<Form.Group controlId="name_2">
							        <Form.Control name="name_2" type="text" placeholder="お名前（名前）" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「お名前（名前）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							フリガナ　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={3}>
							<Form.Group controlId="name_furi_1">
							        <Form.Control name="name_furi_1" type="text" placeholder="フリガナ（名字）" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「フリガナ（名字）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={3}>
							<Form.Group controlId="name_furi_2">
							        <Form.Control name="name_furi_2" type="text" placeholder="フリガナ（名前）" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「フリガナ（名前）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							郵便番号　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="zip_1">
							        <Form.Control name="zip_1" type="text" placeholder="3桁" size="lg" required pattern="\d{3}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「郵便番号（3桁）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="zip_2">
							        <Form.Control name="zip_2" type="text" placeholder="4桁" size="lg" required pattern="\d{4}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「郵便番号（4桁）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							都道府県　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="pref">
							        <Form.Control name="pref" type="text" placeholder="都道府県" size="lg" required pattern=".{2,4}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「都道府県」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							住所（市区町村）　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="addr_1">
							        <Form.Control name="addr_1" type="text" placeholder="市区町村" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「住所（市区町村）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							住所（番地）　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="addr_2">
							        <Form.Control name="addr_2" type="text" placeholder="番地" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「住所（番地）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							住所（その他・建物）
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="addr_3">
							        <Form.Control name="addr_3" type="text" placeholder="その他・建物" size="lg" pattern=".{0,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「住所（その他・建物）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							電話番号　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="tel_1">
							        <Form.Control name="tel_1" type="text" placeholder="市外局番" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「電話番号（市外局番）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="tel_2">
							        <Form.Control name="tel_2" type="text" placeholder="市内局番" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「電話番号（市内局番）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="tel_3">
							        <Form.Control name="tel_3" type="text" placeholder="番号" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「電話番号」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							生年月日　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="birth_y">
								<InputGroup>
								        <Form.Control name="birth_y" type="text" placeholder="2019" size="lg" required pattern="\d{4}" aria-describedby="birth_y" disabled={this.state.isLockForm} />
								        <InputGroup.Prepend>
										<InputGroup.Text id="birth_y">年</InputGroup.Text>
									</InputGroup.Prepend>
									<Form.Control.Feedback type="invalid">
										「生年月日（西暦）」を正しく入力してください。
									</Form.Control.Feedback>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="birth_m">
								<InputGroup>
								        <Form.Control name="birth_m" type="text" placeholder="12" size="lg" required pattern="\d{1,2}" aria-describedby="birth_m" disabled={this.state.isLockForm} />
								        <InputGroup.Prepend>
										<InputGroup.Text id="birth_m">月</InputGroup.Text>
									</InputGroup.Prepend>
									<Form.Control.Feedback type="invalid">
										「生年月日（月）」を正しく入力してください。
									</Form.Control.Feedback>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="birth_d">
								<InputGroup>
								        <Form.Control name="birth_d" type="text" placeholder="01" size="lg" required pattern="\d{1,2}" aria-describedby="birth_d" disabled={this.state.isLockForm} />
								        <InputGroup.Prepend>
										<InputGroup.Text id="birth_d">日</InputGroup.Text>
									</InputGroup.Prepend>
									<Form.Control.Feedback type="invalid">
										「生年月日（日）」を正しく入力してください。
									</Form.Control.Feedback>
								</InputGroup>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							性別　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="sex">
							        <Form.Control name="sex" type="text" placeholder="性別" size="lg" pattern="男性|女性" required disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「性別」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メールマガジンその1
						</Form.Label>
						<Col sm={2}>
							<Button name="mg_kbn_1" variant={this.state.mg_kbn_1 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ mg_kbn_1: this.state.mg_kbn_1 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.mg_kbn_1 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メールマガジンその2
						</Form.Label>
						<Col sm={2}>
							<Button name="mg_kbn_2" variant={this.state.mg_kbn_2 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ mg_kbn_2: this.state.mg_kbn_2 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.mg_kbn_2 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メールマガジンその3
						</Form.Label>
						<Col sm={2}>
							<Button name="mg_kbn_3" variant={this.state.mg_kbn_3 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ mg_kbn_3: this.state.mg_kbn_3 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.mg_kbn_3 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >登録する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props.history.push('/User/'); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					</Form>
				</Container>
			);
		}
	}
}

export default withRouter(Default);


