import React from 'react';
import { Auth } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Alert,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			validated      : false,
			InfoFlag       : false,
			ErrFlag        : false,
			ErrMsg         : '',
			code           : '',
		}
		this._emailVerify  = this._emailVerify.bind(this);
		this.handleChange  = this.handleChange.bind(this);
		this.handleSubmit  = this.handleSubmit.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
	}
	async _emailVerify(code){
	
		try {
			const user = await Auth.currentAuthenticatedUser();
			await Auth.verifyUserAttributeSubmit(user, 'email', code);
			//console.log(data);
			if(this.props.reload){
				this.props.reload();
			}
			else{
				this.setState({ ErrFlag : false   });
				this.setState({ ErrMsg  : ''      });
				this.setState({ InfoFlag: true    });
			}
		}
		catch(error) {
			//console.log("err");
			//console.log(error);
			this.setState({ ErrFlag : true    });
			this.setState({ ErrMsg  : error.message });
			this.setState({ InfoFlag: false    });
		}
	}
	handleChange(e){
		switch (e.target.name) {
			case 'code':
				this.setState({ code: e.target.value });
				break;
			default:
				break;
		}
	}
	handleSubmit () {
		this.setState({ validated: true });
		const code = this.state.code;
		let err_flag = false;
		let err_msg  = '';
		if(!code.match(/^\d{6}$/)){
			err_flag = true;
			err_msg  = err_msg + '※検証コードは数字6文字で入力してください。<br/>';
		}
		if(err_flag){
		
			this.setState({ ErrFlag : true    });
			this.setState({ ErrMsg  : err_msg });
			this.setState({ InfoFlag: false   });
		}
		else{
			this._emailVerify(code);
		}
	}
	handleDismiss() {
		this.setState({ ErrFlag: false });
	}
	componentDidMount () {
		//
	}
	render() {
		return (
			<Container style={{ marginTop: 200, marginBottom: 200 }}>
				{(() => {
					if(this.state.ErrFlag){
						return (
							<Row>
							<Col sm={{ span: 6, offset: 3 }}>
								<Alert dismissible variant="danger" onClose={this.handleDismiss}>
									エラーがありました。<br/>
									{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
								</Alert>
							</Col>
							</Row>
						);
					}
				})()}
			{(() => {
				if(this.state.InfoFlag){
					return (
						<Row>
						<Col sm={{ span: 6, offset: 3 }}>
							<Alert variant="info" onClose={this.handleDismissUseInfo}>
								<p>メールアドレスの変更が完了しました。</p>
							</Alert>
						</Col>
						</Row>
					);
				}
				else{
					return (
						<Row>
						<Col sm={{ span: 8, offset: 2 }}>
							<Alert variant="info" onClose={this.handleDismissUseInfo}>
								<p>お客様の新しいメールアドレス宛にメールアドレスを確認する検証コードを送信しています。</p>
								<p>届いたメールの内容に従って、検証コードを入力してください。</p>
								<p>メールが届かない場合は、ご入力を頂いたメールアドレスに間違いがないかご確認の上、再度登録ください。</p>
							</Alert>
						</Col>
						</Row>
					);
				}
			})()}
				<Form onChange={this.handleChange} validated={this.state.validated}>
				<Row>
					<Col sm={{ span: 4, offset: 4 }}>
						<p className="text-center"><big>メールアドレスの検証</big></p>
					</Col>
				</Row>
				<Row>
					<Col sm={{ span: 4, offset: 4 }}>
						<Form.Group controlId="formCode" size="lg">
						        <Form.Control name="code" type="text" defaultValue={this.state.code} placeholder="検証コード" required />
							<Form.Control.Feedback />
						</Form.Group>
					</Col>
				</Row>
				<Row style={{ marginTop: 10 }}>
					<Col sm={{ span: 4, offset: 4 }}>
					        <Button variant="dark" type="button" block onClick={this.handleSubmit} size="lg">入力</Button>
					</Col>
				</Row>
				</Form>
			</Container>
		);
	}
}

export default withRouter(Default);

