import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	InputGroup,
	Button,
	Image,
	Modal,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import NotFound from '../../Objects/NotFound';
import { AuditLog } from '../../Objects/AuditLog';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isNotFound     : false,
			isValidated    : false,
			isSuccess      : false,
			isDeleted      : false,
			isShowUpdate   : false,
			isShowDelete   : false,
			isLockForm     : false,
			formObj        : null,
			ErrMsg         : '',
			cognitoid      : '',
			email          : '',
			defaultEmail   : '',
			name_1         : '',
			name_2         : '',
			name_furi_1    : '',
			name_furi_2    : '',
			zip_1          : '',
			zip_2          : '',
			pref           : '',
			addr_1         : '',
			addr_2         : '',
			addr_3         : '',
			tel_1          : '',
			tel_2          : '',
			tel_3          : '',
			birth_y        : '',
			birth_m        : '',
			birth_d        : '',
			sex            : '',
			mg_kbn_1       : '',
			mg_kbn_2       : '',
			mg_kbn_3       : '',
			date_add       : '',
			en_kbn         : '',
			not_email_kbn  : '',
		}
		this.handleReload     = this.handleReload.bind(this);
		this.updateSubmit     = this.updateSubmit.bind(this);
		this.deleteSubmit     = this.deleteSubmit.bind(this);
		this.handleUpdate     = this.handleUpdate.bind(this);
		this.handleDelete     = this.handleDelete.bind(this);
		this.handleClose      = this.handleClose.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
		this.lockFormClose    = this.lockFormClose.bind(this);
		this._getData         = this._getData.bind(this);
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	async _getData() {

		try{
			//IDの取得
			const cognitoid = this.props.match.params.cognitoid;
			if(!cognitoid){
					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
			}

			//データ取得
			const Query = `query getProfile($cognitoid: String!) {
				getProfile(cognitoid: $cognitoid) {
					email
					name_1
					name_2
					name_furi_1
					name_furi_2
					zip_1
					zip_2
					pref
					addr_1
					addr_2
					addr_3
					tel_1
					tel_2
					tel_3
					birth_y
					birth_m
					birth_d
					sex
					mg_kbn_1
					mg_kbn_2
					mg_kbn_3
					date_add
					en_kbn
					not_email_kbn
				}
			}`;
			const Data = { cognitoid: cognitoid };
			const ref = await API.graphql(graphqlOperation(Query, Data));
			//console.log(ref);
			
			//データが空かどうか
			if(ref.data.getProfile === null){
				this.setState({
					isLoading   : false,
					isNotFound  : true,
				});
				return(0);
			}
			
			const email         = ref.data.getProfile.email === 'null' ? '' : ref.data.getProfile.email;
			const name_1        = ref.data.getProfile.name_1;
			const name_2        = ref.data.getProfile.name_2;
			const name_furi_1   = ref.data.getProfile.name_furi_1;
			const name_furi_2   = ref.data.getProfile.name_furi_2;
			const zip_1         = ref.data.getProfile.zip_1;
			const zip_2         = ref.data.getProfile.zip_2;
			const pref          = ref.data.getProfile.pref;
			const addr_1        = ref.data.getProfile.addr_1;
			const addr_2        = ref.data.getProfile.addr_2;
			const addr_3        = ref.data.getProfile.addr_3 === 'null' ? '' : ref.data.getProfile.addr_3;
			const tel_1         = ref.data.getProfile.tel_1;
			const tel_2         = ref.data.getProfile.tel_2;
			const tel_3         = ref.data.getProfile.tel_3;
			const birth_y       = ref.data.getProfile.birth_y;
			const birth_m       = ref.data.getProfile.birth_m;
			const birth_d       = ref.data.getProfile.birth_d;
			const sex           = ref.data.getProfile.sex;
			const mg_kbn_1      = ref.data.getProfile.mg_kbn_1;
			const mg_kbn_2      = ref.data.getProfile.mg_kbn_2;
			const mg_kbn_3      = ref.data.getProfile.mg_kbn_3;
			const en_kbn        = ref.data.getProfile.en_kbn;
			const not_email_kbn = ref.data.getProfile.not_email_kbn;
			
			//日付
			const date_add      = new Date(ref.data.getProfile.date_add);
			const year          = date_add.getFullYear().toString();
			const month         = ('00' + (date_add.getMonth()+1).toString()).slice(-2);
			const day           = ('00' + date_add.getDate().toString()).slice(-2);
			const hour          = ('00' + date_add.getHours().toString()).slice(-2);
			const minute        = ('00' + date_add.getMinutes().toString()).slice(-2);
			const second        = ('00' + date_add.getSeconds().toString()).slice(-2);
			this.setState({
				isLoading     : false,
				cognitoid     : cognitoid,
				email         : email,
				defaultEmail  : email,
				name_1        : name_1,
				name_2        : name_2,
				name_furi_1   : name_furi_1,
				name_furi_2   : name_furi_2,
				zip_1         : zip_1,
				zip_2         : zip_2,
				pref          : pref,
				addr_1        : addr_1,
				addr_2        : addr_2,
				addr_3        : addr_3,
				tel_1         : tel_1,
				tel_2         : tel_2,
				tel_3         : tel_3,
				birth_y       : birth_y,
				birth_m       : birth_m,
				birth_d       : birth_d,
				sex           : sex,
				mg_kbn_1      : mg_kbn_1,
				mg_kbn_2      : mg_kbn_2,
				mg_kbn_3      : mg_kbn_3,
				date_add      : year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分' + second + '秒',
				en_kbn        : en_kbn,
				not_email_kbn : not_email_kbn,
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLoading  : false,
				isLockForm : false,
			 });
			return(0);
		}
	}
	async updateSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowUpdate : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//Formから直接取得する更新項目
			const email         = formObj.email.value;
			const name_1        = formObj.name_1.value;
			const name_2        = formObj.name_2.value;
			const name_furi_1   = formObj.name_furi_1.value;
			const name_furi_2   = formObj.name_furi_2.value;
			const zip_1         = formObj.zip_1.value;
			const zip_2         = formObj.zip_2.value;
			const pref          = formObj.pref.value;
			const addr_1        = formObj.addr_1.value;
			const addr_2        = formObj.addr_2.value;
			const addr_3        = formObj.addr_3.value;
			const tel_1         = formObj.tel_1.value;
			const tel_2         = formObj.tel_2.value;
			const tel_3         = formObj.tel_3.value;
			const birth_y       = formObj.birth_y.value;
			const birth_m       = formObj.birth_m.value;
			const birth_d       = formObj.birth_d.value;
			const sex           = formObj.sex.value;

			//Stateから取得する更新項目
			const cognitoid     = this.state.cognitoid;
			const defaultEmail  = this.state.defaultEmail;
			const mg_kbn_1      = this.state.mg_kbn_1        ? true :false;
			const mg_kbn_2      = this.state.mg_kbn_2        ? true :false;
			const mg_kbn_3      = this.state.mg_kbn_3        ? true :false;
			const en_kbn　      = this.state.en_kbn          ? true :false;
			const not_email_kbn = this.state.not_email_kbn   ? true :false;

			//日付処理
		        const date          = new Date();
		        const isoDate       = date.toISOString();

			//メールアドレスの変更
			if(email !== defaultEmail && not_email_kbn === false){
				//ユーザIDのメールアドレス変更
				const execQuery = `mutation executeCognitoAdmin($email: String!, $flag: String!, $newEmail: String) {
					executeCognitoAdmin(email: $email, flag: $flag, newEmail: $newEmail) {
						cognitoid
						error
					}
				}`;
				const execData = {
					email    : defaultEmail,
					flag     : 'UpdateUserAttributes',
					newEmail : email,
				};
				await API.graphql(graphqlOperation(execQuery, execData));

				//鑑査ログ
				await AuditLog('user', 'update', cognitoid, execData, 'メールアドレスを変更しました。');

				this.setState({
					defaultEmail   : email,
				});
			}

			//ユーザIDの有効無効変更
			if(not_email_kbn === false){
				const execQuery = `mutation executeCognitoAdmin($email: String!, $flag: String!) {
					executeCognitoAdmin(email: $email, flag: $flag) {
						cognitoid
						error
					}
				}`;
				const execData = {
					email : email,
					flag  : en_kbn ? 'EnableUser' : 'DisableUser',
				};
				await API.graphql(graphqlOperation(execQuery, execData));

				//鑑査ログ
				await AuditLog('user', 'update', cognitoid, execData, '有効状態を変更しました。');
			}
			
			//プロフィールの更新登録
			const Query = `mutation updateProfile($data: ProfileInput!) {
				updateProfile(input: $data) {
					cognitoid
				}
			}`;
			const Data = {
				data : {
					cognitoid    : cognitoid,
					email        : not_email_kbn ? 'null' : email,
					name_1       : name_1,
					name_2       : name_2,
					name_furi_1  : name_furi_1,
					name_furi_2  : name_furi_2,
					zip_1        : zip_1,
					zip_2        : zip_2,
					pref         : pref,
					addr_1       : addr_1,
					addr_2       : addr_2,
					addr_3       : addr_3 ? addr_3 : 'null',
					tel_1        : tel_1,
					tel_2        : tel_2,
					tel_3        : tel_3,
					birth_y      : birth_y,
					birth_m      : birth_m,
					birth_d      : birth_d,
					sex          : sex,
					mg_kbn_1     : mg_kbn_1,
					mg_kbn_2     : mg_kbn_2,
					mg_kbn_3     : mg_kbn_3,
					en_kbn       : en_kbn,
					date_edit    : isoDate,
				}
			};
			await API.graphql(graphqlOperation(Query, Data));

			//鑑査ログ
			await AuditLog('user', 'update', cognitoid, Data, '会員情報を変更しました。');
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	async deleteSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowDelete : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Stateから取得する更新項目
			const cognitoid = this.state.cognitoid;
			const email     = this.state.email;
			
			//Stateから取得する更新項目
			const not_email_kbn = this.state.not_email_kbn   ? true :false;
			
			//ユーザIDの削除
			if(not_email_kbn === false){
				const execQuery = `mutation executeCognitoAdmin($email: String!, $flag: String!) {
					executeCognitoAdmin(email: $email, flag: $flag) {
						cognitoid
						error
					}
				}`;
				const execData = {
					email: email,
					flag : 'DeleteUser',
				};
				await API.graphql(graphqlOperation(execQuery, execData));
			}
			
			//プロフィールの更新登録
			const Query = `mutation deleteProfile($data: ProfileInput!) {
				deleteProfile(input: $data) {
					cognitoid
				}
			}`;
			const Data = {
				data : {
					cognitoid   : cognitoid,
				}
			};
			await API.graphql(graphqlOperation(Query, Data));

			//鑑査ログ
			await AuditLog('user', 'delete', cognitoid, Data, '会員情報を削除しました。');

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isDeleted   : true,
			isLockForm  : false,
		});
	}
	handleClose() {
		this.setState({
			isShowUpdate : false,
			isShowDelete : false,
			isSuccess    : false,
			isValidated  : false,
			isLockForm   : false,
			ErrMsg     : '',
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleUpdate(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowUpdate : true,
			formObj      : form,
		});
	}
	handleDelete(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowDelete : true,
			formObj      : form,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else if(this.state.isNotFound){
			return (
				<NotFound />
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>更新しました。</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isDeleted} onHide={()=>{ this.props.history.push('/User/'); }} centered>
						<Modal.Body>削除しました。</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={()=>{ this.props.history.push('/User/'); }}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowUpdate} onHide={this.handleClose} centered>
						<Modal.Body>
							本当に更新しますか？<br/>
							メールアドレスを変更する場合は、変更後のメールアドレスに検証コードが送信されます。ご注意ください。
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.updateSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowDelete} onHide={this.handleClose} centered>
						<Modal.Body>本当に削除しますか？</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.deleteSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleUpdate(e)}>
					<Row>
						<Col sm={4}>
							<p><big>ユーザ情報</big></p>
						</Col>
						<Col sm={5} className="text-right">
							<Button variant="dark" type="button" href={'/User/' + this.state.cognitoid + '/Address/'} target="_blank" >{/*onClick={() => this.props.onClickAddr(row)}*/}
								アドレス帳
							</Button>　
							<Button variant="dark" onClick={ this.handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							ユーザID
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="cognitoid">
							        <Form.Control name="cognitoid" type="text" defaultValue={this.state.cognitoid} size="lg" readOnly />
								<Form.Control.Feedback />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							登録日時
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="date_add">
							        <Form.Control name="date_add" type="text" defaultValue={this.state.date_add} size="lg" readOnly />
								<Form.Control.Feedback />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メールアドレス　<Badge variant="danger" style={{ display: this.state.not_email_kbn ? 'none' : '' }} >必須</Badge>
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="email">
							        <Form.Control name="email" type="email" defaultValue={this.state.email} placeholder="メールアドレス" size="lg" required={!this.state.not_email_kbn} pattern=".{1,255}" disabled={this.state.isLockForm || this.state.not_email_kbn} />
								<Form.Control.Feedback type="invalid">
									「メールアドレス」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							お名前　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={3}>
							<Form.Group controlId="name_1">
							        <Form.Control name="name_1" type="text" defaultValue={this.state.name_1} placeholder="お名前（名字）" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「お名前（名字）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={3}>
							<Form.Group controlId="name_2">
							        <Form.Control name="name_2" type="text" defaultValue={this.state.name_2} placeholder="お名前（名前）" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「お名前（名前）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							フリガナ　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={3}>
							<Form.Group controlId="name_furi_1">
							        <Form.Control name="name_furi_1" type="text" defaultValue={this.state.name_furi_1} placeholder="フリガナ（名字）" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「フリガナ（名字）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={3}>
							<Form.Group controlId="name_furi_2">
							        <Form.Control name="name_furi_2" type="text" defaultValue={this.state.name_furi_2} placeholder="フリガナ（名前）" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「フリガナ（名前）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							郵便番号　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="zip_1">
							        <Form.Control name="zip_1" type="text" defaultValue={this.state.zip_1} placeholder="3桁" size="lg" required pattern="\d{3}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「郵便番号（3桁）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="zip_2">
							        <Form.Control name="zip_2" type="text" defaultValue={this.state.zip_2} placeholder="4桁" size="lg" required pattern="\d{4}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「郵便番号（4桁）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							都道府県　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="pref">
							        <Form.Control name="pref" type="text" defaultValue={this.state.pref} placeholder="都道府県" size="lg" required pattern=".{2,4}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「都道府県」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							住所（市区町村）　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="addr_1">
							        <Form.Control name="addr_1" type="text" defaultValue={this.state.addr_1} placeholder="市区町村" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「住所（市区町村）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							住所（番地）　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="addr_2">
							        <Form.Control name="addr_2" type="text" defaultValue={this.state.addr_2} placeholder="番地" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「住所（番地）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							住所（その他・建物）
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="addr_3">
							        <Form.Control name="addr_3" type="text" defaultValue={this.state.addr_3} placeholder="その他・建物" size="lg" pattern=".{0,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「住所（その他・建物）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							電話番号　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="tel_1">
							        <Form.Control name="tel_1" type="text" defaultValue={this.state.tel_1} placeholder="市外局番" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「電話番号（市外局番）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="tel_2">
							        <Form.Control name="tel_2" type="text" defaultValue={this.state.tel_2} placeholder="市内局番" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「電話番号（市内局番）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="tel_3">
							        <Form.Control name="tel_3" type="text" defaultValue={this.state.tel_3} placeholder="番号" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「電話番号」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							生年月日　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="birth_y">
								<InputGroup>
								        <Form.Control name="birth_y" type="text" defaultValue={this.state.birth_y} placeholder="2019" size="lg" required pattern="\d{4}" aria-describedby="birth_y" disabled={this.state.isLockForm} />
								        <InputGroup.Prepend>
										<InputGroup.Text id="birth_y">年</InputGroup.Text>
									</InputGroup.Prepend>
									<Form.Control.Feedback type="invalid">
										「生年月日（西暦）」を正しく入力してください。
									</Form.Control.Feedback>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="birth_m">
								<InputGroup>
								        <Form.Control name="birth_m" type="text" defaultValue={this.state.birth_m} placeholder="12" size="lg" required pattern="\d{1,2}" aria-describedby="birth_m" disabled={this.state.isLockForm} />
								        <InputGroup.Prepend>
										<InputGroup.Text id="birth_m">月</InputGroup.Text>
									</InputGroup.Prepend>
									<Form.Control.Feedback type="invalid">
										「生年月日（月）」を正しく入力してください。
									</Form.Control.Feedback>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="birth_d">
								<InputGroup>
								        <Form.Control name="birth_d" type="text" defaultValue={this.state.birth_d} placeholder="01" size="lg" required pattern="\d{1,2}" aria-describedby="birth_d" disabled={this.state.isLockForm} />
								        <InputGroup.Prepend>
										<InputGroup.Text id="birth_d">日</InputGroup.Text>
									</InputGroup.Prepend>
									<Form.Control.Feedback type="invalid">
										「生年月日（日）」を正しく入力してください。
									</Form.Control.Feedback>
								</InputGroup>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							性別　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="sex">
							        <Form.Control name="sex" type="text" defaultValue={this.state.sex} placeholder="性別" size="lg" pattern="男性|女性" required disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「性別」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メールマガジンその1
						</Form.Label>
						<Col sm={2}>
							<Button name="mg_kbn_1" variant={this.state.mg_kbn_1 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ mg_kbn_1: this.state.mg_kbn_1 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.mg_kbn_1 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メールマガジンその2
						</Form.Label>
						<Col sm={2}>
							<Button name="mg_kbn_2" variant={this.state.mg_kbn_2 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ mg_kbn_2: this.state.mg_kbn_2 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.mg_kbn_2 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メールマガジンその3
						</Form.Label>
						<Col sm={2}>
							<Button name="mg_kbn_3" variant={this.state.mg_kbn_3 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ mg_kbn_3: this.state.mg_kbn_3 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.mg_kbn_3 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							有効フラグ
						</Form.Label>
						<Col sm={2}>
							<Button name="en_kbn" variant={this.state.en_kbn ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ en_kbn: this.state.en_kbn ? false : true }); }} disabled={this.state.isLockForm || this.state.not_email_kbn} >
								{this.state.en_kbn ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props.history.push('/User/'); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					</Form>

					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleDelete(e)}>
					<Row style={{ marginTop: 100 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="danger" type="submit" block size="lg" disabled={this.state.isLockForm} >削除する</Button>
						</Col>
					</Row>
					</Form>

				</Container>
			);
		}
	}
}

export default withRouter(Default);


