import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Modal,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import NotFound from '../../Objects/NotFound';

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading       : true,
			isNotFound      : false,
			ErrMsg          : '',
			orderno         : '',
			date_add        : '',
			to              : '',
			cc              : '',
			bcc             : '',
			from            : '',
			subject         : '',
			msg             : '',
			ref             : '',
			log_text        : '',
			error_flag      : '',
			error_text      : '',
		}
		this.handleReload     = this.handleReload.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
		this._getData         = this._getData.bind(this);
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
			isNextPage  : false,
		});
		this._getData();
	}
	async _getData() {

		try{

			//IDの取得
			const orderno = this.props.match.params.orderno;
			if(!orderno){
					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
			}
			
			//IDの取得
			const date_add = this.props.match.params.date_add;
			if(!date_add){
					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
			}
			
			//データ取得
			const OrderQuery = `query getMailSendLog($orderno: String!, $date_add: String!) {
				getMailSendLog(orderno: $orderno, date_add: $date_add) {
					to        
					cc        
					bcc       
					from      
					subject   
					msg       
					ref       
					log_text  
					error_flag
					error_text
				}
			}`;
			const OrderData = {
				orderno : orderno,
				date_add: date_add,
			};
			const refOrder = await API.graphql(graphqlOperation(OrderQuery, OrderData));
			
			//データが空かどうか
			if(refOrder.data.getMailSendLog === null){
				this.setState({
					isLoading   : false,
					isNotFound  : true,
				});
				return(0);
			}
			
			const to         = refOrder.data.getMailSendLog.to;
			const cc         = refOrder.data.getMailSendLog.cc;
			const bcc        = refOrder.data.getMailSendLog.bcc;
			const from       = refOrder.data.getMailSendLog.from;
			const subject    = refOrder.data.getMailSendLog.subject;
			const msg        = refOrder.data.getMailSendLog.msg;
			const ref        = refOrder.data.getMailSendLog.ref;
			const log_text   = refOrder.data.getMailSendLog.log_text;
			const error_flag = refOrder.data.getMailSendLog.error_flag;
			const error_text = refOrder.data.getMailSendLog.error_text;
			
			//日付
			const date_add_obj     = new Date(date_add);
			const date_add_year    = date_add_obj.getFullYear().toString();
			const date_add_month   = ('00' + (date_add_obj.getMonth()+1).toString()).slice(-2);
			const date_add_day     = ('00' + date_add_obj.getDate().toString()).slice(-2);
			const date_add_hour    = ('00' + date_add_obj.getHours().toString()).slice(-2);
			const date_add_minute  = ('00' + date_add_obj.getMinutes().toString()).slice(-2);
			const date_add_second  = ('00' + date_add_obj.getSeconds().toString()).slice(-2);
			
			this.setState({
				isLoading       : false,
				orderno         : orderno,
				date_add        : date_add,
				date_add_text   : date_add_year + '年' + date_add_month + '月' + date_add_day + '日' + date_add_hour + '時' + date_add_minute + '分' + date_add_second + '秒',
				to              : to         ,
				cc              : cc === 'null' ? '' : cc,
				bcc             : bcc === 'null' ? '' : bcc,
				from            : from       ,
				subject         : subject    ,
				msg             : msg        ,
				ref             : ref        ,
				log_text        : log_text   ,
				error_flag      : error_flag ,
				error_text      : error_text ,
			});                       
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLoading  : false,
			 });
			return(0);
		}
	}
	handleErrorClose() {
		this.setState({
			ErrMsg         : '',
		});
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else if(this.state.isNotFound){
			return (
				<NotFound />
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					<Form>
					<Row>
						<Col sm={12}>
							<p><big>メール送信ログ</big></p>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							受注番号
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="orderno">
							        <Form.Control name="orderno" type="text" defaultValue={this.state.orderno} size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							送信時間
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="orderno">
							        <Form.Control name="orderno" type="text" defaultValue={this.state.date_add_text} size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							送信先アドレス
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="to">
							        <Form.Control name="to" type="email" defaultValue={this.state.to} placeholder="送信先アドレス" size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							差出人アドレス
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="from">
							        <Form.Control name="from" type="email" defaultValue={this.state.from} placeholder="差出人アドレス" size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							CCアドレス
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="cc">
							        <Form.Control name="cc" type="email" defaultValue={this.state.cc} placeholder="CCアドレス" size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							BCCアドレス
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="bcc">
							        <Form.Control name="bcc" type="email" defaultValue={this.state.bcc} placeholder="BCCアドレス" size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メール件名
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="subject">
							        <Form.Control name="subject" type="email" defaultValue={this.state.subject} placeholder="BCCアドレス" size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メール本文
						</Form.Label>
						<Col sm={8}>
							<pre style={{ border: 'solid 1px gray', padding: '2em', marginTop: 20 }}>
							{this.state.msg }
							</pre>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props.history.goBack(); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					</Form>


				</Container>
			);
		}
	}
}

export default withRouter(Default);


