import React from 'react';
import { Auth, API, graphqlOperation, /* Storage */ } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Modal,
	Badge,
	ProgressBar,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
//import { FaCloudDownloadAlt } from 'react-icons/fa';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { AuditLog } from '../../Objects/AuditLog';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading       : true,
			isValidated     : false,
			isNextPage      : false,
			isLockForm      : false,
			showEmail       : false,
			formObj         : null,
			ErrMsg          : '',
			magazineno      : '',
			from            : '',
			subject         : '',
			msg_text        : '',
			msg_html        : '',
			reserv_date     : '',
			magazineData    : [],
			bounceData      : [],
			complaintData   : [],
		}
		this._backTop         = this._backTop.bind(this);
		this.handleReload     = this.handleReload.bind(this);
		this.handleSubmit     = this.handleSubmit.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
		this.lockFormClose    = this.lockFormClose.bind(this);
		this._getData         = this._getData.bind(this);
	}
	_backTop() {
		//データの再取得
		this.setState({
			isLoading   : false,
			isNextPage  : false,
			isValidated : false,
		});
	}
	handleReload(flag) {
		//データの再取得
		this.setState({
			isLoading   : true,
			isNextPage  : false,
		});
		this._getData(flag);
	}
	async _getData(flag) {

		try{
		
			//差出人の取得
			const MailMagazineQuery = `query getParam($keyname: String!) {
				getParam(keyname: $keyname) {
					from
				}
			}`;
			const MailMagazineData = { keyname: 'MailMagazine' };
			const refMailMagazine = await API.graphql(graphqlOperation(MailMagazineQuery, MailMagazineData));
			let from    = '';
			if(refMailMagazine.data.getParam !== null){
			
				from    = refMailMagazine.data.getParam.from;
			}
			else{
				//データがなかった時
				this.setState({
					isLoading : false,
					ErrMsg    : '差出人の設定がありません。<br/>',
				});
				return(0);
			}

			//データ
			let profileData = [];
		
			if(flag === 2 || flag === 3){
		
				//console.log(999);
				//ユーザ件数の取得
				const CountQuery = `query scanProfileCount {
					scanProfileCount {
						scannedCount
						nextToken
					}
				}`;
				let refCount     = await API.graphql(graphqlOperation(CountQuery));
				let profileCount = refCount.data.scanProfileCount.scannedCount;
				
				//進捗情報
				let loadingPer   = 1;
				if(refCount.data.scanProfileCount.nextToken){
					this.setState({
						loadingPer : loadingPer,
					});
				}

				//ユーザ件数の取得（未取得の続きデータがあった場合の処理）
				while (refCount.data.scanProfileCount.nextToken) {
					const CountQuery = `query scanProfileCount($nextToken: String) {
						scanProfileCount(nextToken: $nextToken) {
							scannedCount
							nextToken
						}
					}`;
					const CountData = {
						nextToken: refCount.data.scanProfileCount.nextToken,
					};
					refCount = await API.graphql(graphqlOperation(CountQuery, CountData));
					profileCount = profileCount + refCount.data.scanProfileCount.scannedCount;
				}
				
				//ユーザ情報の取得
				const ProfileQuery = `query scanProfile {
					scanProfile {
						items {
							name_1
							name_2
							email
							en_kbn
							mg_kbn_1
						}
						nextToken
					}
				}`;
				let refProfile = await API.graphql(graphqlOperation(ProfileQuery));
				profileData = refProfile.data.scanProfile.items;
				
				//進捗情報
				if(refProfile.data.scanProfile.nextToken){
					const perNow = Math.floor(profileData.length / profileCount * 100);
					if(loadingPer < perNow){
						this.setState({
							loadingPer : perNow,
						});
					}
				}
				
				//ユーザ情報の取得（未取得の続きデータがあった場合の処理）
				while (refProfile.data.scanProfile.nextToken) {
					const ProfileQuery = `query scanProfile($nextToken: String) {
						scanProfile(nextToken: $nextToken) {
							items {
								name_1
								name_2
								email
								en_kbn
								mg_kbn_1
							}
							nextToken
						}
					}`;
					const Data = {
						nextToken: refProfile.data.scanProfile.nextToken,
					};
					//console.log(refProfile.data.scanProfile.nextToken);
					refProfile = await API.graphql(graphqlOperation(ProfileQuery, Data));
					profileData = profileData.concat(refProfile.data.scanProfile.items);
					//進捗情報
					if(profileData.length){
						const perNow = Math.floor(profileData.length / profileCount * 100);
						if(loadingPer < perNow){
							this.setState({
								loadingPer : perNow,
							});
						}
					}
				}
				
				if(flag === 2){
					//mg_kbn_1除外
					profileData = profileData.filter(item => item.mg_kbn_1 === true);
				}

			}
			else if(flag === 1){

				profileData.push({
					name_1 : 'テスト',
					name_2 : '配信',
					email  : from,
					en_kbn : true,
					mg_kbn_1 : false,
				});
			}
			else{
				this.setState({
					isLoading : false,
					ErrMsg    : '指定された区分が不正です。<br/>',
				});
				return(0);
			}

			//バウンスの取得
			const BounceQuery = `query scanMailBounceLog {
				scanMailBounceLog {
					items {
						email
						bounce_type
						bounce_sub_type
						bounce_action
						bounce_status
						bounce_code
					}
					nextToken
				}
			}`;
			let refBounce = await API.graphql(graphqlOperation(BounceQuery));
			let bounceData = refBounce.data.scanMailBounceLog.items;
			//console.log(bounceData);
			
			//バウンスの取得（未取得の続きデータがあった場合の処理）
			while (refBounce.data.scanMailBounceLog.nextToken) {
				const BounceQuery = `query scanMailBounceLog($nextToken: String) {
					scanMailBounceLog(nextToken: $nextToken) {
						items {
							email
							bounce_type
							bounce_sub_type
							bounce_action
							bounce_status
							bounce_code
						}
						nextToken
					}
				}`;
				const BounceData = {
					nextToken: refBounce.data.scanMailBounceLog.nextToken,
				};
				//console.log(refBounce.data.scanMailBounceLog.nextToken);
				refBounce = await API.graphql(graphqlOperation(BounceQuery, BounceData));
				bounceData = bounceData.concat(refBounce.data.scanMailBounceLog.items);
			}

			//苦情の取得
			const ComplaintQuery = `query scanMailComplaintLog {
				scanMailComplaintLog {
					items {
						email
						complaint_type
						complaint_user
					}
					nextToken
				}
			}`;
			let refComplaint = await API.graphql(graphqlOperation(ComplaintQuery));
			let complaintData = refComplaint.data.scanMailComplaintLog.items;
			//console.log(complaintData);
			
			//苦情の取得（未取得の続きデータがあった場合の処理）
			while (refComplaint.data.scanMailComplaintLog.nextToken) {
				const ComplaintQuery = `query scanMailComplaintLog($nextToken: String) {
					scanMailComplaintLog(nextToken: $nextToken) {
						items {
							email
							complaint_type
							complaint_user
						}
						nextToken
					}
				}`;
				const ComplaintData = {
					nextToken: refComplaint.data.scanMailComplaintLog.nextToken,
				};
				//console.log(refComplaint.data.scanMailComplaintLog.nextToken);
				refComplaint = await API.graphql(graphqlOperation(ComplaintQuery, ComplaintData));
				complaintData = complaintData.concat(refComplaint.data.scanMailComplaintLog.items);
			}
			
			//'null'文字がある場合は''に変換
			profileData.some((item, index) => Object.keys(item).some(key => { if(item[key] === 'null'){ profileData[index][key] = '' } return false; }));
			
			//メールアドレスを確認
			profileData = profileData.filter(item => item.email !== '');
			
			//バウンス除外
			if(flag !== 1){
				profileData = profileData.filter(item => bounceData.findIndex(({email}) => email === item.email) === -1);
			}
			
			//苦情除外
			if(flag !== 1){
				profileData = profileData.filter(item => complaintData.findIndex(({email}) => email === item.email) === -1);
			}
			
			//en_kbn除外
			profileData = profileData.filter(item => item.en_kbn === true);
			
			
			//データコピー
			const magazineData = [];
			//let count = 0;
			for(let row of profileData){
				//debug
				//count++;
				//row.email = 'mztest-' + count + '@mindwave-store.com';
				magazineData.push(row);
			}
			
			//console.log(magazineData);
			
			//データの判別
			if(magazineData.length === 0){
				//データがなかった時
				this.setState({
					isLoading : false,
					ErrMsg    : '対象となるデータがありません。<br/>',
				});
				return(0);
			}

			//番号の生成
			let magazineno = '';
			for (  let i = 0;  i < 255;  i++  ) {
				//日付処理
				const dateObj     = new Date();
				const year        = dateObj.getFullYear().toString();
				const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
				const day         = ('00' + dateObj.getDate().toString()).slice(-2);
				var fromObj       = new Date(year + '/' + month + '/' + day + ' 00:00:00');
				var time          = dateObj.getTime() - fromObj.getTime();
				var sec           = ('00000' + Math.floor(time / 1000).toString()).slice(-5);
				var ms            = ('000' + time.toString()).slice(-3);
				//受注番号の生成
				const generate = 'MailMagazine-' + year + month + day + '-' + sec + ms;
				//重複がないか確認
				const Query = `query getMailMagazine($magazineno: String!) {
					getMailMagazine(magazineno: $magazineno) {
						magazineno
					}
				}`;
				const Data = { magazineno: generate };
				const ref = await API.graphql(graphqlOperation(Query, Data));
				
				//データが空かどうか
				if(ref.data.getMailMagazine === null){
					magazineno = generate;
					break;
				}
			}
			
			//データの判別
			if(!magazineno){
				//データがなかった時
				this.setState({
					isLoading : false,
					ErrMsg    : 'メールマガジンの発行番号の作成に失敗しました。<br/>',
				});
				return(0);
			}

			this.setState({
				isLoading       : false,
				magazineno      : magazineno,
				from            : from,
				magazineData    : magazineData,
				bounceData      : bounceData,
				complaintData   : complaintData,
			});
			
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLoading  : false,
				isLockForm : false,
			 });
			return(0);
		}
	}
	async handleSubmit (event) {

		event.preventDefault();
		event.stopPropagation();

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Stateから直接取得する更新項目
			const magazineno = this.state.magazineno;
			const from       = this.state.from;

			//Formから直接取得する更新項目
			const subject     = formObj.subject.value;
			const msg_text    = formObj.msg_text.value;
			const msg_html    = formObj.msg_html.value;
			const reserv_date = formObj.reserv_date.value;
			
			//更新成功
			this.setState({
				subject     : subject,
				msg_text    : msg_text,
				msg_html    : msg_html,
				reserv_date : reserv_date,
			});

			//予約日の確認
			//const reserv_date_obj = new Date(reserv_date);
			//const now_date_obj    = new Date();
			//const reserv_time     = reserv_date_obj.getTime();
			//const now_time         = now_date_obj.getTime() + (60 * 60 * 1000);
			//Formオブジェクトの確認
			/*if(now_time > reserv_time){
				this.setState({
					ErrMsg     : '※配信日時は現在より1時間以降を指定してください。<br/>',
					isLockForm : false,
				});
				return(0);
			}*/

			//Formオブジェクトの確認
			if(!magazineno){
				this.setState({
					ErrMsg     : '※送信先のデータがありません。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(!from){
				this.setState({
					ErrMsg     : '※差出人の設定がありません。<br/>',
					isLockForm : false,
				});
				return(0);
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isNextPage  : true,
			isLockForm  : false,
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this.setState({
			isLoading  : false,
		});
	}
	render() {
		if(this.state.isLoading && this.state.loadingPer){
			return (
				<div>
					<Row style={{ marginTop: 200, marginBottom: 200 }}>
						<Col sm={{ span: 6, offset: 3 }}>
							<ProgressBar now={this.state.loadingPer} label={this.state.loadingPer + `%`} animated />
						</Col>
					</Row>
				</div>
			);
		}
		else if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else if(this.state.isNextPage){
			return (
				<NextPage _backTop={this._backTop} {...this.state}  {...this.props} />
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Modal show={this.state.showEmail} onHide={()=>{ this.setState({ showEmail: false }); }} centered size="lg">
						<Modal.Body className="text-center">
							<BootstrapTable bootstrap4 keyField='email' data={ this.state.magazineData } columns={ [ { dataField: 'email', text: 'メールアドレス', sort: true, }, { dataField: 'name_1', text: '性', sort: true, }, { dataField: 'name_2', text: '名', sort: true, }, { dataField: 'mg_kbn_1', text: 'メルマガ区分', sort: true, }, ] } bordered={ false } defaultSorted={ [{ dataField: 'email', order: 'asc'}] } pagination={ paginationFactory() } />
						</Modal.Body>
					</Modal>
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>
					<Row>
						<Col sm={12}>
							<p><big>メールマガジン新規発行</big></p>
						</Col>
					</Row>
					<Row>
							<Form.Label column sm={3}>
								配信区分
							</Form.Label>
							<Col sm={8}>

								<Button variant="dark" onClick={ ()=>{ this.handleReload(1); } }>
									配信テスト
								</Button>　
								<Button variant="dark" onClick={ ()=>{ this.handleReload(2); } }>
									メールマガジン対象者
								</Button>　
								<Button variant="dark" onClick={ ()=>{ this.handleReload(3); } }>
									全会員
								</Button>
								<br/>　
							</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メールマガジン番号
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="magazineno">
							        <Form.Control name="magazineno" type="text" defaultValue={this.state.magazineno ? this.state.magazineno : '上記ボタンから送信先情報を取得してください。' } size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							送信対象件数
						</Form.Label>
						<Col sm={7}>
							<Form.Group controlId="count">
							        <Form.Control name="count" type="text" defaultValue={this.state.magazineData.length ? this.state.magazineData.length.toLocaleString() + '件' : '(未取得)' } size="lg" readOnly />
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Button variant="dark" onClick={ ()=>{ this.setState({ showEmail: true }); } }>
								確認
							</Button>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							差出人
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="from">
							        <Form.Control name="from" type="text" defaultValue={this.state.from ? this.state.from : '(未取得)' } size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メール件名　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="subject">
							        <Form.Control name="subject" type="text" defaultValue={this.state.subject} placeholder="メール件名" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「メール件名」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メール本文（TEXT）　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="msg_text">
							        <Form.Control name="msg_text" as="textarea" defaultValue={this.state.msg_text} rows="15" placeholder="" size="lg" required pattern=".{1,1000}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「メール本文（TEXT）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メール本文（HTML）
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="msg_html">
							        <Form.Control name="msg_html" as="textarea" defaultValue={this.state.msg_html} rows="15" placeholder="" size="lg" pattern=".{1,1000}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「メール本文（HTML）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							配信日時　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="reserv_date">
							        <Form.Control name="reserv_date" type="datetime-local" defaultValue={this.state.reserv_date} size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「配信日時」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >プレビューを確認する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props.history.push('/MailTmpl/'); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					</Form>


				</Container>
			);
		}
	}
}

class NextPage extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isValidated     : false,
			isSuccess       : false,
			isShowModal     : false,
			isLockForm      : false,
			loadingPer      : 0,
			ErrMsg          : '',
		}
		this.handleSubmit     = this.handleSubmit.bind(this);
		this.showModal        = this.showModal.bind(this);
		this.handleClose      = this.handleClose.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
		this.lockFormClose    = this.lockFormClose.bind(this);
	}
	async handleSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal  : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{

			//Cognito IDの取得
			const user      = await Auth.currentAuthenticatedUser();
			const cognitoid = user.username;

			//propsから取得する更新項目
			const magazineno   = this.props.magazineno;
			const subject      = this.props.subject;
			const from         = this.props.from;
			const msg_text     = this.props.msg_text;
			const msg_html     = this.props.msg_html;
			const reserv_date  = this.props.reserv_date;
			
			//propsから取得する更新項目
			const magazineData  = this.props.magazineData;
			const bounceData    = this.props.bounceData;
			const complaintData = this.props.complaintData;

			//日時
			const dateObj = new Date();
			const isoDate = dateObj.toISOString();
			
			//日時
			const reserv_date_obj = new Date(reserv_date);
			const reserv_date_iso = reserv_date_obj.toISOString();
			const reserv_time     = (reserv_date_obj.getTime() / 1000);
			
			/*
			//ストレージを定義
			Storage.configure({ AWSS3: { bucket: 'mindwavestore.admin', region: 'ap-northeast-1', level: 'public' } });
			
			//console.log(magazineData);
			
			//S3に保存する
			await Storage.put('MailMagazine/_reserved/' + magazineno + '.json', JSON.stringify({
				magazineno      : magazineno,
				send_count      : magazineData.length,
				bounce_count    : bounceData.length,
				complaint_count : complaintData.length,
				subject         : subject,
				from            : from,
				msg_text        : msg_text,
				msg_html        : msg_html ? msg_html : 'null',
				reserv_date     : reserv_date_iso,
				reserv_time     : reserv_time,
				status          : 'reserved',
				date_add        : isoDate,
				date_edit       : isoDate,
				magazineData    : magazineData,
				bounceData      : bounceData,
				complaintData   : complaintData,
				cognitoid       : cognitoid,
			}));
			*/
			
			let count = 0;
			for(const item of magazineData){
			
				count++;
			
				const email = item.email;

				//登録
				const createQuery = `mutation createMailMagazineAddress($data: MailMagazineAddressInput!) {
					createMailMagazineAddress(input: $data) {
						magazineno
					}
				}`;
				const createData = {
					data : {
						magazineno      : magazineno,
						email           : email,
						status          : 'unexecuted',
						ref             : null,
						date_add        : isoDate,
					}
				};
				//console.log(createData);
				await API.graphql(graphqlOperation(createQuery, createData));
				
				
				this.setState({
					loadingPer : Math.floor(count / magazineData.length * 100),
				});

			}

			//登録
			const createQuery = `mutation createMailMagazine($data: MailMagazineInput!) {
				createMailMagazine(input: $data) {
					magazineno
				}
			}`;
			const createData = {
				data : {
					magazineno      : magazineno,
					send_count      : magazineData.length,
					bounce_count    : bounceData.length,
					complaint_count : complaintData.length,
					subject         : subject,
					from            : from,
					msg_text        : msg_text,
					msg_html        : msg_html ? msg_html : 'null',
					reserv_date     : reserv_date_iso,
					reserv_time     : reserv_time,
					status          : 'reserved',
					date_add        : isoDate,
					date_edit       : isoDate,
					cognitoid       : cognitoid,
				}
			};
			await API.graphql(graphqlOperation(createQuery, createData));


			//鑑査ログ
			await AuditLog('mailmagazine', 'create', magazineno, createData, 'メールマガジン配信を予約しました。');

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
			loadingPer  : 0,
		});
	}
	handleClose() {
		this.setState({
			isShowModal  : false,
			isSuccess    : false,
			isValidated  : false,
			isLockForm   : false,
			ErrMsg     : '',
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	showModal(event) {
		event.preventDefault();
		event.stopPropagation();
		this.setState({
			isShowModal  : true,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		//
	}
	render() {
		if(this.state.loadingPer){
			return (
				<div>
					<Row style={{ marginTop: 200, marginBottom: 200 }}>
						<Col sm={{ span: 6, offset: 3 }}>
							<ProgressBar now={this.state.loadingPer} label={this.state.loadingPer + `%`} animated />
						</Col>
					</Row>
				</div>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={()=>{ this.props.history.push('/MailMagazine/'); }} centered>
						<Modal.Body>
							予約しました。<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={()=>{ this.props.history.push('/MailMagazine/'); }}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowModal} onHide={this.handleClose} centered>
						<Modal.Body>
							本当に予約しますか？<br/>
							配信データの作成には数十分かかります。ご注意ください。<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.handleSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを処理中です。
						</Modal.Body>
					</Modal>
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.showModal(e)}>
					<Row>
						<Col sm={12}>
							<p><big>メール送信</big></p>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メールマガジン番号
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="magazineno">
							        <Form.Control name="magazineno" type="text" defaultValue={this.props.magazineno} size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							送信対象件数
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="count">
							        <Form.Control name="count" type="text" defaultValue={this.props.magazineData.length.toLocaleString() + '件'} size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							差出人アドレス
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="from">
							        <Form.Control name="from" type="email" defaultValue={this.props.from} placeholder="差出人アドレス" size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メール件名
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="subject">
							        <Form.Control name="subject" type="email" defaultValue={this.props.subject} placeholder="メール件名" size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メール本文（TEXT）
						</Form.Label>
						<Col sm={8}>
							<pre style={{ border: 'solid 1px gray', padding: '2em', marginTop: 20 }}>
							{this.props.msg_text }
							</pre>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メール本文（HTML）
						</Form.Label>
						<Col sm={8}>
							<pre style={{ border: 'solid 1px gray', padding: '2em', marginTop: 20 }}>
							{this.props.msg_html }
							</pre>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							配信日時
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="reserv_date">
							        <Form.Control name="reserv_date" type="datetime-local" defaultValue={this.props.reserv_date} placeholder="配信日時" size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >予約する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props._backTop(); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					</Form>


				</Container>
			);
		}
	}
}

export default withRouter(Default);


