import React from 'react';
import { Auth } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Alert,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { AuditLog } from '../../Objects/AuditLog';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			validated      : false,
			ErrFlag        : false,
			ErrMsg         : '',
			InfoFlag       : false,
			old_passwd     : '',
			new_passwd     : '',
		}
		this._passwdChange     = this._passwdChange.bind(this);
		this.handleChange      = this.handleChange.bind(this);
		this.handleSubmit      = this.handleSubmit.bind(this);
		this.handleDismissErr  = this.handleDismissErr.bind(this);
		this.handleDismissInfo = this.handleDismissInfo.bind(this);
	}
	async _passwdChange(old_passwd, new_passwd){
	
		try {
			const user = await Auth.currentAuthenticatedUser();
			await Auth.changePassword(user, old_passwd, new_passwd);
			//console.log(data);

			//鑑査ログ
			await AuditLog('user', 'update', 'password', null, 'パスワードを変更しました。');

			this.setState({ ErrFlag : false   });
			this.setState({ ErrMsg  : ''      });
			this.setState({ InfoFlag: true    });
		}
		catch(error) {
			this.setState({ ErrFlag : true    });
			this.setState({ ErrMsg  : error.message });
			this.setState({ InfoFlag: false    });
		}
	
	}
	handleChange(e){
		switch (e.target.name) {
			case 'old_passwd':
				this.setState({ old_passwd: e.target.value });
				break;
			case 'new_passwd':
				this.setState({ new_passwd: e.target.value });
				break;
			default:
				break;
		}
	}
	handleSubmit () {
		this.setState({ validated: true });
		const old_passwd = this.state.old_passwd;
		const new_passwd = this.state.new_passwd;
		let err_flag = false;
		let err_msg  = '';
		if(!old_passwd.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,255}$/)){
			err_flag = true;
			err_msg  = err_msg + '※古いパスワードは必ず半角数字、半角英字の大文字と小文字を1文字以上ずつ入れて8文字以上255文字以下にしてください。<br/>';
		}
		else if(old_passwd.length >= 255){
			err_flag = true;
			err_msg  = err_msg + '※古いパスワードは255文字以内で入力してください。<br/>';
		}
		if(!new_passwd.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,255}$/)){
			err_flag = true;
			err_msg  = err_msg + '※新しいパスワードは必ず半角数字、半角英字の大文字と小文字を1文字以上ずつ入れて8文字以上255文字以下にしてください。<br/>';
		}
		else if(new_passwd.length >= 255){
			err_flag = true;
			err_msg  = err_msg + '※新しいパスワードは255文字以内で入力してください。<br/>';
		}
		if(err_flag){
		
			this.setState({ ErrFlag : true    });
			this.setState({ ErrMsg  : err_msg });
			this.setState({ InfoFlag: false   });
		}
		else{
			this._passwdChange(old_passwd, new_passwd);
		}
	}
	handleDismissErr() {
		this.setState({ ErrFlag: false });
	}
	handleDismissInfo() {
		this.setState({ InfoFlag: false });
	}
	componentDidMount () {
		//
	}
	render() {
		return (
			<Container style={{ marginTop: 200, marginBottom: 200 }}>
				{(() => {
					if(this.state.ErrFlag){
						return (
							<Row>
							<Col sm={{ span: 6, offset: 3 }}>
								<Alert variant="danger" onClose={this.handleDismissErr}>
									エラーがありました。<br/>
									{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}<br/>
									<p>パスワードを忘れた場合は<a href="https://quickdeploy.jp/signup/forgotPasswordSubmit/">こちら</a>を参照してください。</p>
								</Alert>
							</Col>
							</Row>
						);
					}
				})()}
				{(() => {
					if(this.state.InfoFlag){
						return (
							<Row>
							<Col sm={{ span: 6, offset: 3 }}>
								<Alert variant="info" onClose={this.handleDismissUseInfo}>
									パスワードを変更しました。
								</Alert>
							</Col>
							</Row>
						);
					}
				})()}
				<Form onChange={this.handleChange} validated={this.state.validated}>
				<Row>
					<Col sm={{ span: 4, offset: 4 }}>
						<p className="text-center"><big>パスワードの変更</big></p>
					</Col>
				</Row>
				<Row>
					<Col sm={{ span: 4, offset: 4 }}>
						<Form.Group controlId="formOldPassword" size="lg">
						        <Form.Control name="old_passwd" type="password" value={this.state.old_passwd.value} placeholder="古いパスワード" required />
							<Form.Control.Feedback />
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col sm={{ span: 4, offset: 4 }}>
						<Form.Group controlId="formNewPassword" size="lg">
						        <Form.Control name="new_passwd" type="password" value={this.state.new_passwd.value} placeholder="新しいパスワード" required />
							<Form.Control.Feedback />
						</Form.Group>
					</Col>
				</Row>
				<Row style={{ marginTop: 10 }}>
					<Col sm={{ span: 4, offset: 4 }}>
					        <Button variant="dark" type="button" block onClick={this.handleSubmit} size="lg">変更</Button>
					</Col>
				</Row>
				</Form>
			</Container>
		);
	}
}

export default withRouter(Default);


