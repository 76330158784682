import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Modal,
	Badge,
	Table,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import NotFound from '../../Objects/NotFound';
import { AuditLog } from '../../Objects/AuditLog';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isNotFound     : false,
			isValidated    : false,
			isSuccess      : false,
			isShowModal    : false,
			isLockForm     : false,
			formObj        : null,
			formSelect     : '',
			ErrMsg         : '',
			viewData       : [],
			defaultData    : [],
			orderno        : '',
			cognitoid      : '',
			total_price    : 0,
			payment_type   : '',
			payment_price  : 0,
			carriage_type  : '',
			carriage       : 0,
			cashdeli       : 0,
			wrapping_type  : '',
			wrapping       : 0,
			coupon_type    : '',
			coupon         : 0,
			email          : '',
			name_1         : '',
			name_2         : '',
			name_furi_1    : '',
			name_furi_2    : '',
			zip_1          : '',
			zip_2          : '',
			pref           : '',
			addr_1         : '',
			addr_2         : '',
			addr_3         : '',
			tel_1          : '',
			tel_2          : '',
			tel_3          : '',
			o_name_1       : '',
			o_name_2       : '',
			o_name_furi_1  : '',
			o_name_furi_2  : '',
			o_zip_1        : '',
			o_zip_2        : '',
			o_pref         : '',
			o_addr_1       : '',
			o_addr_2       : '',
			o_addr_3       : '',
			o_tel_1        : '',
			o_tel_2        : '',
			o_tel_3        : '',
			o_com_name     : '',
			o_date_type    : '',
			o_date_h       : '',
			order_memo     : '',
			delivery_no    : '',
			delivery_date  : '',
			status         : '',
			memo           : '',
			delivery_message : '',
			shop_comment   : '',
			not_ec_user_kbn : false,
			flag           : '',
			date_add       : '',
			date_edit      : '',
		}
		this.handleReload           = this.handleReload.bind(this);
		this.handleClose            = this.handleClose.bind(this);
		this.handleErrorClose       = this.handleErrorClose.bind(this);
		this.lockFormClose          = this.lockFormClose.bind(this);
		this._getData               = this._getData.bind(this);
		this.ShowUpdateModal        = this.ShowUpdateModal.bind(this);
		this.OrderMemoUpdate        = this.OrderMemoUpdate.bind(this);
		this.DeliveryMessageUpdate  = this.DeliveryMessageUpdate.bind(this);
		this.OrderShopCommentUpdate = this.OrderShopCommentUpdate.bind(this);
		this.OrderDataUpdate        = this.OrderDataUpdate.bind(this);
		this.OrderPersonUpdate      = this.OrderPersonUpdate.bind(this);
		this.ShippingDataUpdate     = this.ShippingDataUpdate.bind(this);
		this.ShippingPersonUpdate   = this.ShippingPersonUpdate.bind(this);
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	async _getData() {

		try{
			//IDの取得
			const orderno = this.props.match.params.orderno;
			if(!orderno){
					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
			}

			//データ取得
			const Query = `query getOrder($orderno: String!) {
				getOrder(orderno: $orderno) {
					cognitoid
					total_price
					payment_type
					payment_price
					carriage_type
					carriage
					cashdeli
					wrapping_type
					wrapping
					coupon_type
					coupon
					email
					name_1
					name_2
					name_furi_1
					name_furi_2
					zip_1
					zip_2
					pref
					addr_1
					addr_2
					addr_3
					tel_1
					tel_2
					tel_3
					o_name_1
					o_name_2
					o_name_furi_1
					o_name_furi_2
					o_zip_1
					o_zip_2
					o_pref
					o_addr_1
					o_addr_2
					o_addr_3
					o_tel_1
					o_tel_2
					o_tel_3
					o_com_name
					o_date_type
					o_date_h
					order_memo
					delivery_no
					delivery_date
					status
					memo
					delivery_message
					shop_comment
					flag
					not_ec_user_kbn
					date_add
					date_edit
					order_items{
						orderno
						code
						name
						price
						sub_price
						piece
						piece_type
						date_add
						date_edit
					}
				}
			}`;
			const Data = { orderno: orderno };
			const ref = await API.graphql(graphqlOperation(Query, Data));
			let item = ref.data.getOrder;

			//データが空かどうか
			if(item === null){

				//データ取得
				const Query = `query getOldOrder($orderno: String!) {
					getOldOrder(orderno: $orderno) {
						cognitoid
						total_price
						payment_type
						payment_price
						carriage_type
						carriage
						cashdeli
						wrapping_type
						wrapping
						coupon_type
						coupon
						email
						name_1
						name_2
						name_furi_1
						name_furi_2
						zip_1
						zip_2
						pref
						addr_1
						addr_2
						addr_3
						tel_1
						tel_2
						tel_3
						o_name_1
						o_name_2
						o_name_furi_1
						o_name_furi_2
						o_zip_1
						o_zip_2
						o_pref
						o_addr_1
						o_addr_2
						o_addr_3
						o_tel_1
						o_tel_2
						o_tel_3
						o_com_name
						o_date_type
						o_date_h
						order_memo
						delivery_no
						delivery_date
						status
						memo
						delivery_message
						shop_comment
						flag
						not_ec_user_kbn
						date_add
						date_edit
						order_items{
							orderno
							code
							name
							price
							sub_price
							piece
							piece_type
							date_add
							date_edit
						}
					}
				}`;
				const Data = { orderno: orderno };
				const ref = await API.graphql(graphqlOperation(Query, Data));
				item = ref.data.getOldOrder;

				//データが空かどうか
				if(item === null){

					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
				}
			}

			const cognitoid     = item.cognitoid;
			const total_price   = item.total_price;
			const payment_type  = item.payment_type;
			const payment_price = item.payment_price;
			const carriage_type = item.carriage_type;
			const carriage      = item.carriage;
			const cashdeli      = item.cashdeli;
			const wrapping_type = item.wrapping_type;
			const wrapping      = item.wrapping;
			const coupon_type   = item.coupon_type;
			const coupon        = item.coupon;
			const email         = item.email === 'null' ? '' : item.email;
			const name_1        = item.name_1;
			const name_2        = item.name_2;
			const name_furi_1   = item.name_furi_1;
			const name_furi_2   = item.name_furi_2;
			const zip_1         = item.zip_1;
			const zip_2         = item.zip_2;
			const pref          = item.pref;
			const addr_1        = item.addr_1;
			const addr_2        = item.addr_2;
			const addr_3        = item.addr_3 === 'null' ? '' : item.addr_3;
			const tel_1         = item.tel_1;
			const tel_2         = item.tel_2;
			const tel_3         = item.tel_3;
			const o_name_1      = item.o_name_1;
			const o_name_2      = item.o_name_2;
			const o_name_furi_1 = item.o_name_furi_1;
			const o_name_furi_2 = item.o_name_furi_2;
			const o_zip_1       = item.o_zip_1;
			const o_zip_2       = item.o_zip_2;
			const o_pref        = item.o_pref;
			const o_addr_1      = item.o_addr_1;
			const o_addr_2      = item.o_addr_2;
			const o_addr_3      = item.o_addr_3 === 'null' ? '' : item.o_addr_3;
			const o_tel_1       = item.o_tel_1;
			const o_tel_2       = item.o_tel_2;
			const o_tel_3       = item.o_tel_3;
			const o_com_name    = item.o_com_name === 'null' ? '' : item.o_com_name;
			let   o_date_type   = item.o_date_type;
			const o_date_h      = item.o_date_h
			const order_memo    = item.order_memo === 'null' ? '' : item.order_memo;
			const delivery_no   = item.delivery_no === 'null' ? '' : item.delivery_no;
			const delivery_date = item.delivery_date === 'null' ? '' : item.delivery_date;
			const status        = item.status === 'null' ? '未定義' : item.status;
			const memo          = item.memo === 'null' ? '' : item.memo;
			const delivery_message = item.delivery_message === 'null' ? '' : item.delivery_message;
			const shop_comment  = item.shop_comment === 'null' ? '' : item.shop_comment;
			const flag          = item.flag;
			const not_ec_user_kbn = item.not_ec_user_kbn;

			//console.log(payment_type);

			/*
			//旧式の日付型を変換
			if(o_date_type.match('年')){
				const o_date_type_year = o_date_type.split('年');
				if(o_date_type_year.length){
					const o_date_type_month = o_date_type_year[1].split('月');
					o_date_type = o_date_type_year[0] + '-' + o_date_type_month[0] + '-' + o_date_type_month[1].replace('日','');
					console.log(o_date_type);
				}
			}
			*/


			//日付
			const date_add         = new Date(item.date_add);
			const date_add_year    = date_add.getFullYear().toString();
			const date_add_month   = ('00' + (date_add.getMonth()+1).toString()).slice(-2);
			const date_add_day     = ('00' + date_add.getDate().toString()).slice(-2);
			const date_add_hour    = ('00' + date_add.getHours().toString()).slice(-2);
			const date_add_minute  = ('00' + date_add.getMinutes().toString()).slice(-2);
			const date_add_second  = ('00' + date_add.getSeconds().toString()).slice(-2);
			const date_edit        = new Date(item.date_edit);
			const date_edit_year   = date_edit.getFullYear().toString();
			const date_edit_month  = ('00' + (date_edit.getMonth()+1).toString()).slice(-2);
			const date_edit_day    = ('00' + date_edit.getDate().toString()).slice(-2);
			const date_edit_hour   = ('00' + date_edit.getHours().toString()).slice(-2);
			const date_edit_minute = ('00' + date_edit.getMinutes().toString()).slice(-2);
			const date_edit_second = ('00' + date_edit.getSeconds().toString()).slice(-2);

			//受注商品の取得
			const order_items  = item.order_items;

			//データコピー
			let Items = [];
			for(let row of order_items){
				Items.push(row);
			}

			//console.log(Items);

			//データの判別
			if(Items.length === 0){
				//データがなかった時
				//this.setState({
				//	isLoading : false,
				//	ErrMsg    : '受注商品データがありません。',
				//});
			}
			else{
				//'null'文字がある場合は''に変換
				Items.some((item, index) => Object.keys(item).some(key => { if(item[key] === 'null'){ Items[index][key] = '' } return false; }));

				//データをソート
				Items.sort(
					(a, b) => {
						if (a.name < b.name) return -1;
						if (a.name > b.name) return 1;
						return 0;
					}
				);
			}

			this.setState({
				isLoading     : false,
				viewData      : Items,
				defaultData   : Items,
				orderno       : orderno,
				cognitoid     : cognitoid,
				total_price   : total_price ? total_price : 0,
				payment_type  : payment_type,
				payment_price : payment_price ? payment_price : 0,
				carriage_type : carriage_type,
				carriage      : carriage ? carriage : 0,
				cashdeli      : cashdeli ? cashdeli : 0,
				wrapping_type : wrapping_type,
				wrapping      : wrapping ? wrapping : 0,
				coupon_type   : coupon_type,
				coupon        : coupon ? coupon : 0,
				email         : email,
				name_1        : name_1,
				name_2        : name_2,
				name_furi_1   : name_furi_1,
				name_furi_2   : name_furi_2,
				zip_1         : zip_1,
				zip_2         : zip_2,
				pref          : pref,
				addr_1        : addr_1,
				addr_2        : addr_2,
				addr_3        : addr_3,
				tel_1         : tel_1,
				tel_2         : tel_2,
				tel_3         : tel_3,
				o_name_1      : o_name_1,
				o_name_2      : o_name_2,
				o_name_furi_1 : o_name_furi_1,
				o_name_furi_2 : o_name_furi_2,
				o_zip_1       : o_zip_1,
				o_zip_2       : o_zip_2,
				o_pref        : o_pref,
				o_addr_1      : o_addr_1,
				o_addr_2      : o_addr_2,
				o_addr_3      : o_addr_3,
				o_tel_1       : o_tel_1,
				o_tel_2       : o_tel_2,
				o_tel_3       : o_tel_3,
				o_com_name    : o_com_name,
				o_date_type   : o_date_type,
				o_date_h      : o_date_h,
				order_memo    : order_memo,
				delivery_no   : delivery_no,
				delivery_date : delivery_date,
				status        : status,
				memo          : memo,
				delivery_message : delivery_message,
				shop_comment  : shop_comment,
				flag          : flag,
				not_ec_user_kbn : not_ec_user_kbn ? true : false,
				date_add      : date_add_year + '年' + date_add_month + '月' + date_add_day + '日' + date_add_hour + '時' + date_add_minute + '分' + date_add_second + '秒',
				date_edit     : date_edit_year + '年' + date_edit_month + '月' + date_edit_day + '日' + date_edit_hour + '時' + date_edit_minute + '分' + date_edit_second + '秒',
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLoading  : false,
				isLockForm : false,
			 });
			return(0);
		}
	}
	//出荷管理メモの編集
	async OrderMemoUpdate () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal  : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Stateから取得する更新項目
			const orderno       = this.state.orderno;

			//Formから直接取得する更新項目
			const memo          = formObj.memo.value;

			//日付処理
		        const date          = new Date();
		        const isoDate       = date.toISOString();

			//プロフィールの更新登録
			const Data = {
				data : {
					orderno       : orderno,
					memo          : memo ? memo : 'null',
					date_edit     : isoDate,
				}
			};

			//最新のもの
			try{
				const Query = `mutation updateOrder($data: OrderInput!) {
					updateOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}

			//古いもの
			try{
				const Query = `mutation updateOldOrder($data: OrderInput!) {
					updateOldOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}

			//鑑査ログ
			await AuditLog('order', 'update', orderno, memo, 'メモを変更しました。');
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	//出荷指示の編集
	async DeliveryMessageUpdate () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal  : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Stateから取得する更新項目
			const orderno       = this.state.orderno;

			//Formから直接取得する更新項目
			const delivery_message  = formObj.delivery_message.value;

			//日付処理
		        const date          = new Date();
		        const isoDate       = date.toISOString();

			//プロフィールの更新登録
			const Data = {
				data : {
					orderno          : orderno,
					delivery_message : delivery_message ? delivery_message : 'null',
					date_edit        : isoDate,
				}
			};

			//最新のもの
			try{
				const Query = `mutation updateOrder($data: OrderInput!) {
					updateOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}

			//古いもの
			try{
				const Query = `mutation updateOldOrder($data: OrderInput!) {
					updateOldOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}

			//鑑査ログ
			await AuditLog('order', 'update', orderno, delivery_message, '出荷指示を変更しました。');
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	//出荷管理メモの編集
	async OrderShopCommentUpdate () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal  : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Stateから取得する更新項目
			const orderno       = this.state.orderno;

			//Formから直接取得する更新項目
			const shop_comment          = formObj.shop_comment.value;

			//日付処理
		        const date          = new Date();
		        const isoDate       = date.toISOString();

			//プロフィールの更新登録
			const Data = {
				data : {
					orderno       : orderno,
					shop_comment  : shop_comment ? shop_comment : 'null',
					date_edit     : isoDate,
				}
			};

			//最新のもの
			try{
				const Query = `mutation updateOrder($data: OrderInput!) {
					updateOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}

			//古いもの
			try{
				const Query = `mutation updateOldOrder($data: OrderInput!) {
					updateOldOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}

			//鑑査ログ
			await AuditLog('order', 'update', orderno, shop_comment, 'メール用コメント（##shopcomment##）を変更しました。');
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	//受注情報の編集
	async OrderDataUpdate () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal  : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Stateから取得する更新項目
			const orderno       = this.state.orderno;

			//Formから直接取得する更新項目
			const status        = formObj.status.value;
			const payment_type  = formObj.payment_type.value;
			const carriage_type = formObj.carriage_type.value;
			const o_date_type   = formObj.o_date_type.value;
			const o_date_h      = formObj.o_date_h.value;
			const order_memo    = formObj.order_memo.value;
			const wrapping_type = formObj.wrapping_type.value;
			const coupon_type   = formObj.coupon_type.value;


			//日付処理
		        const date          = new Date();
		        const isoDate       = date.toISOString();

			//プロフィールの更新登録
			const Data = {
				data : {
					orderno       : orderno,
					status        : status,
					payment_type  : payment_type,
					carriage_type : carriage_type,
					wrapping_type : wrapping_type,
					coupon_type   : coupon_type ? coupon_type : null,
					o_date_type   : o_date_type,
					o_date_h      : o_date_h,
					order_memo    : order_memo ? order_memo : 'null',
					date_edit     : isoDate,
				}
			};

			//最新のもの
			try{
				const Query = `mutation updateOrder($data: OrderInput!) {
					updateOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}

			//古いもの
			try{
				const Query = `mutation updateOldOrder($data: OrderInput!) {
					updateOldOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}


			//鑑査ログ
			await AuditLog('order', 'update', orderno, Data, '受注情報を変更しました。');

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	//発送情報の編集
	async ShippingDataUpdate () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal  : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Stateから取得する更新項目
			const orderno       = this.state.orderno;

			//Formから直接取得する更新項目
			const delivery_no   = formObj.delivery_no.value;
			const delivery_date = formObj.delivery_date.value;

			//日付処理
		        const date          = new Date();
		        const isoDate       = date.toISOString();

			//プロフィールの更新登録
			const Data = {
				data : {
					orderno       : orderno,
					delivery_no   : delivery_no   ? delivery_no   : 'null',
					delivery_date : delivery_date ? delivery_date : 'null',
					date_edit     : isoDate,
				}
			};

			//最新のもの
			try{
				const Query = `mutation updateOrder($data: OrderInput!) {
					updateOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}

			//古いもの
			try{
				const Query = `mutation updateOldOrder($data: OrderInput!) {
					updateOldOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}

			//鑑査ログ
			await AuditLog('order', 'update', orderno, Data, '発送情報を変更しました。');
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	//注文者情報の編集
	async OrderPersonUpdate () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal  : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Stateから取得する更新項目
			const orderno       = this.state.orderno;

			//Formから直接取得する更新項目
			const email         = formObj.email.value;
			const name_1        = formObj.name_1.value;
			const name_2        = formObj.name_2.value;
			const name_furi_1   = formObj.name_furi_1.value;
			const name_furi_2   = formObj.name_furi_2.value;
			const zip_1         = formObj.zip_1.value;
			const zip_2         = formObj.zip_2.value;
			const pref          = formObj.pref.value;
			const addr_1        = formObj.addr_1.value;
			const addr_2        = formObj.addr_2.value;
			const addr_3        = formObj.addr_3.value;
			const tel_1         = formObj.tel_1.value;
			const tel_2         = formObj.tel_2.value;
			const tel_3         = formObj.tel_3.value;

			//日付処理
		        const date          = new Date();
		        const isoDate       = date.toISOString();

			//プロフィールの更新登録
			const Data = {
				data : {
					orderno      : orderno,
					email        : email ? email : 'null',
					name_1       : name_1,
					name_2       : name_2,
					name_furi_1  : name_furi_1,
					name_furi_2  : name_furi_2,
					zip_1        : zip_1,
					zip_2        : zip_2,
					pref         : pref,
					addr_1       : addr_1,
					addr_2       : addr_2,
					addr_3       : addr_3 ? addr_3 : 'null',
					tel_1        : tel_1,
					tel_2        : tel_2,
					tel_3        : tel_3,
					date_edit    : isoDate,
				}
			};

			//最新のもの
			try{
				const Query = `mutation updateOrder($data: OrderInput!) {
					updateOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}

			//古いもの
			try{
				const Query = `mutation updateOldOrder($data: OrderInput!) {
					updateOldOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}

			//鑑査ログ
			await AuditLog('order', 'update', orderno, Data, '注文者情報を変更しました。');
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	//発送先情報の編集
	async ShippingPersonUpdate () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal  : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Stateから取得する更新項目
			const orderno       = this.state.orderno;

			//Formから直接取得する更新項目
			const o_name_1        = formObj.o_name_1.value;
			const o_name_2        = formObj.o_name_2.value;
			const o_name_furi_1   = formObj.o_name_furi_1.value;
			const o_name_furi_2   = formObj.o_name_furi_2.value;
			const o_zip_1         = formObj.o_zip_1.value;
			const o_zip_2         = formObj.o_zip_2.value;
			const o_pref          = formObj.o_pref.value;
			const o_addr_1        = formObj.o_addr_1.value;
			const o_addr_2        = formObj.o_addr_2.value;
			const o_addr_3        = formObj.o_addr_3.value;
			const o_tel_1         = formObj.o_tel_1.value;
			const o_tel_2         = formObj.o_tel_2.value;
			const o_tel_3         = formObj.o_tel_3.value;
			const o_com_name      = formObj.o_com_name.value;

			//日付処理
		        const date          = new Date();
		        const isoDate       = date.toISOString();

			//プロフィールの更新登録
			const Data = {
				data : {
					orderno       : orderno,
					o_name_1      : o_name_1,
					o_name_2      : o_name_2,
					o_name_furi_1 : o_name_furi_1,
					o_name_furi_2 : o_name_furi_2,
					o_zip_1       : o_zip_1,
					o_zip_2       : o_zip_2,
					o_pref        : o_pref,
					o_addr_1      : o_addr_1,
					o_addr_2      : o_addr_2,
					o_addr_3      : o_addr_3 ? o_addr_3 : 'null',
					o_tel_1       : o_tel_1,
					o_tel_2       : o_tel_2,
					o_tel_3       : o_tel_3,
					o_com_name    : o_com_name ? o_com_name : 'null',
					date_edit     : isoDate,
				}
			};

			//最新のもの
			try{
				const Query = `mutation updateOrder($data: OrderInput!) {
					updateOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}

			//古いもの
			try{
				const Query = `mutation updateOldOrder($data: OrderInput!) {
					updateOldOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}

			//鑑査ログ
			await AuditLog('order', 'update', orderno, Data, '発送先情報を変更しました。');
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	ShowUpdateModal(formSelect, event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowModal  : true,
			formObj      : form,
			formSelect   : formSelect,
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleClose() {
		this.setState({
			isShowModal  : false,
			isShowDelete : false,
			isSuccess    : false,
			isValidated  : false,
			isLockForm   : false,
			ErrMsg       : '',
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else if(this.state.isNotFound){
			return (
				<NotFound />
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>更新しました。</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowModal} onHide={this.handleClose} centered>
						<Modal.Body>
							本当に更新しますか？<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={
								()=>{
									if(this.state.formSelect === 'OrderPerson'){
										this.OrderPersonUpdate();
									}
									else if(this.state.formSelect === 'ShippingPerson'){
										this.ShippingPersonUpdate();
									}
									else if(this.state.formSelect === 'OrderData'){
										this.OrderDataUpdate();
									}
									else if(this.state.formSelect === 'ShippingData'){
										this.ShippingDataUpdate();
									}
									else if(this.state.formSelect === 'OrderMemo'){
										this.OrderMemoUpdate();
									}
									else if(this.state.formSelect === 'DeliveryMessage'){
										this.DeliveryMessageUpdate();
									}
									else if(this.state.formSelect === 'OrderShopComment'){
										this.OrderShopCommentUpdate();
									}
								}
							}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '1em' }}>
						<Row>
							<Col sm={6}>
								<h4>
									受注情報の詳細　
								</h4>
							</Col>
							<Col sm={6} className="text-right">
								{(() => {
									if(this.state.flag){
										return(<Badge variant="danger"><h6>{this.state.flag}</h6></Badge>);
									}
								})()}　
								<Button variant="dark" type="button" href={'/Order/' + this.state.orderno + '/MailSend/'} target="_blank">
									メール送信
								</Button>　
								<Button variant="dark" type="button" href={'/Order/' + this.state.orderno + '/MailSendLog/'} target="_blank">
									過去の送信履歴を見る
								</Button>　
								<Button variant="dark" onClick={ this.handleReload }>
									<FaRedo />
								</Button>
							</Col>
						</Row>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.ShowUpdateModal('OrderMemo', e)}>
						<Row>
							<Col sm={12}>
								<p><big>出荷管理メモ</big></p>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<Form.Group controlId="memo">
								        <Form.Control name="memo" as="textarea" rows="3" defaultValue={this.state.memo} placeholder="出荷管理メモ" size="lg" pattern=".{1,1000}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「出荷管理メモ」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>

						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
							</Col>
						</Row>
						</Form>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.ShowUpdateModal('DeliveryMessage', e)}>
						<Row>
							<Col sm={12}>
								<p><big>出荷指示</big></p>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<Form.Group controlId="delivery_message">
								        <Form.Control name="delivery_message" as="textarea" rows="2" defaultValue={this.state.delivery_message} placeholder="出荷指示" size="lg" pattern=".{1,1000}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「出荷指示」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>

						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
							</Col>
						</Row>
						</Form>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.ShowUpdateModal('OrderData', e)}>
						<Row>
							<Col sm={12}>
								<p><big>受注情報</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={1}>
								受注番号
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="orderno">
								        <Form.Control name="orderno" type="text" defaultValue={this.state.orderno} size="lg" readOnly />
									<Form.Control.Feedback />
								</Form.Group>
							</Col>

							<Form.Label column sm={1}>
								受注状態
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="status">
								    <Form.Control name="status" type="text" defaultValue={this.state.status} size="lg" as="select" required >
									<option value="未定義">未定義</option>
									<option value="仮受注">仮受注</option>
									<option value="未処理">未処理</option>
									<option value="処理中">処理中</option>
									<option value="出荷済み">出荷済み</option>
									<option value="予約・入荷待ち">予約・入荷待ち</option>
									<option value="決済処理待ち">決済処理待ち</option>
									<option value="確認中">確認中</option>
									<option value="返信待ち">返信待ち</option>
									<option value="商品手配中">商品手配中</option>
									<option value="後払い与信中">後払い与信中</option>
									<option value="キャンセル">キャンセル</option>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										「受注状態」を選択してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={1}>
								受注日時
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="date_add">
								        <Form.Control name="date_add" type="text" defaultValue={this.state.date_add} size="lg" readOnly />
									<Form.Control.Feedback />
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								更新日時
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="date_edit">
								        <Form.Control name="date_edit" type="text" defaultValue={this.state.date_edit} size="lg" readOnly />
									<Form.Control.Feedback />
								</Form.Group>
							</Col>
						</Row>
						<hr/>
						<Row>
							<Form.Label column sm={1}>
								決済方法
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="payment_type">
								        <Form.Control name="payment_type" type="text" defaultValue={this.state.payment_type} size="lg" as="select" required >
									<option value="未定義">未定義</option>
									<option value="銀行振込">銀行振込</option>
									<option value="クレジットカード決済">クレジットカード決済</option>
									<option value="代金引換">代金引換</option>
									<option value="後払い決済">後払い決済</option>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										「決済方法」を選択してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								お届け方法
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="carriage_type">
								        <Form.Control name="carriage_type" type="text" defaultValue={this.state.carriage_type} size="lg" as="select" required >
									<option value="未定義">未定義</option>
									<option value="ゆうパケット">ゆうパケット</option>
									<option value="宅急便">宅急便</option>
									<option value="ゆうパック">ゆうパック</option>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										「お届け方法」を選択してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<hr/>
						<Row>
							<Form.Label column sm={1}>
								日付指定
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="o_date_type">
								        <Form.Control name="o_date_type" type="text" defaultValue={this.state.o_date_type} size="lg" required />
									<Form.Control.Feedback type="invalid">
										「日付指定」を正しく入力してください。。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								時間指定
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="o_date_h">
								        <Form.Control name="o_date_h" type="text" defaultValue={this.state.o_date_h} size="lg" as="select" required >
									<option value="未定義">未定義</option>
									<option value="指定なし">指定なし</option>
									<option value="午前中">午前中</option>
									<option value="12：00～14：00">12：00～14：00</option>
									<option value="14：00～16：00">14：00～16：00</option>
									<option value="16：00～18：00">16：00～18：00</option>
									<option value="18：00～20：00">18：00～20：00</option>
									<option value="20：00～21：00">20：00～21：00</option>
									<option value="19：00～21：00">19：00～21：00</option>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										「時間指定」を選択してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<hr/>
						<Row>
							<Form.Label column sm={1}>
								ギフトラッピング
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="wrapping_type">
								        <Form.Control name="wrapping_type" type="text" defaultValue={this.state.wrapping_type} size="lg" as="select" required >
									<option value="有料ラッピングする">有料ラッピングする</option>
									<option value="季節のラッピング">季節のラッピング</option>
									<option value="季節のラッピング（A）">季節のラッピング（A）</option>
									<option value="季節のラッピング（B）">季節のラッピング（B）</option>
									<option value="通常ラッピング＋カード【1】">通常ラッピング＋カード【1】</option>
									<option value="通常ラッピング＋カード【2】">通常ラッピング＋カード【2】</option>
									<option value="通常ラッピング＋カード【3】">通常ラッピング＋カード【3】</option>
									<option value="季節のラッピング（A）＋カード【1】">季節のラッピング（A）＋カード【1】</option>
									<option value="季節のラッピング（A）＋カード【2】">季節のラッピング（A）＋カード【2】</option>
									<option value="季節のラッピング（A）＋カード【3】">季節のラッピング（A）＋カード【3】</option>
									<option value="季節のラッピング（B）＋カード【1】">季節のラッピング（B）＋カード【1】</option>
									<option value="季節のラッピング（B）＋カード【2】">季節のラッピング（B）＋カード【2】</option>
									<option value="季節のラッピング（B）＋カード【3】">季節のラッピング（B）＋カード【3】</option>
									<option value="しない">しない</option>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										「ギフトラッピング」を選択してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								クーポン
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="coupon_type">
								        <Form.Control name="coupon_type" type="text" defaultValue={this.state.coupon_type ? this.state.coupon_type : ''} size="lg" as="select" required >
									<option value="未定義">未定義</option>
									<option value="会員限定クーポン500円割引">会員限定クーポン500円割引</option>
									<option value="">未使用</option>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										「クーポン」を選択してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<hr/>
						<Row>
							<Form.Label column sm={1}>
								連絡事項
							</Form.Label>
							<Col sm={11}>
								<Form.Group controlId="order_memo">
								        <Form.Control name="order_memo" as="textarea" rows="3" defaultValue={this.state.order_memo} placeholder="連絡事項" size="lg" pattern=".{1,1000}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「連絡事項」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
							</Col>
						</Row>
						</Form>
					</Container>






					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.ShowUpdateModal('OrderPerson', e)}>
						<Row>
							<Col sm={12}>
								<p><big>注文者情報</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								ユーザID
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="cognitoid">
								        <Form.Control name="cognitoid" type="text" defaultValue={(this.state.not_ec_user_kbn ? '非会員' : this.state.cognitoid)} size="lg" readOnly />
									<Form.Control.Feedback />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								メールアドレス
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="email">
								        <Form.Control name="email" type="email" defaultValue={this.state.email} placeholder="メールアドレス" size="lg" pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「メールアドレス」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								お名前　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={3}>
								<Form.Group controlId="name_1">
								        <Form.Control name="name_1" type="text" defaultValue={this.state.name_1} placeholder="お名前（名字）" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「お名前（名字）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="name_2">
								        <Form.Control name="name_2" type="text" defaultValue={this.state.name_2} placeholder="お名前（名前）" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「お名前（名前）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								フリガナ　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={3}>
								<Form.Group controlId="name_furi_1">
								        <Form.Control name="name_furi_1" type="text" defaultValue={this.state.name_furi_1} placeholder="フリガナ（名字）" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「フリガナ（名字）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="name_furi_2">
								        <Form.Control name="name_furi_2" type="text" defaultValue={this.state.name_furi_2} placeholder="フリガナ（名前）" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「フリガナ（名前）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								郵便番号　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="zip_1">
								        <Form.Control name="zip_1" type="text" defaultValue={this.state.zip_1} placeholder="3桁" size="lg" required pattern="\d{3}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「郵便番号（3桁）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="zip_2">
								        <Form.Control name="zip_2" type="text" defaultValue={this.state.zip_2} placeholder="4桁" size="lg" required pattern="\d{4}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「郵便番号（4桁）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								都道府県　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="pref">
								        <Form.Control name="pref" type="text" defaultValue={this.state.pref} placeholder="都道府県" size="lg" required pattern=".{2,4}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「都道府県」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（市区町村）　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="addr_1">
								        <Form.Control name="addr_1" type="text" defaultValue={this.state.addr_1} placeholder="市区町村" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「住所（市区町村）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（番地）　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="addr_2">
								        <Form.Control name="addr_2" type="text" defaultValue={this.state.addr_2} placeholder="番地" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「住所（番地）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（その他・建物）
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="addr_3">
								        <Form.Control name="addr_3" type="text" defaultValue={this.state.addr_3} placeholder="その他・建物" size="lg" pattern=".{0,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「住所（その他・建物）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								電話番号　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="tel_1">
								        <Form.Control name="tel_1" type="text" defaultValue={this.state.tel_1} placeholder="市外局番" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「電話番号（市外局番）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="tel_2">
								        <Form.Control name="tel_2" type="text" defaultValue={this.state.tel_2} placeholder="市内局番" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「電話番号（市内局番）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="tel_3">
								        <Form.Control name="tel_3" type="text" defaultValue={this.state.tel_3} placeholder="番号" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「電話番号」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
							</Col>
						</Row>
						</Form>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.ShowUpdateModal('ShippingPerson', e)}>
						<Row>
							<Col sm={12}>
								<p><big>発送先情報</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								お名前　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={3}>
								<Form.Group controlId="o_name_1">
								        <Form.Control name="o_name_1" type="text" defaultValue={this.state.o_name_1} placeholder="お名前（名字）" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「お名前（名字）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="o_name_2">
								        <Form.Control name="o_name_2" type="text" defaultValue={this.state.o_name_2} placeholder="お名前（名前）" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「お名前（名前）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								フリガナ　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={3}>
								<Form.Group controlId="o_name_furi_1">
								        <Form.Control name="o_name_furi_1" type="text" defaultValue={this.state.o_name_furi_1} placeholder="フリガナ（名字）" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「フリガナ（名字）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="o_name_furi_2">
								        <Form.Control name="o_name_furi_2" type="text" defaultValue={this.state.o_name_furi_2} placeholder="フリガナ（名前）" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「フリガナ（名前）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								郵便番号　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="o_zip_1">
								        <Form.Control name="o_zip_1" type="text" defaultValue={this.state.o_zip_1} placeholder="3桁" size="lg" required pattern="\d{3}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「郵便番号（3桁）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="o_zip_2">
								        <Form.Control name="o_zip_2" type="text" defaultValue={this.state.o_zip_2} placeholder="4桁" size="lg" required pattern="\d{4}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「郵便番号（4桁）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								都道府県　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="o_pref">
								        <Form.Control name="o_pref" type="text" defaultValue={this.state.o_pref} placeholder="都道府県" size="lg" required pattern=".{2,4}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「都道府県」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（市区町村）　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="o_addr_1">
								        <Form.Control name="o_addr_1" type="text" defaultValue={this.state.o_addr_1} placeholder="市区町村" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「住所（市区町村）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（番地）　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="o_addr_2">
								        <Form.Control name="o_addr_2" type="text" defaultValue={this.state.o_addr_2} placeholder="番地" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「住所（番地）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（その他・建物）
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="o_addr_3">
								        <Form.Control name="o_addr_3" type="text" defaultValue={this.state.o_addr_3} placeholder="その他・建物" size="lg" pattern=".{0,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「住所（その他・建物）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								会社名
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="o_com_name">
								        <Form.Control name="o_com_name" type="text" defaultValue={this.state.o_com_name} placeholder="会社名" size="lg" pattern=".{0,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「会社名」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								電話番号　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="o_tel_1">
								        <Form.Control name="o_tel_1" type="text" defaultValue={this.state.o_tel_1} placeholder="市外局番" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「電話番号（市外局番）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="o_tel_2">
								        <Form.Control name="o_tel_2" type="o_text" defaultValue={this.state.o_tel_2} placeholder="市内局番" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「電話番号（市内局番）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="o_tel_3">
								        <Form.Control name="o_tel_3" type="text" defaultValue={this.state.o_tel_3} placeholder="番号" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「電話番号」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
							</Col>
						</Row>
						</Form>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form>
						<Row>
							<Col sm={12}>
								<p><big>注文内容</big></p>
							</Col>
						</Row>

						<Table bordered responsive style={{ marginTop: 20 }} className="text-nowrap">
						<thead>
						<tr>
						<th style={{ width: '20%'}}>商品コード</th>
						<th style={{ width: '40%'}}>商品名</th>
						<th style={{ width: '15%'}}>金額</th>
						<th style={{ width: '10%'}}>個数</th>
						<th style={{ width: '15%'}}>合計</th>
						</tr>
						</thead>
						<tbody>


						 { this.state.viewData.map(
						 	(value, index) => {
								const code            = value.code;
								const name            = value.name;
								const price           = value.price;
								const sub_price       = value.sub_price.toLocaleString();
								const piece           = value.piece;
								const piece_type      = value.piece_type;

								return (
									<tr key={index} className="text-nowrap">
										<td>{code}</td>
										<td>{name}</td>
										<td className="text-right">{price} 円</td>
										<td className="text-right">{piece} {piece_type}</td>
										<td className="text-right">{sub_price} 円</td>
									</tr>
								);
							}
						 )}
						<tr className="text-nowrap">
							<td colSpan="2">
								　
							</td>
							<th colSpan="2" className="text-right">
								小計
							</th>
							<td className="text-right">
								{this.state.total_price.toLocaleString()} 円
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="2">
								　
							</td>
							<th colSpan="2" className="text-right">
								送料
							</th>
							<td className="text-right">
								{this.state.carriage.toLocaleString()} 円
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="2">
								　
							</td>
							<th colSpan="2" className="text-right">
								決済手数料
							</th>
							<td className="text-right">
								{this.state.cashdeli.toLocaleString()} 円
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="2">
								　
							</td>
							<th colSpan="2" className="text-right">
								ギフト手数料
							</th>
							<td className="text-right">
								{this.state.wrapping.toLocaleString()} 円
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="2">
								　
							</td>
							<th colSpan="2" className="text-right">
								クーポン
							</th>
							<td className="text-right">
								{this.state.coupon.toLocaleString()} 円
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="2">
								　
							</td>
							<th colSpan="2" className="text-right">
								合計金額
							</th>
							<td className="text-right">
								{this.state.payment_price.toLocaleString()} 円
							</td>
						</tr>
						</tbody>
						</Table>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="info" type="button" block size="lg" disabled={this.state.isLockForm} onClick={()=>{ this.props.history.push('/Order/' + this.state.orderno + '/Item/'); }} >変更する</Button>
							</Col>
						</Row>
						</Form>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.ShowUpdateModal('ShippingData', e)}>
						<Row>
							<Col sm={12}>
								<p><big>発送情報</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={1}>
								発送日
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="delivery_date">
								        <Form.Control name="delivery_date" type="text" defaultValue={this.state.delivery_date} size="lg" pattern="\d{4}年\d{2}月\d{2}日" />
									<Form.Control.Feedback type="invalid">
										「発送日」を正しく入力してください。。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>

							<Form.Label column sm={1}>
								伝票番号
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="delivery_no">
								        <Form.Control name="delivery_no" type="text" defaultValue={this.state.delivery_no} size="lg" pattern=".{1,255}" />
									<Form.Control.Feedback type="invalid">
										「伝票番号」を正しく入力してください。。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
							</Col>
						</Row>
						</Form>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.ShowUpdateModal('OrderShopComment', e)}>
						<Row>
							<Col sm={12}>
								<p><big>メール用コメント（##shopcomment##）</big></p>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<Form.Group controlId="shop_comment">
								        <Form.Control name="shop_comment" as="textarea" rows="3" defaultValue={this.state.shop_comment} placeholder="メール用コメント（##shopcomment##）" size="lg" pattern=".{1,1000}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「メール用コメント（##shopcomment##）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>

						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
							</Col>
						</Row>
						</Form>
					</Container>

					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props.history.push('/Order/'); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>


				</Container>
			);
		}
	}
}

export default withRouter(Default);
