import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Modal,
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Table,
	Breadcrumb,
	Badge,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import ItemSelect from '../../../Objects/ItemSelect';
import AddressSelect from '../../../Objects/AddressSelect';
import { AuditLog } from '../../../Objects/AuditLog';

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading     : true,
			isValidated1  : false,
			isValidated2  : false,
			isShowModal   : false,
			isItemSelect  : false,
			isNextPage    : false,
			isLockForm    : false,
			formObj       : null,
			ErrMsg        : '',
			itemData      : [],
			total_price   : 0,
			payment_price : 0,
			carriage      : 0,
			cashdeli      : 0,
			wrapping      : 0,
			coupon        : 0,
			name          : '',
			price         : '',
			piece         : '',
			piece_type    : '',
			user_name     : '',
		}
		this._backTop             = this._backTop.bind(this);
		this._formatDate          = this._formatDate.bind(this);
		this._getData             = this._getData.bind(this);
		this.handleDismiss        = this.handleDismiss.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
		this.itemAdd              = this.itemAdd.bind(this);
		this.itemUpdate           = this.itemUpdate.bind(this);
		this.itemUpdateEtc        = this.itemUpdateEtc.bind(this);
		this.itemDelete           = this.itemDelete.bind(this);
		this.itemSet              = this.itemSet.bind(this);
		this.onClickSelect        = this.onClickSelect.bind(this);
		this.goNextPage           = this.goNextPage.bind(this);
	}
	_backTop() {
		//子ウィンドウからの呼び出し用
		this.handleReload();
	}
	_formatDate(dateObj) {

		//日付の整形
		const year        = dateObj.getFullYear().toString();
		const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day         = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second      = ('00' + dateObj.getSeconds().toString()).slice(-2);

		const text = year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分' + second + '秒';

		return (text);
	}
	async _getData() {
		
		//処理
		try{

			//IDの取得
			const cognitoid = this.props.match.params.cognitoid;
			if(!cognitoid){
					this.setState({
						ErrMsg     : 'ユーザIDを入力してください。<br/>',
						isLoading     : false,
					});
					return(0);
			}
			
			let user_name = '非会員';
			
			if(cognitoid !== 'not-ec-user'){
			
				//データ取得
				const Query = `query getProfile($cognitoid: String!) {
					getProfile(cognitoid: $cognitoid) {
						name_1
						name_2
					}
				}`;
				const Data = { cognitoid: cognitoid };
				const ref = await API.graphql(graphqlOperation(Query, Data));
				//console.log(ref);
				
				//データが空かどうか
				if(ref.data.getProfile === null){
					this.setState({
						ErrMsg     : '会員情報が見当たりませんでした。<br/>',
						isLockForm : false,
					});
					return(0);
				}
				
				const name_1 = ref.data.getProfile.name_1;
				const name_2 = ref.data.getProfile.name_2;
				user_name    = name_1 + ' ' + name_2;
			}
			
			this.setState({
				isLoading     : false,
				cognitoid     : cognitoid,
				user_name     : user_name,
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}
	}
	handleDismiss() {
		this.setState({ ErrMsg: '' });
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
			isNextPage  : false,
		});
		this._getData();
	}
	itemAdd(event) {

		event.preventDefault();
		event.stopPropagation();

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isValidated2 : true,
			isLockForm   : true,
		});

		//データ
		const itemData = this.state.itemData;

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//Formから直接取得する更新項目
			const code       = formObj.code.value;
			const name       = formObj.name.value;
			const price      = Number(formObj.price.value);
			const piece      = Number(formObj.piece.value);
			const piece_type = formObj.piece_type.value;
			const sub_price  = (price * piece);

			//元データに存在するかどうか
			if(itemData.filter(x => x.code === code).length !== 0){
				this.setState({
					ErrMsg     : 'すでに同じ商品コードが登録されています。<br/>',
					isLockForm : false,
				 });
				return(0);
			}
			
			//ハッシュデータ作成
			const hash = {
				code       : code,
				name       : name,
				price      : price,
				piece      : piece,
				piece_type : piece_type,
				sub_price  : sub_price,
			};
			
			//配列に追加
			itemData.push(hash);
			
			//フォームリセット
			formObj.reset();
			
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//合計計算
		let total_price = 0;
		itemData.some( x => { total_price  = total_price + Number(x.sub_price); return false; } );

		//更新成功
		this.setState({
			isValidated2  : false,
			isLockForm    : false,
			itemData      : itemData,
			total_price   : total_price,
			payment_price : (Number(this.state.carriage) + Number(this.state.cashdeli) + Number(this.state.wrapping) + Number(this.state.coupon) + Number(total_price)),
			code          : '',
			name          : '',
			price         : '',
			piece         : 0,
			piece_type    : '',
		});
	
	}
	itemUpdate(code, objName, event) {

		//データ
		const itemData = this.state.itemData;

		//インデックス
		let index = 0;

		//抽出
		const selectData = itemData.filter((x, i) => { if(x.code === code){ index = i; return true; } else { return false; } });

		//各項目
		let name       = selectData[0].name;
		let price      = selectData[0].price;
		let piece      = selectData[0].piece;
		let piece_type = selectData[0].piece_type;
		let sub_price  = 0;

		//各項目ごとに変更する
		if(objName === 'name'){
			name       = event.target.value;
		}
		else if(objName === 'price'){
			const value = (Number(event.target.value) >= 0 && Number(event.target.value) < 100000000) ? Number(event.target.value) : 0;
			price      = value;
			sub_price  = (Number(piece) * value);
		}
		else if(objName === 'piece'){
			const value = (Number(event.target.value) >= 0 && Number(event.target.value) < 100000000) ? Number(event.target.value) : 0;
			piece      = value;
			sub_price  = (Number(price) * value);
		}
		else if(objName === 'piece_type'){
			piece_type = event.target.value;
		}
		
		//ハッシュ入れ直し
		itemData[index] = {
			code       : code,
			name       : name,
			price      : price,
			piece      : piece,
			piece_type : piece_type,
			sub_price  : sub_price,
		};

		//合計計算
		let total_price = 0;
		itemData.some( x => { total_price  = total_price + Number(x.sub_price); return false; } );

		//表示データ更新
		this.setState({
			itemData      : itemData,
			total_price   : total_price,
			payment_price : (Number(this.state.carriage) + Number(this.state.cashdeli) + Number(this.state.wrapping) + Number(this.state.coupon) + Number(total_price)),
		});
		
	
	}
	itemUpdateEtc(event) {

		const value = (Number(event.target.value) >= 0 && Number(event.target.value) < 100000000) ? Number(event.target.value) : 0;

		switch (event.target.name) {
			case 'carriage':
				this.setState({
					carriage      : value,
					payment_price : (value + Number(this.state.cashdeli) + Number(this.state.wrapping) + Number(this.state.coupon) + Number(this.state.total_price)),
				});
				break;
			case 'cashdeli':
				this.setState({
					cashdeli      : value,
					payment_price : (value + Number(this.state.carriage) + Number(this.state.wrapping) + Number(this.state.coupon) + Number(this.state.total_price)),
				});
				break;
			case 'wrapping':
				this.setState({
					wrapping      : value,
					payment_price : (value + Number(this.state.carriage) + Number(this.state.wrapping) + Number(this.state.coupon) + Number(this.state.total_price)),
				});
				break;
			case 'coupon':
				this.setState({
					coupon      : value,
					payment_price : (value + Number(this.state.carriage) + Number(this.state.wrapping) + Number(this.state.coupon) + Number(this.state.total_price)),
				});
				break;
			default:
				break;
		}

	}
	itemDelete(code) {
	
		//削除
		const itemData = this.state.itemData.filter(x => x.code !== code);
		
		//合計計算
		let total_price = 0;
		itemData.some( x => { total_price  = total_price + Number(x.sub_price); return false; } );


		//表示データ更新
		this.setState({
			itemData      : itemData,
			total_price   : total_price,
			payment_price : (Number(this.state.carriage) + Number(this.state.cashdeli) + Number(this.state.wrapping) + Number(this.state.coupon)  + Number(total_price)),
		});
	
	}
	async itemSet(code) {
		
		//処理
		try{

			//更新開始
			this.setState({
				isLockForm   : true,
				isItemSelect : false
			});

			//IDの取得
			if(!code){
					this.setState({
						ErrMsg     : '商品コードが見つかりません。<br/>',
						isLoading     : false,
					});
					return(0);
			}
			
		
			//データ取得
			const Query = `query getItem($code: String!) {
				getItem(code: $code) {
					name
					price
					piece_type
				}
			}`;
			const Data = { code: code };
			const ref = await API.graphql(graphqlOperation(Query, Data));
			//console.log(ref);
			
			//データが空かどうか
			if(ref.data.getItem === null){
				this.setState({
					ErrMsg     : '商品情報が見当たりませんでした。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			const name       = ref.data.getItem.name;
			const price      = ref.data.getItem.price;
			const piece_type = ref.data.getItem.piece_type;
			
			this.setState({
				isLockForm   : false,
				code         : code,
				name         : name,
				price        : price,
				piece        : 1,
				piece_type   : piece_type,
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg       : err_msg,
				isLockForm   : false,
				isItemSelect : false,
			 });
			return(0);
		}
	}
	ShowUpdateModal(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowModal  : true,
			formObj      : form,
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleClose() {
		this.setState({
			isShowModal  : false,
			isItemSelect : false,
			isShowDelete : false,
			isValidated1 : false,
			isValidated2 : false,
			isLockForm   : false,
			ErrMsg       : '',
		});
	}
	lockFormClose(){
		//
	}
	async onClickSelect(row){
		if(row.code){
			await this.itemSet(row.code);
		}
	}
	goNextPage() {
	
		//商品が選択されているか
		if(this.state.itemData.length === 0){
			this.setState({
				ErrMsg       : '商品が選択されていません。<br/>',
				isShowModal  : false,
			 });
		}
		else if(this.state.payment_price === 0){
			this.setState({
				ErrMsg       : '合計金額が0円です。<br/>',
				isShowModal  : false,
			 });
		}
		else{
			//次のページへ
			this.setState({
				isNextPage  : true,
				isShowModal : false,
			});
		}
	}
	async componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isNextPage){
			return (
				<NextPage _backTop={this._backTop} {...this.state} {...this.props} />
			);
		}
		else if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowModal} onHide={this.handleClose} centered>
						<Modal.Body>
							次の画面へ移動しますか？<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.goNextPage}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isItemSelect} onHide={this.handleClose} centered aria-labelledby="ItemSelect" dialogClassName="modal-xl">
						<Modal.Header closeButton>
							<Modal.Title id="ItemSelect">
								受注商品を選んでください。
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<ItemSelect onClickSelect={this.onClickSelect} />
						</Modal.Body>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>

					<Row>
						<Col sm={12}>
							<h4>受注商品の選択　<small>受注する商品を選んでください。</small></h4>　
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<Breadcrumb>
								<Breadcrumb.Item onClick={()=>{ this.props.history.push('/Order/New/'); }}>
									受注情報の新規登録
								</Breadcrumb.Item>
								<Breadcrumb.Item active>
									受注商品の選択
								</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
					</Row>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form>
						<Row>
							<Col sm={12}>
								<p><big>発注者</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={1}>
								会員番号
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="cognitoid">
								        <Form.Control name="cognitoid" type="text" defaultValue={this.state.cognitoid} size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={1}>
								お名前
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="user_name">
								        <Form.Control name="user_name" type="text" defaultValue={this.state.user_name} size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
					</Form>
					</Container>


					
					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated1} onSubmit={e => this.ShowUpdateModal(e)}>
						<Row>
							<Col sm={12}>
								<p><big>注文内容</big></p>
							</Col>
						</Row>

						<Table bordered responsive style={{ marginTop: 20 }} className="text-nowrap">
						<thead>
						<tr>
						<th style={{ width: '10%'}}>商品コード</th>
						<th style={{ width: '40%'}}>商品名</th>
						<th style={{ width: '10%'}}>金額</th>
						<th style={{ width: '10%'}}>個数</th>
						<th style={{ width: '10%'}}>単位</th>
						<th style={{ width: '10%'}}>合計</th>
						<th style={{ width: '10%'}}>変更</th>
						</tr>
						</thead>
						<tbody>


						 { this.state.itemData.map(
						 	(value, index) => {
								const code            = value.code;
								const name            = value.name;
								const price           = value.price;
								const sub_price       = value.sub_price.toLocaleString();
								const piece           = value.piece;
								const piece_type      = value.piece_type;
								
								return (
									<tr key={index} className="text-nowrap">
										<td>{code}</td>
										<td>
											<Form.Group controlId={code + '.name'}>
											        <Form.Control name={code + '.name'} type="text" defaultValue={name} size="sm" required pattern=".{1,255}" onChange={(e)=>{ this.itemUpdate(code, 'name',  e); }} />
												<Form.Control.Feedback type="invalid">
													「商品名」を正しく入力してください。
												</Form.Control.Feedback>
											</Form.Group>
										</td>
										<td>
											<Form.Group controlId={code + '.price'}>
											        <Form.Control name={code + '.price'} type="text" defaultValue={price} size="sm" required pattern="\d{1,10}" onChange={(e)=>{ this.itemUpdate(code, 'price', e); }} className="text-right" />
												<Form.Control.Feedback type="invalid">
													「金額」を正しく入力してください。
												</Form.Control.Feedback>
											</Form.Group>
										</td>
										<td>
											<Form.Group controlId={code + '.piece'}>
											        <Form.Control name={code + '.piece'} type="text" defaultValue={piece} size="sm" required pattern="\d{1,3}" onChange={(e)=>{ this.itemUpdate(code, 'piece', e); }} className="text-right" />
												<Form.Control.Feedback type="invalid">
													「個数」を正しく入力してください。
												</Form.Control.Feedback>
											</Form.Group>
										</td>
										<td>
											<Form.Group controlId={code + '.piece_type'}>
											        <Form.Control name={code + '.piece_type'} type="text" defaultValue={piece_type} size="sm" required as="select" onChange={(e)=>{ this.itemUpdate(code, 'piece_type', e); }} >
												<option value="個">個</option>
												<option value="枚">枚</option>
												<option value="未定義">未定義</option>
												</Form.Control>
												<Form.Control.Feedback type="invalid">
													「単位」を選択してください。
												</Form.Control.Feedback>
											</Form.Group>
										</td>
										<td className="text-right">{sub_price} 円</td>
										<td>
											<Button variant="secondary" type="reset" block size="sm" onClick={()=>{ this.itemDelete(code); }} disabled={this.state.isLockForm} >削除</Button>
										</td>
									</tr>
								);
							}
						 )}
						<tr className="text-nowrap">
							<td colSpan="3">
								　
							</td>
							<th colSpan="2" className="text-right">
								小計
							</th>
							<td className="text-right">
								{this.state.total_price.toLocaleString()} 円
							</td>
							<td>
								　
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="3">
								　
							</td>
							<th colSpan="2" className="text-right">
								送料
							</th>
							<td>
								<Form.Group controlId="carriage">
								        <Form.Control name="carriage" type="text" size="sm" defaultValue={this.state.carriage} required pattern="\d{1,10}" className="text-right" onChange={(e)=>{ this.itemUpdateEtc(e); }} />
									<Form.Control.Feedback type="invalid">
										「送料」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								　
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="3">
								　
							</td>
							<th colSpan="2" className="text-right">
								決済手数料
							</th>
							<td>
								<Form.Group controlId="cashdeli">
								        <Form.Control name="cashdeli" type="text" size="sm" defaultValue={this.state.cashdeli} required pattern="\d{1,10}" className="text-right" onChange={(e)=>{ this.itemUpdateEtc(e); }} />
									<Form.Control.Feedback type="invalid">
										「決済手数料」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								　
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="3">
								　
							</td>
							<th colSpan="2" className="text-right">
								ギフト手数料
							</th>
							<td>
								<Form.Group controlId="wrapping">
								        <Form.Control name="wrapping" type="text" size="sm" defaultValue={this.state.wrapping} required pattern="\d{1,10}" className="text-right" onChange={(e)=>{ this.itemUpdateEtc(e); }} />
									<Form.Control.Feedback type="invalid">
										「ギフト手数料」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								　
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="3">
								　
							</td>
							<th colSpan="2" className="text-right">
								クーポン
							</th>
							<td>
								<Form.Group controlId="coupon">
								        <Form.Control name="coupon" type="text" size="sm" defaultValue={this.state.coupon} required pattern="(-|)\d{1,10}" className="text-right" onChange={(e)=>{ this.itemUpdateEtc(e); }} />
									<Form.Control.Feedback type="invalid">
										「クーポン」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								　
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="3">
								　
							</td>
							<th colSpan="2" className="text-right">
								合計金額
							</th>
							<td className="text-right">
								{this.state.payment_price.toLocaleString()} 円
							</td>
							<td>
								　
							</td>
						</tr>
						</tbody>
						</Table>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >次の画面へ</Button>
							</Col>
						</Row>
					</Form>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated2} onSubmit={e => this.itemAdd(e)}>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="info" type="submit" block size="sm" disabled={this.state.isLockForm} >↑上記に新しい商品を追加する</Button>
							</Col>
						</Row>
						<Table bordered responsive style={{ marginTop: 20 }} className="text-nowrap">
						<thead>
						<tr>
						<th style={{ width: '10%'}}>検索</th>
						<th style={{ width: '15%'}}>商品コード</th>
						<th style={{ width: '40%'}}>商品名</th>
						<th style={{ width: '10%'}}>金額</th>
						<th style={{ width: '10%'}}>個数</th>
						<th style={{ width: '10%'}}>単位</th>
						</tr>
						</thead>
						<tbody>
						<tr className="text-nowrap">
							<td>
								<Button variant="secondary" type="button" block size="sm" onClick={()=>{ this.setState({ isItemSelect : true }); }} disabled={this.state.isLockForm} >検索</Button>
							</td>
							<td>
								<Form.Group controlId="code">
								        <Form.Control name="code" type="text" size="sm" defaultValue={this.state.code} required pattern=".{1,255}" />
									<Form.Control.Feedback type="invalid">
										「コード」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								<Form.Group controlId="name">
								        <Form.Control name="name" type="text" size="sm" defaultValue={this.state.name} required pattern=".{1,255}" />
									<Form.Control.Feedback type="invalid">
										「商品名」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								<Form.Group controlId="price">
								        <Form.Control name="price" type="text" size="sm" defaultValue={this.state.price} required pattern="\d{1,10}" className="text-right" />
									<Form.Control.Feedback type="invalid">
										「金額」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								<Form.Group controlId="piece">
								        <Form.Control name="piece" type="text" size="sm" defaultValue={this.state.piece} required pattern="\d{1,3}" className="text-right" />
									<Form.Control.Feedback type="invalid">
										「個数」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								<Form.Group controlId="piece_type">
								        <Form.Control name="piece_type" type="text" size="sm" required as="select" defaultValue={this.state.piece_type} >
									<option value="個">個</option>
									<option value="枚">枚</option>
									<option value="未定義">未定義</option>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										「単位」を選択してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
						</tr>
						</tbody>
						</Table>
					</Form>
					</Container>

					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props.history.push('/Order/New/'); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					

				</Container>
			);
		}
	}
}

class NextPage extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading       : true,
			isValidated     : false,
			isShowModal     : false,
			isAddrSelect    : false,
			isLockForm      : false,
			isLastPage      : false,
			formObj         : null,
			ErrMsg          : '',
			cognitoid       : '',
			payment_type    : '',
			carriage_type   : '',
			wrapping_type   : '',
			coupon_type     : '',
			email           : '',
			name_1          : '',
			name_2          : '',
			name_furi_1     : '',
			name_furi_2     : '',
			zip_1           : '',
			zip_2           : '',
			pref            : '',
			addr_1          : '',
			addr_2          : '',
			addr_3          : '',
			tel_1           : '',
			tel_2           : '',
			tel_3           : '',
			o_name_1        : '',
			o_name_2        : '',
			o_name_furi_1   : '',
			o_name_furi_2   : '',
			o_zip_1         : '',
			o_zip_2         : '',
			o_pref          : '',
			o_addr_1        : '',
			o_addr_2        : '',
			o_addr_3        : '',
			o_tel_1         : '',
			o_tel_2         : '',
			o_tel_3         : '',
			o_com_name      : '',
			o_date_type     : '指定なし',
			o_date_h        : '',
			status          : '',
			memo            : '',
			not_ec_user_kbn : false,
			flag            : '',
			date_add        : '',
			date_edit       : '',
			itemData        : [],
			carriage        : 0,
			cashdeli        : 0,
			wrapping        : 0,
			coupon          : 0,
			total_price     : 0,
			payment_price   : 0,
			not_zaiko_processing: false,
			not_mail_processing : true,
		}
		this._backNext            = this._backNext.bind(this);
		this._getData             = this._getData.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.handleChange         = this.handleChange.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
		this.ShowUpdateModal      = this.ShowUpdateModal.bind(this);
		this.copyUserData         = this.copyUserData.bind(this);
		this.goLastPage           = this.goLastPage.bind(this);
		this.onClickSelect        = this.onClickSelect.bind(this);
		this.addressSet           = this.addressSet.bind(this);
	}
	_backNext() {
		//子ウィンドウからの呼び出し用
		this.handleReload();
	}
	async _getData() {

		try{

			//IDの取得
			const cognitoid = this.props.cognitoid;
			if(!cognitoid){
					this.setState({
						ErrMsg     : 'ユーザIDを入力してください。<br/>',
						isLockForm : false,
					});
					return(0);
			}

			//前ページより
			const itemData      = this.props.itemData;
			const carriage      = this.props.carriage;
			const cashdeli      = this.props.cashdeli;
			const wrapping      = this.props.wrapping;
			const coupon        = this.props.coupon;
			const total_price   = this.props.total_price;
			const payment_price = this.props.payment_price;
			
			//会員データ
			let email        = '';
			let name_1       = '';
			let name_2       = '';
			let name_furi_1  = '';
			let name_furi_2  = '';
			let zip_1        = '';
			let zip_2        = '';
			let pref         = '';
			let addr_1       = '';
			let addr_2       = '';
			let addr_3       = '';
			let tel_1        = '';
			let tel_2        = '';
			let tel_3        = '';
			let not_ec_user_kbn = true;
			
			//会員なら情報を取得
			if(cognitoid !== 'not-ec-user'){
			
				//データ取得
				const Query = `query getProfile($cognitoid: String!) {
					getProfile(cognitoid: $cognitoid) {
						email
						name_1
						name_2
						name_furi_1
						name_furi_2
						zip_1
						zip_2
						pref
						addr_1
						addr_2
						addr_3
						tel_1
						tel_2
						tel_3
					}
				}`;
				const Data = { cognitoid: cognitoid };
				const ref = await API.graphql(graphqlOperation(Query, Data));
				//console.log(ref);
				
				//データが空かどうか
				if(ref.data.getProfile === null){
					this.setState({
						ErrMsg     : '会員情報が見当たりませんでした。<br/>',
						isLockForm : false,
					});
					return(0);
				}
				
				email         = ref.data.getProfile.email === 'null' ? '' : ref.data.getProfile.email;
				name_1        = ref.data.getProfile.name_1;
				name_2        = ref.data.getProfile.name_2;
				name_furi_1   = ref.data.getProfile.name_furi_1;
				name_furi_2   = ref.data.getProfile.name_furi_2;
				zip_1         = ref.data.getProfile.zip_1;
				zip_2         = ref.data.getProfile.zip_2;
				pref          = ref.data.getProfile.pref;
				addr_1        = ref.data.getProfile.addr_1;
				addr_2        = ref.data.getProfile.addr_2;
				addr_3        = ref.data.getProfile.addr_3 === 'null' ? '' : ref.data.getProfile.addr_3;
				tel_1         = ref.data.getProfile.tel_1;
				tel_2         = ref.data.getProfile.tel_2;
				tel_3         = ref.data.getProfile.tel_3;
				not_ec_user_kbn = false;
			}

			//データ更新
			this.setState({
				isLoading     : false,
				cognitoid     : cognitoid,
				email         : email,
				name_1        : name_1,
				name_2        : name_2,
				name_furi_1   : name_furi_1,
				name_furi_2   : name_furi_2,
				zip_1         : zip_1,
				zip_2         : zip_2,
				pref          : pref,
				addr_1        : addr_1,
				addr_2        : addr_2,
				addr_3        : addr_3,
				tel_1         : tel_1,
				tel_2         : tel_2,
				tel_3         : tel_3,
				not_ec_user_kbn : not_ec_user_kbn,
				itemData      : itemData,
				carriage      : carriage,
				cashdeli      : cashdeli,
				wrapping      : wrapping,
				coupon        : coupon,
				total_price   : total_price,
				payment_price : payment_price,
			});

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLoading  : false,
			 });
			return(0);
		}
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
			isLastPage  : false,
		});
		this._getData();
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleClose() {
		this.setState({
			isShowModal  : false,
			isAddrSelect : false,
			isShowDelete : false,
			isValidated  : false,
			isLockForm   : false,
			ErrMsg       : '',
		});
	}
	handleChange (event){
		const name  = event.target.name;
		const value = event.target.value;
		if(name === 'not_zaiko_processing'){
			this.setState({
				not_zaiko_processing  : this.state.not_zaiko_processing ? false : true,
			});
		}
		else if(name === 'not_mail_processing'){
			this.setState({
				not_mail_processing  : this.state.not_mail_processing ? false : true,
			});
		}
		else{
			this.setState({
				[name]  : value,
			});
		}
	}
	lockFormClose(){
		//
	}
	copyUserData () {
		this.setState({
			o_name_1      : this.state.name_1,
			o_name_2      : this.state.name_2,
			o_name_furi_1 : this.state.name_furi_1,
			o_name_furi_2 : this.state.name_furi_2,
			o_zip_1       : this.state.zip_1,
			o_zip_2       : this.state.zip_2,
			o_pref        : this.state.pref,
			o_addr_1      : this.state.addr_1,
			o_addr_2      : this.state.addr_2,
			o_addr_3      : this.state.addr_3,
			o_tel_1       : this.state.tel_1,
			o_tel_2       : this.state.tel_2,
			o_tel_3       : this.state.tel_3,
			//o_com_name  : this.state.o_com_name,
		});
	}
	ShowUpdateModal(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowModal  : true,
			formObj      : form,
		});
	}
	goLastPage() {
	
		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal  : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isLockForm  : false,
			isLastPage  : true,
		});
	}
	async onClickSelect(row){
		if(row.addressid){
			await this.addressSet(row.addressid);
		}
	}
	async addressSet(addressid) {
		
		//処理
		try{

			//更新開始
			this.setState({
				isLockForm   : true,
				isAddrSelect : false
			});

			//IDの取得
			const cognitoid = this.props.cognitoid;
			if(!cognitoid){
					this.setState({
						ErrMsg     : 'ユーザIDを入力してください。<br/>',
						isLockForm : false,
					});
					return(0);
			}

			//IDの取得
			if(!addressid){
					this.setState({
						ErrMsg     : 'アドレスが見つかりません。<br/>',
						isLoading     : false,
					});
					return(0);
			}
			
			//データ取得
			const Query = `query getAddress($cognitoid: String!, $addressid: String!) {
				getAddress(cognitoid: $cognitoid, addressid: $addressid) {
					o_name_1
					o_name_2
					o_name_furi_1
					o_name_furi_2
					o_zip_1
					o_zip_2
					o_pref
					o_addr_1
					o_addr_2
					o_addr_3
					o_tel_1
					o_tel_2
					o_tel_3
					o_com_name
				}
			}`;
			const Data = {
				cognitoid: cognitoid,
				addressid: addressid,
			};
			const ref = await API.graphql(graphqlOperation(Query, Data));
			//console.log(ref);
			
			//データが空かどうか
			if(ref.data.getAddress === null){
				this.setState({
					ErrMsg     : '商品情報が見当たりませんでした。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			const o_name_1      = ref.data.getAddress.o_name_1;
			const o_name_2      = ref.data.getAddress.o_name_2;
			const o_name_furi_1 = ref.data.getAddress.o_name_furi_1;
			const o_name_furi_2 = ref.data.getAddress.o_name_furi_2;
			const o_zip_1       = ref.data.getAddress.o_zip_1;
			const o_zip_2       = ref.data.getAddress.o_zip_2;
			const o_pref        = ref.data.getAddress.o_pref;
			const o_addr_1      = ref.data.getAddress.o_addr_1;
			const o_addr_2      = ref.data.getAddress.o_addr_2;
			const o_addr_3      = ref.data.getAddress.o_addr_3 === 'null' ? '' : ref.data.getAddress.o_addr_3;
			const o_tel_1       = ref.data.getAddress.o_tel_1;
			const o_tel_2       = ref.data.getAddress.o_tel_2;
			const o_tel_3       = ref.data.getAddress.o_tel_3;
			const o_com_name    = ref.data.getAddress.o_com_name === 'null' ? '' : ref.data.getAddress.o_com_name;

			
			this.setState({
				isLockForm    : false,
				o_name_1      : o_name_1,
				o_name_2      : o_name_2,
				o_name_furi_1 : o_name_furi_1,
				o_name_furi_2 : o_name_furi_2,
				o_zip_1       : o_zip_1,
				o_zip_2       : o_zip_2,
				o_pref        : o_pref,
				o_addr_1      : o_addr_1,
				o_addr_2      : o_addr_2,
				o_addr_3      : o_addr_3,
				o_tel_1       : o_tel_1,
				o_tel_2       : o_tel_2,
				o_tel_3       : o_tel_3,
				o_com_name    : o_com_name,
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg       : err_msg,
				isLockForm   : false,
				isAddrSelect : false,
			 });
			return(0);
		}
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLastPage){
			return (
				<LastPage _backTop={this._backTop} _backNext={this._backNext} {...this.state} {...this.props} />
			);
		}
		else if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowModal} onHide={this.handleClose} centered>
						<Modal.Body>
							次の画面へ移動しますか？<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.goLastPage}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Modal show={this.state.isAddrSelect} onHide={this.handleClose} centered aria-labelledby="AddressSelect" dialogClassName="modal-xl">
						<Modal.Header closeButton>
							<Modal.Title id="AddressSelect">
								アドレスを選んでください。
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<AddressSelect cognitoid={this.props.cognitoid} onClickSelect={this.onClickSelect} />
						</Modal.Body>
					</Modal>
					
					<Row>
						<Col sm={12}>
							<h4>受注情報の入力</h4>　
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<Breadcrumb>
								<Breadcrumb.Item onClick={()=>{ this.props.history.push('/Order/New/'); }}>
									受注情報の新規登録
								</Breadcrumb.Item>
								<Breadcrumb.Item onClick={()=>{ this.props._backTop(); }}>
									受注商品の選択
								</Breadcrumb.Item>
								<Breadcrumb.Item active>
									受注情報の入力
								</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
					</Row>

					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.ShowUpdateModal(e)} onChange={e => this.handleChange(e)}>
					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
						<Row>
							<Col sm={12}>
								<p><big>出荷管理メモ</big></p>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<Form.Group controlId="memo">
								        <Form.Control name="memo" as="textarea" rows="3" placeholder="出荷管理メモ" size="lg" pattern=".{1,1000}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「出荷管理メモ」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>

					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
						<Row>
							<Col sm={12}>
								<p><big>受注情報</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={1}>
								決済方法
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="payment_type">
								        <Form.Control name="payment_type" type="text" size="lg" as="select" required >
									<option value="銀行振込">銀行振込</option>
									<option value="クレジットカード決済">クレジットカード決済</option>
									<option value="代金引換">代金引換</option>
									<option value="後払い">後払い</option>
									<option value="未定義">未定義</option>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										「決済方法」を選択してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								お届け方法
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="carriage_type">
								        <Form.Control name="carriage_type" type="text" size="lg" as="select" required >
									<option value="ゆうパケット">ゆうパケット</option>
									<option value="宅急便">宅急便</option>
									<option value="未定義">未定義</option>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										「お届け方法」を選択してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<hr/>
						<Row>
							<Form.Label column sm={1}>
								日付指定
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="o_date_type">
								        <Form.Control name="o_date_type" type="text" size="lg" pattern="指定なし|\d{4}年\d{2}月\d{2}日" defaultValue="指定なし" required />
									<Form.Control.Feedback type="invalid">
										「日付指定」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								時間指定
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="o_date_h">
								        <Form.Control name="o_date_h" type="text" size="lg" as="select" required >
									<option value="指定なし">指定なし</option>
									<option value="午前中">午前中</option>
									<option value="12：00～14：00">12：00～14：00</option>
									<option value="14：00～16：00">14：00～16：00</option>
									<option value="16：00～18：00">16：00～18：00</option>
									<option value="18：00～20：00">18：00～20：00</option>
									<option value="20：00～21：00">20：00～21：00</option>
									<option value="19：00～21：00">19：00～21：00</option>
									<option value="未定義">未定義</option>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										「時間指定」を選択してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
					</Container>
					
					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
						<Row>
							<Col sm={12}>
								<p><big>注文者情報</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								ユーザID
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="cognitoid">
								        <Form.Control name="cognitoid" type="text" required={!this.state.not_ec_user_kbn} placeholder="会員番号" defaultValue={this.state.not_ec_user_kbn ? '非会員' : this.state.cognitoid} size="lg"  readOnly />
									<Form.Control.Feedback />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								メールアドレス
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="email">
								        <Form.Control name="email" type="email" defaultValue={this.state.email} placeholder="メールアドレス" size="lg" pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「メールアドレス」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								お名前　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={3}>
								<Form.Group controlId="name_1">
								        <Form.Control name="name_1" type="text" defaultValue={this.state.name_1} placeholder="お名前（名字）" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「お名前（名字）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="name_2">
								        <Form.Control name="name_2" type="text" defaultValue={this.state.name_2} placeholder="お名前（名前）" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「お名前（名前）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								フリガナ　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={3}>
								<Form.Group controlId="name_furi_1">
								        <Form.Control name="name_furi_1" type="text" defaultValue={this.state.name_furi_1} placeholder="フリガナ（名字）" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「フリガナ（名字）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="name_furi_2">
								        <Form.Control name="name_furi_2" type="text" defaultValue={this.state.name_furi_2} placeholder="フリガナ（名前）" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「フリガナ（名前）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								郵便番号　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="zip_1">
								        <Form.Control name="zip_1" type="text" defaultValue={this.state.zip_1} placeholder="3桁" size="lg" required pattern="\d{3}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「郵便番号（3桁）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="zip_2">
								        <Form.Control name="zip_2" type="text" defaultValue={this.state.zip_2} placeholder="4桁" size="lg" required pattern="\d{4}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「郵便番号（4桁）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								都道府県　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="pref">
								        <Form.Control name="pref" type="text" defaultValue={this.state.pref} placeholder="都道府県" size="lg" required pattern=".{2,4}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「都道府県」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（市区町村）　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="addr_1">
								        <Form.Control name="addr_1" type="text" defaultValue={this.state.addr_1} placeholder="市区町村" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「住所（市区町村）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（番地）　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="addr_2">
								        <Form.Control name="addr_2" type="text" defaultValue={this.state.addr_2} placeholder="番地" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「住所（番地）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（その他・建物）
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="addr_3">
								        <Form.Control name="addr_3" type="text" defaultValue={this.state.addr_3} placeholder="その他・建物" size="lg" pattern=".{0,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「住所（その他・建物）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								電話番号　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="tel_1">
								        <Form.Control name="tel_1" type="text" defaultValue={this.state.tel_1} placeholder="市外局番" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「電話番号（市外局番）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="tel_2">
								        <Form.Control name="tel_2" type="text" defaultValue={this.state.tel_2} placeholder="市内局番" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「電話番号（市内局番）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="tel_3">
								        <Form.Control name="tel_3" type="text" defaultValue={this.state.tel_3} placeholder="番号" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「電話番号」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
						<Row>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="info" type="button" block size="lg" disabled={this.state.isLockForm} onClick={this.copyUserData} >注文者情報をコピーする</Button>
							</Col>
						</Row>
						<Row style={{ marginTop: 20 }}>
							<Col sm={{ span: 6, offset: 3 }}>
								<Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.setState({ isAddrSelect : true }); }} disabled={this.state.isLockForm} >アドレス情報を検索する</Button>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<p><big>発送先情報</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								お名前　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={3}>
								<Form.Group controlId="o_name_1">
								        <Form.Control name="o_name_1" type="text" defaultValue={this.state.o_name_1} placeholder="お名前（名字）" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「お名前（名字）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="o_name_2">
								        <Form.Control name="o_name_2" type="text" defaultValue={this.state.o_name_2} placeholder="お名前（名前）" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「お名前（名前）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								フリガナ　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={3}>
								<Form.Group controlId="o_name_furi_1">
								        <Form.Control name="o_name_furi_1" type="text" defaultValue={this.state.o_name_furi_1} placeholder="フリガナ（名字）" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「フリガナ（名字）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="o_name_furi_2">
								        <Form.Control name="o_name_furi_2" type="text" defaultValue={this.state.o_name_furi_2} placeholder="フリガナ（名前）" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「フリガナ（名前）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								郵便番号　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="o_zip_1">
								        <Form.Control name="o_zip_1" type="text" defaultValue={this.state.o_zip_1} placeholder="3桁" size="lg" required pattern="\d{3}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「郵便番号（3桁）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="o_zip_2">
								        <Form.Control name="o_zip_2" type="text" defaultValue={this.state.o_zip_2} placeholder="4桁" size="lg" required pattern="\d{4}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「郵便番号（4桁）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								都道府県　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="o_pref">
								        <Form.Control name="o_pref" type="text" defaultValue={this.state.o_pref} placeholder="都道府県" size="lg" required pattern=".{2,4}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「都道府県」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（市区町村）　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="o_addr_1">
								        <Form.Control name="o_addr_1" type="text" defaultValue={this.state.o_addr_1} placeholder="市区町村" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「住所（市区町村）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（番地）　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="o_addr_2">
								        <Form.Control name="o_addr_2" type="text" defaultValue={this.state.o_addr_2} placeholder="番地" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「住所（番地）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（その他・建物）
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="o_addr_3">
								        <Form.Control name="o_addr_3" type="text" defaultValue={this.state.o_addr_3} placeholder="その他・建物" size="lg" pattern=".{0,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「住所（その他・建物）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								会社名
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="o_com_name">
								        <Form.Control name="o_com_name" type="text" defaultValue={this.state.o_com_name} placeholder="会社名" size="lg" pattern=".{0,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「会社名」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								電話番号　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="o_tel_1">
								        <Form.Control name="o_tel_1" type="text" defaultValue={this.state.o_tel_1} placeholder="市外局番" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「電話番号（市外局番）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="o_tel_2">
								        <Form.Control name="o_tel_2" type="o_text" defaultValue={this.state.tel_2} placeholder="市内局番" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「電話番号（市内局番）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="o_tel_3">
								        <Form.Control name="o_tel_3" type="text" defaultValue={this.state.o_tel_3} placeholder="番号" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「電話番号」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
					</Container>
					
					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
						<Row>
							<Form.Label column sm={3}>
								在庫処理
							</Form.Label>
							<Col sm={6}>
								<Form.Check type="checkbox" name="not_zaiko_processing" defaultChecked={this.state.not_zaiko_processing}  label="在庫処理をしない" />
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								メール処理
							</Form.Label>
							<Col sm={6}>
								<Form.Check type="checkbox" name="not_mail_processing" defaultChecked={this.state.not_mail_processing}  label="メールを送らない" />
							</Col>
						</Row>
					</Container>

					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >次の画面へ</Button>
						</Col>
					</Row>

					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props._backTop(); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					</Form>
					

				</Container>
			);
		}
	}
}

class LastPage extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isNotFound     : false,
			isValidated    : false,
			isSuccess      : false,
			isShowModal    : false,
			isLockForm     : false,
			formObj        : null,
			ErrMsg         : '',
			orderno        : '',
		}
		this._getData             = this._getData.bind(this);
		this.handleSubmit         = this.handleSubmit.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
		this.ShowUpdateModal      = this.ShowUpdateModal.bind(this);
	}
	async _getData () {

		try{

			//IDの取得
			const cognitoid = this.props.cognitoid;
			if(!cognitoid){
					this.setState({
						ErrMsg     : 'ユーザIDを入力してください。<br/>',
						isLockForm : false,
					});
					return(0);
			}
			
			//会員なら情報を取得
			if(cognitoid !== 'not-ec-user'){
			
				//データ取得
				const Query = `query getProfile($cognitoid: String!) {
					getProfile(cognitoid: $cognitoid) {
						cognitoid
					}
				}`;
				const Data = { cognitoid: cognitoid };
				const ref = await API.graphql(graphqlOperation(Query, Data));
				//console.log(ref);
				
				//データが空かどうか
				if(ref.data.getProfile === null){
					this.setState({
						ErrMsg     : '会員情報が見当たりませんでした。<br/>',
						isLockForm : false,
					});
					return(0);
				}
			}

			//データ更新
			this.setState({
				isLoading     : false,
			});

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLoading  : false,
			 });
			return(0);
		}
	}
	async handleSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal : false,
			isValidated : true,
			isLockForm  : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//項目
			const cognitoid       = this.props.cognitoid      ;
			const total_price     = this.props.total_price     === '' ? 0          : this.props.payment_price;
			const payment_type    = this.props.payment_type    === '' ? '未定義'   : this.props.payment_type;
			const payment_price   = this.props.payment_price   === '' ? 0          : this.props.payment_price;
			const carriage_type   = this.props.carriage_type   === '' ? '未定義'   : this.props.carriage_type;
			const carriage        = this.props.carriage        === '' ? 0          : this.props.carriage;
			const cashdeli        = this.props.cashdeli        === '' ? 0          : this.props.cashdeli;
			const wrapping_type   = this.props.wrapping_type   === '' ? '未定義'   : this.props.wrapping_type;
			const wrapping        = this.props.wrapping        === '' ? 0          : this.props.wrapping;
			const coupon_type     = this.props.coupon_type     === '' ? '未定義'   : this.props.coupon_type;
			const coupon          = this.props.coupon          === '' ? 0          : this.props.coupon;
			const email           = this.props.email           === '' ? 'null'     : this.props.email;
			const name_1          = this.props.name_1         ;
			const name_2          = this.props.name_2         ;
			const name_furi_1     = this.props.name_furi_1    ;
			const name_furi_2     = this.props.name_furi_2    ;
			const zip_1           = this.props.zip_1          ;
			const zip_2           = this.props.zip_2          ;
			const pref            = this.props.pref           ;
			const addr_1          = this.props.addr_1         ;
			const addr_2          = this.props.addr_2         ;
			const addr_3          = this.props.addr_3          === '' ? 'null'     : this.props.addr_3;
			const tel_1           = this.props.tel_1          ;
			const tel_2           = this.props.tel_2          ;
			const tel_3           = this.props.tel_3          ;
			const o_name_1        = this.props.o_name_1       ;
			const o_name_2        = this.props.o_name_2       ;
			const o_name_furi_1   = this.props.o_name_furi_1  ;
			const o_name_furi_2   = this.props.o_name_furi_2  ;
			const o_zip_1         = this.props.o_zip_1        ;
			const o_zip_2         = this.props.o_zip_2        ;
			const o_pref          = this.props.o_pref         ;
			const o_addr_1        = this.props.o_addr_1       ;
			const o_addr_2        = this.props.o_addr_2       ;
			const o_addr_3        = this.props.o_addr_3        === '' ? 'null'     : this.props.o_addr_3;
			const o_tel_1         = this.props.o_tel_1        ;
			const o_tel_2         = this.props.o_tel_2        ;
			const o_tel_3         = this.props.o_tel_3        ;
			const o_com_name      = this.props.o_com_name      === '' ? 'null'     : this.props.o_com_name;
			const o_date_type     = this.props.o_date_type     === '' ? '指定なし' : this.props.o_date_type;
			const o_date_h        = this.props.o_date_h        === '' ? '指定なし' : this.props.o_date_type;
			const order_memo      = 'null';
			const delivery_no     = this.props.delivery_no     === '' ? 'null'     : this.props.delivery_no;
			const delivery_date   = this.props.delivery_date   === '' ? 'null'     : this.props.delivery_date;
			const status          = this.props.status          === '' ? '仮受注'   : this.props.status;
			const memo            = this.props.memo            === '' ? 'null'     : this.props.memo;
			const not_ec_user_kbn = this.props.not_ec_user_kbn        ? true       : false;
			const flag            = this.props.flag                   ? this.props.flag       : null;
			const itemData        = this.props.itemData       ;
			
			//在庫処理フラグ
			const not_zaiko_processing = this.props.not_zaiko_processing ? true : false;
			
			//メール処理フラグ
			const not_mail_processing = this.props.not_mail_processing ? true : false;
			

			//登録
			const createQuery = `mutation executeOrderCommit($data: OrderInput!) {
				executeOrderCommit(input: $data) {
					orderno
					error
				}
			}`;
			const createData = {
				data : {
					cognitoid       : cognitoid       ,
					total_price     : total_price     ,
					payment_type    : payment_type    ,
					payment_price   : payment_price   ,
					carriage_type   : carriage_type   ,
					carriage        : carriage        ,
					wrapping_type   : wrapping_type   ,
					wrapping        : wrapping        ,
					coupon_type     : coupon_type     ,
					coupon          : coupon          ,
					cashdeli        : cashdeli        ,
					email           : email           ,
					name_1          : name_1          ,
					name_2          : name_2          ,
					name_furi_1     : name_furi_1     ,
					name_furi_2     : name_furi_2     ,
					zip_1           : zip_1           ,
					zip_2           : zip_2           ,
					pref            : pref            ,
					addr_1          : addr_1          ,
					addr_2          : addr_2          ,
					addr_3          : addr_3          ,
					tel_1           : tel_1           ,
					tel_2           : tel_2           ,
					tel_3           : tel_3           ,
					o_name_1        : o_name_1        ,
					o_name_2        : o_name_2        ,
					o_name_furi_1   : o_name_furi_1   ,
					o_name_furi_2   : o_name_furi_2   ,
					o_zip_1         : o_zip_1         ,
					o_zip_2         : o_zip_2         ,
					o_pref          : o_pref          ,
					o_addr_1        : o_addr_1        ,
					o_addr_2        : o_addr_2        ,
					o_addr_3        : o_addr_3        ,
					o_tel_1         : o_tel_1         ,
					o_tel_2         : o_tel_2         ,
					o_tel_3         : o_tel_3         ,
					o_com_name      : o_com_name      ,
					o_date_type     : o_date_type     ,
					o_date_h        : o_date_h        ,
					order_memo      : order_memo      ,
					delivery_no     : delivery_no     ,
					delivery_date   : delivery_date   ,
					status          : status          ,
					memo            : memo            ,
					not_ec_user_kbn : not_ec_user_kbn ,
					flag            : flag            ,
					not_zaiko_processing : not_zaiko_processing,
					not_mail_processing  : not_mail_processing,
					order_items     : itemData        ,
				}
			};
			const ref = await API.graphql(graphqlOperation(createQuery, createData));

			const orderno = ref.data.executeOrderCommit.orderno;
			const error   = ref.data.executeOrderCommit.error;

			//鑑査ログ
			await AuditLog('order', 'create', orderno, createData, '受注情報を追加しました。');
			
			if(orderno === null || error){
				this.setState({
					ErrMsg     : '※' + error + '<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			this.setState({
				orderno : orderno,
			});
		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors[0].errorType === 'DynamoDB:ConditionalCheckFailedException'){
				err_msg = '指定された商品コードはすでに登録されています。<br/>';
			}
			else if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			});
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	handleReload() {
		//データの再取得
		this._getData();
	}
	ShowUpdateModal(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowModal  : true,
			formObj      : form,
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleClose() {
		this.setState({
			isShowModal  : false,
			isShowDelete : false,
			isSuccess    : false,
			isValidated  : false,
			isLockForm   : false,
			ErrMsg       : '',
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>
							登録しました。<br/>
							続けて登録しますか？
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={()=>{ this.props.history.push('/Order/'); }}>
								いいえ
							</Button>
							<Button variant="primary" onClick={()=>{ this.props.history.push('/Order/New/'); }}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowModal} onHide={this.handleClose} centered>
						<Modal.Body>
							本当に更新しますか？<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.handleSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					

					<Row>
						<Col sm={12}>
							<h4>最終確認</h4>　
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<Breadcrumb>
								<Breadcrumb.Item onClick={()=>{ this.props.history.push('/Order/New/'); }}>
									受注情報の新規登録
								</Breadcrumb.Item>
								<Breadcrumb.Item onClick={()=>{ this.props._backTop(); }}>
									受注商品の選択
								</Breadcrumb.Item>
								<Breadcrumb.Item onClick={()=>{ this.props._backNext(); }}>
									受注情報の入力
								</Breadcrumb.Item>
								<Breadcrumb.Item active>
									最終確認
								</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
					</Row>


					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.ShowUpdateModal(e)}>
					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
						<Row>
							<Col sm={12}>
								<p><big>出荷管理メモ</big></p>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<Form.Group controlId="memo">
								        <Form.Control name="memo" as="textarea" rows="3" defaultValue={this.props.memo} placeholder="出荷管理メモ" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
						<Row>
							<Col sm={12}>
								<p><big>受注情報</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={1}>
								決済方法
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="payment_type">
								        <Form.Control name="payment_type" type="text" defaultValue={this.props.payment_type} size="lg" as="select" readOnly >
									<option value="銀行振込">銀行振込</option>
									<option value="クレジットカード決済">クレジットカード決済</option>
									<option value="代金引換">代金引換</option>
									<option value="後払い">後払い</option>
									<option value="未定義">未定義</option>
									</Form.Control>
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								お届け方法
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="carriage_type">
								        <Form.Control name="carriage_type" type="text" defaultValue={this.props.carriage_type} size="lg" as="select" readOnly >
									<option value="ゆうパケット">ゆうパケット</option>
									<option value="宅急便">宅急便</option>
									<option value="未定義">未定義</option>
									</Form.Control>
								</Form.Group>
							</Col>
						</Row>
						<hr/>
						<Row>
							<Form.Label column sm={1}>
								日付指定
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="o_date_type">
								        <Form.Control name="o_date_type" type="text" defaultValue={this.props.o_date_type} size="lg" readOnly />
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								時間指定
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="o_date_h">
								        <Form.Control name="o_date_h" type="text" defaultValue={this.props.o_date_h} size="lg" as="select" readOnly >
									<option value="指定なし">指定なし</option>
									<option value="午前中">午前中</option>
									<option value="12：00～14：00">12：00～14：00</option>
									<option value="14：00～16：00">14：00～16：00</option>
									<option value="16：00～18：00">16：00～18：00</option>
									<option value="18：00～20：00">18：00～20：00</option>
									<option value="20：00～21：00">20：00～21：00</option>
									<option value="19：00～21：00">19：00～21：00</option>
									<option value="未定義">未定義</option>
									</Form.Control>
								</Form.Group>
							</Col>
						</Row>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
						<Row>
							<Col sm={12}>
								<p><big>注文内容</big></p>
							</Col>
						</Row>

						<Table bordered responsive style={{ marginTop: 20 }} className="text-nowrap">
						<thead>
						<tr>
						<th style={{ width: '20%'}}>商品コード</th>
						<th style={{ width: '40%'}}>商品名</th>
						<th style={{ width: '15%'}}>金額</th>
						<th style={{ width: '10%'}}>個数</th>
						<th style={{ width: '15%'}}>合計</th>
						</tr>
						</thead>
						<tbody>


						 { this.props.itemData.map(
						 	(value, index) => {
								const code            = value.code;
								const name            = value.name;
								const price           = value.price;
								const sub_price       = value.sub_price.toLocaleString();
								const piece           = value.piece;
								const piece_type      = value.piece_type;
								
								return (
									<tr key={index} className="text-nowrap">
										<td>{code}</td>
										<td>{name}</td>
										<td className="text-right">{price} 円</td>
										<td className="text-right">{piece} {piece_type}</td>
										<td className="text-right">{sub_price} 円</td>
									</tr>
								);
							}
						 )}
						<tr className="text-nowrap">
							<td colSpan="2">
								　
							</td>
							<th colSpan="2" className="text-right">
								小計
							</th>
							<td className="text-right">
								{this.props.total_price.toLocaleString()} 円
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="2">
								　
							</td>
							<th colSpan="2" className="text-right">
								送料
							</th>
							<td className="text-right">
								{this.props.carriage.toLocaleString()} 円
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="2">
								　
							</td>
							<th colSpan="2" className="text-right">
								決済手数料
							</th>
							<td className="text-right">
								{this.props.cashdeli.toLocaleString()} 円
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="2">
								　
							</td>
							<th colSpan="2" className="text-right">
								ギフト手数料
							</th>
							<td className="text-right">
								{this.props.wrapping.toLocaleString()} 円
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="2">
								　
							</td>
							<th colSpan="2" className="text-right">
								クーポン
							</th>
							<td className="text-right">
								{this.props.coupon.toLocaleString()} 円
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="2">
								　
							</td>
							<th colSpan="2" className="text-right">
								合計金額
							</th>
							<td className="text-right">
								{this.props.payment_price.toLocaleString()} 円
							</td>
						</tr>
						</tbody>
						</Table>
					</Container>
					
					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
						<Row>
							<Col sm={12}>
								<p><big>注文者情報</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								ユーザID
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="cognitoid">
								        <Form.Control name="cognitoid" type="text" defaultValue={(this.props.not_ec_user_kbn ? '非会員' : this.props.cognitoid)} size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								メールアドレス
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="email">
								        <Form.Control name="email" type="email" defaultValue={this.props.email} placeholder="メールアドレス" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								お名前　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={3}>
								<Form.Group controlId="name_1">
								        <Form.Control name="name_1" type="text" defaultValue={this.props.name_1} placeholder="お名前（名字）" size="lg" readOnly />
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="name_2">
								        <Form.Control name="name_2" type="text" defaultValue={this.props.name_2} placeholder="お名前（名前）" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								フリガナ　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={3}>
								<Form.Group controlId="name_furi_1">
								        <Form.Control name="name_furi_1" type="text" defaultValue={this.props.name_furi_1} placeholder="フリガナ（名字）" size="lg" readOnly />
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="name_furi_2">
								        <Form.Control name="name_furi_2" type="text" defaultValue={this.props.name_furi_2} placeholder="フリガナ（名前）" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								郵便番号　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="zip_1">
								        <Form.Control name="zip_1" type="text" defaultValue={this.props.zip_1} placeholder="3桁" size="lg" readOnly />
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="zip_2">
								        <Form.Control name="zip_2" type="text" defaultValue={this.props.zip_2} placeholder="4桁" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								都道府県　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="pref">
								        <Form.Control name="pref" type="text" defaultValue={this.props.pref} placeholder="都道府県" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（市区町村）　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="addr_1">
								        <Form.Control name="addr_1" type="text" defaultValue={this.props.addr_1} placeholder="市区町村" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（番地）　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="addr_2">
								        <Form.Control name="addr_2" type="text" defaultValue={this.props.addr_2} placeholder="番地" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（その他・建物）
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="addr_3">
								        <Form.Control name="addr_3" type="text" defaultValue={this.props.addr_3} placeholder="その他・建物" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								電話番号　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="tel_1">
								        <Form.Control name="tel_1" type="text" defaultValue={this.props.tel_1} placeholder="市外局番" size="lg" readOnly />
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="tel_2">
								        <Form.Control name="tel_2" type="text" defaultValue={this.props.tel_2} placeholder="市内局番" size="lg" readOnly />
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="tel_3">
								        <Form.Control name="tel_3" type="text" defaultValue={this.props.tel_3} placeholder="番号" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
						<Row>
							<Col sm={12}>
								<p><big>発送先情報</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								お名前　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={3}>
								<Form.Group controlId="o_name_1">
								        <Form.Control name="o_name_1" type="text" defaultValue={this.props.o_name_1} placeholder="お名前（名字）" size="lg" readOnly />
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="o_name_2">
								        <Form.Control name="o_name_2" type="text" defaultValue={this.props.o_name_2} placeholder="お名前（名前）" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								フリガナ　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={3}>
								<Form.Group controlId="o_name_furi_1">
								        <Form.Control name="o_name_furi_1" type="text" defaultValue={this.props.o_name_furi_1} placeholder="フリガナ（名字）" size="lg" readOnly />
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="o_name_furi_2">
								        <Form.Control name="o_name_furi_2" type="text" defaultValue={this.props.o_name_furi_2} placeholder="フリガナ（名前）" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								郵便番号　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="o_zip_1">
								        <Form.Control name="o_zip_1" type="text" defaultValue={this.props.o_zip_1} placeholder="3桁" size="lg" readOnly />
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="o_zip_2">
								        <Form.Control name="o_zip_2" type="text" defaultValue={this.props.o_zip_2} placeholder="4桁" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								都道府県　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="o_pref">
								        <Form.Control name="o_pref" type="text" defaultValue={this.props.o_pref} placeholder="都道府県" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（市区町村）　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="o_addr_1">
								        <Form.Control name="o_addr_1" type="text" defaultValue={this.props.o_addr_1} placeholder="市区町村" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（番地）　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="o_addr_2">
								        <Form.Control name="o_addr_2" type="text" defaultValue={this.props.o_addr_2} placeholder="番地" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								住所（その他・建物）
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="o_addr_3">
								        <Form.Control name="o_addr_3" type="text" defaultValue={this.props.o_addr_3} placeholder="その他・建物" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								会社名
							</Form.Label>
							<Col sm={6}>
								<Form.Group controlId="o_com_name">
								        <Form.Control name="o_com_name" type="text" defaultValue={this.props.o_com_name} placeholder="会社名" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								電話番号　<Badge variant="danger">必須</Badge>
							</Form.Label>
							<Col sm={2}>
								<Form.Group controlId="o_tel_1">
								        <Form.Control name="o_tel_1" type="text" defaultValue={this.props.o_tel_1} placeholder="市外局番" size="lg" readOnly />
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="o_tel_2">
								        <Form.Control name="o_tel_2" type="o_text" defaultValue={this.props.tel_2} placeholder="市内局番" size="lg" readOnly />
								</Form.Group>
							</Col>
							<Col sm={2}>
								<Form.Group controlId="o_tel_3">
								        <Form.Control name="o_tel_3" type="text" defaultValue={this.props.o_tel_3} placeholder="番号" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
						<Row>
							<Form.Label column sm={3}>
								在庫処理
							</Form.Label>
							<Col sm={6}>
								<Form.Check type="checkbox" name="not_zaiko_processing" checked={this.props.not_zaiko_processing} label="在庫処理をしない" readOnly />
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								メール処理
							</Form.Label>
							<Col sm={6}>
								<Form.Check type="checkbox" name="not_mail_processing" checked={this.props.not_mail_processing} label="メール処理をしない" readOnly />
							</Col>
						</Row>
					</Container>

					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >登録する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props._backNext(); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					
					</Form>

				</Container>
			);
		}
	}
}

export default withRouter(Default);

