import React from 'react';

class Footer extends React.Component {
	render() {
		return (
			<footer className="text-center no_print" style={{backgroundColor: "#525864", color: "#FFF", padding:"10px", marginTop:"40px"}}>
				<small>Copyright since 2019 Onocomm Co Ltd. All rights reserved.</small>
			</footer>
		);
	}
}

export default Footer;

