import React from 'react';
import {
	Container,
	Navbar,
	Nav,
	NavDropdown,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';


class Header extends React.Component {
	constructor(props) {
		super(props);
		this._click = this._click.bind(this);
	}
	_click(uri) {
		if(uri){
			this.props.history.push(uri);
		}
		//console.log(uri);
	}
	render() {
		return (
			<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="no_print">
				<Container fluid>
					<Navbar.Brand href="/">
						マインドウェイブ オンラインストア管理画面
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav onSelect={this._click} className="mr-auto">
							<Nav.Link eventKey={"/Order/"}>受注情報</Nav.Link>
							<Nav.Link eventKey={"/Category/"}>カテゴリ</Nav.Link>
							<Nav.Link eventKey={"/Item/"}>商品情報</Nav.Link>
							<Nav.Link eventKey={"/User/"}>ユーザ情報</Nav.Link>
							<NavDropdown title="メルマガ" id="basic-nav-dropdown">
								<NavDropdown.Item eventKey={"/MailMagazine/New/"}>新規発行</NavDropdown.Item>
								<NavDropdown.Item eventKey={"/MailMagazine/"}>予約一覧</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown title="その他" id="basic-nav-dropdown">
								<NavDropdown.Item eventKey={"/Login/"}>ログイン情報</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item eventKey={"/Setting/"}>各種パラメータ設定</NavDropdown.Item>
								<NavDropdown.Item eventKey={"/CSVUpload/"}>CSVアップロード</NavDropdown.Item>
								<NavDropdown.Item eventKey={"/MailTmpl/"}>メール送信テンプレート</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item eventKey={"/MailLog/Bounce/"}>バウンスメールログ</NavDropdown.Item>
								<NavDropdown.Item eventKey={"/MailLog/Complaint/"}>ブロックメールログ</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item eventKey={"/StripeCardErrorLog/"}>カード決済エラーログ</NavDropdown.Item>
							</NavDropdown>
						</Nav>
						<Nav onSelect={this._click}>
							 <Nav.Link eventKey={"/Version/"}>ver.2.1031</Nav.Link>
						</Nav>
						<Nav onSelect={this._click}>
							 <Nav.Link eventKey={"/SignOut/"}>ログオフ</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		);
	}
}

export default withRouter(Header);
