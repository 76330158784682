import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Alert,
	Modal,
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';


class MyTable extends React.Component {
	constructor(props) {
		super(props);
		this._dataFormatDate         = this._dataFormatDate.bind(this);
	}
	_dataFormatDate(cell, row) {

		//日付の整形
		const dateObj     = new Date(cell);
		const year        = dateObj.getFullYear().toString();
		const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day         = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second      = ('00' + dateObj.getSeconds().toString()).slice(-2);

		//出力
		return (
			<div>
				{year}年{month}月{day}日{hour}時{minute}分{second}秒
			</div>
		);
	}
	render() {
		if(this.props.data.length === 0){
			return (
				<Alert variant="danger">
					対象のデータが見つかりません。
				</Alert>
			);
		}
		else{
		
			//表示用に整形
			let TableData = [];
			for(let item of this.props.data){

				const email           = item.email;
				const date_add        = item.date_add        ;
				const message_id      = item.message_id      ;
				const source          = item.source          ;
				const complaint_type  = item.complaint_type  ;
				const complaint_user  = item.complaint_user  ;


				const RowData = {
					key             : email + date_add,
					email           : email,
					date_add        : date_add,
					message_id      : message_id,
					source          : source,
					complaint_type  : complaint_type,
					complaint_user  : complaint_user,
				};
				
				TableData.push(RowData);
			}
			
			const columns = [
				{
					dataField: 'date_add',
					text: '日時',
					sort: true,
					formatter: this._dataFormatDate,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'email',
					text: '送信先',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'source',
					text: '送信元',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'message_id',
					text: 'メッセージID',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'complaint_type',
					text: 'タイプ',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'complaint_user',
					text: '申告元',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
			];

			const defaultSorted = [
				{
					dataField: 'date_add',
					order: 'desc'
				}
			];
			
			const options = {
				sizePerPageList: [
					{ text: '10', value: 10},
					{ text: '50', value: 50},
					{ text: '100', value: 100},
					{ text: 'All', value: TableData.length},
				],
			};
			
			return (
				<BootstrapTable bootstrap4 keyField='key' data={ TableData } columns={ columns } bordered={ false } defaultSorted={ defaultSorted } pagination={ paginationFactory(options) } />
			);

		}
	}
}

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading   : true,
			ErrMsg      : '',
			dateTime    : '',
			viewData    : [],
			defaultData : [],
		}
		this._reload              = this._reload.bind(this);
		this._getData             = this._getData.bind(this);
		this.handleDismiss        = this.handleDismiss.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleSearch         = this.handleSearch.bind(this);
	}
	_reload() {
		//子ウィンドウからの呼び出し用
		this.handleReload();
	}
	async _getData() {
		
		//処理
		try{

			//商品情報の取得
			const Query = `query scanMailComplaintLog {
				scanMailComplaintLog {
					items {
						email
						date_add
						message_id
						source
						complaint_type
						complaint_user
					}
					nextToken
				}
			}`;
			let ref = await API.graphql(graphqlOperation(Query));
			let Items = ref.data.scanMailComplaintLog.items;
			//console.log(Items);
			
			//商品情報の取得（未取得の続きデータがあった場合の処理）
			while (ref.data.scanMailComplaintLog.nextToken) {
				const Query = `query scanMailComplaintLog($nextToken: String) {
					scanMailComplaintLog(nextToken: $nextToken) {
						items {
							email
							date_add
							message_id
							source
							complaint_type
							complaint_user
						}
						nextToken
					}
				}`;
				const Data = {
					nextToken: ref.data.scanMailComplaintLog.nextToken,
				};
				//console.log(ref.data.scanMailComplaintLog.nextToken);
				ref = await API.graphql(graphqlOperation(Query, Data));
				Items = Items.concat(ref.data.scanMailComplaintLog.items);
			}

			//データの判別
			if(Items.length === 0){
				//データがなかった時
				this.setState({
					isLoading : false,
					ErrMsg    : 'データがありません。',
				});
			}
			else{

				//'null'文字がある場合は''に変換
				//Items.some((item, index) => Object.keys(item).some(key => { if(item[key] === 'null'){ Items[index][key] = '' } return false; }));
				
				//データコピー
				const viewData = [];
				for(let row of Items){
					viewData.push(row);
				}
				
				//データコピー
				const defaultData = [];
				for(let row of Items){
					defaultData.push(row);
				}
				
				//データの保存
				this.setState({
					isLoading   : false,
					viewData    : viewData,
					defaultData : defaultData,
				});
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				isLoading  : false,
				ErrMsg     : err_msg,
			});
		}
	}
	handleDismiss() {
		this.setState({ ErrMsg: '' });
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
			viewData    : [],
			defaultData : [],
		});
		this._getData();
	}
	handleSearch(e){

		//Submitをキャンセル
		e.preventDefault();
		e.stopPropagation();
	
		//検索文字列と対象データを取得
		const key   = e.target.value;
		const defaultData = this.state.defaultData;
		
		//検索文字及び対象データがない場合はリセット
		if(key === '' || defaultData.length === 0){
			this.setState({ viewData: defaultData});
			return(0);
		}
		
		//検索文字オブジェクト
		const matchString = new RegExp(key);
		
		//検索
		const viewData = defaultData.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		//表示データ更新
		this.setState({ viewData: viewData});
	}
	setSelectIDs (selectIDs){
	
		//デフォルトのデータを取得
		const defaultData = this.state.defaultData;

		//デフォルトのデータ内から選択されているIDのデータのみ抽出
		const csvData = defaultData.filter(item => selectIDs.indexOf(item.cognitoid) !== -1);
		
		//選択しているIDとCSV用データを保存
		this.setState({
			selectIDs: selectIDs,
			csvData  : csvData,
		});
	}
	async componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleDismiss} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleDismiss}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					<Form onSubmit={e => this.handleSearch(e)}>
						<Row style={{ marginTop: 20 }}>
							<Col sm={4}>
								<Form.Group controlId="search">
								        <Form.Control name="search" type="text" placeholder="検索" size="lg" onChange={e => this.handleSearch(e)}/>
								</Form.Group>
							</Col>
							<Col sm={4}>
								<Badge pill variant="secondary">
									<h5>　{ this.state.viewData.length.toLocaleString() }件　</h5>
								</Badge>
							</Col>
						</Row>
					</Form>

					<Row style={{ marginBottom: 20 }}>
						<Col sm={6}>
						        <big>ブロックメールログ</big>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ this.handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<MyTable data={this.state.viewData}  />
						</Col>
					</Row>
				</Container>
			);
		}
	}
}

export default withRouter(Default);

