import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Modal,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import NotFound from '../../../Objects/NotFound';
import { AuditLog } from '../../../Objects/AuditLog';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isNotFound     : false,
			isValidated    : false,
			isSuccess      : false,
			isDeleted      : false,
			isShowUpdate   : false,
			isShowDelete   : false,
			isLockForm     : false,
			formObj        : null,
			ErrMsg         : '',
			cognitoid      : '',
			addressid      : '',
			o_name_1       : '',
			o_name_2       : '',
			o_name_furi_1  : '',
			o_name_furi_2  : '',
			o_zip_1        : '',
			o_zip_2        : '',
			o_pref         : '',
			o_addr_1       : '',
			o_addr_2       : '',
			o_addr_3       : '',
			o_tel_1        : '',
			o_tel_2        : '',
			o_tel_3        : '',
			o_com_name     : '',
			date_add       : '',
			date_edit      : '',
		}
		this.handleReload     = this.handleReload.bind(this);
		this.updateSubmit     = this.updateSubmit.bind(this);
		this.deleteSubmit     = this.deleteSubmit.bind(this);
		this.handleUpdate     = this.handleUpdate.bind(this);
		this.handleDelete     = this.handleDelete.bind(this);
		this.handleClose      = this.handleClose.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
		this.lockFormClose    = this.lockFormClose.bind(this);
		this._getData         = this._getData.bind(this);
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	async _getData() {

		try{
			//IDの取得
			const cognitoid = this.props.match.params.cognitoid;
			if(!cognitoid){
					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
			}

			//IDの取得
			const addressid = this.props.match.params.addressid;
			if(!addressid){
					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
			}

			//データ取得
			const Query = `query getAddress($cognitoid: String!, $addressid: String!) {
				getAddress(cognitoid: $cognitoid, addressid: $addressid) {
					cognitoid
					addressid
					o_name_1
					o_name_2
					o_name_furi_1
					o_name_furi_2
					o_zip_1
					o_zip_2
					o_pref
					o_addr_1
					o_addr_2
					o_addr_3
					o_tel_1
					o_tel_2
					o_tel_3
					o_com_name
					date_add
					date_edit

				}
			}`;
			const Data = {
				cognitoid: cognitoid,
				addressid: addressid,
			};
			const ref = await API.graphql(graphqlOperation(Query, Data));
			//console.log(ref);
			
			//データが空かどうか
			if(ref.data.getAddress === null){
				this.setState({
					isLoading   : false,
					isNotFound  : true,
				});
				return(0);
			}
			
			const o_name_1        = ref.data.getAddress.o_name_1;
			const o_name_2        = ref.data.getAddress.o_name_2;
			const o_name_furi_1   = ref.data.getAddress.o_name_furi_1;
			const o_name_furi_2   = ref.data.getAddress.o_name_furi_2;
			const o_zip_1         = ref.data.getAddress.o_zip_1;
			const o_zip_2         = ref.data.getAddress.o_zip_2;
			const o_pref          = ref.data.getAddress.o_pref;
			const o_addr_1        = ref.data.getAddress.o_addr_1;
			const o_addr_2        = ref.data.getAddress.o_addr_2;
			const o_addr_3        = ref.data.getAddress.o_addr_3 === 'null' ? '' : ref.data.getAddress.o_addr_3;
			const o_tel_1         = ref.data.getAddress.o_tel_1;
			const o_tel_2         = ref.data.getAddress.o_tel_2;
			const o_tel_3         = ref.data.getAddress.o_tel_3;
			const o_com_name      = ref.data.getAddress.o_com_name === 'null' ? '' : ref.data.getAddress.o_com_name;
			
			//日付
			const date_add      = new Date(ref.data.getAddress.date_add);
			const year          = date_add.getFullYear().toString();
			const month         = ('00' + (date_add.getMonth()+1).toString()).slice(-2);
			const day           = ('00' + date_add.getDate().toString()).slice(-2);
			const hour          = ('00' + date_add.getHours().toString()).slice(-2);
			const minute        = ('00' + date_add.getMinutes().toString()).slice(-2);
			const second        = ('00' + date_add.getSeconds().toString()).slice(-2);
			this.setState({
				isLoading     : false,
				cognitoid     : cognitoid,
				addressid     : addressid,
				o_name_1      : o_name_1,
				o_name_2      : o_name_2,
				o_name_furi_1 : o_name_furi_1,
				o_name_furi_2 : o_name_furi_2,
				o_zip_1       : o_zip_1,
				o_zip_2       : o_zip_2,
				o_pref        : o_pref,
				o_addr_1      : o_addr_1,
				o_addr_2      : o_addr_2,
				o_addr_3      : o_addr_3,
				o_tel_1       : o_tel_1,
				o_tel_2       : o_tel_2,
				o_tel_3       : o_tel_3,
				o_com_name    : o_com_name,
				date_add      : year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分' + second + '秒',
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLoading  : false,
				isLockForm : false,
			 });
			return(0);
		}
	}
	async updateSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowUpdate : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//Stateから取得する更新項目
			const cognitoid     = this.state.cognitoid;
			const addressid     = this.state.addressid;

			//Formから直接取得する更新項目
			const o_name_1      = formObj.o_name_1.value;
			const o_name_2      = formObj.o_name_2.value;
			const o_name_furi_1 = formObj.o_name_furi_1.value;
			const o_name_furi_2 = formObj.o_name_furi_2.value;
			const o_zip_1       = formObj.o_zip_1.value;
			const o_zip_2       = formObj.o_zip_2.value;
			const o_pref        = formObj.o_pref.value;
			const o_addr_1      = formObj.o_addr_1.value;
			const o_addr_2      = formObj.o_addr_2.value;
			const o_addr_3      = formObj.o_addr_3.value;
			const o_tel_1       = formObj.o_tel_1.value;
			const o_tel_2       = formObj.o_tel_2.value;
			const o_tel_3       = formObj.o_tel_3.value;
			const o_com_name    = formObj.o_com_name.value;

			//日付処理
		        const date          = new Date();
		        const isoDate       = date.toISOString();

			//プロフィールの更新登録
			const Query = `mutation updateAddress($data: AddressInput!) {
				updateAddress(input: $data) {
					cognitoid
				}
			}`;
			const Data = {
				data : {
					cognitoid     : cognitoid,
					addressid     : addressid,
					o_name_1      : o_name_1,
					o_name_2      : o_name_2,
					o_name_furi_1 : o_name_furi_1,
					o_name_furi_2 : o_name_furi_2,
					o_zip_1       : o_zip_1,
					o_zip_2       : o_zip_2,
					o_pref        : o_pref,
					o_addr_1      : o_addr_1,
					o_addr_2      : o_addr_2,
					o_addr_3      : o_addr_3 ? o_addr_3 : 'null',
					o_tel_1       : o_tel_1,
					o_tel_2       : o_tel_2,
					o_tel_3       : o_tel_3,
					o_com_name    : o_com_name ? o_com_name : 'null',
					date_edit     : isoDate,
				}
			};
			await API.graphql(graphqlOperation(Query, Data));

			//鑑査ログ
			await AuditLog('user', 'update', cognitoid, Data, 'アドレス情報を変更しました。');
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	async deleteSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowDelete : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Stateから取得する更新項目
			const cognitoid = this.state.cognitoid;
			const addressid = this.state.addressid;
			
			//プロフィールの更新登録
			const Query = `mutation deleteAddress($data: AddressInput!) {
				deleteAddress(input: $data) {
					cognitoid
					addressid
				}
			}`;
			const Data = {
				data : {
					cognitoid   : cognitoid,
					addressid   : addressid,
				}
			};
			await API.graphql(graphqlOperation(Query, Data));

			//鑑査ログ
			await AuditLog('user', 'update', cognitoid, Data, 'アドレス情報を削除しました。');
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isDeleted   : true,
			isLockForm  : false,
		});
	}
	handleClose() {
		this.setState({
			isShowUpdate : false,
			isShowDelete : false,
			isSuccess    : false,
			isValidated  : false,
			isLockForm   : false,
			ErrMsg     : '',
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleUpdate(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowUpdate : true,
			formObj      : form,
		});
	}
	handleDelete(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowDelete : true,
			formObj      : form,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else if(this.state.isNotFound){
			return (
				<NotFound />
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>更新しました。</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isDeleted} onHide={()=>{ this.props.history.push('/User/' + this.state.cognitoid + '/Address/'); }} centered>
						<Modal.Body>削除しました。</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={()=>{ this.props.history.push('/User/' + this.state.cognitoid + '/Address/'); }}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowUpdate} onHide={this.handleClose} centered>
						<Modal.Body>
							本当に更新しますか？<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.updateSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowDelete} onHide={this.handleClose} centered>
						<Modal.Body>本当に削除しますか？</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.deleteSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleUpdate(e)}>
					<Row>
						<Col sm={4}>
							<p><big>アドレス情報</big></p>
						</Col>
						<Col sm={5} className="text-right">
							<Button variant="dark" onClick={ this.handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							ユーザID
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="cognitoid">
							        <Form.Control name="cognitoid" type="text" defaultValue={this.state.cognitoid} size="lg" readOnly />
								<Form.Control.Feedback />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							登録日時
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="date_add">
							        <Form.Control name="date_add" type="text" defaultValue={this.state.date_add} size="lg" readOnly />
								<Form.Control.Feedback />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							お名前　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={3}>
							<Form.Group controlId="o_name_1">
							        <Form.Control name="o_name_1" type="text" defaultValue={this.state.o_name_1} placeholder="お名前（名字）" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「お名前（名字）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={3}>
							<Form.Group controlId="o_name_2">
							        <Form.Control name="o_name_2" type="text" defaultValue={this.state.o_name_2} placeholder="お名前（名前）" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「お名前（名前）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							フリガナ　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={3}>
							<Form.Group controlId="o_name_furi_1">
							        <Form.Control name="o_name_furi_1" type="text" defaultValue={this.state.o_name_furi_1} placeholder="フリガナ（名字）" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「フリガナ（名字）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={3}>
							<Form.Group controlId="o_name_furi_2">
							        <Form.Control name="o_name_furi_2" type="text" defaultValue={this.state.o_name_furi_2} placeholder="フリガナ（名前）" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「フリガナ（名前）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							郵便番号　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="o_zip_1">
							        <Form.Control name="o_zip_1" type="text" defaultValue={this.state.o_zip_1} placeholder="3桁" size="lg" required pattern="\d{3}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「郵便番号（3桁）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="o_zip_2">
							        <Form.Control name="o_zip_2" type="text" defaultValue={this.state.o_zip_2} placeholder="4桁" size="lg" required pattern="\d{4}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「郵便番号（4桁）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							都道府県　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="o_pref">
							        <Form.Control name="o_pref" type="text" defaultValue={this.state.o_pref} placeholder="都道府県" size="lg" required pattern=".{2,4}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「都道府県」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							住所（市区町村）　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="o_addr_1">
							        <Form.Control name="o_addr_1" type="text" defaultValue={this.state.o_addr_1} placeholder="市区町村" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「住所（市区町村）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							住所（番地）　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="o_addr_2">
							        <Form.Control name="o_addr_2" type="text" defaultValue={this.state.o_addr_2} placeholder="番地" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「住所（番地）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							住所（その他・建物）
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="o_addr_3">
							        <Form.Control name="o_addr_3" type="text" defaultValue={this.state.o_addr_3} placeholder="その他・建物" size="lg" pattern=".{0,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「住所（その他・建物）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							会社名
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="o_com_name">
							        <Form.Control name="o_com_name" type="text" defaultValue={this.state.o_com_name} placeholder="会社名" size="lg" pattern=".{0,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「会社名」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							電話番号　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="o_tel_1">
							        <Form.Control name="o_tel_1" type="text" defaultValue={this.state.o_tel_1} placeholder="市外局番" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「電話番号（市外局番）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="o_tel_2">
							        <Form.Control name="o_tel_2" type="text" defaultValue={this.state.o_tel_2} placeholder="市内局番" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「電話番号（市内局番）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="o_tel_3">
							        <Form.Control name="o_tel_3" type="text" defaultValue={this.state.o_tel_3} placeholder="番号" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「電話番号」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props.history.push('/User/' + this.state.cognitoid + '/Address/'); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					</Form>

					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleDelete(e)}>
					<Row style={{ marginTop: 100 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="danger" type="submit" block size="lg" disabled={this.state.isLockForm} >削除する</Button>
						</Col>
					</Row>
					</Form>

				</Container>
			);
		}
	}
}

export default withRouter(Default);


