import { API, graphqlOperation, Auth } from 'aws-amplify';

async function AuditLog(className, flagName, objName, objValue, logText)  {

	try{

		const user      = await Auth.currentAuthenticatedUser();
		const cognitoid = user.username;

		const createQuery = `mutation createAuditLog($data: AuditLogInput!) {
			createAuditLog(input: $data) {
				cognitoid
			}
		}`;
		const createData = {
			data : {
				cognitoid  : cognitoid,
				className  : className,
				flagName   : flagName,
				objName    : objName,
				objValue   : objValue,
				logText    : logText,
			}
		};
		await API.graphql(graphqlOperation(createQuery, createData));
	}
	catch(error) {
		console.log(error);
	}
	return 0;
}

export { AuditLog };

