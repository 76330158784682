import React from 'react';
import { Auth, API, Storage, graphqlOperation } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Modal,
	Badge,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import NotFound from '../../Objects/NotFound';
import { AuditLog } from '../../Objects/AuditLog';

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading       : true,
			isNotFound      : false,
			isValidated     : false,
			isNextPage      : false,
			isLockForm      : false,
			formObj         : null,
			ErrMsg          : '',
			orderno         : '',
			cognitoid       : '',
			total_price     : '',
			payment_type    : '',
			payment_price   : '',
			carriage_type   : '',
			carriage        : '',
			wrapping_type   : '',
			wrapping        : 0,
			coupon_type     : '',
			coupon          : 0,
			cashdeli        : '',
			email           : '',
			name_1          : '',
			name_2          : '',
			name_furi_1     : '',
			name_furi_2     : '',
			zip_1           : '',
			zip_2           : '',
			pref            : '',
			addr_1          : '',
			addr_2          : '',
			addr_3          : '',
			tel_1           : '',
			tel_2           : '',
			tel_3           : '',
			o_name_1        : '',
			o_name_2        : '',
			o_name_furi_1   : '',
			o_name_furi_2   : '',
			o_zip_1         : '',
			o_zip_2         : '',
			o_pref          : '',
			o_addr_1        : '',
			o_addr_2        : '',
			o_addr_3        : '',
			o_tel_1         : '',
			o_tel_2         : '',
			o_tel_3         : '',
			o_com_name      : '',
			o_date_type     : '',
			o_date_h        : '',
			order_memo      : '',
			delivery_no     : '',
			delivery_date   : '',
			status          : '',
			memo            : '',
			flag            : '',
			not_ec_user_kbn : '',
			date_add        : '',
			order_items     : [],
			tmpl_name       : '',
			to              : '',
			from            : '',
			cc              : '',
			bcc             : '',
			subject         : '',
			msg             : '',
			newData         : [],
			viewData        : [],
			last_modified   : null,
		}
		this._backTop         = this._backTop.bind(this);
		this.handleReload     = this.handleReload.bind(this);
		this.handleSubmit     = this.handleSubmit.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
		this.lockFormClose    = this.lockFormClose.bind(this);
		this._getData         = this._getData.bind(this);
		this.selectTmpl       = this.selectTmpl.bind(this);
	}
	_backTop() {
		//子ウィンドウからの呼び出し用
		this.setState({
			isNextPage  : false,
		});
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
			isNextPage  : false,
		});
		this._getData();
	}
	async _getData() {

		try{

			//IDの取得
			const orderno = this.props.match.params.orderno;
			//console.log(orderno);
			if(!orderno){
					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
			}
			
			let selectIDs = [];
			const newData   = [];
			let last_modified = null;

			if(orderno === 'Multiple'){
				try{
					//ストレージを定義
					Storage.configure({ AWSS3: { bucket: 'mindwavestore.admin', region: 'ap-northeast-1', level: 'private' } });

					//S3からファイルを取得
					const result   = await Storage.get('mailSend.json', { download: true });
					last_modified = result.LastModified;
					//console.log(result.LastModified);

					//データ
					const jsonText = await new Promise(resolve =>
					{
						const reader = new FileReader();
						reader.onload = () => resolve(reader.result);
						reader.readAsText(result.Body);
					});

					//JSON形式を変換
					const jsonData = JSON.parse(jsonText);

					//データ取得
					selectIDs = jsonData.selectIDs;
					
					//console.log(selectIDs);
					
					//1件だったら複数送信画面から通常送信画面に移行
					if(selectIDs.length === 1){
						window.location.href = '/Order/' + selectIDs[0] + '/MailSend/';
						return(0)
					}
				}
				catch(err){
					//console.log(err);
				}
			}
			else{
				selectIDs.push(orderno);
			}
			//console.log(last_modified);
			
			if(selectIDs.length === 0 ){
				this.setState({
					isLoading   : false,
					isNotFound  : true,
				});
				return(0);
			}
			
			//データ取得
			for(const orderno of selectIDs){
				const OrderQuery = `query getOrder($orderno: String!) {
					getOrder(orderno: $orderno) {
						cognitoid
						total_price
						payment_type
						payment_price
						carriage_type
						carriage
						cashdeli
						wrapping_type
						wrapping
						coupon_type
						coupon
						email
						name_1
						name_2
						name_furi_1
						name_furi_2
						zip_1
						zip_2
						pref
						addr_1
						addr_2
						addr_3
						tel_1
						tel_2
						tel_3
						o_name_1
						o_name_2
						o_name_furi_1
						o_name_furi_2
						o_zip_1
						o_zip_2
						o_pref
						o_addr_1
						o_addr_2
						o_addr_3
						o_tel_1
						o_tel_2
						o_tel_3
						o_com_name
						o_date_type
						o_date_h
						order_memo
						delivery_no
						delivery_date
						status
						memo
						flag
						not_ec_user_kbn
						date_add
						order_items{
							orderno
							code
							name
							price
							sub_price
							piece
							piece_type
						}
					}
				}`;
				const OrderData = { orderno: orderno };
				const refOrder  = await API.graphql(graphqlOperation(OrderQuery, OrderData));
				let   item      = refOrder.data.getOrder;
				//console.log(item.email);
				
				//データが空かどうか
				if(item === null){
				
					//データ取得
					const OrderQuery = `query getOldOrder($orderno: String!) {
						getOldOrder(orderno: $orderno) {
							cognitoid
							total_price
							payment_type
							payment_price
							carriage_type
							carriage
							cashdeli
							wrapping_type
							wrapping
							coupon_type
							coupon
							email
							name_1
							name_2
							name_furi_1
							name_furi_2
							zip_1
							zip_2
							pref
							addr_1
							addr_2
							addr_3
							tel_1
							tel_2
							tel_3
							o_name_1
							o_name_2
							o_name_furi_1
							o_name_furi_2
							o_zip_1
							o_zip_2
							o_pref
							o_addr_1
							o_addr_2
							o_addr_3
							o_tel_1
							o_tel_2
							o_tel_3
							o_com_name
							o_date_type
							o_date_h
							order_memo
							delivery_no
							delivery_date
							status
							memo
							flag
							not_ec_user_kbn
							date_add
							order_items{
								orderno
								code
								name
								price
								sub_price
								piece
								piece_type
							}
						}
					}`;
					const OrderData = { orderno: orderno };
					const refOrder = await API.graphql(graphqlOperation(OrderQuery, OrderData));
					item      = refOrder.data.getOldOrder;
					//console.log(item);
					
					//データが空かどうか
					if(item === null){

						this.setState({
							isLoading   : false,
							isNotFound  : true,
						});
						return(0);
					}
				}
				
				const cognitoid     = item.cognitoid;
				const total_price   = item.total_price;
				const payment_type  = item.payment_type;
				const payment_price = item.payment_price;
				const carriage_type = item.carriage_type;
				const carriage      = item.carriage;
				const cashdeli      = item.cashdeli;
				const wrapping_type = item.wrapping_type;
				const wrapping      = item.wrapping;
				let   coupon_type   = item.coupon_type;
				const coupon        = item.coupon;
				let   email         = item.email;
				const name_1        = item.name_1;
				const name_2        = item.name_2;
				const name_furi_1   = item.name_furi_1;
				const name_furi_2   = item.name_furi_2;
				const zip_1         = item.zip_1;
				const zip_2         = item.zip_2;
				const pref          = item.pref;
				const addr_1        = item.addr_1;
				const addr_2        = item.addr_2;
				let   addr_3        = item.addr_3;
				const tel_1         = item.tel_1;
				const tel_2         = item.tel_2;
				const tel_3         = item.tel_3;
				const o_name_1      = item.o_name_1;
				const o_name_2      = item.o_name_2;
				const o_name_furi_1 = item.o_name_furi_1;
				const o_name_furi_2 = item.o_name_furi_2;
				const o_zip_1       = item.o_zip_1;
				const o_zip_2       = item.o_zip_2;
				const o_pref        = item.o_pref;
				const o_addr_1      = item.o_addr_1;
				const o_addr_2      = item.o_addr_2;
				let   o_addr_3      = item.o_addr_3;
				const o_tel_1       = item.o_tel_1;
				const o_tel_2       = item.o_tel_2;
				const o_tel_3       = item.o_tel_3;
				let   o_com_name    = item.o_com_name;
				const o_date_type   = item.o_date_type;
				const o_date_h      = item.o_date_h
				let   order_memo    = item.order_memo;
				let   delivery_no   = item.delivery_no;
				let   delivery_date = item.delivery_date;
				const status        = item.status;
				let   memo          = item.memo;
				const flag          = item.flag;
				const not_ec_user_kbn = item.not_ec_user_kbn;
				const order_items   = item.order_items;
				
				//日付
				const date_add         = new Date(item.date_add);
				const date_add_year    = date_add.getFullYear().toString();
				const date_add_month   = ('00' + (date_add.getMonth()+1).toString()).slice(-2);
				const date_add_day     = ('00' + date_add.getDate().toString()).slice(-2);
				const date_add_hour    = ('00' + date_add.getHours().toString()).slice(-2);
				const date_add_minute  = ('00' + date_add.getMinutes().toString()).slice(-2);
				const date_add_second  = ('00' + date_add.getSeconds().toString()).slice(-2);
				
				//会員情報があればそちらのメールアドレスを使用する
				/*
				if(not_ec_user_kbn === false){
					const ProfileQuery = `query getProfile($cognitoid: String!) {
						getProfile(cognitoid: $cognitoid) {
							email
						}
					}`;
					const ProfileData = { cognitoid: cognitoid };
					const refProfile = await API.graphql(graphqlOperation(ProfileQuery, ProfileData));
					if(refProfile.data.getProfile !== null){
						email = refProfile.data.getProfile.email;
					}
					
				}
				*/
				
				//'null'の場合空文字に変更
				if(coupon_type   === 'null') { coupon_type = '使用しない'; }
				if(email         === 'null') { email = ''; }
				if(addr_3        === 'null') { addr_3 = ''; }
				if(o_addr_3      === 'null') { o_addr_3 = ''; }
				if(o_com_name    === 'null') { o_com_name = ''; }
				if(order_memo    === 'null') { order_memo = ''; }
				if(delivery_no   === 'null') { delivery_no = ''; }
				if(delivery_date === 'null') { delivery_date = ''; }
				if(memo          === 'null') { memo = ''; }

				//nullの場合空文字に変更
				if(coupon_type   === null) { coupon_type = '使用しない'; }
				if(email         === null) { email = ''; }
				if(addr_3        === null) { addr_3 = ''; }
				if(o_addr_3      === null) { o_addr_3 = ''; }
				if(o_com_name    === null) { o_com_name = ''; }
				if(order_memo    === null) { order_memo = ''; }
				if(delivery_no   === null) { delivery_no = ''; }
				if(delivery_date === null) { delivery_date = ''; }
				if(memo          === null) { memo = ''; }

				//アドレスがあるか
				if(email === ''){
					this.setState({
						isLoading : false,
						ErrMsg    : '※Eメールアドレスの情報がありません。',
					});
					return(0);
				}
				
				//データ保存
				newData.push({
					orderno         : orderno,
					cognitoid       : cognitoid       ,
					total_price     : total_price     ,
					payment_type    : payment_type    ,
					payment_price   : payment_price   ,
					carriage_type   : carriage_type   ,
					carriage        : carriage        ,
					cashdeli        : cashdeli        ,
					wrapping_type   : wrapping_type   ,
					wrapping        : wrapping        ,
					coupon_type     : coupon_type     ,
					coupon          : coupon          ,
					email           : email           ,
					name_1          : name_1          ,
					name_2          : name_2          ,
					name_furi_1     : name_furi_1     ,
					name_furi_2     : name_furi_2     ,
					zip_1           : zip_1           ,
					zip_2           : zip_2           ,
					pref            : pref            ,
					addr_1          : addr_1          ,
					addr_2          : addr_2          ,
					addr_3          : addr_3          ,
					tel_1           : tel_1           ,
					tel_2           : tel_2           ,
					tel_3           : tel_3           ,
					o_name_1        : o_name_1        ,
					o_name_2        : o_name_2        ,
					o_name_furi_1   : o_name_furi_1   ,
					o_name_furi_2   : o_name_furi_2   ,
					o_zip_1         : o_zip_1         ,
					o_zip_2         : o_zip_2         ,
					o_pref          : o_pref          ,
					o_addr_1        : o_addr_1        ,
					o_addr_2        : o_addr_2        ,
					o_addr_3        : o_addr_3        ,
					o_tel_1         : o_tel_1         ,
					o_tel_2         : o_tel_2         ,
					o_tel_3         : o_tel_3         ,
					o_com_name      : o_com_name      ,
					o_date_type     : o_date_type     ,
					o_date_h        : o_date_h        ,
					order_memo      : order_memo      ,
					delivery_no     : delivery_no     ,
					delivery_date   : delivery_date   ,
					status          : status          ,
					memo            : memo            ,
					flag            : flag            ,
					not_ec_user_kbn : not_ec_user_kbn ,
					date_add        : date_add_year + '年' + date_add_month + '月' + date_add_day + '日' + date_add_hour + '時' + date_add_minute + '分' + date_add_second + '秒',
					order_items     : order_items,
				});

			}
			//console.log(newData);
			
			//情報の取得
			const MailTmplQuery = `query scanMailTmpl {
				scanMailTmpl {
					items {
						tmpl_name
						tmpl_sort
						tmpl_from
						tmpl_cc
						tmpl_bcc
						tmpl_subject
						tmpl_msg
					}
					nextToken
				}
			}`;
			let refMailTmpl = await API.graphql(graphqlOperation(MailTmplQuery));
			let Items = refMailTmpl.data.scanMailTmpl.items;
			
			
			//情報の取得（未取得の続きデータがあった場合の処理）
			while (refMailTmpl.data.scanMailTmpl.nextToken) {
				const MailTmplQuery = `query scanMailTmpl($nextToken: String) {
					scanMailTmpl(nextToken: $nextToken) {
						items {
							tmpl_name
							tmpl_sort
							tmpl_from
							tmpl_cc
							tmpl_bcc
							tmpl_subject
							tmpl_msg
						}
						nextToken
					}
				}`;
				const MailTmplData = {
					nextToken: refMailTmpl.data.scanMailTmpl.nextToken,
				};
				refMailTmpl = await API.graphql(graphqlOperation(MailTmplQuery, MailTmplData));
				Items = Items.concat(refMailTmpl.data.scanMailTmpl.items);
			}

			//データの判別
			if(Items.length === 0){
				//データがなかった時
				this.setState({
					isLoading : false,
					ErrMsg    : 'メール送信テンプレートのデータがありません。',
				});
				return(0);
			}

			//'null'文字がある場合は''に変換
			Items.some((item, index) => Object.keys(item).some(key => { if(item[key] === 'null'){ Items[index][key] = '' } return false; }));
			
			//データコピー
			const viewData = [];
			for(let row of Items){
				viewData.push(row);
			}
			
			//データをソート
			viewData.sort(
				(a, b) => {
					if (a.tmpl_sort < b.tmpl_sort) return -1;
					if (a.tmpl_sort > b.tmpl_sort) return 1;
					return 0;
				}
			);
			
			//console.log(newData);
			
			this.setState({
				isLoading       : false,
				newData         : newData,
				viewData        : viewData,
				last_modified   : last_modified,
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLoading  : false,
				isLockForm : false,
			 });
			return(0);
		}
	}
	async handleSubmit (event) {

		event.preventDefault();
		event.stopPropagation();

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formから直接取得する更新項目
			const to      = formObj.to.value;
			const from    = formObj.from.value;
			const cc      = formObj.cc.value;
			const bcc     = formObj.bcc.value;
			const subject = formObj.subject.value;
			const msg     = formObj.msg.value;

			//stateから取得
			const newData   = this.state.newData;
			const tmpl_name = this.state.tmpl_name;
			
			let createData = [];
			for(const item of newData){
				const orderno        = item.orderno;
				const order_items    = item.order_items;
				let order_items_text = '';
				let i = 0;
				for(let row of order_items){
					i++;
					const code       = row.code;
					const name       = row.name;
					const price      = row.price;
					const sub_price  = row.sub_price;
					const piece      = row.piece;
					const piece_type = row.piece_type;
					
					order_items_text = order_items_text + '　' + i + '\n';
					order_items_text = order_items_text + '　商品番号:' + code + '\n';
					order_items_text = order_items_text + '　商品名:' + name + '\n';
					order_items_text = order_items_text + '　金額(税込):' + price.toLocaleString() + '円\n';
					order_items_text = order_items_text + '　個数:' + piece.toLocaleString() + piece_type + '\n';
					order_items_text = order_items_text + '　小計(税込):' + sub_price.toLocaleString() + '円\n';
					order_items_text = order_items_text + '　\n';
				}
				
				//shop_comment取得
				let shop_comment = '';
				const Query = `query getOrder($orderno: String!) {
					getOrder(orderno: $orderno) {
						shop_comment
					}
				}`;
				const Data = { orderno: orderno };
				const ref = await API.graphql(graphqlOperation(Query, Data));
				let shop_comment_item = ref.data.getOrder;
				//データが空かどうか
				if(shop_comment_item === null){
					//データ取得
					const Query = `query getOldOrder($orderno: String!) {
						getOldOrder(orderno: $orderno) {
							shop_comment
						}
					}`;
					const Data = { orderno: orderno };
					const ref = await API.graphql(graphqlOperation(Query, Data));
					shop_comment_item = ref.data.getOldOrder;
				}
				//データが空かどうか
				if(shop_comment_item !== null && shop_comment_item.shop_comment !== null){
					shop_comment  = shop_comment_item.shop_comment === 'null' ? '' : shop_comment_item.shop_comment;
				}
				
				let tmp_msg = msg;
				//console.log(msg);
				//console.log(item.email);

				//置換
				tmp_msg = tmp_msg.replace(/##email##/g, item.email);
				tmp_msg = tmp_msg.replace(/##orderno##/g, item.orderno);
				tmp_msg = tmp_msg.replace(/##cognitoid##/g,item.cognitoid);
				tmp_msg = tmp_msg.replace(/##total_price##/g,item.total_price.toLocaleString());
				tmp_msg = tmp_msg.replace(/##payment_type##/g,item.payment_type);
				tmp_msg = tmp_msg.replace(/##payment_price##/g,item.payment_price.toLocaleString());
				tmp_msg = tmp_msg.replace(/##carriage_type##/g,item.carriage_type);
				tmp_msg = tmp_msg.replace(/##carriage##/g,item.carriage.toLocaleString());
				tmp_msg = tmp_msg.replace(/##cashdeli##/g,item.cashdeli.toLocaleString());
				tmp_msg = tmp_msg.replace(/##wrapping_type##/g,item.wrapping_type);
				tmp_msg = tmp_msg.replace(/##wrapping##/g,item.wrapping.toLocaleString());
				tmp_msg = tmp_msg.replace(/##coupon_type##/g,item.coupon_type);
				tmp_msg = tmp_msg.replace(/##coupon##/g,item.coupon.toLocaleString());
				tmp_msg = tmp_msg.replace(/##cardid##/g,item.cardid);
				tmp_msg = tmp_msg.replace(/##email##/g,item.email);
				tmp_msg = tmp_msg.replace(/##name_1##/g,item.name_1);
				tmp_msg = tmp_msg.replace(/##name_2##/g,item.name_2);
				tmp_msg = tmp_msg.replace(/##name_furi_1##/g,item.name_furi_1);
				tmp_msg = tmp_msg.replace(/##name_furi_2##/g,item.name_furi_2);
				tmp_msg = tmp_msg.replace(/##zip_1##/g,item.zip_1);
				tmp_msg = tmp_msg.replace(/##zip_2##/g,item.zip_2);
				tmp_msg = tmp_msg.replace(/##pref##/g,item.pref);
				tmp_msg = tmp_msg.replace(/##addr_1##/g,item.addr_1);
				tmp_msg = tmp_msg.replace(/##addr_2##/g,item.addr_2);
				tmp_msg = tmp_msg.replace(/##addr_3##/g,item.addr_3);
				tmp_msg = tmp_msg.replace(/##tel_1##/g,item.tel_1);
				tmp_msg = tmp_msg.replace(/##tel_2##/g,item.tel_2);
				tmp_msg = tmp_msg.replace(/##tel_3##/g,item.tel_3);
				tmp_msg = tmp_msg.replace(/##o_name_1##/g,item.o_name_1);
				tmp_msg = tmp_msg.replace(/##o_name_2##/g,item.o_name_2);
				tmp_msg = tmp_msg.replace(/##o_name_furi_1##/g,item.o_name_furi_1);
				tmp_msg = tmp_msg.replace(/##o_name_furi_2##/g,item.o_name_furi_2);
				tmp_msg = tmp_msg.replace(/##o_zip_1##/g,item.o_zip_1);
				tmp_msg = tmp_msg.replace(/##o_zip_2##/g,item.o_zip_2);
				tmp_msg = tmp_msg.replace(/##o_pref##/g,item.o_pref);
				tmp_msg = tmp_msg.replace(/##o_addr_1##/g,item.o_addr_1);
				tmp_msg = tmp_msg.replace(/##o_addr_2##/g,item.o_addr_2);
				tmp_msg = tmp_msg.replace(/##o_addr_3##/g,item.o_addr_3);
				tmp_msg = tmp_msg.replace(/##o_tel_1##/g,item.o_tel_1);
				tmp_msg = tmp_msg.replace(/##o_tel_2##/g,item.o_tel_2);
				tmp_msg = tmp_msg.replace(/##o_tel_3##/g,item.o_tel_3);
				tmp_msg = tmp_msg.replace(/##o_com_name##/g,item.o_com_name);
				tmp_msg = tmp_msg.replace(/##o_date_type##/g,item.o_date_type);
				tmp_msg = tmp_msg.replace(/##o_date_h##/g,item.o_date_h);
				tmp_msg = tmp_msg.replace(/##order_memo##/g,item.order_memo);
				tmp_msg = tmp_msg.replace(/##delivery_no##/g,item.delivery_no);
				tmp_msg = tmp_msg.replace(/##delivery_date##/g,item.delivery_date);
				tmp_msg = tmp_msg.replace(/##status##/g,item.status);
				tmp_msg = tmp_msg.replace(/##memo##/g,item.memo);
				tmp_msg = tmp_msg.replace(/##flag##/g,item.flag);
				tmp_msg = tmp_msg.replace(/##not_ec_user_kbn##/g,item.not_ec_user_kbn);
				tmp_msg = tmp_msg.replace(/##order_items##/g,order_items_text);
				tmp_msg = tmp_msg.replace(/##date_add##/g,item.date_add);
				tmp_msg = tmp_msg.replace(/##shopcomment##/g,shop_comment);
				
				//console.log(tmp_msg);
				item.tmpl_name = tmpl_name;
				item.from      = from;
				item.cc        = cc;
				item.bcc       = bcc;
				item.subject   = subject;
				item.msg       = tmp_msg;
				
				//差出人
				if(newData.length === 1){
					item.to = to;
				}
				else{
					item.to = item.email;
				}
				
				createData.push(item);

			}
			//console.log(createData);

			//更新成功
			this.setState({
				isNextPage  : true,
				isLockForm  : false,
				isValidated : false,
				newData     : createData,
				tmpl_name   : tmpl_name,
				from        : from,
				cc          : cc,
				bcc         : bcc,
				subject     : subject,
				msg         : msg,
			});

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	selectTmpl(e){
		const viewData     = this.state.viewData;
		const tmpl_name    = e.target.value;
		let   tmpl_from    = '';
		let   tmpl_cc      = '';
		let   tmpl_bcc     = '';
		let   tmpl_subject = '';
		let   tmpl_msg     = '';
		
		for(let row of viewData){
			if(tmpl_name === row.tmpl_name){
				tmpl_from    = row.tmpl_from;
				tmpl_cc      = row.tmpl_cc;
				tmpl_bcc     = row.tmpl_bcc;
				tmpl_subject = row.tmpl_subject;
				tmpl_msg     = row.tmpl_msg;
				break;
			}
		}
		
		this.setState({
			tmpl_name : tmpl_name,
			from      : tmpl_from,
			cc        : tmpl_cc,
			bcc       : tmpl_bcc,
			subject   : tmpl_subject,
			msg       : tmpl_msg,
		});
		document.form_mail.tmpl_name.value = tmpl_name;
		document.form_mail.from.value      = tmpl_from;
		document.form_mail.cc.value        = tmpl_cc;
		document.form_mail.bcc.value       = tmpl_bcc;
		document.form_mail.subject.value   = tmpl_subject;
		document.form_mail.msg.value       = tmpl_msg;
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else if(this.state.isNotFound){
			return (
				<NotFound />
			);
		}
		else if(this.state.isNextPage){
			return (
				<NextPage _backTop={this._backTop} {...this.state}  {...this.props} />
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>

					<Form>
					<Row style={{ marginTop: 10 }}>
						<Col sm={11} className="text-right">
						        <Button variant="dark" type="button" size="lg" disabled={this.state.isLockForm} href={'/Order/' + (this.state.newData.length === 1 ? this.state.newData[0].orderno : 'Multiple') + '/MailSendLog/'} target="_blank">
						        	過去の送信履歴を見る
						        </Button>
						</Col>
					</Row>
					</Form>

					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)} name="form_mail">
					<Row>
						<Col sm={12}>
							<p><big>メール送信</big></p>
						</Col>
					</Row>
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							受注番号
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="orderno">
							        <Form.Control name="orderno" type="text" defaultValue={this.state.newData.length === 1 ? this.state.newData[0].orderno : 'Multiple(' + this.state.newData.length +') - ' + this.state.last_modified } size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							購入者名
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="name">
							        <Form.Control name="name" type="text" defaultValue={this.state.newData.length === 1 ? this.state.newData[0].name_1 + ' ' +this.state.newData[0].name_2 : 'Multiple(' + this.state.newData.length +') - ' + this.state.last_modified } size="lg" readOnly />
							</Form.Group>
						</Col>
					</Row>

					<Row>
						<Form.Label column sm={3}>
							テンプレート選択　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="tmpl_name">
							        <Form.Control name="tmpl_name" type="text" defaultValue={this.state.tmpl_name} size="lg" as="select" required disabled={this.state.isLockForm} onChange={this.selectTmpl}>
								<option value="">---選択してください---</option>
								{
									this.state.viewData.map(
										(value, index)=> {
											return(
												<option key={index} value={value.tmpl_name}>{value.tmpl_name}</option>
											);
										}
									)
								}
								</Form.Control>
								<Form.Control.Feedback type="invalid">
									「テンプレート」を選択してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							購入者アドレス　<Badge variant="danger">{ this.state.newData.length === 1 ? '必須' : ''}</Badge>
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="to">
							        <Form.Control name="to" type="email" defaultValue={this.state.newData.length === 1 ? this.state.newData[0].email : 'Multiple(' + this.state.newData.length +') - ' + this.state.last_modified } placeholder="購入者アドレス" size="lg" required={this.state.newData.length === 1 ? true : false} readOnly={this.state.newData.length === 1 ? false : true} disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「購入者アドレス」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							差出人アドレス　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="from">
							        <Form.Control name="from" type="email" defaultValue={this.state.from} placeholder="差出人アドレス" size="lg" required disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「差出人アドレス」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							CCアドレス
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="cc">
							        <Form.Control name="cc" type="email" defaultValue={this.state.cc} placeholder="CCアドレス" size="lg" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「CCアドレス」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							BCCアドレス
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="bcc">
							        <Form.Control name="bcc" type="email" defaultValue={this.state.bcc} placeholder="BCCアドレス" size="lg" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「BCCアドレス」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メール件名　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="subject">
							        <Form.Control name="subject" type="text" defaultValue={this.state.subject} placeholder="メール件名" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「メール件名」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メール本文　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={8}>
							<Form.Group controlId="msg">
							        <Form.Control name="msg" as="textarea" defaultValue={this.state.msg ? this.state.msg : 0 } rows="15" placeholder="" size="lg" required pattern=".{1,1000}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「メール本文」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >プレビューを確認する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props.history.push('/MailTmpl/'); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					</Form>


				</Container>
			);
		}
	}
}

class NextPage extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isValidated     : false,
			isSuccess       : false,
			isShowModal     : false,
			isLockForm      : false,
			ErrMsg          : '',
		}
		this.handleSubmit     = this.handleSubmit.bind(this);
		this.showModal        = this.showModal.bind(this);
		this.handleClose      = this.handleClose.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
		this.lockFormClose    = this.lockFormClose.bind(this);
	}
	async handleSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal  : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
		
			//関数
			const sleep = sec => new Promise(resolve => setTimeout(resolve, sec * 1000));

			//Cognito IDの取得
			const user      = await Auth.currentAuthenticatedUser();
			const cognitoid = user.username;

			//propsから取得する更新項目
			const newData = this.props.newData;
			
			//バウンス確認
			for(const item of newData){
			
				const to      = item.to;

				//バウンス対象か確認
				const BounceQuery = `query queryMailBounceLog($email: String!) {
					queryMailBounceLog(email: $email) {
						scannedCount
					}
				}`;
				const BounceData  = { email: to };
				const refBounce   = await API.graphql(graphqlOperation(BounceQuery, BounceData));
				const countBounce = refBounce.data.queryMailBounceLog.scannedCount;
				if(countBounce > 0){
					this.setState({
						ErrMsg     : '宛先がバウンスメールログに存在します。(' + to + ')<br/>',
						isLockForm : false,
					 });
					return(0);
				}
				//console.log(refBounce);
				//console.log(countBounce);

				//ブロック対象か確認
				const ComplaintQuery = `query queryMailComplaintLog($email: String!) {
					queryMailComplaintLog(email: $email) {
						scannedCount
					}
				}`;
				const ComplaintData  = { email: to };
				const refComplaint   = await API.graphql(graphqlOperation(ComplaintQuery, ComplaintData));
				const countComplaint = refComplaint.data.queryMailComplaintLog.scannedCount;
				if(countComplaint > 0){
					this.setState({
						ErrMsg     : '宛先がブロックメールログに存在します。(' + to + ')<br/>',
						isLockForm : false,
					 });
					return(0);
				}
				//console.log(refComplaint);
				//console.log(countComplaint);
			}

			//メール送信
			for(const item of newData){
			
				const orderno = item.orderno;
				const to      = item.to;
				const from    = item.from;
				const cc      = item.cc;
				const bcc     = item.bcc;
				const subject = item.subject;
				const msg     = item.msg;

				//更新登録
				const Query = `mutation executeMailSend($data: MailSendInput!) {
					executeMailSend(input: $data) {
						result
						error
					}
				}`;
				const Data = {
					data : {
						orderno   : orderno,
						to        : to,
						from      : from,
						cc        : cc,
						bcc       : bcc,
						subject   : subject,
						msg       : msg,
						cognitoid : cognitoid,
					}
				};
				const ref = await API.graphql(graphqlOperation(Query, Data));
				const result = ref.data.executeMailSend.result;
				const error = ref.data.executeMailSend.error;
				const MessageId = result.replace(/^.*MessageId=/,'').replace(/}.*$/,'');
				if((!MessageId)|| error){
					this.setState({
						ErrMsg     : 'メールの送信に失敗しました。',
						isLockForm : false,
					 });
					return(0);
				}

				//鑑査ログ
				await AuditLog('mail', 'send', to, Data, 'メールを送信しました。');
				
				//wait
				await sleep(1);
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}
		
		//ストレージを定義
		Storage.configure({ AWSS3: { bucket: 'mindwavestore.admin', region: 'ap-northeast-1', level: 'private' } });

		//S3削除
		try{
			await Storage.remove('mailSend.json');
		}
		catch(err){
			//console.log(err);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	handleClose() {
		this.setState({
			isShowModal  : false,
			isSuccess    : false,
			isValidated  : false,
			isLockForm   : false,
			ErrMsg     : '',
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	showModal(event) {
		event.preventDefault();
		event.stopPropagation();
		this.setState({
			isShowModal  : true,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		//
	}
	render() {
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
					<Modal.Body>
						エラーがありました。<br/>
						{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={this.handleErrorClose}>
							ＯＫ
						</Button>
					</Modal.Footer>
				</Modal>
				<Modal show={this.state.isSuccess} onHide={()=>{ this.props.history.push('/Order/'); }} centered>
					<Modal.Body>
						送信しました。
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={()=>{ this.props.history.push('/Order/'); }}>
							ＯＫ
						</Button>
					</Modal.Footer>
				</Modal>
				<Modal show={this.state.isShowModal} onHide={this.handleClose} centered>
					<Modal.Body>
						本当に送信しますか？<br/>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							いいえ
						</Button>
						<Button variant="primary" onClick={this.handleSubmit}>
							はい
						</Button>
					</Modal.Footer>
				</Modal>
				<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
					<Modal.Body className="text-center">
						<Image src="/img/loading.gif" />　
						データを処理中です。
					</Modal.Body>
				</Modal>

				<Form noValidate validated={this.state.isValidated} onSubmit={e => this.showModal(e)}>
				<Row>
					<Col sm={12}>
						<p><big>メール送信</big></p>
					</Col>
				</Row>
				{this.props.newData.map((item, index) => 
					<div key={index}>
						<hr/>
						<Row>
							<Form.Label column sm={3}>
								受注番号
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="orderno">
								        <Form.Control name="orderno" type="text" defaultValue={item.orderno} size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								購入者名
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="name">
								        <Form.Control name="name" type="text" defaultValue={item.name_1 + ' ' + item.name_2} size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Form.Label column sm={3}>
								購入者アドレス
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="to">
								        <Form.Control name="to" type="email" defaultValue={item.to} placeholder="購入者アドレス" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								差出人アドレス
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="from">
								        <Form.Control name="from" type="email" defaultValue={item.from} placeholder="差出人アドレス" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								CCアドレス
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="cc">
								        <Form.Control name="cc" type="email" defaultValue={item.cc} placeholder="CCアドレス" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								BCCアドレス
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="bcc">
								        <Form.Control name="bcc" type="email" defaultValue={item.bcc} placeholder="BCCアドレス" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								メール件名
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="subject">
								        <Form.Control name="subject" type="email" defaultValue={item.subject} placeholder="BCCアドレス" size="lg" readOnly />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								メール本文
							</Form.Label>
							<Col sm={8}>
								<pre style={{ border: 'solid 1px gray', padding: '2em', marginTop: 20 }}>
								{item.msg }
								</pre>
							</Col>
						</Row>
					</div>
				)}
				<hr/>
				<Row style={{ marginTop: 10 }}>
					<Col sm={{ span: 6, offset: 3 }}>
					        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >送信する</Button>
					</Col>
				</Row>
				<Row style={{ marginTop: 10 }}>
					<Col sm={{ span: 6, offset: 3 }}>
					        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props._backTop(); }} disabled={this.state.isLockForm} >戻る</Button>
					</Col>
				</Row>
				</Form>


			</Container>
		);
	}
}

export default withRouter(Default);


