import React from 'react';
import Amplify from 'aws-amplify';
import AwsExports from './include/AwsExports';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { withAuthenticator } from './Objects/Auth';
import Header from './Objects/Header';
import Footer from './Objects/Footer';
import SignOut from './Objects/SignOut';
import NotFound from './Objects/NotFound';
import Main from './Pages/Main';
import LoginMain from './Pages/Login/Main';
import LoginEmail from './Pages/Login/Email';
import LoginEmailVerify from './Pages/Login/EmailVerify';
import LoginPassword from './Pages/Login/Password';
import UserMain from './Pages/User/Main';
import UserNew from './Pages/User/New';
import UserDetails from './Pages/User/Details';
import UserAddressMain from './Pages/User/Address/Main';
import UserAddressNew from './Pages/User/Address/New';
import UserAddressDetails from './Pages/User/Address/Details';
import ItemMain from './Pages/Item/Main';
import ItemNew from './Pages/Item/New';
import ItemDetails from './Pages/Item/Details';
import ItemCategory from './Pages/Item/Category';
import CategoryMain from './Pages/Category/Main';
import OrderMain from './Pages/Order/Main';
import OrderDetails from './Pages/Order/Details';
import OrderItem from './Pages/Order/Item';
import OrderNewMain from './Pages/Order/New/Main';
import OrderNewCart from './Pages/Order/New/Cart';
import OrderFormA from './Pages/Order/FormA';
import OrderFormB from './Pages/Order/FormB';
import OrderMailSend from './Pages/Order/MailSend';
import OrderMailSendLog from './Pages/Order/MailSendLog';
import OrderMailSendLogDetails from './Pages/Order/MailSendLogDetails';
import CSVUploadMain from './Pages/CSVUpload/Main';
import CSVUploadLog from './Pages/CSVUpload/Log';
import SettingMain from './Pages/Setting/Main';
import MailTmplMain from './Pages/MailTmpl/Main';
import MailTmplNew from './Pages/MailTmpl/New';
import MailTmplDetails from './Pages/MailTmpl/Details';
import MailLogBounce from './Pages/MailLog/Bounce';
import MailLogComplaint from './Pages/MailLog/Complaint';
import MailMagazineMain from './Pages/MailMagazine/Main';
import MailMagazineNew from './Pages/MailMagazine/New';
import MailMagazineDetails from './Pages/MailMagazine/Details';
import AnalysisUser from './Pages/Analysis/User';
import StripeCardErrorLog from './Pages/StripeCardErrorLog/Main';
import Version from './Objects/Version';

Amplify.configure(AwsExports);

class App extends React.Component {
	render() {
		return (
			<BrowserRouter>
				<div>
					<Header/>
					<Switch>
						<Route exact path='/' component={Main} />
						<Route path='/StripeCardErrorLog' component={StripeCardErrorLog} />
						<Route path='/Analysis/User/:cognitoid' component={AnalysisUser} />
						<Route path='/MailMagazine/New' component={MailMagazineNew} />
						<Route path='/MailMagazine/:magazineno/' component={MailMagazineDetails} />
						<Route path='/MailMagazine' component={MailMagazineMain} />
						<Route path='/MailTmpl/New' component={MailTmplNew} />
						<Route path='/MailTmpl/:tmpl_name/' component={MailTmplDetails} />
						{/*<Route path='/MailTmpl/Detail?name=:tmpl_name' component={MailTmplDetails} />*/}
						<Route path='/MailTmpl' component={MailTmplMain} />
						<Route path='/Setting' component={SettingMain} />
						<Route path='/CSVUpload/Log' component={CSVUploadLog} />
						<Route path='/CSVUpload' component={CSVUploadMain} />
						<Route path='/MailLog/Complaint' component={MailLogComplaint} />
						<Route path='/MailLog/Bounce' component={MailLogBounce} />
						<Route path='/Order/FormA/:selectIDs/' component={OrderFormA}/>
						<Route path='/Order/FormB/:selectIDs/' component={OrderFormB}/>
						<Route path='/Order/FormA' component={OrderFormA}/>
						<Route path='/Order/FormB' component={OrderFormB}/>
						<Route path='/Order/New/:cognitoid/' component={OrderNewCart}/>
						<Route path='/Order/New' component={OrderNewMain} />
						<Route path='/Order/:orderno/MailSendLog/:date_add/' component={OrderMailSendLogDetails}/>
						<Route path='/Order/:orderno/MailSendLog' component={OrderMailSendLog}/>
						<Route path='/Order/:orderno/MailSend' component={OrderMailSend}/>
						<Route path='/Order/:orderno/Item' component={OrderItem}/>
						<Route path='/Order/:orderno' component={OrderDetails}/>
						<Route path='/Order' component={OrderMain} />
						<Route path='/Category' component={CategoryMain} />
						<Route path='/Item/New' component={ItemNew} />
						<Route path='/Item/:code/Category' component={ItemCategory}/>
						<Route path='/Item/:code' component={ItemDetails}/>
						<Route path='/Item' component={ItemMain} />
						<Route path='/User/New' component={UserNew} />
						<Route path='/User/:cognitoid/Address/New' component={UserAddressNew}/>
						<Route path='/User/:cognitoid/Address/:addressid' component={UserAddressDetails}/>
						<Route path='/User/:cognitoid/Address' component={UserAddressMain}/>
						<Route path='/User/:cognitoid' component={UserDetails}/>
						<Route path='/User' component={UserMain} />
						<Route path='/Login/EmailVerify' component={LoginEmailVerify}/>
						<Route path='/Login/Email' component={LoginEmail}/>
						<Route path='/Login/Password' component={LoginPassword}/>
						<Route path='/Login' component={LoginMain}/>
						<Route path='/SignOut' render={props => <SignOut {...this.props} />} />
						<Route path='/Version' component={Version} />
						<Route component={NotFound} />
					</Switch>
					<Footer/>
				</div>
			</BrowserRouter>
		);
	}
}

export default withAuthenticator(App);
