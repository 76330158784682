import React from 'react';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Modal,
	Breadcrumb,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import UserSelect from '../../../Objects/UserSelect';

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isValidated    : false,
			isSuccess      : false,
			isShowModal    : false,
			isUserSelect   : false,
			isLockForm     : false,
			formObj        : null,
			ErrMsg         : '',
		}
		this.handleReload         = this.handleReload.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
		this.handleSbumit         = this.handleSbumit.bind(this);
		this.onClickSelect        = this.onClickSelect.bind(this);
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
	}
	handleSbumit(event) {
		event.preventDefault();
		event.stopPropagation();

	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleClose() {
		this.setState({
			isShowModal  : false,
			isUserSelect : false,
			isShowDelete : false,
			isSuccess    : false,
			isValidated  : false,
			isLockForm   : false,
			ErrMsg       : '',
		});
	}
	lockFormClose(){
		//
	}
	onClickSelect(row){
		if(row.cognitoid){
			this.props.history.push('/Order/New/' + row.cognitoid + '/');
		}
	}
	componentDidMount () {
		this.setState({
			isLoading   : false,
		});
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>更新しました。</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowModal} onHide={this.handleClose} centered>
						<Modal.Body>
							本当に更新しますか？<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.orderSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isUserSelect} onHide={this.handleClose} centered aria-labelledby="UserSelect" dialogClassName="modal-xl">
						<Modal.Header closeButton>
							<Modal.Title id="UserSelect">
								受注情報を登録する会員を選んでください。
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<UserSelect onClickSelect={this.onClickSelect} />
						</Modal.Body>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					
					<Row>
						<Col sm={12}>
							<h4>受注情報の新規登録</h4>　
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<Breadcrumb>
								<Breadcrumb.Item active>
									受注情報の新規登録
								</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
					</Row>
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSbumit(e)}>
					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block size="lg" disabled={this.state.isLockForm} onClick={()=>{ this.setState({ isUserSelect : true }); }} >会員情報から登録する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block size="lg" disabled={this.state.isLockForm} onClick={()=>{ this.props.history.push('/Order/New/not-ec-user/'); }} >非会員として登録する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props.history.push('/Order/'); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					</Form>
					

				</Container>
			);
		}
	}
}

export default withRouter(Default);


