import React from 'react';
import { Auth } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Navbar,
	Modal,
} from 'react-bootstrap';

export function withAuthenticator(WrappedComponent) {

		return class PP extends React.Component {
			constructor(props) {
				super(props);
				this.state = {
					isAuthenticated : undefined,
				};
				this._reload = this._reload.bind(this);
			}
			_reload() {
				this.setState({ isAuthenticated: undefined });
				Auth.currentAuthenticatedUser()
					.then(user => this.setState({ isAuthenticated: true  }) )
					.catch(err => this.setState({ isAuthenticated: false }) );
			}
			componentDidMount () {
				this._reload();
			}
			render() {
				if(this.state.isAuthenticated === false ){
					return <SignIn reload={this._reload} />
				}
				else if(this.state.isAuthenticated === true ){
					return <WrappedComponent {...this.props} reload={this._reload} />
				}
				else{
					return <div />
				}
			}
		}
}

class Header extends React.Component {
	render() {
		return (
			<Navbar bg="dark" variant="dark">
				<Container fluid>
					<Navbar.Brand href="/">
						マインドウェイブ オンラインストア管理画面
					</Navbar.Brand>
					<Navbar.Toggle />
				</Container>
			</Navbar>
		);
	}
}


class Footer extends React.Component {
	render() {
		return (
			<footer className="text-center" style={{backgroundColor: "#525864", color: "#FFF", padding:"10px", marginTop:"40px" }}>
				<small>Copyright since 2018 Onocomm Co Ltd. All rights reserved.</small>
			</footer>
		);
	}
}

class SignIn extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading               : false,
			isValidated             : false,
			isLockForm              : false,
			ErrMsg                  : '',
			email                   : '',
			isChallengeResponses    : false,
			isPasswordResetRequired : false,
			isForgotPassword        : false,
		}
		this._reload       = this._reload.bind(this);
		this.handleSubmit  = this.handleSubmit.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
	}
	_reload() {
		this.setState({ isLoading: true });
		this.props.reload();
	}
	async handleSubmit (event) {

		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isValidated : true,
			isLockForm  : true,
		});

		//Formオブジェクトの確認
		const formObj     = event.currentTarget;
		if(!formObj){
			this.setState({
				ErrMsg     : '※不正なエラーが発生しました。<br/>',
				isLockForm : false,
			});
			return(0);
		}

		//Formオブジェクトの確認
		if(formObj.checkValidity() === false){
			this.setState({
				ErrMsg     : '※入力項目にエラーがありました。<br/>',
				isLockForm : false,
			});
			return(0);
		}
		
		//Formから直接取得する更新項目
		const email         = formObj.email.value;
		const passwd        = formObj.passwd.value;

		//更新処理
		try{
			//サインイン
			const result = await Auth.signIn(email, passwd);
			
			//パスワードの変更要求があった場合
			if(result.challengeName === 'NEW_PASSWORD_REQUIRED'){
				this.setState({
					isChallengeResponses: true,
					email               : email,
				});
				return(0);
			}
		}
		catch(error) {
			//console.log(error);
			//パスワードのリセットがあった場合
			if(error.code === 'PasswordResetRequiredException'){
				this.setState({
					isPasswordResetRequired: true,
					email                  : email,
				});
				return(0);
			}
			//パスワードが正しくない場合
			if(error.code === 'NotAuthorizedException'){
				this.setState({
					ErrMsg     : 'メールアドレス、またはパスワードが正しくありません。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error.message + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			});
			return(0);
		}
		//更新成功
		this.setState({
			isLockForm  : false,
		});
		this.props.reload();
	}
	handleDismiss() {
		this.setState({
			ErrMsg     : '',
			isLockForm : false,
		});
	}
	componentDidMount () {
		this.setState({ isLoading: false });
	}
	render() {
		if(this.state.isPasswordResetRequired){
			return (
				<PasswordResetRequired email={this.state.email} reload={this._reload} />
			);
		}
		else if(this.state.isChallengeResponses){
			return (
				<ChangePassword email={this.state.email} reload={this._reload} />
			);
		}
		else if(this.state.isForgotPassword){
			return (
				<ForgotPassword email={this.state.email} reload={this._reload} />
			);
		}
		else{
			return (
				<div>
					<Header/>
					<Container style={{ marginTop: 200, marginBottom: 200 }}>
						<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleDismiss}>
							<Modal.Body>
								エラーがありました。<br/>
								{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
							</Modal.Body>
							<Modal.Footer>
								<Button variant="primary" onClick={this.handleDismiss}>
									ＯＫ
								</Button>
							</Modal.Footer>
						</Modal>
						<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>
						<Row>
							<Col sm={{ span: 4, offset: 4 }}>
								<p className="text-center"><big>ログイン</big></p>
							</Col>
						</Row>
						<Row>
							<Col sm={{ span: 4, offset: 4 }}>
								<Form.Group controlId="email">
								        <Form.Control name="email" type="email" placeholder="メールアドレス" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「メールアドレス」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col sm={{ span: 4, offset: 4 }}>
								<Form.Group controlId="passwd">
								        <Form.Control name="passwd" type="password" placeholder="パスワード" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「パスワード」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 4, offset: 4 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >ログイン</Button>
							</Col>
						</Row>
						<Row style={{ marginTop: 50 }}>
							<Col sm={{ span: 4, offset: 4 }}>
								パスワードを忘れた場合はこちらから申請してください。
							        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.setState({ isForgotPassword: this.state.isForgotPassword ? false : true }); }} disabled={this.state.isLockForm} >申請</Button>
							</Col>
						</Row>
						</Form>
					</Container>
					<Footer/>
				</div>
			);
		}
	}
}

class ChangePassword extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : false,
			isValidated    : false,
			isLockForm     : false,
			ErrMsg         : '',
		}
		this.handleSubmit  = this.handleSubmit.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
	}
	async handleSubmit (event) {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isValidated : true,
			isLockForm  : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//親から直接取得する更新項目
			const email           = this.props.email;

			//Formから直接取得する更新項目
			const oldPassword     = formObj.oldPassword.value;
			const newPassword     = formObj.newPassword.value;

			//サインイン
			const result = await Auth.signIn(email, oldPassword);
			
			//requiredAttributesの取得
			const requiredAttributes = result.challengeParam.requiredAttributes;
			
			//パスワードの変更
			await Auth.completeNewPassword(result, newPassword, requiredAttributes);

		}
		catch(error) {
			//console.log(error);
			let err_msg = '';
			if(error.message){
				err_msg  = error.message + '<br/>';
			}
			else if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error.message + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			});
			return(0);
		}
		//更新成功
		this.setState({
			isLockForm  : false,
		});
		this.props.reload();
	}
	handleDismiss() {
		this.setState({
			ErrMsg     : '',
			isLockForm : false,
		});
	}
	componentDidMount () {
		this.setState({ isLoading: false });
	}
	render() {
		return (
			<div>
				<Header/>
				<Container style={{ marginTop: 200, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleDismiss}>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleDismiss}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<p className="text-center"><big>新しいパスワードを設定してください。</big></p>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<Form.Group controlId="oldPassword">
							        <Form.Control name="oldPassword" type="password" placeholder="古いパスワード" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「古いパスワード」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<Form.Group controlId="newPassword">
							        <Form.Control name="newPassword" type="password" placeholder="新しいパスワード" size="lg" required pattern=".{1,255}"  disabled={this.state.isLockForm} autoComplete="off" />
								<Form.Control.Feedback type="invalid">
									「新しいパスワード」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 4, offset: 4 }}>
						        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >設定</Button>
						</Col>
					</Row>
					</Form>
				</Container>
				<Footer/>
			</div>
		);
	}
}

class PasswordResetRequired extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : false,
			isValidated    : false,
			isLockForm     : false,
			isSuccess      : false,
			ErrMsg         : '',
		}
		this._reload       = this._reload.bind(this);
		this.handleSubmit  = this.handleSubmit.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
	}
	_reload() {
		this.setState({ isLoading: true });
		this.props.reload();
	}
	async handleSubmit (event) {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isValidated : true,
			isLockForm  : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//Formから直接取得する更新項目
			const email    = formObj.email.value;
			const code     = formObj.code.value;
			const passwd   = formObj.passwd.value;

			//パスワード再設定
			await Auth.forgotPasswordSubmit(email, code, passwd);
		}
		catch(error) {
			//console.log(error);
			let err_msg = '';
			if(error.message){
				err_msg  = error.message + '<br/>';
			}
			else if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error.message + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			});
			return(0);
		}
		//更新成功
		this.setState({
			isLockForm  : false,
			isSuccess   : true,
		});
	}
	handleDismiss() {
		this.setState({
			ErrMsg     : '',
			isLockForm : false,
		});
	}
	componentDidMount () {
		this.setState({ isLoading: false });
	}
	render() {
		return (

			<div>
				<Header/>
				<Container style={{ marginTop: 200, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleDismiss}>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleDismiss}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this._reload}>
						<Modal.Body>
							パスワードを再登録しました。<br/>
							新しいパスワードで再度ログインを行ってください。
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this._reload}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<p className="text-center">
								<big>パスワードの再登録が申請されました。</big><br/>
							</p>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<p className="text-center">
								メールアドレスに届いた検証コードと新しいパスワードを入力してください。
							</p>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<Form.Group controlId="email">
							        <Form.Control name="email" type="email" placeholder="メールアドレス" size="lg" defaultValue={this.props.email ? this.props.email : ''} required pattern=".{1,255}" disabled={this.state.isLockForm} autoComplete="off" />
								<Form.Control.Feedback type="invalid">
									「検証コード」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<Form.Group controlId="code">
							        <Form.Control name="code" type="text" placeholder="検証コード" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} autoComplete="off" />
								<Form.Control.Feedback type="invalid">
									「検証コード」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<Form.Group controlId="passwd">
							        <Form.Control name="passwd" type="password" placeholder="新しいパスワード" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} autoComplete="off" />
								<Form.Control.Feedback type="invalid">
									「新しいパスワード」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 4, offset: 4 }}>
						        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >登録</Button>
						</Col>
					</Row>
					</Form>
				</Container>
				<Footer/>
			</div>
		);
	}
}

class ForgotPassword extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : false,
			isValidated    : false,
			isLockForm     : false,
			isSuccess      : false,
			ErrMsg         : '',
		}
		this._reload       = this._reload.bind(this);
		this.handleSubmit  = this.handleSubmit.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
	}
	_reload() {
		this.setState({ isLoading: true });
		this.props.reload();
	}
	async handleSubmit (event) {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isValidated : true,
			isLockForm  : true,
		});

		//Formオブジェクトの確認
		const formObj     = event.currentTarget;
		if(!formObj){
			this.setState({
				ErrMsg     : '※不正なエラーが発生しました。<br/>',
				isLockForm : false,
			});
			return(0);
		}

		//Formオブジェクトの確認
		if(formObj.checkValidity() === false){
			this.setState({
				ErrMsg     : '※入力項目にエラーがありました。<br/>',
				isLockForm : false,
			});
			return(0);
		}
		
		//Formから直接取得する更新項目
		const email     = formObj.email.value;

		//更新処理
		try{
			//パスワード再設定
			await Auth.forgotPassword(email);
		}
		catch(error) {
			//console.log(error);
			let err_msg = '';
			if(error.message){
				err_msg  = error.message + '<br/>';
			}
			else if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error.message + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			});
			return(0);
		}
		//更新成功
		this.setState({
			isLockForm  : false,
			isSuccess   : true,
			email       : email,
		});
	}
	handleDismiss() {
		this.setState({
			ErrMsg     : '',
			isLockForm : false,
		});
	}
	componentDidMount () {
		this.setState({ isLoading: false });
	}
	render() {
		if(this.state.isPasswordResetRequired){
			return (
				<PasswordResetRequired email={this.state.email} reload={this._reload} />
			);
		}
		else if(this.state.isSuccess){
			return (
				<PasswordResetRequired email={this.state.email} reload={this._reload} />
			);
		}
		else{
			return (
				<div>
					<Header/>
					<Container style={{ marginTop: 200, marginBottom: 200 }}>
						<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleDismiss}>
							<Modal.Body>
								エラーがありました。<br/>
								{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
							</Modal.Body>
							<Modal.Footer>
								<Button variant="primary" onClick={this.handleDismiss}>
									ＯＫ
								</Button>
							</Modal.Footer>
						</Modal>
						<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>
						<Row>
							<Col sm={{ span: 4, offset: 4 }}>
								<p className="text-center">
									<big>パスワードをお忘れですか？</big><br/>
								</p>
							</Col>
						</Row>
						<Row>
							<Col sm={{ span: 4, offset: 4 }}>
								<p className="text-center">
									パスワード再登録のための検証コードをお送りします。メールアドレスをご入力ください。
								</p>
							</Col>
						</Row>
						<Row>
							<Col sm={{ span: 4, offset: 4 }}>
								<Form.Group controlId="email">
								        <Form.Control name="email" type="email" placeholder="メールアドレス" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「メールアドレス」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 4, offset: 4 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >申請</Button>
							</Col>
						</Row>
						<Row style={{ marginTop: 50 }}>
							<Col sm={{ span: 4, offset: 4 }}>
								また、こちらから検証コードによるパスワード変更が可能です。
							        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.setState({ isPasswordResetRequired: this.state.isPasswordResetRequired ? false : true }); }} disabled={this.state.isLockForm} >再登録</Button>
							</Col>
						</Row>
						</Form>
					</Container>
					<Footer/>
				</div>
			);
		}
	}
}
