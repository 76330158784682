import React from 'react';
import { Auth } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Alert,
} from 'react-bootstrap';
import { withRouter, Redirect } from 'react-router-dom';
import { AuditLog } from '../../Objects/AuditLog';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			validated      : false,
			isSubmit       : false,
			ErrFlag        : false,
			ErrMsg         : '',
			email          : '',
		}
		this._emailChange  = this._emailChange.bind(this);
		this.handleChange  = this.handleChange.bind(this);
		this.handleSubmit  = this.handleSubmit.bind(this);
		this.handleDismissErr  = this.handleDismissErr.bind(this);
		this.handleDismissInfo = this.handleDismissInfo.bind(this);
	}
	async _emailChange(email){
	
		try {
			const attributes = { email: email }
			const user = await Auth.currentAuthenticatedUser();
			await Auth.updateUserAttributes(user, attributes);
			//console.log(data);

			//鑑査ログ
			await AuditLog('login', 'update', 'email', email, 'メールアドレスを変更しました。');

			this.setState({ ErrFlag : false   });
			this.setState({ ErrMsg  : ''      });
			this.setState({ isSubmit: true    });
		}
		catch(error) {
			//console.log("err");
			//console.log(error);
			this.setState({ ErrFlag : true    });
			this.setState({ ErrMsg  : error.message });
			this.setState({ isSubmit: false    });
		}
	
	}
	handleChange(e){
		switch (e.target.name) {
			case 'email':
				this.setState({ email: e.target.value });
				break;
			default:
				break;
		}
	}
	handleSubmit () {
		this.setState({ validated: true });
		const email  = this.state.email;
		let err_flag = false;
		let err_msg  = '';
		if(!email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
			err_flag = true;
			err_msg  = err_msg + '※メールアドレスの形式が不正です。<br/>';
		}
		else if(email.length >= 255){
			err_flag = true;
			err_msg  = err_msg + '※メールアドレスは255文字以内で入力してください。<br/>';
		}
		if(err_flag){
		
			this.setState({ ErrFlag : true    });
			this.setState({ ErrMsg  : err_msg });
			this.setState({ isSubmit: false   });
		}
		else{
			
			this._emailChange(email);
		}
	}
	handleDismissErr() {
		this.setState({ ErrFlag: false });
	}
	handleDismissInfo() {
		this.setState({ isSubmit: false });
	}
	componentDidMount () {
		//
	}
	render() {
		if(this.state.isSubmit){
			return (
				<Redirect to="/Login/EmailVerify/"/>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 200, marginBottom: 200 }}>
					{(() => {
						if(this.state.ErrFlag){
							return (
								<Row>
								<Col sm={{ span: 6, offset: 3 }}>
									<Alert dismissible variant="danger" onClose={this.handleDismiss}>
										エラーがありました。<br/>
										{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
									</Alert>
								</Col>
								</Row>
							);
						}
					})()}
					<Form onChange={this.handleChange} validated={this.state.validated}>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<p className="text-center"><big>メールアドレスの変更</big></p>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<Form.Group controlId="formEmail" size="lg">
							        <Form.Control name="email" type="email" defaultValue={this.state.email.value} placeholder="メールアドレス" required />
								<Form.Control.Feedback />
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 4, offset: 4 }}>
						        <Button variant="dark" type="button" block onClick={this.handleSubmit} size="lg">変更</Button>
						</Col>
					</Row>
					</Form>
				</Container>
			);
		}
	}
}

export default withRouter(Default);

