import React from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import {
	Alert,
	Modal,
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Badge,
	ProgressBar,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone }  from 'react-bootstrap-table2-paginator';
//追加200619 PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone
//import { CSVLink } from "react-csv";
import { AuditLog } from '../../Objects/AuditLog';
import CSVDownload from '../../Objects/CSVDownload';
import CSVDownload2 from '../../Objects/CSVDownload2';


class MyTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isSuccess    : false,
			isShowStatus : false,
			orderno      : '',
			status       : '',
			selectIDs    : [],
			ErrMsg       : '',
		}
		this.handleSubmit            = this.handleSubmit.bind(this);
		this.handleClickDetails      = this.handleClickDetails.bind(this);
		this.handleClickMailSend     = this.handleClickMailSend.bind(this);
		this.handleClose             = this.handleClose.bind(this);
		this.showStatus              = this.showStatus.bind(this);
		this.updateStatus            = this.updateStatus.bind(this);
		this._selectAllRows          = this._selectAllRows.bind(this);
		this._selectRows             = this._selectRows.bind(this);
		this._dataFormatOverlay      = this._dataFormatOverlay.bind(this);
		this._dataFormatOverlay2      = this._dataFormatOverlay2.bind(this);//追加 220203
		this._dataFormatLabel        = this._dataFormatLabel.bind(this);
		this._dataFormatIcon         = this._dataFormatIcon.bind(this);//追加 220203
		this._dataFormatStatus       = this._dataFormatStatus.bind(this);
		this._dataFormatDate         = this._dataFormatDate.bind(this);
		this._dataFormatDate2         = this._dataFormatDate2.bind(this);//追加 220203
		this._dataFormatOdate         = this._dataFormatOdate.bind(this);//追加 220203
		this._dataFormatButton       = this._dataFormatButton.bind(this);
		this._dataFormatMail         = this._dataFormatMail.bind(this);
		this._dataFormatName         = this._dataFormatName.bind(this);
	}

	handleSubmit(e) {
		//Submitをキャンセル
		e.preventDefault();
		e.stopPropagation();
	}
	handleClickDetails(row){
		if(row.orderno){
			this.props.history.push('/Order/' + row.orderno + '/');
		}
	}
	handleClickMailSend(row){
		if(row.orderno){
			this.props.history.push('/Order/' + row.orderno + '/MailSend/');
		}
	}
	handleClose() {
		this.setState({
			isShowStatus : false,
			isSuccess    : false,
			ErrMsg       : '',
		});
	}
	showStatus(e, row) {
		if(row.orderno && e.target.value){
			this.setState({
				isShowStatus : true,
				orderno      : row.orderno,
				status       : e.target.value,
			});
		}
	}
	async updateStatus () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowStatus : false,
		});

		//更新処理
		try{

			//Stateから取得する更新項目
			const orderno = this.state.orderno;
			const status  = this.state.status;

			//日付処理
		        const date          = new Date();
		        const isoDate       = date.toISOString();

			const Data = {
				data : {
					orderno       : orderno,
					status        : status,
					date_edit     : isoDate,
				}
			};

			//最新のもの
			try{
				const Query = `mutation updateOrder($data: OrderInput!) {
					updateOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}

			//古いもの
			try{
				const Query = `mutation updateOldOrder($data: OrderInput!) {
					updateOldOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}

			//鑑査ログ
			await AuditLog('order', 'update', orderno, Data, 'ステータスを変更しました。');
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
		});
	}
	_selectRows(row, isSelect, rowIndex, e) {

		//新しく選択した内容
		const selectID  = row.selectid;

		//今まで選択した内容
		let selectIDs = this.state.selectIDs;

		//選択したかどうか
		if(isSelect){
			//新しく選択した内容を追加
			selectIDs.push(selectID);

			//重複を削除
			selectIDs = selectIDs.filter((x, i, self) => self.indexOf(x) === i);
		}
		else{
			//対応する要素を削除
			selectIDs = selectIDs.filter(n => n !== selectID);
		}

		//今まで選択した内容を更新
		this.setState({ selectIDs: selectIDs});

		//親に数値を渡す
		this.props.setSelectIDs(selectIDs);

	}
	_selectAllRows(isSelect, rows, e) {

		//今まで選択した内容
		let selectIDs = this.state.selectIDs;

		//ループ処理
		rows.some(row => {

			//新しく選択した内容
			const selectID  = row.selectid;

			//選択したかどうか
			if(isSelect){
				//新しく選択した内容を追加
				selectIDs.push(selectID);

				//重複を削除
				selectIDs = selectIDs.filter((x, i, self) => self.indexOf(x) === i);
			}
			else{
				//対応する要素を削除
				selectIDs = selectIDs.filter(n => n !== selectID);
			}

			return false;

		});

		//今まで選択した内容を更新
		this.setState({ selectIDs: selectIDs});

		//親に数値を渡す
		this.props.setSelectIDs(selectIDs);

	}
	_dataFormatLabel(cell, row) {//margin: "10px" ■220203 変更
		return (
			<div className="text-center" style={{margin: "3px"}}>
				<Button variant={cell ? 'success' : 'secondary'} href={'/Analysis/User/' + row.cognitoid + '/'} target="_blank" disabled={!cell} size="sm">
					{cell ? '○' : '×'}
				</Button>
			</div>
		);
	}
	_dataFormatIcon(cell, row) {// ■220203 追加
		return (
			<div className="text-center" style={{margin: "3px"}}>
				<Badge variant="primary" className="p-2" style={cell==='stationery' ? {backgroundColor: "#00B7EB"} : cell==='cosme' ? {backgroundColor: "pink"} :  {backgroundColor: "gray"}}>
					{cell==='stationery' ? 'S' : cell==='cosme' ? 'C' : cell==='post' ? 'P' :' '}
				</Badge>
			</div>
		);
	}

	_dataFormatOverlay(cell, row) {// ■220203 font-size追加
		return (
			<OverlayTrigger placement="bottom" overlay={<Tooltip>{row.memo_full}</Tooltip>}>
				<div style={{fontSize: "0.9em"}}>{cell}</div>
			</OverlayTrigger>
		);
	}

	_dataFormatOverlay2(cell, row) {// ■220203 追加
		return (
			<OverlayTrigger placement="bottom" overlay={<Tooltip>{row.order_memo_full}</Tooltip>}>
				<div style={{fontSize: "0.9em"}}>{cell}</div>
			</OverlayTrigger>
		);
	}

	_dataFormatStatus(cell, row) {
		return (
			<Form.Group controlId="status" style={{marginBottom:0}}>
				<Form.Control as="select" name="status" defaultValue={cell} onChange={(e) => this.showStatus(e, row)} size="sm">
				<option value="仮受注">仮受注</option>
				<option value="未処理">未処理</option>
				<option value="処理中">処理中</option>
				<option value="出荷済み">出荷済み</option>
				<option value="予約・入荷待ち">予約・入荷待ち</option>
				<option value="決済処理待ち">決済処理待ち</option>
				<option value="確認中">確認中</option>
				<option value="返信待ち">返信待ち</option>
				<option value="商品手配中">商品手配中</option>
				<option value="後払い与信中">後払い与信中</option>
				<option value="キャンセル">キャンセル</option>
				<option value="未定義">未定義</option>
				</Form.Control>
			</Form.Group>
		);
	}
	_dataFormatDate(cell, row) {

		//日付の整形
		const dateObj     = new Date(cell);
		const year        = dateObj.getFullYear().toString();
		const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day         = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second      = ('00' + dateObj.getSeconds().toString()).slice(-2);

		//出力
		return (
			<div>
				{year}年{month}月{day}日{hour}時{minute}分{second}秒
			</div>
		);
	}
	_dataFormatDate2(cell, row) {// ■220203 追加

		//日付の整形
		const dateObj     = new Date(cell);
		const year        = dateObj.getFullYear().toString();
		const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day         = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second      = ('00' + dateObj.getSeconds().toString()).slice(-2);

		//出力
		return (
			<div className="text-center" style={{margin: "2px"}}>
				{year}-{month}-{day}<br/>{hour}:{minute}:{second}
			</div>
		);
	}
	_dataFormatOdate(cell, row) {// ■220203 追加

		//日付の整形
		cell = cell.replace(/：/g,':');
		const o_date     =  cell.split(' ');

		//出力
		return (
			<div className="text-center" style={{margin: "2px"}}>
				{o_date[0]}<br/>{o_date[1]}
			</div>
		);
	}

	_dataFormatButton(cell, row) {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" href={'/Order/' + row.orderno + '/'} target="_blank" size="sm">{/*onClick={() => this.handleClickDetails(row)}*/}
					詳細
				</Button>
			</div>
		);
	}
	_dataFormatMail(cell, row) {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" href={'/Order/' + row.orderno + '/MailSend/'} target="_blank" size="sm">{/*onClick={() => this.handleClickMailSend(row)}*/}
					メール
				</Button>
			</div>
		);
	}
	_dataFormatName(cell, row) {
		const flag = (this.props.bounceData.filter(x => x.email === row.email).length || this.props.complaintData.filter(x => x.email === row.email).length);
		//console.log(flag);
		if(flag){
			return (
				<div className="text-center">
					<font color="red">{cell}</font>
				</div>
			);
		}
		else{
			return (
				<div className="text-center">
					{cell}
				</div>
			);
		}
	}
	render() {
		if(this.props.data.length === 0){
			return (
				<Alert variant="danger">
					対象のデータが見つかりません。
				</Alert>
			);
		}
		else{

			//表示用に整形
			let TableData = [];
			for(let item of this.props.data){
				const orderno       = item.orderno;
				const cognitoid     = item.cognitoid;
				const email         = item.email;
				const name_1        = item.name_1;
				const name_2        = item.name_2;
				const payment_type  = item.payment_type;
				const payment_price = item.payment_price;
				const o_pref        = item.o_pref;
				const memo          = item.memo;
				const order_memo    = item.order_memo; //追加 220203
				const o_date_type   = item.o_date_type;
				const o_date_h      = item.o_date_h;
				const delivery_date = item.delivery_date;//追加 220203
				const not_ec_user_kbn = item.not_ec_user_kbn;
				const flag          = item.flag;
				const carriage_type = item.carriage_type;
				const order_history_flag = item.order_history_flag;
				const status        = item.status;
				const date_add      = item.date_add;
				const date_edit     = item.date_edit;
				const RowData = {
					selectid        : orderno,
					orderno         : orderno,
					cognitoid       : cognitoid,
					email           : email,
					name            : name_1 + ' ' +name_2,
					ec_user_kbn     : not_ec_user_kbn ? '非会員' : '会員',
					payment_type    : payment_type,
					payment_price   : payment_price ? payment_price.toLocaleString() + ' 円' : '0 円',
					o_pref          : o_pref,
					memo            : memo ? (memo.length > 10 ? memo.substr(0,10) + '...' : memo) : '',
					memo_full       : memo,
					order_memo      : order_memo ? (order_memo.length > 10 ? order_memo.substr(0,10) + '...' : order_memo) : '',//追加 220203
					order_memo_full : order_memo,//追加 220203
					o_date          : o_date_type + ' ' + o_date_h,
					delivery_date   : delivery_date ? delivery_date : '未発送',//追加 220203
					flag            : flag,
					carriage_type   : carriage_type,
					order_history_flag : order_history_flag ? true : false,
					status          : status,
					date_add        : date_add,
					date_edit       : date_edit,
				};

				TableData.push(RowData);
			}

			const windowSize = window.innerWidth;


			const columns = [
				{
					dataField: 'selectid',
					text: '詳細',
					sort: false,
					formatter: this._dataFormatButton,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'orderno',
					text: '受注番号',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'date_add',
					text: '受注日',
					sort: true,
					formatter: this._dataFormatDate2,//this._dataFormatDate ■220203更新
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'name',
					text: '注文者名',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					formatter: this._dataFormatName,
				},
				{
					dataField: 'ec_user_kbn',
					text: '会員',//会員種別 ■220203更新
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'order_history_flag',
					text: 'リピ',//リピーター区分 ■220203更新
					sort: true,
					formatter: this._dataFormatLabel,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'payment_type',
					text: '決済方法',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'payment_price',
					text: '決済金額',
					sort: true,
					sortFunc: (a, b, order, dataField, rowA, rowB) => {
						a = a.replace('円', '')
						a = a.replace(' ', '')
						a = a.replace(',', '')
						b = b.replace('円', '')
						b = b.replace(' ', '')
						b = b.replace(',', '')
						if (order === 'asc') {
							return b - a;
						}
						else{
							return a - b;
						}
					},
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
					align: 'right',
				},
				{
					dataField: 'o_pref',
					text: 'お届け先',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'memo',
					text: 'メモ',
					sort: true,
					formatter: this._dataFormatOverlay,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{//220203 追加
					dataField: 'order_memo',
					text: '連絡事項',
					sort: true,
					formatter: this._dataFormatOverlay2,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'o_date',
					text: '配送日時指定',
					sort: true,
					formatter: this._dataFormatOdate,//220203追加
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{//220203追加
					dataField: 'delivery_date',
					text: '発送日',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'flag',
					text: 'フラグ',
					sort: true,
					formatter: this._dataFormatIcon,//220203 追加
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'carriage_type',
					text: 'お届け方法',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'status',
					text: '受注状態',
					sort: true,
					formatter: this._dataFormatStatus,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'dummy1',
					isDummyField: true,
					text: 'メール',
					sort: false,
					formatter: this._dataFormatMail,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
			];

			const defaultSorted = [
				{
					dataField: 'date_add',
					order: 'desc'
				}
			];

			const selectRow = {
				mode         : 'checkbox',
				clickToSelect: false,
				onSelect     : this._selectRows,
				onSelectAll  : this._selectAllRows,
			};

			const options = {
				custom: true,//追加200619
				sizePerPageList: [
					{ text: '100', value: 100},
					{ text: '10', value: 10},
					{ text: '50', value: 50},
					{ text: '200', value: 200},//追加200610
					{ text: 'All', value: TableData.length},
				],
			};

			return (
				<Form onSubmit={e => this.handleSubmit(e)} >
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>更新しました。</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowStatus} onHide={this.handleClose} centered>
						<Modal.Body>
							本当に更新しますか？<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.updateStatus}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>


					{/*追加20200619 表示件数とページネーションを上下につけるカスタマイズ*/}
					<PaginationProvider pagination={ paginationFactory(options) }>
					{({ paginationProps, paginationTableProps }) => (
							<div>
							<SizePerPageDropdownStandalone { ...paginationProps } {...this.props}/>
							<PaginationListStandalone { ...paginationProps } {...this.props}/>

							<BootstrapTable bootstrap4 keyField='orderno' data={ TableData } columns={ columns } bordered={ false }  striped hover condensed defaultSorted={ defaultSorted } selectRow={ selectRow } { ...paginationTableProps } />

							<SizePerPageDropdownStandalone { ...paginationProps } {...this.props}/>
							<PaginationListStandalone { ...paginationProps } {...this.props}/>

							</div>
					)}
					</PaginationProvider>


				</Form>
			);

		}
	}
}

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading    : true,
			loadingPer   : 0,
			isShowStatus : false,
			isSuccess    : false,
			ErrMsg       : '',
			viewData     : [],
			defaultData  : [],
			selectIDs    : [],
			dateTime     : '',
			dateData     : [],
			datePart     : [],
			startDate    : '',
			lastDate     : '',
			status       : '',
			csvData      : [],
			bounceData   : [],
			complaintData: [],
		}
		this._reload              = this._reload.bind(this);
		this._formatDate          = this._formatDate.bind(this);
		this._getFile             = this._getFile.bind(this);
		this._getDataLatest       = this._getDataLatest.bind(this);
		this._getDataPart         = this._getDataPart.bind(this);
		this._getData             = this._getData.bind(this);
		this._getBounceMail       = this._getBounceMail.bind(this);
		this._getComplaintMail    = this._getComplaintMail.bind(this);		
		this.handleDismiss        = this.handleDismiss.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleSearch         = this.handleSearch.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.handleSelectDate     = this.handleSelectDate.bind(this);
		this.updateStatus         = this.updateStatus.bind(this);
		this.showStatus           = this.showStatus.bind(this);
		this.setSelectIDs         = this.setSelectIDs.bind(this);
		this.createCSV            = this.createCSV.bind(this);
		this.createFormA          = this.createFormA.bind(this);
		this.createFormB          = this.createFormB.bind(this);
		this.MailSend             = this.MailSend.bind(this);
	}
	_reload() {
		//子ウィンドウからの呼び出し用
		this.handleReload();
	}
	_formatDate(dateObj) {

		//日付の整形
		const year        = dateObj.getFullYear().toString();
		const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day         = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second      = ('00' + dateObj.getSeconds().toString()).slice(-2);

		const text = year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分' + second + '秒';

		return (text);
	}
	async _getFile(datePart) {
		//処理
		try{
			//ストレージを定義
			Storage.configure({ AWSS3: { bucket: 'mindwavestore.admin', region: 'ap-northeast-1', level: 'public' } });

			//S3からファイルを取得
			const result   = await Storage.get('_cache/Order_' + datePart + '.json', { download: true });
			const jsonText = await new Promise(resolve =>
			{
				const reader = new FileReader();
				reader.onload = () => resolve(reader.result);
				reader.readAsText(result.Body);
			});
			return(jsonText);
		}
		catch(error) {
			return(0);
		}
	}
	async _getDataLatest() {
		//処理
		try{

			//ユーザ件数の取得
			const CountQuery = `query scanOrderCount {
				scanOrderCount {
					scannedCount
					nextToken
				}
			}`;
			let countRef  = await API.graphql(graphqlOperation(CountQuery));
			let itemCount = countRef.data.scanOrderCount.scannedCount;
			while (countRef.data.scanOrderCount.nextToken) {
				const Query = `query scanOrderCount($nextToken: String) {
					scanOrderCount(nextToken: $nextToken) {
						scannedCount
						nextToken
					}
				}`;
				const Data = {
					nextToken: countRef.data.scanOrderCount.nextToken,
				};
				countRef  = await API.graphql(graphqlOperation(Query, Data));
				itemCount = itemCount + countRef.data.scanOrderCount.scannedCount;
			}

			//進捗情報
			let loadingPer   = 1;
			/*
			if(itemCount){
				this.setState({
					loadingPer : loadingPer,
				});
			}
			*/

			//ユーザ情報の取得
			const Query = `query scanOrder {
				scanOrder {
					items {
						orderno
						cognitoid
						total_price
						payment_type
						payment_price
						carriage_type
						carriage
						cashdeli
						wrapping_type
						wrapping
						coupon_type
						coupon
						email
						name_1
						name_2
						name_furi_1
						name_furi_2
						zip_1
						zip_2
						pref
						addr_1
						addr_2
						addr_3
						tel_1
						tel_2
						tel_3
						o_name_1
						o_name_2
						o_name_furi_1
						o_name_furi_2
						o_zip_1
						o_zip_2
						o_pref
						o_addr_1
						o_addr_2
						o_addr_3
						o_tel_1
						o_tel_2
						o_tel_3
						o_com_name
						o_date_type
						o_date_h
						order_memo
						order_history_flag
						delivery_no
						delivery_date
						status
						memo
						flag
						not_ec_user_kbn
						date_add
						date_edit
						old_order_id
						order_items{
							orderno
							code
							name
							price
							sub_price
							piece
							piece_type
							date_add
							date_edit
						}
					}
					nextToken
				}
			}`;
			let ref = await API.graphql(graphqlOperation(Query));
			let Items = ref.data.scanOrder.items;

			//進捗情報
			/*
			if(Items.length){
				loadingPer = Math.floor(Items.length / itemCount * 100);
				this.setState({
					loadingPer : loadingPer,
				});
			}
			*/

			//ユーザ情報の取得（未取得の続きデータがあった場合の処理）
			while (ref.data.scanOrder.nextToken) {
				const Query = `query scanOrder($nextToken: String) {
					scanOrder(nextToken: $nextToken) {
						items {
							orderno
							cognitoid
							total_price
							payment_type
							payment_price
							carriage_type
							carriage
							cashdeli
							wrapping_type
							wrapping
							coupon_type
							coupon
							email
							name_1
							name_2
							name_furi_1
							name_furi_2
							zip_1
							zip_2
							pref
							addr_1
							addr_2
							addr_3
							tel_1
							tel_2
							tel_3
							o_name_1
							o_name_2
							o_name_furi_1
							o_name_furi_2
							o_zip_1
							o_zip_2
							o_pref
							o_addr_1
							o_addr_2
							o_addr_3
							o_tel_1
							o_tel_2
							o_tel_3
							o_com_name
							o_date_type
							o_date_h
							order_memo
							order_history_flag
							delivery_no
							delivery_date
							status
							memo
							flag
							not_ec_user_kbn
							date_add
							date_edit
							old_order_id
							order_items{
								orderno
								code
								name
								price
								sub_price
								piece
								piece_type
								date_add
								date_edit
							}
						}
						nextToken
					}
				}`;
				const Data = {
					nextToken: ref.data.scanOrder.nextToken,
				};
				//console.log(ref.data.scanOrder.nextToken);
				ref = await API.graphql(graphqlOperation(Query, Data));
				Items = Items.concat(ref.data.scanOrder.items);
				//進捗情報
				if(Items.length){
					loadingPer = Math.floor(Items.length / itemCount * 100);
					this.setState({
						loadingPer : loadingPer,
					});
				}
			}

			//データの判別
			if(Items.length === 0){
				//データがなかった時
				this.setState({
					isLoading : false,
					ErrMsg    : 'データがありません。',
					datePart  : '',
					startDate : '',
					lastDate  : '',
				});
			}
			else{

				//'null'文字がある場合は''に変換
				Items.some((item, index) => Object.keys(item).some(key => { if(item[key] === 'null'){ Items[index][key] = '' } return false; }));

				//nullがある場合は''に変換
				Items.some((item, index) => Object.keys(item).some(key => { if(item[key] === null){ Items[index][key] = '' } return false; }));

				//データコピー
				const viewData = [];
				for(let row of Items){
					row.name_full = row.name_1 + ' ' + row.name_2;
					row.name_furi_full = row.name_furi_1 + ' ' + row.name_furi_2;
					let order_item_name = '';
					let order_item_code = '';
					for(const item of row.order_items){
						order_item_name = order_item_name + ' ' + item.name;
						order_item_code = order_item_code + ' ' + item.code;
					}
					row.order_item_name = order_item_name;
					row.order_item_code = order_item_code;
					viewData.push(row);
				}

				//データコピー
				const defaultData = [];
				for(let row of Items){
					row.name_full = row.name_1 + ' ' + row.name_2;
					row.name_furi_full = row.name_furi_1 + ' ' + row.name_furi_2;
					let order_item_name = '';
					let order_item_code = '';
					for(const item of row.order_items){
						order_item_name = order_item_name + ' ' + item.name;
						order_item_code = order_item_code + ' ' + item.code;
					}
					row.order_item_name = order_item_name;
					row.order_item_code = order_item_code;
					defaultData.push(row);
				}

				//現在時刻の取得
				const dateObj = new Date();

				//日付の指定
				defaultData.sort(function(a,b){
					if(a.date_add < b.date_add) return -1;
					if(a.date_add > b.date_add) return 1;
					return 0;
				});
				const dateS = defaultData[0].date_add;
				defaultData.sort(function(a,b){
					if(a.date_add > b.date_add) return -1;
					if(a.date_add < b.date_add) return 1;
					return 0;
				});
				const dateL = defaultData[0].date_add;
				const dateSObj     = new Date(dateS);
				const Syear        = dateSObj.getFullYear().toString();
				const Smonth       = ('00' + (dateSObj.getMonth()+1).toString()).slice(-2);
				const Sday         = ('00' + dateSObj.getDate().toString()).slice(-2);
				const startDate    = Syear + '-' + Smonth + '-' + Sday;
				const dateLObj     = new Date(dateL);
				const Lyear        = dateLObj.getFullYear().toString();
				const Lmonth       = ('00' + (dateLObj.getMonth()+1).toString()).slice(-2);
				const Lday         = ('00' + dateLObj.getDate().toString()).slice(-2);
				const lastDate     = Lyear + '-' + Lmonth + '-' + Lday;

				//データの保存
				this.setState({
					isLoading   : false,
					loadingPer  : 0,
					viewData    : viewData,
					defaultData : defaultData,
					dateTime    : this._formatDate(dateObj),
					datePart    : '',
					startDate   : startDate,
					lastDate    : lastDate,
				});
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				isLoading  : false,
				loadingPer : 0,
				ErrMsg     : err_msg,
			});
		}
	}
	async _getDataPart(datePart, jsonText) {

		//処理
		try{

			//選択できる日付範囲
			const year    = datePart.substr(0, 4);
			const month   = datePart.substr(4, 2);
			const dateObj = new Date(year, month, 0);
			const lastDay = dateObj.getDate();
			const startDate = year + '-' + month + '-01';
			const lastDate  = year + '-' + month + '-' + lastDay;

			if(jsonText){

				//JSON形式を変換
				const jsonData = JSON.parse(jsonText);

				//日付オブジェクト作成
				const dateTime = new Date(jsonData.dateTime);

				//データ取得
				const Items    = jsonData.Items;

				//データコピー
				const viewData = [];
				for(let row of Items){
					viewData.push(row);
				}

				//データコピー
				const defaultData = [];
				for(let row of Items){
					defaultData.push(row);
				}

				//データの保存
				this.setState({
					isLoading   : false,
					loadingPer  : 0,
					viewData    : viewData,
					defaultData : defaultData,
					dateTime    : this._formatDate(dateTime),
					datePart    : datePart,
					startDate   : startDate,
					lastDate    : lastDate,
				});

			}
			else{

				//ユーザ件数の取得（新しいもの）
				const CountQuery = `query queryOrderDatePartCount($date_part_jst: String!) {
					queryOrderDatePartCount(date_part_jst: $date_part_jst) {
						scannedCount
						nextToken
					}
				}`;
				const CountData = {
					date_part_jst: datePart,
				};
				let countRef  = await API.graphql(graphqlOperation(CountQuery, CountData));
				let itemCount = countRef.data.queryOrderDatePartCount.scannedCount;
				while (countRef.data.queryOrderDatePartCount.nextToken) {
					const Query = `query queryOrderDatePartCount($date_part_jst: String!, $nextToken: String) {
						queryOrderDatePartCount(date_part_jst: $date_part_jst, nextToken: $nextToken) {
							scannedCount
							nextToken
						}
					}`;
					const Data = {
						date_part_jst: datePart,
						nextToken: countRef.data.queryOrderDatePartCount.nextToken,
					};
					countRef  = await API.graphql(graphqlOperation(Query, Data));
					itemCount = itemCount + countRef.data.queryOrderDatePartCount.scannedCount;
				}

				//ユーザ件数の取得（古いもの）
				const OldCountQuery = `query queryOldOrderDatePartCount($date_part_jst: String!) {
					queryOldOrderDatePartCount(date_part_jst: $date_part_jst) {
						scannedCount
						nextToken
					}
				}`;
				const OldCountData = {
					date_part_jst: datePart,
				};
				countRef  = await API.graphql(graphqlOperation(OldCountQuery, OldCountData));
				itemCount = itemCount + countRef.data.queryOldOrderDatePartCount.scannedCount;
				while (countRef.data.queryOldOrderDatePartCount.nextToken) {
					const Query = `query queryOldOrderDatePartCount($date_part_jst: String!, $nextToken: String) {
						queryOldOrderDatePartCount(date_part_jst: $date_part_jst, nextToken: $nextToken) {
							scannedCount
							nextToken
						}
					}`;
					const Data = {
						date_part_jst: datePart,
						nextToken: countRef.data.queryOldOrderDatePartCount.nextToken,
					};
					countRef  = await API.graphql(graphqlOperation(Query, Data));
					itemCount = itemCount + countRef.data.queryOldOrderDatePartCount.scannedCount;
				}
				//進捗情報
				let loadingPer   = 1;
				/*
				if(itemCount){
					this.setState({
						loadingPer : loadingPer,
					});
				}
				*/

				//ユーザ情報の取得（新しいもの）
				const Query = `query queryOrderDatePart($date_part_jst: String!) {
					queryOrderDatePart(date_part_jst: $date_part_jst) {
						items {
							orderno
							cognitoid
							total_price
							payment_type
							payment_price
							carriage_type
							carriage
							cashdeli
							wrapping_type
							wrapping
							coupon_type
							coupon
							email
							name_1
							name_2
							name_furi_1
							name_furi_2
							zip_1
							zip_2
							pref
							addr_1
							addr_2
							addr_3
							tel_1
							tel_2
							tel_3
							o_name_1
							o_name_2
							o_name_furi_1
							o_name_furi_2
							o_zip_1
							o_zip_2
							o_pref
							o_addr_1
							o_addr_2
							o_addr_3
							o_tel_1
							o_tel_2
							o_tel_3
							o_com_name
							o_date_type
							o_date_h
							order_memo
							order_history_flag
							delivery_no
							delivery_date
							status
							memo
							flag
							not_ec_user_kbn
							date_add
							date_edit
							old_order_id
							order_items{
								orderno
								code
								name
								price
								sub_price
								piece
								piece_type
								date_add
								date_edit
							}
						}
						nextToken
					}
				}`;
				const Data = {
					date_part_jst: datePart,
				};
				let ref = await API.graphql(graphqlOperation(Query, Data));
				let Items = ref.data.queryOrderDatePart.items;

				//ユーザ情報の取得（未取得の続きデータがあった場合の処理）
				while (ref.data.queryOrderDatePart.nextToken) {
					const Query = `query queryOrderDatePart($date_part_jst: String!, $nextToken: String) {
						queryOrderDatePart(date_part_jst: $date_part_jst, nextToken: $nextToken) {
							items {
								orderno
								cognitoid
								total_price
								payment_type
								payment_price
								carriage_type
								carriage
								cashdeli
								wrapping_type
								wrapping
								coupon_type
								coupon
								email
								name_1
								name_2
								name_furi_1
								name_furi_2
								zip_1
								zip_2
								pref
								addr_1
								addr_2
								addr_3
								tel_1
								tel_2
								tel_3
								o_name_1
								o_name_2
								o_name_furi_1
								o_name_furi_2
								o_zip_1
								o_zip_2
								o_pref
								o_addr_1
								o_addr_2
								o_addr_3
								o_tel_1
								o_tel_2
								o_tel_3
								o_com_name
								o_date_type
								o_date_h
								order_memo
								order_history_flag
								delivery_no
								delivery_date
								status
								memo
								flag
								not_ec_user_kbn
								date_add
								date_edit
								old_order_id
								order_items{
									orderno
									code
									name
									price
									sub_price
									piece
									piece_type
									date_add
									date_edit
								}
							}
							nextToken
						}
					}`;
					const Data = {
						date_part_jst: datePart,
						nextToken: ref.data.queryOrderDatePart.nextToken,
					};
					//console.log(ref.data.queryOrderDatePart.nextToken);
					ref = await API.graphql(graphqlOperation(Query, Data));
					Items = Items.concat(ref.data.queryOrderDatePart.items);
					//進捗情報
					if(Items.length){
						loadingPer = Math.floor(Items.length / itemCount * 100);
						this.setState({
							loadingPer : loadingPer,
						});
					}
				}
				//console.log(Items.length);


				//ユーザ情報の取得（古いもの）
				const OldQuery = `query queryOldOrderDatePart($date_part_jst: String!) {
					queryOldOrderDatePart(date_part_jst: $date_part_jst) {
						items {
							orderno
							cognitoid
							total_price
							payment_type
							payment_price
							carriage_type
							carriage
							cashdeli
							wrapping_type
							wrapping
							coupon_type
							coupon
							email
							name_1
							name_2
							name_furi_1
							name_furi_2
							zip_1
							zip_2
							pref
							addr_1
							addr_2
							addr_3
							tel_1
							tel_2
							tel_3
							o_name_1
							o_name_2
							o_name_furi_1
							o_name_furi_2
							o_zip_1
							o_zip_2
							o_pref
							o_addr_1
							o_addr_2
							o_addr_3
							o_tel_1
							o_tel_2
							o_tel_3
							o_com_name
							o_date_type
							o_date_h
							order_memo
							order_history_flag
							delivery_no
							delivery_date
							status
							memo
							flag
							not_ec_user_kbn
							date_add
							date_edit
							old_order_id
							order_items{
								orderno
								code
								name
								price
								sub_price
								piece
								piece_type
								date_add
								date_edit
							}
						}
						nextToken
					}
				}`;
				const OldData = {
					date_part_jst: datePart,
				};
				ref = await API.graphql(graphqlOperation(OldQuery, OldData));
				Items = Items.concat(ref.data.queryOldOrderDatePart.items);

				//ユーザ情報の取得（未取得の続きデータがあった場合の処理）
				while (ref.data.queryOldOrderDatePart.nextToken) {
					const Query = `query queryOldOrderDatePart($date_part_jst: String!, $nextToken: String) {
						queryOldOrderDatePart(date_part_jst: $date_part_jst, nextToken: $nextToken) {
							items {
								orderno
								cognitoid
								total_price
								payment_type
								payment_price
								carriage_type
								carriage
								cashdeli
								wrapping_type
								wrapping
								coupon_type
								coupon
								email
								name_1
								name_2
								name_furi_1
								name_furi_2
								zip_1
								zip_2
								pref
								addr_1
								addr_2
								addr_3
								tel_1
								tel_2
								tel_3
								o_name_1
								o_name_2
								o_name_furi_1
								o_name_furi_2
								o_zip_1
								o_zip_2
								o_pref
								o_addr_1
								o_addr_2
								o_addr_3
								o_tel_1
								o_tel_2
								o_tel_3
								o_com_name
								o_date_type
								o_date_h
								order_memo
								order_history_flag
								delivery_no
								delivery_date
								status
								memo
								flag
								not_ec_user_kbn
								date_add
								date_edit
								old_order_id
								order_items{
									orderno
									code
									name
									price
									sub_price
									piece
									piece_type
									date_add
									date_edit
								}
							}
							nextToken
						}
					}`;
					const Data = {
						date_part_jst: datePart,
						nextToken: ref.data.queryOldOrderDatePart.nextToken,
					};
					//console.log(ref.data.queryOldOrderDatePart.nextToken);
					ref = await API.graphql(graphqlOperation(Query, Data));
					Items = Items.concat(ref.data.queryOldOrderDatePart.items);
					//進捗情報
					if(Items.length){
						loadingPer = Math.floor(Items.length / itemCount * 100);
						this.setState({
							loadingPer : loadingPer,
						});
					}
				}
				//console.log(Items.length);

				//データの判別
				if(Items.length === 0){

					//現在時刻の取得
					//const dateObj = new Date();

					//ストレージを定義
					//Storage.configure({ AWSS3: { bucket: 'mindwavestore.admin', region: 'ap-northeast-1', level: 'public' } });

					//S3に保存する
					//await Storage.put('_cache/Order_' + datePart + '.json', JSON.stringify({ Items:[], dateTime: dateObj.toISOString() }));

					//データがなかった時
					this.setState({
						isLoading : false,
						ErrMsg    : 'データがありません。',
						datePart  : datePart,
						startDate : startDate,
						lastDate  : lastDate,
					});
				}
				else{

					//'null'文字がある場合は''に変換
					Items.some((item, index) => Object.keys(item).some(key => { if(item[key] === 'null'){ Items[index][key] = '' } return false; }));

					//nullがある場合は''に変換
					Items.some((item, index) => Object.keys(item).some(key => { if(item[key] === null){ Items[index][key] = '' } return false; }));

					//データコピー
					const viewData = [];
					for(let row of Items){
						row.name_full = row.name_1 + ' ' + row.name_2;
						row.name_furi_full = row.name_furi_1 + ' ' + row.name_furi_2;
						let order_item_name = '';
						let order_item_code = '';
						for(const item of row.order_items){
							order_item_name = order_item_name + ' ' + item.name;
							order_item_code = order_item_code + ' ' + item.code;
						}
						row.order_item_name = order_item_name;
						row.order_item_code = order_item_code;
						viewData.push(row);
					}

					//データコピー
					const defaultData = [];
					for(let row of Items){
						row.name_full = row.name_1 + ' ' + row.name_2;
						row.name_furi_full = row.name_furi_1 + ' ' + row.name_furi_2;
						let order_item_name = '';
						let order_item_code = '';
						for(const item of row.order_items){
							order_item_name = order_item_name + ' ' + item.name;
							order_item_code = order_item_code + ' ' + item.code;
						}
						row.order_item_name = order_item_name;
						row.order_item_code = order_item_code;
						defaultData.push(row);
					}

					//現在時刻の取得
					const dateObj = new Date();

					//ストレージを定義
					//Storage.configure({ AWSS3: { bucket: 'mindwavestore.admin', region: 'ap-northeast-1', level: 'public' } });

					//S3に保存する
					//await Storage.put('_cache/Order_' + datePart + '.json', JSON.stringify({ Items:defaultData, dateTime: dateObj.toISOString() }));

					//データの保存
					this.setState({
						isLoading   : false,
						loadingPer  : 0,
						viewData    : viewData,
						defaultData : defaultData,
						dateTime    : this._formatDate(dateObj),
						datePart    : datePart,
						startDate   : startDate,
						lastDate    : lastDate,
					});
				}
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				isLoading  : false,
				loadingPer : 0,
				ErrMsg     : err_msg,
			});
		}
	}
	async _getData(datePart, jsonText) {

		if(datePart){
			await this._getDataPart(datePart, jsonText);
		}
		else{
			await this._getDataLatest();
		}

	}
	async _getBounceMail(){
		try{
			const Query = `query scanMailBounceLog {
				scanMailBounceLog {
					items {
						email
						date_add
						message_id
						source
						bounce_type
						bounce_sub_type
						bounce_action
						bounce_status
						bounce_code
					}
					nextToken
				}
			}`;
			let ref = await API.graphql(graphqlOperation(Query));
			let Items = ref.data.scanMailBounceLog.items;
			while (ref.data.scanMailBounceLog.nextToken) {
				const Query = `query scanMailBounceLog($nextToken: String) {
					scanMailBounceLog(nextToken: $nextToken) {
						items {
							email
							date_add
							message_id
							source
							bounce_type
							bounce_sub_type
							bounce_action
							bounce_status
							bounce_code
						}
						nextToken
					}
				}`;
				const Data = {
					nextToken: ref.data.scanMailBounceLog.nextToken,
				};
				ref = await API.graphql(graphqlOperation(Query, Data));
				Items = Items.concat(ref.data.scanMailBounceLog.items);
			}
			this.setState({
				bounceData   : Items,
			});
		}
		catch(error) {
			console.log(error);
		}		
	}
	async _getComplaintMail() {
		try{
			const Query = `query scanMailComplaintLog {
				scanMailComplaintLog {
					items {
						email
						date_add
						message_id
						source
						complaint_type
						complaint_user
					}
					nextToken
				}
			}`;
			let ref = await API.graphql(graphqlOperation(Query));
			let Items = ref.data.scanMailComplaintLog.items;
			while (ref.data.scanMailComplaintLog.nextToken) {
				const Query = `query scanMailComplaintLog($nextToken: String) {
					scanMailComplaintLog(nextToken: $nextToken) {
						items {
							email
							date_add
							message_id
							source
							complaint_type
							complaint_user
						}
						nextToken
					}
				}`;
				const Data = {
					nextToken: ref.data.scanMailComplaintLog.nextToken,
				};
				ref = await API.graphql(graphqlOperation(Query, Data));
				Items = Items.concat(ref.data.scanMailComplaintLog.items);
			}
			this.setState({
				complaintData: Items,
			});
		}
		catch(error) {
			console.log(error);
		}
	}
	handleDismiss() {
		this.setState({ ErrMsg: '' });
	}
	handleReload() {

		//データの再取得
		this.setState({
			isLoading   : true,
			viewData    : [],
			defaultData : [],
			selectIDs   : [],
			dateTime    : '',
		});

		//表示データ更新
		this._getData(this.state.datePart);
	}
	handleSearch(e){

		//Submitをキャンセル
		e.preventDefault();
		e.stopPropagation();

		//Formオブジェクトの確認
		const formObj     = e.currentTarget;
		if(!formObj){
			this.setState({
				ErrMsg     : '※不正なエラーが発生しました。<br/>',
			 });
			return(0);
		}

		//Formオブジェクトの確認
		if(formObj.checkValidity() === false){
			this.setState({
				ErrMsg     : '※入力項目にエラーがありました。<br/>',
			});
			return(0);
		}

		//対象データを取得
		let viewData = this.state.defaultData;

		//Formから直接取得する更新項目
		const search_start_date    = formObj.search_start_date.value;
		const search_last_date     = formObj.search_last_date.value;
		const search_ec_user_kbn   = formObj.search_ec_user_kbn.value;
		const search_payment_type  = formObj.search_payment_type.value;
		const search_carriage_type = formObj.search_carriage_type.value;
		const search_status        = formObj.search_status.value;
		const search_flag          = formObj.search_flag.value;
		const search_key           = formObj.search_key.value;

		//日付オブジェクトに変換
		const startDate = new Date(search_start_date);
		const lastDate  = new Date(search_last_date);

		//TIME形式に変換
		const startTime = startDate.getTime();
		const lastTime  = lastDate.getTime() + (86400 * 1000);

		//検索日付のチェック
		if(startTime > lastTime){
			this.setState({
				ErrMsg     : '※検索日付の指定に誤りがあります。<br/>',
			});
			return(0);
		}

		//日付検索
		viewData = viewData.filter((item) =>
			{
				const dateObj  = new Date(item.date_add);
				const dateTime = dateObj.getTime() + (9 * 60 * 60 * 1000);

				if(dateTime >= startTime && dateTime < lastTime){
					return true;
				}
				else{
					return false;
				}
			}
		);


		//検索文字オブジェクト
		const matchString = new RegExp(search_key);

		//検索
		viewData = viewData.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);

		//search_ec_user_kbn
		if(search_ec_user_kbn === "会員"){
			viewData = viewData.filter(item => item.not_ec_user_kbn === false );
		}
		else if(search_ec_user_kbn === "非会員"){
			viewData = viewData.filter(item => item.not_ec_user_kbn === true );
		}

		//search_payment_type
		if(search_payment_type){
			viewData = viewData.filter(item => item.payment_type === search_payment_type );
		}

		//ゆうパックの場合は、post＋宅急便として検索
		//if(search_carriage_type === "ゆうパック"){
		//	viewData = viewData.filter(item => item.carriage_type === "宅急便" );
		//	viewData = viewData.filter(item => item.flag === 'post' );
		//}
		//else if(search_carriage_type){
		if(search_carriage_type){
			viewData = viewData.filter(item => item.carriage_type === search_carriage_type );
		}


		//search_status
		if(search_status){
			viewData = viewData.filter(item => item.status === search_status );
		}

		//search_flag
		if(search_flag === "stationery"){
			viewData = viewData.filter(item => item.flag === 'stationery' );
		}
		else if(search_flag === "post"){
			viewData = viewData.filter(item => item.flag === 'post' );
		}
		else if(search_flag === "cosme"){
			viewData = viewData.filter(item => item.flag === 'cosme' );
		}

		//表示データ更新
		this.setState({ viewData: viewData});

	}
	async updateStatus(e){

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowStatus : false,
		});

		//更新処理
		try{
			//Stateから取得する更新項目
			const status  = this.state.status;

			//ステータスなければスキップ
			if(status){

				//日付処理
			        const date          = new Date();
			        const isoDate       = date.toISOString();

				//取得
				const selectIDs = this.state.selectIDs

				for(let orderno of selectIDs){


					const Data = {
						data : {
							orderno       : orderno,
							status        : status,
							date_edit     : isoDate,
						}
					};

					//最新のもの
					try{
						const Query = `mutation updateOrder($data: OrderInput!) {
							updateOrder(input: $data) {
								orderno
							}
						}`;
						await API.graphql(graphqlOperation(Query, Data));
					}
					catch(err){
						console.log(err);
					}

					//古いもの
					try{
						const Query = `mutation updateOldOrder($data: OrderInput!) {
							updateOldOrder(input: $data) {
								orderno
							}
						}`;
						await API.graphql(graphqlOperation(Query, Data));
					}
					catch(err){
						console.log(err);
					}

					//鑑査ログ
					await AuditLog('order', 'update', orderno, Data, 'ステータスを変更しました。');
				}
			}

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
		});


	}
	async handleSelectDate(e){

		//Submitをキャンセル
		e.preventDefault();
		e.stopPropagation();

		//日付の選択
		const datePart   = e.target.value;

		//データのクリア
		this.setState({
			isLoading   : true,
			viewData    : [],
			defaultData : [],
			selectIDs   : [],
			dateTime    : '',
			datePart    : '',
		});

		//表示データ更新
		//const jsonText = await this._getFile(datePart);
		//this._getData(datePart, jsonText);
		this._getData(datePart);
	}
	handleClose() {
		this.setState({
			isShowStatus : false,
			isSuccess    : false,
			ErrMsg       : '',
		});
	}
	showStatus(e) {
		if(e.target.value){
			this.setState({
				isShowStatus : true,
				status       : e.target.value,
			});
		}
	}
	setSelectIDs (selectIDs){

		//選択しているIDとCSV用データを保存
		this.setState({
			selectIDs : selectIDs,
		});

	}
	createCSV(flag){

		//デフォルトのデータ内から選択されているIDのデータのみ抽出
		const defaultData = this.state.defaultData.filter(item => this.state.selectIDs.indexOf(item.orderno) !== -1);

		//ソート
		defaultData.sort(
			(a, b) => {
				if (a.orderno < b.orderno) return -1;
				if (a.orderno > b.orderno) return 1;
				if (a.code < b.code) return -1;
				if (a.code > b.code) return 1;
				return 0;
			}
		);

		//CSV1データの作成
		const csvData = [];

		//売上データ
		if(flag === 1){

			//ソート　受注日時順に出力
			defaultData.sort(
				(a, b) => {
					if (a.date_add > b.date_add) return -1;
					if (a.date_add < b.date_add) return 1;
					return 0;
				}
			);

			for(let row of defaultData){
				if(row.order_items === null) {continue;}
				for(let item of row.order_items){
					csvData.push(
						{
							orderno         : row.orderno         ,
							cognitoid       : row.cognitoid       ,
							total_price     : row.total_price     ,
							payment_type    : row.payment_type    ,
							payment_price   : row.payment_price   ,
							carriage_type   : row.carriage_type   ,
							carriage        : row.carriage        ,
							cashdeli        : row.cashdeli        ,
							wrapping_type   : row.wrapping_type   ,
							wrapping        : row.wrapping        ,
							coupon_type     : row.coupon_type     ,
							coupon          : row.coupon          ,
							email           : row.email           ,
							name_1          : row.name_1          ,
							name_2          : row.name_2          ,
							name_furi_1     : row.name_furi_1     ,
							name_furi_2     : row.name_furi_2     ,
							zip_1           : row.zip_1           ,
							zip_2           : row.zip_2           ,
							pref            : row.pref            ,
							addr_1          : row.addr_1          ,
							addr_2          : row.addr_2          ,
							addr_3          : row.addr_3          ,
							tel_1           : row.tel_1           ,
							tel_2           : row.tel_2           ,
							tel_3           : row.tel_3           ,
							o_name_1        : row.o_name_1        ,
							o_name_2        : row.o_name_2        ,
							o_name_furi_1   : row.o_name_furi_1   ,
							o_name_furi_2   : row.o_name_furi_2   ,
							o_zip_1         : row.o_zip_1         ,
							o_zip_2         : row.o_zip_2         ,
							o_pref          : row.o_pref          ,
							o_addr_1        : row.o_addr_1        ,
							o_addr_2        : row.o_addr_2        ,
							o_addr_3        : row.o_addr_3        ,
							o_tel_1         : row.o_tel_1         ,
							o_tel_2         : row.o_tel_2         ,
							o_tel_3         : row.o_tel_3         ,
							o_com_name      : row.o_com_name      ,
							o_date_type     : row.o_date_type     ,
							o_date_h        : row.o_date_h        ,
							order_memo      : row.order_memo      ,
							order_history_flag : row.order_history_flag,
							delivery_no     : row.delivery_no     ,
							delivery_date   : row.delivery_date   ,
							status          : row.status          ,
							memo            : row.memo            ,
							flag            : row.flag            ,
							not_ec_user_kbn : row.not_ec_user_kbn ,
							code            : item.code           ,
							name            : item.name           ,
							price           : item.price          ,
							piece           : item.piece          ,
							piece_type      : item.piece_type     ,
							sub_price       : item.sub_price      ,
							date_add        : row.date_add        ,
							date_edit       : row.date_edit       ,
							old_order_id    : row.old_order_id    ,
						}
					);
				}
			}
		}
		//送り状データ
		else if(flag === 2){

			//ソート　受注日時順に出力
			defaultData.sort(
				(a, b) => {
					if (a.date_add > b.date_add) return -1;
					if (a.date_add < b.date_add) return 1;
					return 0;
				}
			);

			for(let row of defaultData){

				//住所、名前が同じ受注は送り主をクヌムに変更する。
				let sender_flag = false;
				if(
					row.zip_1  === row.o_zip_1  &&
					row.zip_2  === row.o_zip_2  &&
					row.pref   === row.o_pref   &&
					row.addr_1 === row.o_addr_1 &&
					row.addr_2 === row.o_addr_2 &&
					row.addr_3 === row.o_addr_3 &&
					row.name_1 === row.o_name_1 &&
					row.name_2 === row.o_name_2
				){
					sender_flag = true;
				}

				csvData.push(
					{
						column1  : row.orderno         ,
						column2  : row.payment_type === '代金引換'   ? 2 : (row.carriage_type === 'ゆうパケット' ? 3 : 0),
						column3  : '',
						column4  : '',
						column5  : '',
						column6  : row.o_date_type === '指定なし'       ? ''     : row.o_date_type,
						column7  : row.o_date_h    === '指定なし'       ? ''     :
						           row.o_date_h    === '午前中'         ? '0812' :
						           row.o_date_h    === '12：00～14：00' ? '1214' :
						           row.o_date_h    === '14：00～16：00' ? '1416' :
						           row.o_date_h    === '16：00～18：00' ? '1618' :
						           row.o_date_h    === '18：00～20：00' ? '1820' :
						           row.o_date_h    === '20：00～21：00' ? '2021' :
						           row.o_date_h    === '19：00～21：00' ? '1921' : '',
						column8  : '',
						column9  : row.o_tel_1 + '-' + row.o_tel_2 + '-' + row.o_tel_3,
						column10 : '',
						column11 : row.o_zip_1 + row.o_zip_2,
						column12 : row.o_pref + row.o_addr_1 + row.o_addr_2 + ' ',
						column13 : row.o_addr_3 === 'null' ? '' : row.o_addr_3,
						column14 : row.o_com_name === 'null' ? '' : row.o_com_name,
						column15 : '',
						column16 : row.o_name_1 + ' ' + row.o_name_2,
						column17 : '',
						column18 : '',
						column19 : '',
						column20 : sender_flag ? '06-7220-4222'                     : row.tel_1 + '-' + row.tel_2 + '-' + row.tel_3,
						column21 : '',
						column22 : sender_flag ? '5530004'                          : row.zip_1 + row.zip_2,
						column23 : sender_flag ? '大阪府大阪市福島区玉川4-15-11'    : row.pref + row.addr_1 + row.addr_2 + ' ',
						column24 : sender_flag ? 'タマガワファーストビル5F'         : row.addr_3 === 'null' ? '' : row.addr_3,
						column25 : sender_flag ? 'マインドウェイブオンラインストア' : row.name_1 + ' ' + row.name_2,
						column26 : '',
						column27 : '',
						column28 : '文房具',
						column29 : '',
						column30 : row.payment_type === '後払い決済' ? '代金後払いのため転送不可／本人確認必須' : '',
						column31 : '',
						column32 : '',
						column33 : '',
						column34 : row.payment_type === '代金引換' ? row.payment_price : '',
						column35 : '',
						column36 : '',
						column37 : '',
						column38 : '',
						column39 : '',
						column40 : '',
						column41 : '',
						column42 : '',
						column43 : '',
						column44 : '',
						column45 : '',
						column46 : '',
						column47 : '',
						column48 : '',
						column49 : '',
						column50 : '',
						column51 : '',
						column52 : '',
						column53 : '',
						column54 : '',
						column55 : '',
						column56 : '',
						column57 : '',
						column58 : '',
						column59 : '',
						column60 : '',
						column61 : '',
						column62 : '',
						column63 : '',
						column64 : '',
						column65 : '',
						column66 : '',
						column67 : '',
						column68 : '',
						column69 : '',
						column70 : '',
						column71 : '',
						column72 : '',
						column73 : '',
					}
				);
			}
		}
		//取引登録CSV
		if(flag === 3){

			//ソート　受注日時順に出力
			defaultData.sort(
				(a, b) => {
					if (a.date_add > b.date_add) return -1;
					if (a.date_add < b.date_add) return 1;
					return 0;
				}
			);

			for(let row of defaultData){
				if(row.order_items === null) {continue;}

				//住所、名前が同じか判定
				let sender_flag = false;
				if(
					row.zip_1  === row.o_zip_1  &&
					row.zip_2  === row.o_zip_2  &&
					row.pref   === row.o_pref   &&
					row.addr_1 === row.o_addr_1 &&
					row.addr_2 === row.o_addr_2 &&
					row.addr_3 === row.o_addr_3 &&
					row.name_1 === row.o_name_1 &&
					row.name_2 === row.o_name_2
				){
					sender_flag = true;
				}

				//日付の整形
				const dateObj      = new Date(row.date_add);
				const year         = dateObj.getFullYear().toString();
				const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
				const day          = ('00' + dateObj.getDate().toString()).slice(-2);
				const date_add_ymd = year + '/' + month + '/' + day;

				//件数判定
				let j = 0;

				for(let item of row.order_items){
					j++;
					if(j === 1){
						csvData.push(
							{
								'加盟店取引ID'       : row.orderno,
								'注文日'             : date_add_ymd,
								'会社名'             : '',
								'部署名'             : '',
								'氏名'               : row.name_1 + ' ' + row.name_2,
								'氏名（カナ）'       : row.name_furi_1 + ' ' + row.name_furi_2,
								'郵便番号'           : row.zip_1 + '-' + row.zip_2,
								'住所'               : row.pref + row.addr_1 + row.addr_2 + '　' + row.addr_3,
								'電話番号1'          : row.tel_1 + '-' + row.tel_2 + '-' + row.tel_3,
								'電話番号2'          : '',
								'メールアドレス1'    : row.email,
								'メールアドレス2'    : '',
								'決済種別'           : '2',
								'顧客請求金額'       : row.payment_price,
								'GMO拡張項目1'       : '',
								'GMO拡張項目2'       : '',
								'GMO拡張項目3'       : '',
								'配送先会社名'       : sender_flag ? '' : row.o_com_name,
								'配送先部署名'       : '',
								'配送先氏名'         : sender_flag ? '' : row.o_name_1 + ' ' + row.o_name_2,
								'配送先氏名（カナ）' : sender_flag ? '' : row.o_name_furi_1 + ' ' + row.o_name_furi_2,
								'配送先郵便番号'     : sender_flag ? '' : row.o_zip_1 + '-' + row.o_zip_2,
								'配送先住所'         : sender_flag ? '' : row.o_pref + row.o_addr_1 + row.o_addr_2 + '　' + row.o_addr_3,
								'配送先電話番号'     : sender_flag ? '' : row.o_tel_1 + '-' + row.o_tel_2 + '-' + row.o_tel_3,
								'商品名'             : item.name,
								'単価'               : item.price,
								'数量'               : item.piece,
								'GMO拡張項目4'       : '',
								'GMO拡張項目5'       : '',
								'GMO拡張項目6'       : '',
								'GMO拡張項目7'       : '',
							}
						);
					}
					else{
						csvData.push(
							{
								'加盟店取引ID'       : row.orderno,
								'注文日'             : date_add_ymd,
								'会社名'             : '',
								'部署名'             : '',
								'氏名'               : '',
								'氏名（カナ）'       : '',
								'郵便番号'           : '',
								'住所'               : '',
								'電話番号1'          : '',
								'電話番号2'          : '',
								'メールアドレス1'    : '',
								'メールアドレス2'    : '',
								'決済種別'           : '',
								'顧客請求金額'       : '',
								'GMO拡張項目1'       : '',
								'GMO拡張項目2'       : '',
								'GMO拡張項目3'       : '',
								'配送先会社名'       : '',
								'配送先部署名'       : '',
								'配送先氏名'         : '',
								'配送先氏名（カナ）' : '',
								'配送先郵便番号'     : '',
								'配送先住所'         : '',
								'配送先電話番号'     : '',
								'商品名'             : item.name,
								'単価'               : item.price,
								'数量'               : item.piece,
								'GMO拡張項目4'       : '',
								'GMO拡張項目5'       : '',
								'GMO拡張項目6'       : '',
								'GMO拡張項目7'       : '',
							}
						);
					}
				}
				//送料がある場合
				if(row.carriage){
					csvData.push(
						{
							'加盟店取引ID'       : row.orderno,
							'注文日'             : date_add_ymd,
							'会社名'             : '',
							'部署名'             : '',
							'氏名'               : '',
							'氏名（カナ）'       : '',
							'郵便番号'           : '',
							'住所'               : '',
							'電話番号1'          : '',
							'電話番号2'          : '',
							'メールアドレス1'    : '',
							'メールアドレス2'    : '',
							'決済種別'           : '',
							'顧客請求金額'       : '',
							'GMO拡張項目1'       : '',
							'GMO拡張項目2'       : '',
							'GMO拡張項目3'       : '',
							'配送先会社名'       : '',
							'配送先部署名'       : '',
							'配送先氏名'         : '',
							'配送先氏名（カナ）' : '',
							'配送先郵便番号'     : '',
							'配送先住所'         : '',
							'配送先電話番号'     : '',
							'商品名'             : '送料',
							'単価'               : row.carriage,
							'数量'               : '1',
							'GMO拡張項目4'       : '',
							'GMO拡張項目5'       : '',
							'GMO拡張項目6'       : '',
							'GMO拡張項目7'       : '',
						}
					);
				}
				//代引手数料がある場合
				if(row.cashdeli){
					csvData.push(
						{
							'加盟店取引ID'       : row.orderno,
							'注文日'             : date_add_ymd,
							'会社名'             : '',
							'部署名'             : '',
							'氏名'               : '',
							'氏名（カナ）'       : '',
							'郵便番号'           : '',
							'住所'               : '',
							'電話番号1'          : '',
							'電話番号2'          : '',
							'メールアドレス1'    : '',
							'メールアドレス2'    : '',
							'決済種別'           : '',
							'顧客請求金額'       : '',
							'GMO拡張項目1'       : '',
							'GMO拡張項目2'       : '',
							'GMO拡張項目3'       : '',
							'配送先会社名'       : '',
							'配送先部署名'       : '',
							'配送先氏名'         : '',
							'配送先氏名（カナ）' : '',
							'配送先郵便番号'     : '',
							'配送先住所'         : '',
							'配送先電話番号'     : '',
							'商品名'             : '決済手数料',
							'単価'               : row.cashdeli,
							'数量'               : '1',
							'GMO拡張項目4'       : '',
							'GMO拡張項目5'       : '',
							'GMO拡張項目6'       : '',
							'GMO拡張項目7'       : '',
						}
					);
				}
				//ギフトラッピング手数料がある場合
				if(row.wrapping){
					csvData.push(
						{
							'加盟店取引ID'       : row.orderno,
							'注文日'             : date_add_ymd,
							'会社名'             : '',
							'部署名'             : '',
							'氏名'               : '',
							'氏名（カナ）'       : '',
							'郵便番号'           : '',
							'住所'               : '',
							'電話番号1'          : '',
							'電話番号2'          : '',
							'メールアドレス1'    : '',
							'メールアドレス2'    : '',
							'決済種別'           : '',
							'顧客請求金額'       : '',
							'GMO拡張項目1'       : '',
							'GMO拡張項目2'       : '',
							'GMO拡張項目3'       : '',
							'配送先会社名'       : '',
							'配送先部署名'       : '',
							'配送先氏名'         : '',
							'配送先氏名（カナ）' : '',
							'配送先郵便番号'     : '',
							'配送先住所'         : '',
							'配送先電話番号'     : '',
							'商品名'             : 'ギフトラッピング手数料',
							'単価'               : row.wrapping,
							'数量'               : '1',
							'GMO拡張項目4'       : '',
							'GMO拡張項目5'       : '',
							'GMO拡張項目6'       : '',
							'GMO拡張項目7'       : '',
						}
					);
				}
				//後払い手数料がある場合
				/*
				if(row.cashdeli_2){
					csvData.push(
						{
							'加盟店取引ID'       : row.orderno,
							'注文日'             : date_add_ymd,
							'会社名'             : '',
							'部署名'             : '',
							'氏名'               : '',
							'氏名（カナ）'       : '',
							'郵便番号'           : '',
							'住所'               : '',
							'電話番号1'          : '',
							'電話番号2'          : '',
							'メールアドレス1'    : '',
							'メールアドレス2'    : '',
							'決済種別'           : '',
							'顧客請求金額'       : '',
							'GMO拡張項目1'       : '',
							'GMO拡張項目2'       : '',
							'GMO拡張項目3'       : '',
							'配送先会社名'       : '',
							'配送先部署名'       : '',
							'配送先氏名'         : '',
							'配送先氏名（カナ）' : '',
							'配送先郵便番号'     : '',
							'配送先住所'         : '',
							'配送先電話番号'     : '',
							'商品名'             : '後払い手数料',
							'単価'               : row.cashdeli_2,
							'数量'               : '1',
							'GMO拡張項目4'       : '',
							'GMO拡張項目5'       : '',
							'GMO拡張項目6'       : '',
							'GMO拡張項目7'       : '',
						}
					);
				}
				*/
			}
		}
		//出荷報告
		else if(flag === 4){

			//ソート　受注日時順に出力
			defaultData.sort(
				(a, b) => {
					if (a.date_add > b.date_add) return -1;
					if (a.date_add < b.date_add) return 1;
					return 0;
				}
			);


			for(let row of defaultData){
				csvData.push(
					{
						'運送会社名'   : row.flag === 'post'                  ? 'ゆうパック' :
						                 row.carriage_type === 'ゆうパケット' ? 'ゆうパック' : 'ヤマト運輸' ,
						'伝票番号'     : row.delivery_no,
						'加盟店注文日' : '',
						'GMO取引ID'    : '',
						'加盟店取引ID' : row.orderno,
						'発送通番'     : '',
						'決済手段'     : '',
						'顧客請求金額' : '',
						'購入者名'     : '',
						'配達先氏名'   : '',
						'配達先住所'   : '',
						'審査結果'     : '',
					}
				);
			}
		}
		//発送日・伝票番号
		else if(flag === 5){

			//ソート　受注日時順に出力
			defaultData.sort(
				(a, b) => {
					if (a.date_add > b.date_add) return -1;
					if (a.date_add < b.date_add) return 1;
					return 0;
				}
			);


			for(let row of defaultData){
				csvData.push(
					{
						'受注番号'  : row.orderno,
						'発送日'    : row.delivery_date,
						'伝票番号'  : row.delivery_no,
					}
				);
			}
		}

		//データ更新
		this.setState({
			csvData : csvData,
		});

	}
	async createFormA(){

		//ストレージを定義
		Storage.configure({ AWSS3: { bucket: 'mindwavestore.admin', region: 'ap-northeast-1', level: 'private' } });

		//S3に保存する
		await Storage.put('formA.json', JSON.stringify({ selectIDs:this.state.selectIDs }));
		window.open('/Order/FormA/', '_blank') ;
		//href={'/Order/FormA/' + JSON.stringify(this.state.selectIDs) + '/'}
	}
	async createFormB(){

		//ストレージを定義
		Storage.configure({ AWSS3: { bucket: 'mindwavestore.admin', region: 'ap-northeast-1', level: 'private' } });

		//S3に保存する
		await Storage.put('formB.json', JSON.stringify({ selectIDs:this.state.selectIDs }));
		window.open('/Order/FormB/', '_blank') ;
		//href={'/Order/FormB/' + JSON.stringify(this.state.selectIDs) + '/'}
	}
	async MailSend(){

		//ストレージを定義
		Storage.configure({ AWSS3: { bucket: 'mindwavestore.admin', region: 'ap-northeast-1', level: 'private' } });

		//S3削除
		try{
			await Storage.remove('mailSend.json');
		}
		catch(err){
			//console.log(err);
		}

		//S3に保存する
		await Storage.put('mailSend.json', JSON.stringify({ selectIDs:this.state.selectIDs }));

		//画面移動
		window.open('/Order/Multiple/MailSend/', '_blank') ;
		//href={'/Order/FormA/' + JSON.stringify(this.state.selectIDs) + '/'}
	}
	async componentDidMount () {

		await this._getBounceMail();
		await this._getComplaintMail();
		
		//日付オブジェクト作成
		const dateObj       = new Date();
		const year          = dateObj.getFullYear().toString();
		const month         = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);

		//初期選択値
		let datePart = '';

		//日付選択肢
		const dateData = [{ text : '直近三ヶ月', value : ''}];
		let i = 0; let flag = true;
		while (flag) {

			const dateObj      = new Date(year, month - i, 0);
			const year_l       = dateObj.getFullYear().toString();
			const month_l      = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const date_text_l  = '' + year_l + '年' + month_l + '月';
			const date_value_l = '' + year_l + month_l;
			const data = {
				text  : date_text_l,
				value :date_value_l,

			};
			dateData.push(data);
			i++;
			if(date_value_l === '201108'){
				flag = false;
			}
			if(i === 1){
				datePart = date_value_l;
			}
		}

		//日付関連のみ
		this.setState({
			dateData    : dateData,
		});

		//データ読み出し
		await this._getData(datePart);
	}
	render() {
		if(this.state.isLoading && this.state.loadingPer){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={{ span: 6, offset: 3 }}>
						<ProgressBar now={this.state.loadingPer} label={this.state.loadingPer + `%`} animated />
					</Col>
				</Row>
			);
		}
		else if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleDismiss} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleDismiss}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>更新しました。</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowStatus} onHide={this.handleClose} centered>
						<Modal.Body>
							本当に更新しますか？<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.updateStatus}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>

					<Row>
						<Col sm={12}>
						<Form onSubmit={e => this.handleSearch(e)}>
						<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap float-left">
							<Row style={{ marginTop: 10 }}>
								<Col sm={3}>
									<Form.Group controlId="dateData">
										<Form.Control as="select" defaultValue={this.state.datePart} onChange={e => this.handleSelectDate(e)}>
											{this.state.dateData.map( (x, i) => { return (<option key={i} value={x.value}>{x.text}</option>); })}
										</Form.Control>
									</Form.Group>
								</Col>
								<Col sm={3}>
									<Form.Group controlId="search_start_date">
									        <Form.Control name="search_start_date" type="date" defaultValue={this.state.startDate} min={this.state.startDate} max={this.state.lastDate} />
									</Form.Group>
								</Col>
								<Col sm={3}>
									<Form.Group controlId="search_last_date">
									        <Form.Control name="search_last_date" type="date" defaultValue={this.state.lastDate}  min={this.state.startDate} max={this.state.lastDate} />
									</Form.Group>
								</Col>
							</Row>
							<Row style={{ marginTop: 10 }}>
								<Col sm={2}>
									<Form.Group controlId="search_ec_user_kbn">
										<Form.Label>会員・非会員</Form.Label>
										<Form.Control as="select" name="search_ec_user_kbn" defaultValue="">
										<option value="">すべて</option>
										<option value="会員">会員</option>
										<option value="非会員">非会員</option>
										</Form.Control>
									</Form.Group>
								</Col>
								<Col sm={2}>
									<Form.Group controlId="search_payment_type">
										<Form.Label>決済方法</Form.Label>
										<Form.Control as="select" name="search_payment_type" defaultValue="">
										<option value="">すべて</option>
										<option value="銀行振込">銀行振込</option>
										<option value="クレジットカード決済">クレジットカード決済</option>
										<option value="代金引換">代金引換</option>
										<option value="後払い決済">後払い決済</option>
										<option value="後払い">後払い</option>
										<option value="未定義">未定義</option>
										</Form.Control>
									</Form.Group>
								</Col>
								<Col sm={2}>
									<Form.Group controlId="search_carriage_type">
										<Form.Label>配送方法</Form.Label>
										<Form.Control as="select" name="search_carriage_type" defaultValue="">
										<option value="">すべて</option>
										<option value="ゆうパケット">ゆうパケット</option>
										<option value="宅急便">宅急便</option>
										<option value="ゆうパック">ゆうパック</option>
										<option value="未定義">未定義</option>
										</Form.Control>
									</Form.Group>
								</Col>
								<Col sm={2}>
									<Form.Group controlId="search_status">
										<Form.Label>ステータス</Form.Label>
										<Form.Control as="select" name="search_status" defaultValue="">
										<option value="">すべて</option>
										<option value="仮受注">仮受注</option>
										<option value="未処理">未処理</option>
										<option value="処理中">処理中</option>
										<option value="出荷済み">出荷済み</option>
										<option value="予約・入荷待ち">予約・入荷待ち</option>
										<option value="決済処理待ち">決済処理待ち</option>
										<option value="確認中">確認中</option>
										<option value="返信待ち">返信待ち</option>
										<option value="商品手配中">商品手配中</option>
										<option value="後払い与信中">後払い与信中</option>
										<option value="キャンセル">キャンセル</option>
										<option value="未定義">未定義</option>
										</Form.Control>
									</Form.Group>
								</Col>
								<Col sm={2}>
									<Form.Group controlId="search_flag">
										<Form.Label>サイト区分</Form.Label>
										<Form.Control as="select" name="search_flag" defaultValue="">
										<option value="">すべて</option>
										<option value="stationery">stationery</option>
										<option value="post">post</option>
										<option value="cosme">cosme</option>
										</Form.Control>
									</Form.Group>
								</Col>
							</Row>
							<Row style={{ marginTop: 10 }}>
								<Col sm={8}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="検索" />
									</Form.Group>
								</Col>
								<Col sm={2}>
									<Button variant="dark" type="submit" block >検索</Button>
								</Col>
							</Row>
						</Container>
						</Form>
						</Col>
					</Row>


					<Row style={{ marginTop: 20, marginBottom: 20 }}>
						<Col sm={6}>
							<Badge variant="secondary" className="p-1">
								<h6 style={{ marginBottom: 0 }}>　データ取得時間：{ this.state.dateTime ? this.state.dateTime : 'データなし' }　</h6>
							</Badge>　
							<Badge pill variant="secondary" className="p-1">
								<h6 style={{ marginBottom: 0 }}>　{ this.state.viewData.length ? this.state.viewData.length.toLocaleString() : '0' }件　</h6>
							</Badge>　
							{/*チェック件数追加20200619*/}
							<Badge pill variant="primary" className="p-1">
								<h6 style={{ marginBottom: 0 }}>　選択件数：{ this.state.selectIDs.length }件　</h6>
							</Badge>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="danger" onClick={()=>{ this.props.history.push('/Order/New/'); }}>
								新規登録
							</Button>　
							<Button variant="dark" onClick={ this.handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<MyTable data={this.state.viewData} bounceData={this.state.bounceData} complaintData={this.state.complaintData} setSelectIDs={this.setSelectIDs} {...this.props}/>
						</Col>
					</Row>
					<hr/>

					<Form>

						<div  style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
							<Row>
								<Col sm={12}>

									{/*
									<CSVLink data={this.state.csvData} filename={"order1.csv"} className={'btn btn-secondary ' + (this.state.selectIDs.length === 0 ? 'disabled' : '')} onClick={() => { this.createCSV(1); }} >売上データを出力する</CSVLink>　
									<CSVLink data={this.state.csvData} filename={"order2.csv"} className={'btn btn-secondary ' + (this.state.selectIDs.length === 0 ? 'disabled' : '')} onClick={() => { this.createCSV(2); }} >送り状発行データを出力する</CSVLink>　
									<CSVLink data={this.state.csvData} filename={"torihikitouroku.csv"} className={'btn btn-secondary ' + (this.state.selectIDs.length === 0 ? 'disabled' : '')} onClick={() => { this.createCSV(3); }} enclosingCharacter={''} >取引登録CSVデータを出力する</CSVLink>　
									<CSVLink data={this.state.csvData} filename={"shukkahokoku.csv"} className={'btn btn-secondary ' + (this.state.selectIDs.length === 0 ? 'disabled' : '')} onClick={() => { this.createCSV(4); }} >出荷報告CSVデータを出力する</CSVLink>　
									<CSVLink data={this.state.csvData} filename={"csv2018070601.csv"} className={'btn btn-secondary ' + (this.state.selectIDs.length === 0 ? 'disabled' : '')} onClick={() => { this.createCSV(5); }} >発送日・伝票番号を出力する</CSVLink>　
									*/}

									<CSVDownload2 data={this.state.csvData} filename={"order1.csv"}          className={'btn btn-secondary ' + (this.state.selectIDs.length === 0 ? 'disabled' : '')} onClick={() => { this.createCSV(1); }}                   >売上データを出力する</CSVDownload2>　
									<CSVDownload2 data={this.state.csvData} filename={"order2.csv"}          className={'btn btn-secondary ' + (this.state.selectIDs.length === 0 ? 'disabled' : '')} onClick={() => { this.createCSV(2); }} skipHeader={true} >送り状発行データを出力する</CSVDownload2>　
									<CSVDownload data={this.state.csvData} filename={"torihikitouroku.csv"} className={'btn btn-secondary ' + (this.state.selectIDs.length === 0 ? 'disabled' : '')} onClick={() => { this.createCSV(3); }}                   >取引登録CSVデータを出力する</CSVDownload>　
									<CSVDownload data={this.state.csvData} filename={"shukkahokoku.csv"}    className={'btn btn-secondary ' + (this.state.selectIDs.length === 0 ? 'disabled' : '')} onClick={() => { this.createCSV(4); }}                   >出荷報告CSVデータを出力する</CSVDownload>　
									<CSVDownload data={this.state.csvData} filename={"csv2018070601.csv"}   className={'btn btn-secondary ' + (this.state.selectIDs.length === 0 ? 'disabled' : '')} onClick={() => { this.createCSV(5); }}                   >発送日・伝票番号を出力する</CSVDownload>　

									<Button variant="secondary" disabled={!this.state.selectIDs.length} onClick={this.createFormA}>受注伝票を出力する</Button>　
									<Button variant="secondary" disabled={!this.state.selectIDs.length} onClick={this.createFormB}>明細書を出力する</Button>　
								</Col>
							</Row>
						</div>

						<Row>
							<Col sm={3}>
								<div  style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
									<Form.Group as={Row} controlId="allstatus" onChange={e => this.showStatus(e)} >
										<Form.Label column sm={5}>一括ステータス変更</Form.Label>
										<Col sm={7}>
												<Form.Control as="select" name="allstatus" disabled={!this.state.selectIDs.length} >
												<option value="">---選択してください---</option>
												<option value="仮受注">仮受注</option>
										<option value="未処理">未処理</option>
										<option value="処理中">処理中</option>
										<option value="出荷済み">出荷済み</option>
										<option value="予約・入荷待ち">予約・入荷待ち</option>
										<option value="決済処理待ち">決済処理待ち</option>
										<option value="確認中">確認中</option>
										<option value="返信待ち">返信待ち</option>
										<option value="商品手配中">商品手配中</option>
										<option value="後払い与信中">後払い与信中</option>
										<option value="キャンセル">キャンセル</option>
										<option value="未定義">未定義</option>
												</Form.Control>
										</Col>
									</Form.Group>
								</div>
							</Col>
							<Col sm={2}>
								<div  style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
										<Button variant="secondary" disabled={!this.state.selectIDs.length} onClick={this.MailSend}>一括メール送信</Button>
								</div>
							</Col>
						</Row>
					</Form>

				</Container>
			);
		}
	}
}

export default withRouter(Default);
