import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Modal,
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Table,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import ItemSelect from '../../Objects/ItemSelect';
import { AuditLog } from '../../Objects/AuditLog';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading     : true,
			isNotFound    : false,
			isValidated1  : false,
			isValidated2  : false,
			isValidated3  : false,
			isSuccess     : false,
			isShowModal   : false,
			isItemSelect  : false,
			isLockForm    : false,
			formObj       : null,
			formSelect    : '',
			ErrMsg        : '',
			viewData      : [],
			defaultData   : [],
			total_price   : '',
			payment_type  : '',
			payment_price : '',
			carriage_type : '',
			carriage      : '',
			wrapping_type : '',
			wrapping      : '',
			coupon_type   : '',
			coupon        : '',
			cashdeli      : '',
			status        : '',
			date_add      : '',
			date_edit     : '',
			name          : '',
			price         : '',
			piece         : '',
			piece_type    : '',
		}
		this._reload              = this._reload.bind(this);
		this._formatDate          = this._formatDate.bind(this);
		this._getData             = this._getData.bind(this);
		this.handleDismiss        = this.handleDismiss.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
		this.itemAdd              = this.itemAdd.bind(this);
		this.itemUpdate           = this.itemUpdate.bind(this);
		this.itemUpdateEtc        = this.itemUpdateEtc.bind(this);
		this.itemDelete           = this.itemDelete.bind(this);
		this.itemSubmit           = this.itemSubmit.bind(this);
		this.itemSet              = this.itemSet.bind(this);
		this.onClickSelect        = this.onClickSelect.bind(this);
		this.OrderDataUpdate      = this.OrderDataUpdate.bind(this);
	}
	_reload() {
		//子ウィンドウからの呼び出し用
		this.handleReload();
	}
	_formatDate(dateObj) {

		//日付の整形
		const year        = dateObj.getFullYear().toString();
		const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day         = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second      = ('00' + dateObj.getSeconds().toString()).slice(-2);

		const text = year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分' + second + '秒';

		return (text);
	}
	async _getData() {
		
		//処理
		try{
			//IDの取得
			const orderno = this.props.match.params.orderno;
			if(!orderno){
					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
			}
			
			//データ取得
			const Query = `query getOrder($orderno: String!) {
				getOrder(orderno: $orderno) {
					total_price
					payment_type
					payment_price
					carriage_type
					carriage
					wrapping_type
					wrapping
					coupon_type
					coupon
					cashdeli
					status
					date_add
					date_edit
					order_items{
						orderno
						code
						name
						price
						sub_price
						piece
						piece_type
						date_add
						date_edit
					}
				}
			}`;
			const Data = { orderno: orderno };
			const ref  = await API.graphql(graphqlOperation(Query, Data));
			let   item = ref.data.getOrder;
			
			//データが空かどうか
			if(item === null){

				//データ取得
				const Query = `query getOldOrder($orderno: String!) {
					getOldOrder(orderno: $orderno) {
						total_price
						payment_type
						payment_price
						carriage_type
						carriage
						wrapping_type
						wrapping
						coupon_type
						coupon
						cashdeli
						status
						date_add
						date_edit
						order_items{
							orderno
							code
							name
							price
							sub_price
							piece
							piece_type
							date_add
							date_edit
						}
					}
				}`;
				const Data = { orderno: orderno };
				const ref  = await API.graphql(graphqlOperation(Query, Data));
				item = ref.data.getOldOrder;


				//データが空かどうか
				if(item === null){
					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
				}
			}
			
			const total_price   = item.total_price;
			const payment_type  = item.payment_type;
			const payment_price = item.payment_price;
			const carriage_type = item.carriage_type;
			const carriage      = item.carriage;
			const wrapping_type = item.wrapping_type;
			const wrapping      = item.wrapping;
			const coupon_type   = item.coupon_type;
			const coupon        = item.coupon;
			const cashdeli      = item.cashdeli;
			const status        = item.status === 'null' ? '未定義' : item.status;
			
			//日付
			const date_add         = new Date(item.date_add);
			const date_add_year    = date_add.getFullYear().toString();
			const date_add_month   = ('00' + (date_add.getMonth()+1).toString()).slice(-2);
			const date_add_day     = ('00' + date_add.getDate().toString()).slice(-2);
			const date_add_hour    = ('00' + date_add.getHours().toString()).slice(-2);
			const date_add_minute  = ('00' + date_add.getMinutes().toString()).slice(-2);
			const date_add_second  = ('00' + date_add.getSeconds().toString()).slice(-2);
			const date_edit        = new Date(item.date_edit);
			const date_edit_year   = date_add.getFullYear().toString();
			const date_edit_month  = ('00' + (date_edit.getMonth()+1).toString()).slice(-2);
			const date_edit_day    = ('00' + date_edit.getDate().toString()).slice(-2);
			const date_edit_hour   = ('00' + date_edit.getHours().toString()).slice(-2);
			const date_edit_minute = ('00' + date_edit.getMinutes().toString()).slice(-2);
			const date_edit_second = ('00' + date_edit.getSeconds().toString()).slice(-2);

			//受注商品の取得
			const order_items   = item.order_items;
			
			//データコピー
			let Items = [];
			for(let row of order_items){
				Items.push(row);
			}
			
			//データの判別
			if(Items.length === 0){
				//データがなかった時
				//this.setState({
				//	isLoading : false,
				//	ErrMsg    : 'データがありません。',
				//});
			}
			else{
			
				//'null'文字がある場合は''に変換
				Items.some((item, index) => Object.keys(item).some(key => { if(item[key] === 'null'){ Items[index][key] = '' } return false; }));
				
				//データをソート
				Items.sort(
					(a, b) => {
						if (a.name < b.name) return -1;
						if (a.name > b.name) return 1;
						return 0;
					}
				);

			}
			
			//データコピー
			const viewData = [];
			for(let row of Items){
				viewData.push(row);
			}
			
			//データコピー
			const defaultData = [];
			for(let row of Items){
				defaultData.push(row);
			}
			
			//データの保存
			this.setState({
				isLoading     : false,
				viewData      : viewData,
				defaultData   : defaultData,
				orderno       : orderno,
				total_price   : total_price ? total_price : 0,
				payment_type  : payment_type,
				payment_price : payment_price ? payment_price : 0,
				carriage_type : carriage_type,
				carriage      : carriage ? carriage : 0,
				wrapping_type : wrapping_type,
				wrapping      : wrapping ? wrapping : 0,
				coupon_type   : coupon_type,
				coupon        : coupon ? coupon : 0,
				cashdeli      : cashdeli ? cashdeli : 0,
				status        : status,
				date_add      : date_add_year + '年' + date_add_month + '月' + date_add_day + '日' + date_add_hour + '時' + date_add_minute + '分' + date_add_second + '秒',
				date_edit     : date_edit_year + '年' + date_edit_month + '月' + date_edit_day + '日' + date_edit_hour + '時' + date_edit_minute + '分' + date_edit_second + '秒',
			});

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				isLoading  : false,
				ErrMsg     : err_msg,
			});
		}
	}
	handleDismiss() {
		this.setState({ ErrMsg: '' });
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
			viewData    : [],
			defaultData : [],
		});
		this._getData();
	}
	itemAdd(event) {

		event.preventDefault();
		event.stopPropagation();

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isValidated3 : true,
			isLockForm   : true,
		});

		//データ
		const viewData = this.state.viewData;

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//Formから直接取得する更新項目
			const code       = formObj.code.value;
			const name       = formObj.name.value;
			const price      = Number(formObj.price.value);
			const piece      = Number(formObj.piece.value);
			const piece_type = formObj.piece_type.value;
			const sub_price  = (price * piece);

			//元データに存在するかどうか
			if(viewData.filter(x => x.code === code).length !== 0){
				this.setState({
					ErrMsg     : 'すでに同じ商品コードが登録されています。<br/>',
					isLockForm : false,
				 });
				return(0);
			}
			
			//ハッシュデータ作成
			const hash = {
				code       : code,
				name       : name,
				price      : price,
				piece      : piece,
				piece_type : piece_type,
				sub_price  : sub_price,
			};
			
			//配列に追加
			viewData.push(hash);
			
			//フォームリセット
			formObj.reset();
			
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//合計計算
		let total_price = 0;
		viewData.some( x => { total_price  = total_price + Number(x.sub_price); return false; } );

		//更新成功
		this.setState({
			isValidated3  : false,
			isLockForm    : false,
			viewData      : viewData,
			total_price   : total_price,
			payment_price : (Number(this.state.carriage) + Number(this.state.cashdeli) + Number(this.state.wrapping) + Number(this.state.coupon) + Number(total_price)),
		});
	
	}
	itemUpdate(code, objName, event) {

		//データ
		const viewData = this.state.viewData;

		//インデックス
		let index = 0;

		//抽出
		const selectData = viewData.filter((x, i) => { if(x.code === code){ index = i; return true; } else { return false; } });

		//各項目
		let name       = selectData[0].name;
		let price      = selectData[0].price;
		let piece      = selectData[0].piece;
		let piece_type = selectData[0].piece_type;
		let sub_price  = 0;

		//各項目ごとに変更する
		if(objName === 'name'){
			name       = event.target.value;
		}
		else if(objName === 'price'){
			const value = (Number(event.target.value) >= 0 && Number(event.target.value) < 100000000) ? Number(event.target.value) : 0;
			price      = value;
			sub_price  = (Number(piece) * value);
		}
		else if(objName === 'piece'){
			const value = (Number(event.target.value) >= 0 && Number(event.target.value) < 100000000) ? Number(event.target.value) : 0;
			piece      = value;
			sub_price  = (Number(price) * value);
		}
		else if(objName === 'piece_type'){
			piece_type = event.target.value;
		}
		
		//ハッシュ入れ直し
		viewData[index] = {
			code       : code,
			name       : name,
			price      : price,
			piece      : piece,
			piece_type : piece_type,
			sub_price  : sub_price,
		};

		//合計計算
		let total_price = 0;
		viewData.some( x => { total_price  = total_price + Number(x.sub_price); return false; } );

		//表示データ更新
		this.setState({
			viewData      : viewData,
			total_price   : total_price,
			payment_price : (Number(this.state.carriage) + Number(this.state.cashdeli) + Number(this.state.wrapping) + Number(this.state.coupon) + Number(total_price)),
		});
		
	
	}
	itemUpdateEtc(event) {

		const value = (Number(event.target.value) >= -100000000 && Number(event.target.value) < 100000000) ? Number(event.target.value) : 0;

		switch (event.target.name) {
			case 'carriage':
				this.setState({
					carriage      : value,
					payment_price : (value + Number(this.state.cashdeli) + Number(this.state.wrapping) + Number(this.state.coupon) + Number(this.state.total_price)),
				});
				break;
			case 'cashdeli':
				this.setState({
					cashdeli      : value,
					payment_price : (value + Number(this.state.carriage) + Number(this.state.wrapping) + Number(this.state.coupon) + Number(this.state.total_price)),
				});
				break;
			case 'wrapping':
				this.setState({
					wrapping      : value,
					payment_price : (value + Number(this.state.carriage) + Number(this.state.cashdeli) + Number(this.state.coupon) + Number(this.state.total_price)),
				});
				break;
			case 'coupon':
				this.setState({
					coupon      : value,
					payment_price : (value + Number(this.state.carriage) + Number(this.state.cashdeli) + Number(this.state.wrapping) + Number(this.state.total_price)),
				});
				break;
			default:
				break;
		}

	}
	itemDelete(code) {
	
		//削除
		const viewData = this.state.viewData.filter(x => x.code !== code);
		
		//合計計算
		let total_price = 0;
		viewData.some( x => { total_price  = total_price + Number(x.sub_price); return false; } );


		//表示データ更新
		this.setState({
			viewData      : viewData,
			total_price   : total_price,
			payment_price : (Number(this.state.carriage) + Number(this.state.cashdeli) + Number(this.state.wrapping) + Number(this.state.coupon) + Number(total_price)),
		});
	
	}
	async itemSet(code) {
		
		//処理
		try{

			//更新開始
			this.setState({
				isLockForm   : true,
				isItemSelect : false
			});

			//IDの取得
			if(!code){
					this.setState({
						ErrMsg     : '商品コードが見つかりません。<br/>',
						isLoading     : false,
					});
					return(0);
			}
			
		
			//データ取得
			const Query = `query getItem($code: String!) {
				getItem(code: $code) {
					name
					price
					piece_type
				}
			}`;
			const Data = { code: code };
			const ref = await API.graphql(graphqlOperation(Query, Data));
			//console.log(ref);
			
			//データが空かどうか
			if(ref.data.getItem === null){
				this.setState({
					ErrMsg     : '商品情報が見当たりませんでした。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			const name       = ref.data.getItem.name;
			const price      = ref.data.getItem.price;
			const piece_type = ref.data.getItem.piece_type;
			
			this.setState({
				isLockForm   : false,
				code         : code,
				name         : name,
				price        : price,
				piece        : 1,
				piece_type   : piece_type,
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg       : err_msg,
				isLockForm   : false,
				isItemSelect : false,
			 });
			return(0);
		}
	}
	async itemSubmit(event) {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal  : false,
			isValidated2 : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//Stateから取得する更新項目
			const viewData      = this.state.viewData;
			const orderno       = this.state.orderno;
			const total_price   = this.state.total_price;
			const payment_price = this.state.payment_price;
			
			//Formから直接取得する更新項目
			const carriage      = formObj.carriage.value;
			const wrapping      = formObj.wrapping.value;
			const coupon        = formObj.coupon.value;
			const cashdeli      = formObj.cashdeli.value;
			
			//元のデータからループ
			
			//日付処理
		        const date          = new Date();
		        const isoDate       = date.toISOString();

			//受注情報の更新登録
			const Data = {
				data : {
					orderno       : orderno,
					carriage      : carriage,
					wrapping      : wrapping,
					coupon        : coupon,
					cashdeli      : cashdeli,
					total_price   : total_price,
					payment_price : payment_price,
					date_edit     : isoDate,
					order_items   : viewData,
				}
			};

			//最新のもの
			try{
				const Query = `mutation updateOrder($data: OrderInput!) {
					updateOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}
			
			//古いもの
			try{
				const Query = `mutation updateOldOrder($data: OrderInput!) {
					updateOldOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}

			//鑑査ログ
			await AuditLog('order', 'update', orderno, Data, '受注商品を変更しました。');
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isValidated3 : false,
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	//受注情報の編集
	async OrderDataUpdate () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal  : false,
			isValidated1  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//Stateから取得する更新項目
			const orderno       = this.state.orderno;

			//Formから直接取得する更新項目
			const status        = formObj.status.value;
			const payment_type  = formObj.payment_type.value;
			const carriage_type = formObj.carriage_type.value;
			const wrapping_type = formObj.wrapping_type.value;
			const coupon_type   = formObj.coupon_type.value;

			//日付処理
		        const date          = new Date();
		        const isoDate       = date.toISOString();
			
			//受注情報の更新登録
			const Data = {
				data : {
					orderno       : orderno,
					status        : status,
					payment_type  : payment_type,
					carriage_type : carriage_type,
					wrapping_type : wrapping_type,
					coupon_type   : coupon_type ? coupon_type : null,
					date_edit     : isoDate,
				}
			};

			//最新のもの
			try{
				const Query = `mutation updateOrder($data: OrderInput!) {
					updateOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}
			
			//古いもの
			try{
				const Query = `mutation updateOldOrder($data: OrderInput!) {
					updateOldOrder(input: $data) {
						orderno
					}
				}`;
				await API.graphql(graphqlOperation(Query, Data));
			}
			catch(err){
				console.log(err);
			}
			
			//鑑査ログ
			await AuditLog('order', 'update', orderno, Data, '受注情報を変更しました。');
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	ShowUpdateModal(formSelect, event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowModal  : true,
			formObj      : form,
			formSelect   : formSelect,
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleClose() {
		this.setState({
			isShowModal  : false,
			isItemSelect : false,
			isShowDelete : false,
			isSuccess    : false,
			isValidated1 : false,
			isValidated2 : false,
			isValidated3 : false,
			isLockForm   : false,
			ErrMsg       : '',
		});
	}
	lockFormClose(){
		//
	}
	async onClickSelect(row){
		if(row.code){
			await this.itemSet(row.code);
		}
	}
	async componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>更新しました。</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowModal} onHide={this.handleClose} centered>
						<Modal.Body>
							本当に更新しますか？<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={
								()=>{ 
									if(this.state.formSelect === 'OrderData'){
										this.OrderDataUpdate();
									}
									else if(this.state.formSelect === 'OrderItem'){
										this.itemSubmit();
									}
								}
							}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isItemSelect} onHide={this.handleClose} centered aria-labelledby="ItemSelect" dialogClassName="modal-xl">
						<Modal.Header closeButton>
							<Modal.Title id="ItemSelect">
								受注商品を選んでください。
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<ItemSelect onClickSelect={this.onClickSelect} />
						</Modal.Body>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					
					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '1em' }} fluid>
						<Row>
							<Col sm={6}>
								<h4>注文内容の変更</h4>
							</Col>
							<Col sm={6} className="text-right">
								<Button variant="dark" onClick={ this.handleReload }>
									<FaRedo />
								</Button>
							</Col>
						</Row>
					</Container>
					
					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated1} onSubmit={e => this.ShowUpdateModal('OrderData', e)}>
						<Row>
							<Col sm={12}>
								<p><big>受注情報</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={1}>
								受注番号
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="orderno">
								        <Form.Control name="orderno" type="text" defaultValue={this.state.orderno} size="lg" readOnly />
									<Form.Control.Feedback />
								</Form.Group>
							</Col>

							<Form.Label column sm={1}>
								受注状態
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="status">
								    <Form.Control name="status" type="text" defaultValue={this.state.status} size="lg" as="select" required >
									<option value="仮受注">仮受注</option>
									<option value="未処理">未処理</option>
									<option value="処理中">処理中</option>
									<option value="出荷済み">出荷済み</option>
									<option value="予約・入荷待ち">予約・入荷待ち</option>
									<option value="決済処理待ち">決済処理待ち</option>
									<option value="確認中">確認中</option>
									<option value="返信待ち">返信待ち</option>
									<option value="商品手配中">商品手配中</option>
									<option value="後払い与信中">後払い与信中</option>
									<option value="キャンセル">キャンセル</option>
									<option value="未定義">未定義</option>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										「受注状態」を選択してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={1}>
								決済方法
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="payment_type">
								        <Form.Control name="payment_type" type="text" defaultValue={this.state.payment_type} size="lg" as="select" required >
									<option value="未定義">未定義</option>
									<option value="銀行振込">銀行振込</option>
									<option value="クレジットカード決済">クレジットカード決済</option>
									<option value="代金引換">代金引換</option>
									<option value="後払い決済">後払い決済</option>
									<option value="後払い">後払い</option>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										「決済方法」を選択してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								お届け方法
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="carriage_type">
								        <Form.Control name="carriage_type" type="text" defaultValue={this.state.carriage_type} size="lg" as="select" required >
									<option value="未定義">未定義</option>
									<option value="ゆうパケット">ゆうパケット</option>
									<option value="宅急便">宅急便</option>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										「お届け方法」を選択してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<hr/>
						<Row>
							<Form.Label column sm={1}>
								ギフトラッピング
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="wrapping_type">
								        <Form.Control name="wrapping_type" type="text" defaultValue={this.state.wrapping_type} size="lg" as="select" required >
									<option value="未定義">未定義</option>
									<option value="有料ラッピングする">有料ラッピングする</option>
									<option value="季節のラッピング">季節のラッピング</option>
									<option value="しない">しない</option>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										「ギフトラッピング」を選択してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								クーポン
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="coupon_type">
								        <Form.Control name="coupon_type" type="text" defaultValue={this.state.coupon_type ? this.state.coupon_type : ''} size="lg" as="select" required >
									<option value="未定義">未定義</option>
									<option value="会員限定クーポン500円割引">会員限定クーポン500円割引</option>
									<option value="">未使用</option>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										「クーポン」を選択してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<hr/>
						<Row>
							<Form.Label column sm={1}>
								連絡事項
							</Form.Label>
							<Col sm={11}>
								<Form.Group controlId="order_memo">
								        <Form.Control name="order_memo" as="textarea" rows="3" defaultValue={this.state.order_memo} placeholder="連絡事項" size="lg" pattern=".{1,1000}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「連絡事項」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
							</Col>
						</Row>
						</Form>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated2} onSubmit={e => this.ShowUpdateModal('OrderItem', e)}>
						<Row>
							<Col sm={12}>
								<p><big>注文内容</big></p>
							</Col>
						</Row>

						<Table bordered responsive style={{ marginTop: 20 }} className="text-nowrap">
						<thead>
						<tr>
						<th style={{ width: '10%'}}>商品コード</th>
						<th style={{ width: '40%'}}>商品名</th>
						<th style={{ width: '10%'}}>金額</th>
						<th style={{ width: '10%'}}>個数</th>
						<th style={{ width: '10%'}}>単位</th>
						<th style={{ width: '10%'}}>合計</th>
						<th style={{ width: '10%'}}>変更</th>
						</tr>
						</thead>
						<tbody>


						 { this.state.viewData.map(
						 	(value, index) => {
								const code            = value.code;
								const name            = value.name;
								const price           = value.price;
								const sub_price       = value.sub_price.toLocaleString();
								const piece           = value.piece;
								const piece_type      = value.piece_type;
								
								return (
									<tr key={index} className="text-nowrap">
										<td>{code}</td>
										<td>
											<Form.Group controlId={code + '.name'}>
											        <Form.Control name={code + '.name'} type="text" defaultValue={name} size="sm" required pattern=".{1,255}" onChange={(e)=>{ this.itemUpdate(code, 'name',  e); }} />
												<Form.Control.Feedback type="invalid">
													「商品名」を正しく入力してください。
												</Form.Control.Feedback>
											</Form.Group>
										</td>
										<td>
											<Form.Group controlId={code + '.price'}>
											        <Form.Control name={code + '.price'} type="text" defaultValue={price} size="sm" required pattern="\d{1,10}" onChange={(e)=>{ this.itemUpdate(code, 'price', e); }} className="text-right" />
												<Form.Control.Feedback type="invalid">
													「金額」を正しく入力してください。
												</Form.Control.Feedback>
											</Form.Group>
										</td>
										<td>
											<Form.Group controlId={code + '.piece'}>
											        <Form.Control name={code + '.piece'} type="text" defaultValue={piece} size="sm" required pattern="\d{1,3}" onChange={(e)=>{ this.itemUpdate(code, 'piece', e); }} className="text-right" />
												<Form.Control.Feedback type="invalid">
													「個数」を正しく入力してください。
												</Form.Control.Feedback>
											</Form.Group>
										</td>
										<td>
											<Form.Group controlId={code + '.piece_type'}>
											        <Form.Control name={code + '.piece_type'} type="text" defaultValue={piece_type} size="sm" required as="select" onChange={(e)=>{ this.itemUpdate(code, 'piece_type', e); }} >
												<option value="個">個</option>
												<option value="枚">枚</option>
												<option value="未定義">未定義</option>
												</Form.Control>
												<Form.Control.Feedback type="invalid">
													「単位」を選択してください。
												</Form.Control.Feedback>
											</Form.Group>
										</td>
										<td className="text-right">{sub_price} 円</td>
										<td>
											<Button variant="secondary" type="reset" block size="sm" onClick={()=>{ this.itemDelete(code); }} disabled={this.state.isLockForm} >削除</Button>
										</td>
									</tr>
								);
							}
						 )}
						<tr className="text-nowrap">
							<td colSpan="3">
								　
							</td>
							<th colSpan="2" className="text-right">
								小計
							</th>
							<td className="text-right">
								{this.state.total_price.toLocaleString()} 円
							</td>
							<td>
								　
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="3">
								　
							</td>
							<th colSpan="2" className="text-right">
								送料
							</th>
							<td>
								<Form.Group controlId="carriage">
								        <Form.Control name="carriage" type="text" size="sm" defaultValue={this.state.carriage} required pattern="\d{1,10}" className="text-right" onChange={(e)=>{ this.itemUpdateEtc(e); }} />
									<Form.Control.Feedback type="invalid">
										「送料」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								　
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="3">
								　
							</td>
							<th colSpan="2" className="text-right">
								決済手数料
							</th>
							<td>
								<Form.Group controlId="cashdeli">
								        <Form.Control name="cashdeli" type="text" size="sm" defaultValue={this.state.cashdeli} required pattern="\d{1,10}" className="text-right" onChange={(e)=>{ this.itemUpdateEtc(e); }} />
									<Form.Control.Feedback type="invalid">
										「代引手数料」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								　
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="3">
								　
							</td>
							<th colSpan="2" className="text-right">
								ギフト手数料
							</th>
							<td>
								<Form.Group controlId="wrapping">
								        <Form.Control name="wrapping" type="text" size="sm" defaultValue={this.state.wrapping} required pattern="\d{1,10}" className="text-right" onChange={(e)=>{ this.itemUpdateEtc(e); }} />
									<Form.Control.Feedback type="invalid">
										「ギフト手数料」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								　
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="3">
								　
							</td>
							<th colSpan="2" className="text-right">
								クーポン
							</th>
							<td>
								<Form.Group controlId="coupon">
								        <Form.Control name="coupon" type="text" size="sm" defaultValue={this.state.coupon} required pattern="(-|)\d{1,10}" className="text-right" onChange={(e)=>{ this.itemUpdateEtc(e); }} />
									<Form.Control.Feedback type="invalid">
										「クーポン」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								　
							</td>
						</tr>
						<tr className="text-nowrap">
							<td colSpan="3">
								　
							</td>
							<th colSpan="2" className="text-right">
								合計金額
							</th>
							<td className="text-right">
								{this.state.payment_price.toLocaleString()} 円
							</td>
							<td>
								　
							</td>
						</tr>
						</tbody>
						</Table>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >変更する</Button>
							</Col>
						</Row>
					</Form>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated3} onSubmit={e => this.itemAdd(e)}>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="info" type="submit" block size="sm" disabled={this.state.isLockForm} >↑上記に新しい商品を追加する</Button>
							</Col>
						</Row>
						<Table bordered responsive style={{ marginTop: 20 }} className="text-nowrap">
						<thead>
						<tr>
						<th style={{ width: '10%'}}>検索</th>
						<th style={{ width: '15%'}}>商品コード</th>
						<th style={{ width: '40%'}}>商品名</th>
						<th style={{ width: '10%'}}>金額</th>
						<th style={{ width: '10%'}}>個数</th>
						<th style={{ width: '10%'}}>単位</th>
						</tr>
						</thead>
						<tbody>
						<tr className="text-nowrap">
							<td>
								<Button variant="secondary" type="button" block size="sm" onClick={()=>{ this.setState({ isItemSelect : true }); }} disabled={this.state.isLockForm} >検索</Button>
							</td>
							<td>
								<Form.Group controlId="code">
								        <Form.Control name="code" type="text" size="sm" defaultValue={this.state.code} required pattern=".{1,255}" />
									<Form.Control.Feedback type="invalid">
										「コード」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								<Form.Group controlId="name">
								        <Form.Control name="name" type="text" size="sm" defaultValue={this.state.name} required pattern=".{1,255}" />
									<Form.Control.Feedback type="invalid">
										「商品名」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								<Form.Group controlId="price">
								        <Form.Control name="price" type="text" size="sm" defaultValue={this.state.price} required pattern="\d{1,10}" className="text-right" />
									<Form.Control.Feedback type="invalid">
										「金額」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								<Form.Group controlId="piece">
								        <Form.Control name="piece" type="text" size="sm" defaultValue={this.state.piece} required pattern="\d{1,3}" className="text-right" />
									<Form.Control.Feedback type="invalid">
										「個数」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								<Form.Group controlId="piece_type">
								        <Form.Control name="piece_type" type="text" size="sm" required as="select" defaultValue={this.state.piece_type} >
									<option value="個">個</option>
									<option value="枚">枚</option>
									<option value="未定義">未定義</option>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										「単位」を選択してください。
									</Form.Control.Feedback>
								</Form.Group>
							</td>
						</tr>
						</tbody>
						</Table>
					</Form>
					</Container>

					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props.history.push('/Order/' + this.state.orderno + '/'); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					

				</Container>
			);
		}
	}
}

export default withRouter(Default);

