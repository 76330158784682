
const AwsExports = {
    Auth: {
        identityPoolId: 'ap-northeast-1:7d059743-3c46-4dd9-8a3e-2423ec579d85',
        userPoolId: 'ap-northeast-1_iO0V17f58',
        userPoolWebClientId: '4q6t66lj02u54foukmgkokdq32',
        region: 'ap-northeast-1',
    },
    API: {
        'aws_appsync_graphqlEndpoint': 'https://3ozv45fzenfwzblyv67v2czka4.appsync-api.ap-northeast-1.amazonaws.com/graphql',
        'aws_appsync_region': 'ap-northeast-1',
        'aws_appsync_authenticationType': 'AWS_IAM',
    },
};

export default AwsExports;
