import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';

class Default extends React.Component {
	render() {
		return (
			<Redirect to="/Order/" />
		);
	}
}

export default withRouter(Default);


