import React from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import {
	Alert,
	Modal,
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Badge,
	ProgressBar,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';


class MyTable extends React.Component {
	constructor(props) {
		super(props);
		this._dataFormatButton       = this._dataFormatButton.bind(this);
		this._dataFormatLabel        = this._dataFormatLabel.bind(this);
		this._dataFormatOverlay1     = this._dataFormatOverlay1.bind(this);
		this._dataFormatOverlay2     = this._dataFormatOverlay2.bind(this);
	}
	_dataFormatButton(cell, row) {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => this.props.onClickSelect(row)} >
					選択
				</Button>
			</div>
		);
	}
	_dataFormatLabel(cell, row) {
		return (
			<div className="text-center" style={{margin: "10px"}}>
				<Badge variant={cell ? 'success' : 'secondary'}>
					{cell ? '有効' : '無効'}
				</Badge>　
			</div>
		);
	}
	_dataFormatOverlay1(cell, row) {
		return (
			<OverlayTrigger placement="bottom" overlay={<Tooltip>{row.name_full}</Tooltip>}>
				<div>{cell}</div>
			</OverlayTrigger>
		);
	}
	_dataFormatOverlay2(cell, row) {
		return (
			<OverlayTrigger placement="bottom" overlay={<Tooltip>{row.text_1_full}</Tooltip>}>
				<div>{cell}</div>
			</OverlayTrigger>
		);
	}
	render() {
		if(this.props.data.length === 0){
			return (
				<Alert variant="danger">
					対象のデータが見つかりません。
				</Alert>
			);
		}
		else{
		
			//表示用に整形
			let TableData = [];
			for(let item of this.props.data){
				const code        = item.code;
				const name        = item.name;
				const price       = item.price;
				const text_1      = item.text_1;
				const zaiko       = item.zaiko;
				const en_kbn      = item.en_kbn;
				const date_add    = item.date_add;
				const date_edit   = item.date_edit;
				const RowData = {
					selectid     : code,
					code         : code,
					name         : name.length > 10 ? name.substr(0,10) + '...' : name,
					name_full    : name,
					price        : price.toLocaleString() + ' 円',
					text_1       : text_1.length > 10 ? text_1.substr(0,10) + '...' : text_1,
					text_1_full  : text_1,
					zaiko        : zaiko.toLocaleString() + ' 件',
					en_kbn       : en_kbn,
					date_add     : date_add,
					date_edit    : date_edit,
				};
				
				TableData.push(RowData);
			}
			
			const windowSize = window.innerWidth;

			const columns = [
				{
					dataField: 'selectid',
					text: '詳細',
					sort: false,
					formatter: this._dataFormatButton,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'code',
					text: '商品コード',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'name',
					text: '商品名',
					sort: true,
					formatter: this._dataFormatOverlay1,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'price',
					text: '販売価格',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
					align: 'right',
				},
				{
					dataField: 'text_1',
					text: '商品説明',
					sort: true,
					formatter: this._dataFormatOverlay2,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
				{
					dataField: 'zaiko',
					text: '在庫',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
					align: 'right',
				},
				{
					dataField: 'en_kbn',
					text: '有効',
					sort: true,
					formatter: this._dataFormatLabel,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
					hidden: windowSize < 1000 ? true : false,
				},
			];

			const defaultSorted = [
				{
					dataField: 'date_edit',
					order: 'desc'
				}
			];
			
			const options = {
				sizePerPageList: [
					{ text: '10', value: 10},
					{ text: '50', value: 50},
					{ text: '100', value: 100},
					{ text: 'All', value: TableData.length},
				],
			};
			
			return (
				<BootstrapTable bootstrap4 keyField='code' data={ TableData } columns={ columns } bordered={ false } defaultSorted={ defaultSorted } pagination={ paginationFactory(options) } />
			);

		}
	}
}

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading   : true,
			loadingPer  : 0,
			ErrMsg      : '',
			dateTime    : '',
			viewData    : [],
			csvData     : [],
			defaultData : [],
		}
		this._reload              = this._reload.bind(this);
		this._formatDate          = this._formatDate.bind(this);
		this._getFile             = this._getFile.bind(this);
		this._getData             = this._getData.bind(this);
		this.handleDismiss        = this.handleDismiss.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleSearch         = this.handleSearch.bind(this);
	}
	_reload() {
		//子ウィンドウからの呼び出し用
		this.handleReload();
	}
	_formatDate(dateObj) {

		//日付の整形
		const year        = dateObj.getFullYear().toString();
		const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day         = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second      = ('00' + dateObj.getSeconds().toString()).slice(-2);

		const text = year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分' + second + '秒';

		return (text);
	}
	async _getFile() {
		//処理
		try{
			//ストレージを定義
			Storage.configure({ AWSS3: { bucket: 'mindwavestore.admin', region: 'ap-northeast-1' } });

			//S3からファイルを取得
			const result   = await Storage.get('_cache/Items.json', { download: true });

			//APIバージョンアップから生データからBLOBに変更
			//const jsonText = result.Body.toString();
			const jsonText = await new Promise(resolve =>
			{
				const reader = new FileReader();
				reader.onload = () => resolve(reader.result);
				reader.readAsText(result.Body);
			});
			return(jsonText);
		}
		catch(error) {
			return(0);
		}
	}
	async _getData(jsonText) {
		
		//処理
		try{

			if(jsonText){
				
				//JSON形式を変換
				const jsonData = JSON.parse(jsonText);
				
				//日付オブジェクト作成
				const dateTime     = new Date(jsonData.dateTime);

				//商品データ
				const Items    = jsonData.Items;

				//データコピー
				const viewData = [];
				for(let row of Items){
					viewData.push(row);
				}
				
				//データコピー
				const defaultData = [];
				for(let row of Items){
					defaultData.push(row);
				}

				//データの保存
				this.setState({
					isLoading   : false,
					loadingPer  : 0,
					viewData    : viewData,
					defaultData : defaultData,
					dateTime    : this._formatDate(dateTime),
				});
			}
			else{
			
				//商品件数の取得
				const CountQuery = `query scanItemCount {
					scanItemCount {
						scannedCount
						nextToken
					}
				}`;
				let countRef     = await API.graphql(graphqlOperation(CountQuery));
				let itemCount = countRef.data.scanItemCount.scannedCount;

				//進捗情報
				let loadingPer   = 1;
				if(countRef.data.scanItemCount.nextToken){
					this.setState({
						loadingPer : loadingPer,
					});
				}

				//商品件数の取得（未取得の続きデータがあった場合の処理）
				while (countRef.data.scanItemCount.nextToken) {
					const Query = `query scanItemCount($nextToken: String) {
						scanItemCount(nextToken: $nextToken) {
							scannedCount
							nextToken
						}
					}`;
					const Data = {
						nextToken: countRef.data.scanItemCount.nextToken,
					};
					countRef = await API.graphql(graphqlOperation(Query, Data));
					itemCount = itemCount + countRef.data.scanItemCount.scannedCount;
				}
				
				//商品情報の取得
				const Query = `query scanItem {
					scanItem {
						items {
							code
							name
							m_code
							m_name
							price
							price_notax
							piece_type
							title
							text_1
							text_2
							text_3
							text_4
							text_5
							select_1
							select_2
							select_3
							zaiko
							flag_1
							flag_2
							flag_3
							flag_4
							flag_5
							flag_6
							flag_7
							flag_8
							flag_9
							en_kbn
							date_add
							date_edit
							old_item_id
						}
						nextToken
					}
				}`;
				let ref = await API.graphql(graphqlOperation(Query));
				let Items = ref.data.scanItem.items;
				
				//進捗情報
				if(ref.data.scanItem.nextToken){
					const perNow = Math.floor(Items.length / itemCount * 100);
					if(loadingPer < perNow){
						this.setState({
							loadingPer : perNow,
						});
					}
				}
				
				//商品情報の取得（未取得の続きデータがあった場合の処理）
				while (ref.data.scanItem.nextToken) {
					const Query = `query scanItem($nextToken: String) {
						scanItem(nextToken: $nextToken) {
							items {
								code
								name
								m_code
								m_name
								price
								price_notax
								piece_type
								title
								text_1
								text_2
								text_3
								text_4
								text_5
								select_1
								select_2
								select_3
								zaiko
								flag_1
								flag_2
								flag_3
								flag_4
								flag_5
								flag_6
								flag_7
								flag_8
								flag_9
								en_kbn
								date_add
								date_edit
								old_item_id
							}
							nextToken
						}
					}`;
					const Data = {
						nextToken: ref.data.scanItem.nextToken,
					};
					//console.log(ref.data.scanItem.nextToken);
					ref = await API.graphql(graphqlOperation(Query, Data));
					Items = Items.concat(ref.data.scanItem.items);
					//進捗情報
					if(Items.length){
						const perNow = Math.floor(Items.length / itemCount * 100);
						if(loadingPer < perNow){
							this.setState({
								loadingPer : perNow,
							});
						}
					}
				}

				//データの判別
				if(Items.length === 0){
					//データがなかった時
					this.setState({
						isLoading : false,
						ErrMsg    : 'データがありません。',
					});
				}
				else{

					//'null'文字がある場合は''に変換
					Items.some((item, index) => Object.keys(item).some(key => { if(item[key] === 'null'){ Items[index][key] = '' } return false; }));
					
					//データコピー
					const viewData = [];
					for(let row of Items){
						viewData.push(row);
					}
					
					//データコピー
					const defaultData = [];
					for(let row of Items){
						defaultData.push(row);
					}
					
					//現在時刻の取得
					const dateObj = new Date();
					
					//ストレージを定義
					Storage.configure({ AWSS3: { bucket: 'mindwavestore.admin', region: 'ap-northeast-1' } });

					//S3に保存する
					await Storage.put('_cache/Items.json', JSON.stringify({ Items:defaultData, dateTime: dateObj.toISOString() }));
					
					//データの保存
					this.setState({
						isLoading   : false,
						loadingPer  : 0,
						viewData    : viewData,
						defaultData : defaultData,
						dateTime    : this._formatDate(dateObj),
					});
				}
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				isLoading  : false,
				loadingPer : 0,
				ErrMsg     : err_msg,
			});
		}
	}
	handleDismiss() {
		this.setState({ ErrMsg: '' });
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
			loadingPer  : 0,
			viewData    : [],
			csvData     : [],
			defaultData : [],
		});
		this._getData();
	}
	handleSearch(e){

		//Submitをキャンセル
		e.preventDefault();
		e.stopPropagation();
	
		//検索文字列と対象データを取得
		const key   = e.target.value;
		const defaultData = this.state.defaultData;
		
		//検索文字及び対象データがない場合はリセット
		if(key === '' || defaultData.length === 0){
			this.setState({ viewData: defaultData});
			return(0);
		}
		
		//検索文字オブジェクト
		const matchString = new RegExp(key);
		
		//検索
		const viewData = defaultData.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		//表示データ更新
		this.setState({ viewData: viewData});
	}
	async componentDidMount () {
		const jsonText = await this._getFile();
		this._getData(jsonText);
	}
	render() {
		if(this.state.isLoading && this.state.loadingPer){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={{ span: 6, offset: 3 }}>
						<ProgressBar now={this.state.loadingPer} label={this.state.loadingPer + `%`} animated />
					</Col>
				</Row>
			);
		}
		else if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container fluid>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleDismiss} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleDismiss}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Form onSubmit={e => this.handleSearch(e)}>
						<Row style={{ marginTop: 20 }}>
							<Col sm={4}>
								<Form.Group controlId="search">
								        <Form.Control name="search" type="text" placeholder="検索" size="lg" onChange={e => this.handleSearch(e)}/>
								</Form.Group>
							</Col>
							<Col sm={4}>
								<Badge pill variant="secondary">
									<h5>　{ this.state.viewData.length.toLocaleString() }件　</h5>
								</Badge>
							</Col>
						</Row>
					</Form>

					<Row style={{ marginBottom: 20 }}>
						<Col sm={6}>
							<Badge variant="secondary">
								<h6>　データ取得時間：{ this.state.dateTime }　</h6>
							</Badge>　
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ this.handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<MyTable data={this.state.viewData} onClickSelect={this.props.onClickSelect} />
						</Col>
					</Row>
				</Container>
			);
		}
	}
}

export default withRouter(Default);

