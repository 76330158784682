import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Modal,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import NotFound from '../../Objects/NotFound';
import { AuditLog } from '../../Objects/AuditLog';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading                       : true,
			isNotFound                      : false,
			isValidated                     : false,
			isSuccess                       : false,
			isShowModal                     : false,
			isLockForm                      : false,
			formObj                         : null,
			formSelect                      : '',
			ErrMsg                          : '',
			carriage_free_flag              : false,
			carriage_free_price             : 0,
			carriage_free_ec_user_only_flag : false,
			carriage_free_order_history_kbn : '',
			carriage_1                      : 0,
			carriage_2                      : 0,
			carriage_3                      : 0,
			carriage_4                      : 0,
			carriage_5                      : 0,
			cashdeli_1                      : 0,
			cashdeli_2                      : 0,
			wrapping_1                      : 0,
			date_diff                       : 0,
			join_bcc                        : '',
			join_from                       : '',
			join_subject                    : '',
			join_msg                        : '',
			order_bcc                       : '',
			order_from                      : '',
			order_subject                   : '',
			order_msg                       : '',
			resign_bcc                      : '',
			resign_from                     : '',
			resign_subject                  : '',
			resign_msg                      : '',
		}
		this.handleReload         = this.handleReload.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
		this._getData             = this._getData.bind(this);
		this.ShowUpdateModal      = this.ShowUpdateModal.bind(this);
		this.setting1Update       = this.setting1Update.bind(this);
		this.setting2Update       = this.setting2Update.bind(this);
		this.mailUpdate           = this.mailUpdate.bind(this);
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	async _getData() {

		try{
			//------------------------------設定データ------------------------------
			const SettingQuery = `query getParam($keyname: String!) {
				getParam(keyname: $keyname) {
					carriage_free_flag
					carriage_free_price
					carriage_free_ec_user_only_flag
					carriage_free_order_history_kbn
					carriage_1
					carriage_2
					carriage_3
					carriage_4
					carriage_5
					cashdeli_1
					cashdeli_2
					wrapping_1
					date_diff
				}
			}`;
			const SettingData = { keyname: 'setting' };
			const refSetting = await API.graphql(graphqlOperation(SettingQuery, SettingData));
			
			//変数
			let carriage_free_flag              = false;
			let carriage_free_price             = 0;
			let carriage_free_ec_user_only_flag = false;
			let carriage_free_order_history_kbn = '';
			let carriage_1                      = 0;
			let carriage_2                      = 0;
			let carriage_3                      = 0;
			let carriage_4                      = 0;
			let carriage_5                      = 0;
			let cashdeli_1                      = 0;
			let cashdeli_2                      = 0;
			let wrapping_1                      = 0;
			let date_diff                       = 0;

			//データが空かどうか
			if(refSetting.data.getParam !== null){
			
				carriage_free_flag              = refSetting.data.getParam.carriage_free_flag;
				carriage_free_price             = refSetting.data.getParam.carriage_free_price;
				carriage_free_ec_user_only_flag = refSetting.data.getParam.carriage_free_ec_user_only_flag;
				carriage_free_order_history_kbn = refSetting.data.getParam.carriage_free_order_history_kbn;
				carriage_1                      = refSetting.data.getParam.carriage_1;
				carriage_2                      = refSetting.data.getParam.carriage_2;
				carriage_3                      = refSetting.data.getParam.carriage_3;
				carriage_4                      = refSetting.data.getParam.carriage_4;
				carriage_5                      = refSetting.data.getParam.carriage_5;
				cashdeli_1                      = refSetting.data.getParam.cashdeli_1;
				cashdeli_2                      = refSetting.data.getParam.cashdeli_2;
				wrapping_1                      = refSetting.data.getParam.wrapping_1;
				date_diff                       = refSetting.data.getParam.date_diff;
			}
			
			//------------------------------入会時の自動返信メール設定------------------------------
			const JoinQuery = `query getParam($keyname: String!) {
				getParam(keyname: $keyname) {
					bcc
					from
					subject
					msg
				}
			}`;
			const JoinData = { keyname: 'joinMail' };
			const refJoin = await API.graphql(graphqlOperation(JoinQuery, JoinData));
			
			//変数
			let join_bcc     = '';
			let join_from    = '';
			let join_subject = '';
			let join_msg     = '';

			//データが空かどうか
			if(refJoin.data.getParam !== null){
			
				join_bcc     = refJoin.data.getParam.bcc;
				join_from    = refJoin.data.getParam.from;
				join_subject = refJoin.data.getParam.subject;
				join_msg     = refJoin.data.getParam.msg;
			}
			
			//------------------------------受注時の自動返信メール設定------------------------------
			const OrderQuery = `query getParam($keyname: String!) {
				getParam(keyname: $keyname) {
					bcc
					from
					subject
					msg
				}
			}`;
			const OrderData = { keyname: 'orderMail' };
			const refOrder = await API.graphql(graphqlOperation(OrderQuery, OrderData));
			
			//変数
			let order_bcc     = '';
			let order_from    = '';
			let order_subject = '';
			let order_msg     = '';

			//データが空かどうか
			if(refOrder.data.getParam !== null){
			
				order_bcc     = refOrder.data.getParam.bcc;
				order_from    = refOrder.data.getParam.from;
				order_subject = refOrder.data.getParam.subject;
				order_msg     = refOrder.data.getParam.msg;
			}
			
			//------------------------------受注時の自動返信メール設定------------------------------
			const ResignQuery = `query getParam($keyname: String!) {
				getParam(keyname: $keyname) {
					bcc
					from
					subject
					msg
				}
			}`;
			const ResignData = { keyname: 'resignMail' };
			const refResign = await API.graphql(graphqlOperation(ResignQuery, ResignData));
			
			//変数
			let resign_bcc     = '';
			let resign_from    = '';
			let resign_subject = '';
			let resign_msg     = '';

			//データが空かどうか
			if(refResign.data.getParam !== null){
			
				resign_bcc     = refResign.data.getParam.bcc;
				resign_from    = refResign.data.getParam.from;
				resign_subject = refResign.data.getParam.subject;
				resign_msg     = refResign.data.getParam.msg;
			}
			
			//------------------------------お問い合わせの自動返信メール設定------------------------------
			const ContactQuery = `query getParam($keyname: String!) {
				getParam(keyname: $keyname) {
					bcc
					from
					subject
					msg
				}
			}`;
			const ContactData = { keyname: 'contactMail' };
			const refContact = await API.graphql(graphqlOperation(ContactQuery, ContactData));
			
			//変数
			let contact_bcc     = '';
			let contact_from    = '';
			let contact_subject = '';
			let contact_msg     = '';

			//データが空かどうか
			if(refContact.data.getParam !== null){
			
				contact_bcc     = refContact.data.getParam.bcc;
				contact_from    = refContact.data.getParam.from;
				contact_subject = refContact.data.getParam.subject;
				contact_msg     = refContact.data.getParam.msg;
			}

			//------------------------------メールマガジンのメール設定------------------------------
			const MailMagazineQuery = `query getParam($keyname: String!) {
				getParam(keyname: $keyname) {
					from
				}
			}`;
			const MailMagazineData = { keyname: 'MailMagazine' };
			const refMailMagazine = await API.graphql(graphqlOperation(MailMagazineQuery, MailMagazineData));
			
			//変数
			let mailmagazine_from    = '';

			//データが空かどうか
			if(refMailMagazine.data.getParam !== null){
			
				mailmagazine_from    = refMailMagazine.data.getParam.from;
			}

			//設定
			this.setState({
				isLoading                       : false,
				carriage_free_flag              : carriage_free_flag,
				carriage_free_price             : carriage_free_price,
				carriage_free_ec_user_only_flag : carriage_free_ec_user_only_flag,
				carriage_free_order_history_kbn : carriage_free_order_history_kbn,
				carriage_1                      : carriage_1,
				carriage_2                      : carriage_2,
				carriage_3                      : carriage_3,
				carriage_4                      : carriage_4,
				carriage_5                      : carriage_5,
				cashdeli_1                      : cashdeli_1,
				cashdeli_2                      : cashdeli_2,
				wrapping_1                      : wrapping_1,
				date_diff                       : date_diff,
				join_bcc                        : join_bcc,
				join_from                       : join_from,
				join_subject                    : join_subject,
				join_msg                        : join_msg,
				order_bcc                       : order_bcc,
				order_from                      : order_from,
				order_subject                   : order_subject,
				order_msg                       : order_msg,
				resign_bcc                      : resign_bcc,
				resign_from                     : resign_from,
				resign_subject                  : resign_subject,
				resign_msg                      : resign_msg,
				contact_bcc                     : contact_bcc,
				contact_from                    : contact_from,
				contact_subject                 : contact_subject,
				contact_msg                     : contact_msg,
				mailmagazine_from               : mailmagazine_from,
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLoading  : false,
				isLockForm : false,
			 });
			return(0);
		}
	}
	async setting1Update () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal  : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//Formから直接取得する更新項目
			const carriage_free_flag              = formObj.carriage_free_flag.checked;
			const carriage_free_price             = formObj.carriage_free_price.value;
			const carriage_free_ec_user_only_flag = formObj.carriage_free_ec_user_only_flag.checked;
			const carriage_free_order_history_kbn = formObj.carriage_free_order_history_kbn.value;
			const carriage_1                      = formObj.carriage_1.value;
			const carriage_2                      = formObj.carriage_2.value;
			const carriage_3                      = formObj.carriage_3.value;
			const carriage_4                      = formObj.carriage_4.value;
			const carriage_5                      = formObj.carriage_5.value;
			const cashdeli_1                      = formObj.cashdeli_1.value;
			const cashdeli_2                      = formObj.cashdeli_2.value;
			const wrapping_1                      = formObj.wrapping_1.value;
			
			//プロフィールの更新登録
			const Query = `mutation updateParam($data: ParamInput!) {
				updateParam(input: $data) {
					keyname
				}
			}`;
			const Data = {
				data : {
					keyname                         : 'setting',
					carriage_free_flag              : carriage_free_flag              ? true                            : false,
					carriage_free_price             : carriage_free_price             ? carriage_free_price             : 0,
					carriage_free_ec_user_only_flag : carriage_free_ec_user_only_flag ? true                            : false,
					carriage_free_order_history_kbn : carriage_free_order_history_kbn ? carriage_free_order_history_kbn : null,
					carriage_1                      : carriage_1                      ? carriage_1                      : 0,
					carriage_2                      : carriage_2                      ? carriage_2                      : 0,
					carriage_3                      : carriage_3                      ? carriage_3                      : 0,
					carriage_4                      : carriage_4                      ? carriage_4                      : 0,
					carriage_5                      : carriage_5                      ? carriage_5                      : 0,
					cashdeli_1                      : cashdeli_1                      ? cashdeli_1                      : 0,
					cashdeli_2                      : cashdeli_2                      ? cashdeli_2                      : 0,
					wrapping_1                      : wrapping_1                      ? wrapping_1                      : 0,
				}
			};
			await API.graphql(graphqlOperation(Query, Data));

			//鑑査ログ
			await AuditLog('setting', 'update', 'setting', Data, '送料関係の設定を変更しました。');
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	async setting2Update () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal  : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//Formから直接取得する更新項目
			const date_diff          = formObj.date_diff.value;
			
			//プロフィールの更新登録
			const Query = `mutation updateParam($data: ParamInput!) {
				updateParam(input: $data) {
					keyname
				}
			}`;
			const Data = {
				data : {
					keyname       : 'setting',
					date_diff     : date_diff ? date_diff : 0,
				}
			};
			await API.graphql(graphqlOperation(Query, Data));

			//鑑査ログ
			await AuditLog('setting', 'update', 'setting', Data, '配送日関係の設定を変更しました。');
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	async mailUpdate (mailFlag) {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal  : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//Formから直接取得する更新項目
			const bcc     = formObj.bcc     ? formObj.bcc.value     : 'null';
			const from    = formObj.from    ? formObj.from.value    : 'null';
			const subject = formObj.subject ? formObj.subject.value : 'null';
			const msg     = formObj.msg     ? formObj.msg.value     : 'null';
			
			//プロフィールの更新登録
			const Query = `mutation updateParam($data: ParamInput!) {
				updateParam(input: $data) {
					keyname
				}
			}`;
			const Data = {
				data : {
					keyname       : mailFlag,
					bcc           : bcc     ? bcc     : 'null',
					from          : from    ? from    : 'null',
					subject       : subject ? subject : 'null',
					msg           : msg     ? msg     : 'null',
				}
			};
			await API.graphql(graphqlOperation(Query, Data));

			//鑑査ログ
			await AuditLog('setting', 'update', mailFlag, Data, 'メールの設定を変更しました。');

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	ShowUpdateModal(formSelect, event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowModal  : true,
			formObj      : form,
			formSelect   : formSelect,
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleClose() {
		this.setState({
			isShowModal  : false,
			isShowDelete : false,
			isSuccess    : false,
			isValidated  : false,
			isLockForm   : false,
			ErrMsg       : '',
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else if(this.state.isNotFound){
			return (
				<NotFound />
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>更新しました。</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowModal} onHide={this.handleClose} centered>
						<Modal.Body>
							本当に更新しますか？<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={
								()=>{ 
									if(this.state.formSelect === 'Setting1'){
										this.setting1Update();
									}
									else if(this.state.formSelect === 'Setting2'){
										this.setting2Update();
									}
									else if(this.state.formSelect === 'joinMail'){
										this.mailUpdate('joinMail');
									}
									else if(this.state.formSelect === 'orderMail'){
										this.mailUpdate('orderMail');
									}
									else if(this.state.formSelect === 'resignMail'){
										this.mailUpdate('resignMail');
									}
									else if(this.state.formSelect === 'contactMail'){
										this.mailUpdate('contactMail');
									}
									else if(this.state.formSelect === 'MailMagazine'){
										this.mailUpdate('MailMagazine');
									}
								}
							}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					
					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '1em' }}>
						<Row>
							<Col sm={6}>
								<h4>各種パラメータ設定</h4>　
							</Col>
							<Col sm={6} className="text-right">
								<Button variant="dark" onClick={ this.handleReload }>
									<FaRedo />
								</Button>
							</Col>
						</Row>
					</Container>
					
					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.ShowUpdateModal('Setting1', e)}>
						<Row>
							<Col sm={12}>
								<p><big>送料関係の設定</big></p>
							</Col>
						</Row>
						<hr/>
						<Row>
							<Form.Label column sm={4}>
								送料無料にする条件
							</Form.Label>
							<Col sm={8}>
								<Row>
									<Col sm={12}>
										<Form.Group controlId="carriage_free_flag">
										        <Form.Check name="carriage_free_flag" type="checkbox" defaultChecked={this.state.carriage_free_flag} label="送料無料を有効にする" onChange={(e) => { this.setState({ carriage_free_flag : e.currentTarget.checked }) } } />
											<Form.Control.Feedback type="invalid"/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Form.Label column sm={2}>
										購入金額
									</Form.Label>
									<Col sm={5}>
										<Form.Group controlId="carriage_free_price">
										        <Form.Control name="carriage_free_price" type="number" defaultValue={this.state.carriage_free_price} size="lg" className="text-right" disabled={!this.state.carriage_free_flag} />
											<Form.Control.Feedback type="invalid"/>
										</Form.Group>
									</Col>
									<Form.Label column sm={1}>
										円以上を無料にする
									</Form.Label>
								</Row>
								<Row>
									<Col sm={12}>
										<Form.Group controlId="carriage_free_ec_user_only_flag">
										        <Form.Check name="carriage_free_ec_user_only_flag" type="checkbox" defaultChecked={this.state.carriage_free_ec_user_only_flag} label="会員のみを有効にする"  disabled={!this.state.carriage_free_flag} />
											<Form.Control.Feedback type="invalid"/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Form.Label column sm={2}>
										リピーター区分
									</Form.Label>
									<Col sm={5}>
										<Form.Group controlId="carriage_free_order_history_kbn">
										        <Form.Control as="select" name="carriage_free_order_history_kbn" defaultValue={this.state.carriage_free_order_history_kbn} disabled={!this.state.carriage_free_flag} >
										        <option value="">設定しない</option>
										        <option value="リピーター">リピーター</option>
										        <option value="初回購入">初回購入</option>
										        </Form.Control>
											<Form.Control.Feedback type="invalid"/>
										</Form.Group>
									</Col>
								</Row>
							</Col>
						</Row>
						<hr/>
						<Row>
							<Form.Label column sm={4}>
								1受注あたりのメール便送料（税込）
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="carriage_1">
								        <Form.Control name="carriage_1" type="text" defaultValue={this.state.carriage_1} size="lg" pattern="\d{1,6}" className="text-right" required />
									<Form.Control.Feedback type="invalid">
										「メール便送料」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								円
							</Form.Label>
						</Row>
						<Row>
							<Form.Label column sm={4}>
								1受注あたりの宅急便送料（税込）
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="carriage_2">
								        <Form.Control name="carriage_2" type="text" defaultValue={this.state.carriage_2} size="lg" pattern="\d{1,6}" className="text-right" required />
									<Form.Control.Feedback type="invalid">
										「宅急便送料」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								円
							</Form.Label>
						</Row>
						<Row>
							<Form.Label column sm={4}>
								1受注あたりの宅急便（税込）<br/>
								（北海道・沖縄県対応）
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="carriage_3">
								        <Form.Control name="carriage_3" type="text" defaultValue={this.state.carriage_3} size="lg" pattern="\d{1,6}" className="text-right" required />
									<Form.Control.Feedback type="invalid">
										「宅急便送料（北海道・沖縄県）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								円
							</Form.Label>
						</Row>
						<Row>
							<Form.Label column sm={4}>
								1受注あたりのゆうパック送料（税込）
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="carriage_4">
								        <Form.Control name="carriage_4" type="text" defaultValue={this.state.carriage_4} size="lg" pattern="\d{1,6}" className="text-right" required />
									<Form.Control.Feedback type="invalid">
										「ゆうパック送料」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								円
							</Form.Label>
						</Row>
						<Row>
							<Form.Label column sm={4}>
								1受注あたりのゆうパック（税込）<br/>
								（北海道・沖縄県対応）
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="carriage_5">
								        <Form.Control name="carriage_5" type="text" defaultValue={this.state.carriage_5} size="lg" pattern="\d{1,6}" className="text-right" required />
									<Form.Control.Feedback type="invalid">
										「ゆうパック送料（北海道・沖縄県）」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								円
							</Form.Label>
						</Row>
						<Row>
							<Form.Label column sm={4}>
								1受注あたりの代引き手数料（税込）
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="cashdeli_1">
								        <Form.Control name="cashdeli_1" type="text" defaultValue={this.state.cashdeli_1} size="lg" pattern="\d{1,6}" className="text-right" required />
									<Form.Control.Feedback type="invalid">
										「代引き手数料」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								円
							</Form.Label>
						</Row>
						<Row>
							<Form.Label column sm={4}>
								1受注あたりの後払い手数料（税込）
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="cashdeli_2">
								        <Form.Control name="cashdeli_2" type="text" defaultValue={this.state.cashdeli_2} size="lg" pattern="\d{1,6}" className="text-right" required />
									<Form.Control.Feedback type="invalid">
										「後払い手数料」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								円
							</Form.Label>
						</Row>
						<Row>
							<Form.Label column sm={4}>
								1受注あたりのギフトラッピング手数料（税込）
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="wrapping_1">
								        <Form.Control name="wrapping_1" type="text" defaultValue={this.state.wrapping_1} size="lg" pattern="\d{1,6}" className="text-right" required />
									<Form.Control.Feedback type="invalid">
										「ギフトラッピング手数料」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								円
							</Form.Label>
						</Row>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
							</Col>
						</Row>
						</Form>
					</Container>


					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.ShowUpdateModal('Setting2', e)}>
						<Row>
							<Col sm={12}>
								<p><big>配送日関係の設定</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={4}>
								配達希望日の可能期間
							</Form.Label>
							<Col sm={5}>
								<Form.Group controlId="date_diff">
								        <Form.Control name="date_diff" type="text" defaultValue={this.state.date_diff} size="lg" pattern="\d{1,6}" className="text-right" required />
									<Form.Control.Feedback type="invalid">
										「配達希望日の可能期間」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Form.Label column sm={1}>
								日
							</Form.Label>
						</Row>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
							</Col>
						</Row>
						</Form>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.ShowUpdateModal('MailMagazine', e)}>
						<Row>
							<Col sm={12}>
								<p><big>メールマガジン設定</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								差出人メールアドレス
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="from">
								        <Form.Control name="from" type="email" defaultValue={this.state.mailmagazine_from} size="lg" required />
									<Form.Control.Feedback type="invalid">
										「差出人メールアドレス」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
							</Col>
						</Row>
						</Form>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.ShowUpdateModal('joinMail', e)}>
						<Row>
							<Col sm={12}>
								<p><big>入会時の自動返信メール設定</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								受信メールアドレス
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="bcc">
								        <Form.Control name="bcc" type="email" defaultValue={this.state.join_bcc} size="lg"required />
									<Form.Control.Feedback type="invalid">
										「受信メールアドレス」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								差出人メールアドレス
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="from">
								        <Form.Control name="from" type="email" defaultValue={this.state.join_from} size="lg" required />
									<Form.Control.Feedback type="invalid">
										「差出人メールアドレス」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								メールタイトル
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="subject">
								        <Form.Control name="subject" type="text" defaultValue={this.state.join_subject} size="lg" pattern=".{1,255}" required />
									<Form.Control.Feedback type="invalid">
										「メールタイトル」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								メール本文
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="msg">
								        <Form.Control name="msg" as="textarea" rows="15" defaultValue={this.state.join_msg} placeholder="" size="lg" pattern=".{1,1000}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「メール本文」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
							</Col>
						</Row>
						</Form>
					</Container>

					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.ShowUpdateModal('orderMail', e)}>
						<Row>
							<Col sm={12}>
								<p><big>受注時の自動返信メール設定</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								受信メールアドレス
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="bcc">
								        <Form.Control name="bcc" type="email" defaultValue={this.state.order_bcc} size="lg"required />
									<Form.Control.Feedback type="invalid">
										「受信メールアドレス」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								差出人メールアドレス
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="from">
								        <Form.Control name="from" type="email" defaultValue={this.state.order_from} size="lg" required />
									<Form.Control.Feedback type="invalid">
										「差出人メールアドレス」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								メールタイトル
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="subject">
								        <Form.Control name="subject" type="text" defaultValue={this.state.order_subject} size="lg" pattern=".{1,255}" required />
									<Form.Control.Feedback type="invalid">
										「メールタイトル」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								メール本文
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="msg">
								        <Form.Control name="msg" as="textarea" rows="15" defaultValue={this.state.order_msg} placeholder="" size="lg" pattern=".{1,1000}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「メール本文」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
							</Col>
						</Row>
						</Form>
					</Container>


					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.ShowUpdateModal('resignMail', e)}>
						<Row>
							<Col sm={12}>
								<p><big>退会時の自動返信メール設定</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								受信メールアドレス
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="bcc">
								        <Form.Control name="bcc" type="email" defaultValue={this.state.resign_bcc} size="lg"required />
									<Form.Control.Feedback type="invalid">
										「受信メールアドレス」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								差出人メールアドレス
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="from">
								        <Form.Control name="from" type="email" defaultValue={this.state.resign_from} size="lg" required />
									<Form.Control.Feedback type="invalid">
										「差出人メールアドレス」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								メールタイトル
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="subject">
								        <Form.Control name="subject" type="text" defaultValue={this.state.resign_subject} size="lg" pattern=".{1,255}" required />
									<Form.Control.Feedback type="invalid">
										「メールタイトル」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								メール本文
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="msg">
								        <Form.Control name="msg" as="textarea" rows="15" defaultValue={this.state.resign_msg} placeholder="" size="lg" pattern=".{1,1000}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「メール本文」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
							</Col>
						</Row>
						</Form>
					</Container>



					<Container style={{ border: 'solid 1px gray', borderRadius: '1em', padding: '2em', marginTop: 20 }} className="text-nowrap">
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.ShowUpdateModal('contactMail', e)}>
						<Row>
							<Col sm={12}>
								<p><big>お問い合わせの自動返信メール設定</big></p>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								受信メールアドレス
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="bcc">
								        <Form.Control name="bcc" type="email" defaultValue={this.state.contact_bcc} size="lg"required />
									<Form.Control.Feedback type="invalid">
										「受信メールアドレス」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								差出人メールアドレス
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="from">
								        <Form.Control name="from" type="email" defaultValue={this.state.contact_from} size="lg" required />
									<Form.Control.Feedback type="invalid">
										「差出人メールアドレス」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								メールタイトル
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="subject">
								        <Form.Control name="subject" type="text" defaultValue={this.state.contact_subject} size="lg" pattern=".{1,255}" required />
									<Form.Control.Feedback type="invalid">
										「メールタイトル」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm={3}>
								メール本文
							</Form.Label>
							<Col sm={8}>
								<Form.Group controlId="msg">
								        <Form.Control name="msg" as="textarea" rows="15" defaultValue={this.state.contact_msg} placeholder="" size="lg" pattern=".{1,1000}" disabled={this.state.isLockForm} />
									<Form.Control.Feedback type="invalid">
										「メール本文」を正しく入力してください。
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: 10 }}>
							<Col sm={{ span: 6, offset: 3 }}>
							        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
							</Col>
						</Row>
						</Form>
					</Container>


					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props.history.push('/Order/'); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					

				</Container>
			);
		}
	}
}

export default withRouter(Default);


