import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Modal,
	Badge,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import NotFound from '../../../Objects/NotFound';
import { AuditLog } from '../../../Objects/AuditLog';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isNotFound     : false,
			isValidated    : false,
			isSuccess      : false,
			isShowModal    : false,
			isLockForm     : false,
			formObj        : null,
			ErrMsg         : '',
			cognitoid      : '',
			addressid      : '',
		}
		this.handleReload     = this.handleReload.bind(this);
		this.handleSubmit     = this.handleSubmit.bind(this);
		this.handleShow       = this.handleShow.bind(this);
		this.handleClose      = this.handleClose.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
		this.lockFormClose    = this.lockFormClose.bind(this);
		this._getData         = this._getData.bind(this);
	}
	handleReload() {

		//Formオブジェクトの取得
		const formObj = this.state.formObj;

		//フォームのリセット
		formObj.reset();

		//データの初期化
		this.setState({
			isShowModal    : false,
			isSuccess      : false,
			isValidated    : false,
			isLockForm     : false,
			ErrMsg         : '',
			cognitoid      : '',
			mg_kbn_1       : '',
			mg_kbn_2       : '',
			mg_kbn_3       : '',
			en_kbn         : '',
			not_email_kbn  : '',
		});

	}
	async _getData() {

		try{
			//IDの取得
			const cognitoid = this.props.match.params.cognitoid;
			if(!cognitoid){
					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
			}

			//データ取得
			const ProfileQuery = `query getProfile($cognitoid: String!) {
				getProfile(cognitoid: $cognitoid) {
					name_1
					name_2
				}
			}`;
			const ProfileData = { cognitoid: cognitoid };
			const refProfile = await API.graphql(graphqlOperation(ProfileQuery, ProfileData));
			//console.log(ref);

			//データが空かどうか
			if(refProfile.data.getProfile === null){
				this.setState({
					isLoading   : false,
					isNotFound  : true,
				});
				return(0);
			}
			
			//名前取得
			const name = refProfile.data.getProfile.name_1 + ' ' + refProfile.data.getProfile.name_2;
			
			this.setState({
				isLoading     : false,
				cognitoid     : cognitoid,
				name          : name,
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLoading  : false,
				isLockForm : false,
			 });
			return(0);
		}
	}
	async handleSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowModal : false,
			isValidated : true,
			isLockForm  : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//Stateから取得する更新項目
			const cognitoid = this.state.cognitoid;

			//Formから直接取得する更新項目
			const o_name_1      = formObj.o_name_1.value;
			const o_name_2      = formObj.o_name_2.value;
			const o_name_furi_1 = formObj.o_name_furi_1.value;
			const o_name_furi_2 = formObj.o_name_furi_2.value;
			const o_zip_1       = formObj.o_zip_1.value;
			const o_zip_2       = formObj.o_zip_2.value;
			const o_pref        = formObj.o_pref.value;
			const o_addr_1      = formObj.o_addr_1.value;
			const o_addr_2      = formObj.o_addr_2.value;
			const o_addr_3      = formObj.o_addr_3.value;
			const o_tel_1       = formObj.o_tel_1.value;
			const o_tel_2       = formObj.o_tel_2.value;
			const o_tel_3       = formObj.o_tel_3.value;
			const o_com_name    = formObj.o_com_name.value;
			
			//日付処理
		        const date           = new Date();
		        const isoDate        = date.toISOString();
		        
			//IDの作成
			let addressid    = '';
			for (  let i = 0;  i < 255;  i++  ) {
				let random = '';
				for (  let j = 0;  j < 8;  j++  ) {
					random = random + ('00' + Math.ceil( Math.random()*255 ).toString(16)).slice(-2);
				}
				const generate = 'address-' + random;
				//カテゴリの取得
				const Query = `query getAddress($cognitoid: String!, $addressid: String!) {
					getAddress(cognitoid: $cognitoid, addressid: $addressid) {
						cognitoid
						addressid
					}
				}`;
				const Data = {
					cognitoid: cognitoid,
					addressid: generate,
				};
				const ref = await API.graphql(graphqlOperation(Query, Data));
				//console.log(ref);
				if(ref.data.getAddress === null){
					addressid = generate;
					break;
				}
			}
			if(addressid === ''){
				this.setState({
					ErrMsg     : '※ID取得に失敗しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			

			//プロフィールの登録
			const createQuery = `mutation createAddress($data: AddressInput!) {
				createAddress(input: $data) {
					cognitoid
					addressid
				}
			}`;
			const createData = {
				data : {
					cognitoid     : cognitoid,
					addressid     : addressid,
					o_name_1      : o_name_1,
					o_name_2      : o_name_2,
					o_name_furi_1 : o_name_furi_1,
					o_name_furi_2 : o_name_furi_2,
					o_zip_1       : o_zip_1,
					o_zip_2       : o_zip_2,
					o_pref        : o_pref,
					o_addr_1      : o_addr_1,
					o_addr_2      : o_addr_2,
					o_addr_3      : o_addr_3 ? o_addr_3 : 'null',
					o_tel_1       : o_tel_1,
					o_tel_2       : o_tel_2,
					o_tel_3       : o_tel_3,
					o_com_name    : o_com_name ? o_com_name : 'null',
					date_add      : isoDate,
					date_edit     : isoDate,
				}
			};
			await API.graphql(graphqlOperation(createQuery, createData));

			//鑑査ログ
			await AuditLog('user', 'update', cognitoid, createData, 'アドレス情報を登録しました。');

			this.setState({
				cognitoid : cognitoid,
				addressid : addressid,
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			});
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	handleClose() {
		this.setState({
			isShowModal    : false,
			isSuccess      : false,
			isValidated    : false,
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleShow(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowModal: true,
			formObj    : form,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else if(this.state.isNotFound){
			return (
				<NotFound />
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>
							登録しました。<br/>
							続けて登録しますか？
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={()=>{ this.props.history.push('/User/' + this.state.cognitoid + '/Address/' + this.state.addressid); }}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.handleReload}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowModal} onHide={this.handleClose} centered>
						<Modal.Body>本当に登録しますか？</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.handleSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleShow(e)}>
					<Row>
						<Col sm={8}>
							<p><big>アドレス情報の新規登録</big></p>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							お名前　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={3}>
							<Form.Group controlId="o_name_1">
							        <Form.Control name="o_name_1" type="text" placeholder="お名前（名字）" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「お名前（名字）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={3}>
							<Form.Group controlId="o_name_2">
							        <Form.Control name="o_name_2" type="text" placeholder="お名前（名前）" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「お名前（名前）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							フリガナ　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={3}>
							<Form.Group controlId="o_name_furi_1">
							        <Form.Control name="o_name_furi_1" type="text" placeholder="フリガナ（名字）" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「フリガナ（名字）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={3}>
							<Form.Group controlId="o_name_furi_2">
							        <Form.Control name="o_name_furi_2" type="text" placeholder="フリガナ（名前）" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「フリガナ（名前）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							郵便番号　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="o_zip_1">
							        <Form.Control name="o_zip_1" type="text" placeholder="3桁" size="lg" required pattern="\d{3}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「郵便番号（3桁）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="o_zip_2">
							        <Form.Control name="o_zip_2" type="text" placeholder="4桁" size="lg" required pattern="\d{4}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「郵便番号（4桁）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							都道府県　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="o_pref">
							        <Form.Control name="o_pref" type="text" placeholder="都道府県" size="lg" required pattern=".{2,4}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「都道府県」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							住所（市区町村）　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="o_addr_1">
							        <Form.Control name="o_addr_1" type="text" placeholder="市区町村" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「住所（市区町村）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							住所（番地）　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="o_addr_2">
							        <Form.Control name="o_addr_2" type="text" placeholder="番地" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「住所（番地）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							住所（その他・建物）
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="o_addr_3">
							        <Form.Control name="o_addr_3" type="text" placeholder="その他・建物" size="lg" pattern=".{0,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「住所（その他・建物）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							会社名
						</Form.Label>
						<Col sm={6}>
							<Form.Group controlId="o_com_name">
							        <Form.Control name="o_com_name" type="text" placeholder="会社名" size="lg" pattern=".{0,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「会社名」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							電話番号　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="o_tel_1">
							        <Form.Control name="o_tel_1" type="text" placeholder="市外局番" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「電話番号（市外局番）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="o_tel_2">
							        <Form.Control name="o_tel_2" type="text" placeholder="市内局番" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「電話番号（市内局番）」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Group controlId="o_tel_3">
							        <Form.Control name="o_tel_3" type="text" placeholder="番号" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「電話番号」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >登録する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props.history.push('/User/' + this.state.cognitoid + '/Address/'); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					</Form>
				</Container>
			);
		}
	}
}

export default withRouter(Default);


