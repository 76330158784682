import React from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import {
	Alert,
	Modal,
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';


class MyTable extends React.Component {
	constructor(props) {
		super(props);
		this.handleClickDetails      = this.handleClickDetails.bind(this);
		this._dataFormatLabel        = this._dataFormatLabel.bind(this);
		this._dataFormatButton       = this._dataFormatButton.bind(this);
		this._dataFormatDate         = this._dataFormatDate.bind(this);
	}
	handleClickDetails(row){
		if(row.orderno && row.date_add){
			this.props.history.push('/Order/' + row.orderno + '/MailSendLog/' + row.date_add + '/');
		}
	}
	_dataFormatLabel(cell, row) {
		return (
			<div className="text-center" style={{margin: "10px"}}>
				<Badge variant={cell ? 'danger' : 'success'}>
					{cell ? '失敗' : '成功'}
				</Badge>　
			</div>
		);
	}
	_dataFormatButton(cell, row) {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" href={'/Order/' + row.orderno + '/MailSendLog/' + row.date_add + '/'} target="_blank" >{/*onClick={() => this.handleClickDetails(row)}*/}
					詳細
				</Button>
			</div>
		);
	}
	_dataFormatDate(cell, row) {

		//日付の整形
		const dateObj     = new Date(cell);
		const year        = dateObj.getFullYear().toString();
		const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day         = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second      = ('00' + dateObj.getSeconds().toString()).slice(-2);

		//出力
		return (
			<div>
				{year}年{month}月{day}日{hour}時{minute}分{second}秒
			</div>
		);
	}
	render() {
		if(this.props.data.length === 0){
			return (
				<Alert variant="danger">
					対象のデータが見つかりません。
				</Alert>
			);
		}
		else{
		
			//表示用に整形
			let TableData = [];
			for(let item of this.props.data){

				const orderno    = item.orderno;
				const date_add   = item.date_add;
				const to         = item.to;
				const cc         = item.cc;
				const bcc        = item.bcc;
				const from       = item.from;
				const subject    = item.subject;
				const msg        = item.msg.length > 10 ? item.msg.substr(0,10) + '...' : item.msg;
				const msg_full   = item.msg;
				const ref        = item.ref ? item.ref.replace(/^.*MessageId=/,'').replace(/}.*$/,'') : '';
				const log_text   = item.log_text;
				const error_flag = item.error_flag;
				const error_text = item.error_text;
				const RowData = {
					orderno    : orderno,
					date_add   : date_add,
					to         : to,
					cc         : cc,
					bcc        : bcc,
					from       : from,
					subject    : subject,
					msg        : msg,
					msg_full   : msg_full,
					ref        : ref,
					log_text   : log_text,
					error_flag : error_flag,
					error_text : error_text,
				};
				
				TableData.push(RowData);
			}
			
			const columns = [
				{
					dataField: 'orderno',
					text: '詳細',
					sort: false,
					formatter: this._dataFormatButton,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'date_add',
					text: '日時',
					sort: true,
					formatter: this._dataFormatDate,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'error_flag',
					text: '判定',
					sort: true,
					formatter: this._dataFormatLabel,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'to',
					text: '送信先',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'from',
					text: '送信元',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'ref',
					text: 'メッセージID',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'subject',
					text: '件名',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
				{
					dataField: 'log_text',
					text: 'ログ内容',
					sort: true,
					headerStyle:  { whiteSpace : 'nowrap' },
					style:  { whiteSpace : 'nowrap' },
				},
			];

			const defaultSorted = [
				{
					dataField: 'date_add',
					order: 'desc'
				}
			];
			
			const options = {
				sizePerPageList: [
					{ text: '10', value: 10},
					{ text: '50', value: 50},
					{ text: '100', value: 100},
					{ text: 'All', value: TableData.length},
				],
			};
			
			return (
				<BootstrapTable bootstrap4 keyField='date_add' data={ TableData } columns={ columns } bordered={ false } defaultSorted={ defaultSorted } pagination={ paginationFactory(options) } />
			);

		}
	}
}

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading   : true,
			ErrMsg      : '',
			dateTime    : '',
			viewData    : [],
			defaultData : [],
		}
		this._reload              = this._reload.bind(this);
		this._getData             = this._getData.bind(this);
		this.handleDismiss        = this.handleDismiss.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleSearch         = this.handleSearch.bind(this);
	}
	_reload() {
		//子ウィンドウからの呼び出し用
		this.handleReload();
	}
	async _getData() {
		
		//処理
		try{

			//IDの取得
			const orderno = this.props.match.params.orderno;
			if(!orderno){
					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
			}
			
			//console.log(orderno);
			
			let selectIDs = [];

			if(orderno === 'Multiple'){
				try{
					//ストレージを定義
					Storage.configure({ AWSS3: { bucket: 'mindwavestore.admin', region: 'ap-northeast-1', level: 'private' } });

					//S3からファイルを取得
					const result   = await Storage.get('mailSend.json', { download: true });

					//データ
					const jsonText = await new Promise(resolve =>
					{
						const reader = new FileReader();
						reader.onload = () => resolve(reader.result);
						reader.readAsText(result.Body);
					});
					
					//console.log(jsonText);

					//JSON形式を変換
					const jsonData = JSON.parse(jsonText);

					//データ取得
					selectIDs = jsonData.selectIDs;
					
					//console.log(selectIDs);
					
					//1件だったら複数送信画面から通常送信画面に移行
					if(selectIDs.length === 1){
						window.location.href = '/Order/' + selectIDs[0] + '/MailSendLog/';
						return(0)
					}
				}
				catch(err){
					//console.log(err);
				}
			}
			else{
				selectIDs.push(orderno);
			}
			
			//console.log(selectIDs);
			
			if(selectIDs.length === 0 ){
				this.setState({
					isLoading   : false,
					isNotFound  : true,
				});
				return(0);
			}
			
			const newData   = [];
			
			//データ取得
			for(const orderno of selectIDs){

				//商品情報の取得
				const Query = `query queryMailSendLog($orderno: String!) {
					queryMailSendLog(orderno: $orderno) {
						items {
							orderno
							date_add
							to
							cc
							bcc
							from
							subject
							msg
							ref
							log_text
							error_flag
							error_text
						}
						nextToken
					}
				}`;
				const Data = {
					orderno  : orderno,
				};
				let ref = await API.graphql(graphqlOperation(Query, Data));
				let Items = ref.data.queryMailSendLog.items;
				//console.log(Items);
				
				//商品情報の取得（未取得の続きデータがあった場合の処理）
				while (ref.data.queryMailSendLog.nextToken) {
					const Query = `query queryMailSendLog($orderno: String!, $nextToken: String) {
						queryMailSendLog(orderno: $orderno, nextToken: $nextToken) {
							items {
								orderno
								date_add
								to
								cc
								bcc
								from
								subject
								msg
								ref
								log_text
								error_flag
								error_text
							}
							nextToken
						}
					}`;
					const Data = {
						orderno  : orderno,
						nextToken: ref.data.queryMailSendLog.nextToken,
					};
					//console.log(ref.data.queryMailSendLog.nextToken);
					ref = await API.graphql(graphqlOperation(Query, Data));
					Items = Items.concat(ref.data.queryMailSendLog.items);
				}
				
				//データ格納
				for(const item of Items){
					newData.push(item);
				}
			}

			//データの判別
			if(newData.length === 0){
				//データがなかった時
				this.setState({
					isLoading : false,
					ErrMsg    : 'データがありません。',
				});
			}
			else{

				//'null'文字がある場合は''に変換
				//Items.some((item, index) => Object.keys(item).some(key => { if(item[key] === 'null'){ Items[index][key] = '' } return false; }));
				
				//データコピー
				const viewData = [];
				for(let row of newData){
					viewData.push(row);
				}
				
				//データコピー
				const defaultData = [];
				for(let row of newData){
					defaultData.push(row);
				}
				
				//データの保存
				this.setState({
					isLoading   : false,
					viewData    : viewData,
					defaultData : defaultData,
				});
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				isLoading  : false,
				ErrMsg     : err_msg,
			});
		}
	}
	handleDismiss() {
		this.setState({ ErrMsg: '' });
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
			viewData    : [],
			defaultData : [],
		});
		this._getData();
	}
	handleSearch(e){

		//Submitをキャンセル
		e.preventDefault();
		e.stopPropagation();
	
		//検索文字列と対象データを取得
		const key   = e.target.value;
		const defaultData = this.state.defaultData;
		
		//検索文字及び対象データがない場合はリセット
		if(key === '' || defaultData.length === 0){
			this.setState({ viewData: defaultData});
			return(0);
		}
		
		//検索文字オブジェクト
		const matchString = new RegExp(key);
		
		//検索
		const viewData = defaultData.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		//表示データ更新
		this.setState({ viewData: viewData});
	}
	setSelectIDs (selectIDs){
	
		//デフォルトのデータを取得
		const defaultData = this.state.defaultData;

		//デフォルトのデータ内から選択されているIDのデータのみ抽出
		const csvData = defaultData.filter(item => selectIDs.indexOf(item.cognitoid) !== -1);
		
		//選択しているIDとCSV用データを保存
		this.setState({
			selectIDs: selectIDs,
			csvData  : csvData,
		});
	}
	async componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else{
			return (
				<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleDismiss} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleDismiss}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					<Form onSubmit={e => this.handleSearch(e)}>
						<Row style={{ marginTop: 20 }}>
							<Col sm={4}>
								<Form.Group controlId="search">
								        <Form.Control name="search" type="text" placeholder="検索" size="lg" onChange={e => this.handleSearch(e)}/>
								</Form.Group>
							</Col>
							<Col sm={4}>
								<Badge pill variant="secondary">
									<h5>　{ this.state.viewData.length.toLocaleString() }件　</h5>
								</Badge>
							</Col>
						</Row>
					</Form>

					<Row style={{ marginBottom: 20 }}>
						<Col sm={6}>
						        <big>メール送信ログ</big>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ this.handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<MyTable data={this.state.viewData} {...this.props} />
						</Col>
					</Row>
				</Container>
			);
		}
	}
}

export default withRouter(Default);

