import React from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image,
	Modal,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import NotFound from '../../Objects/NotFound';
import { AuditLog } from '../../Objects/AuditLog';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isNotFound     : false,
			isValidated    : false,
			isSuccess      : false,
			isDeleted      : false,
			isShowUpdate   : false,
			isShowDelete   : false,
			isShowImage    : false,
			isLockForm     : false,
			formObj        : null,
			ErrMsg         : '',
			imageName      : '',
			code           : '',
			name           : '',
			m_code         : '',
			m_name         : '',
			price          : '',
			price_notax    : '',
			piece_type     : '',
			title          : '',
			text_1         : '',
			text_2         : '',
			text_3         : '',
			text_4         : '',
			text_5         : '',
			select_1       : '',
			select_2       : '',
			select_3       : '',
			zaiko          : '',
			zaiko_flag     : '',
			flag_1         : '',
			flag_2         : '',
			flag_3         : '',
			flag_4         : '',
			flag_5         : '',
			flag_6         : '',
			flag_7         : '',
			flag_8         : '',
			flag_9         : '',
			en_kbn         : '',
			date_add       : '',
			date_edit      : '',
		}
		this.handleReload      = this.handleReload.bind(this);
		this.updateSubmit      = this.updateSubmit.bind(this);
		this.deleteSubmit      = this.deleteSubmit.bind(this);
		this.handleUpdate      = this.handleUpdate.bind(this);
		this.handleDelete      = this.handleDelete.bind(this);
		this.handleClose       = this.handleClose.bind(this);
		this.handleErrorClose  = this.handleErrorClose.bind(this);
		this.updateImage       = this.updateImage.bind(this);
		this.deleteImage       = this.deleteImage.bind(this);
		this.deleteImageSubmit = this.deleteImageSubmit.bind(this);
		this.lockFormClose     = this.lockFormClose.bind(this);
		this._getData          = this._getData.bind(this);
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	async _getData() {

		try{

			//IDの取得
			const code = this.props.match.params.code;
			if(!code){
					this.setState({
						isLoading   : false,
						ErrMsg  : '商品IDが読み取れません。<br/>',
					});
					return(0);
			}

			//データ取得
			const Query = `query getItem($code: String!) {
				getItem(code: $code) {
					name
					m_code
					m_name
					price
					price_notax
					piece_type
					title
					text_1
					text_2
					text_3
					text_4
					text_5
					select_1
					select_2
					select_3
					zaiko
					zaiko_flag
					flag_1
					flag_2
					flag_3
					flag_4
					flag_5
					flag_6
					flag_7
					flag_8
					flag_9
					en_kbn
					date_add
					date_edit
				}
			}`;
			const Data = { code: code };
			const ref = await API.graphql(graphqlOperation(Query, Data));
			//console.log(ref);
			
			//データが空かどうか
			if(ref.data.getItem === null){
				this.setState({
					isLoading   : false,
					isNotFound  : true,
				});
				return(0);
			}

			const name             = ref.data.getItem.name;
			const m_code           = ref.data.getItem.m_code;
			const m_name           = ref.data.getItem.m_name;
			const price            = ref.data.getItem.price;
			const price_notax      = ref.data.getItem.price_notax;
			const piece_type       = ref.data.getItem.piece_type;
			const title            = ref.data.getItem.title;
			const text_1           = ref.data.getItem.text_1;
			const text_2           = ref.data.getItem.text_2;
			const text_3           = ref.data.getItem.text_3;
			const text_4           = ref.data.getItem.text_4;
			const text_5           = ref.data.getItem.text_5;
			const select_1         = ref.data.getItem.select_1 === 'null' ? '' : ref.data.getItem.select_1;
			const select_2         = ref.data.getItem.select_2 === 'null' ? '' : ref.data.getItem.select_2;
			const select_3         = ref.data.getItem.select_3 === 'null' ? '' : ref.data.getItem.select_3;
			const zaiko            = ref.data.getItem.zaiko;
			const zaiko_flag       = ref.data.getItem.zaiko_flag;
			const flag_1           = ref.data.getItem.flag_1;
			const flag_2           = ref.data.getItem.flag_2;
			const flag_3           = ref.data.getItem.flag_3;
			const flag_4           = ref.data.getItem.flag_4;
			const flag_5           = ref.data.getItem.flag_5;
			const flag_6           = ref.data.getItem.flag_6;
			const flag_7           = ref.data.getItem.flag_7;
			const flag_8           = ref.data.getItem.flag_8;
			const flag_9           = ref.data.getItem.flag_9;
			const en_kbn           = ref.data.getItem.en_kbn;

			//日付
			const date_add         = new Date(ref.data.getItem.date_add);
			const date_add_year    = date_add.getFullYear().toString();
			const date_add_month   = ('00' + (date_add.getMonth()+1).toString()).slice(-2);
			const date_add_day     = ('00' + date_add.getDate().toString()).slice(-2);
			const date_add_hour    = ('00' + date_add.getHours().toString()).slice(-2);
			const date_add_minute  = ('00' + date_add.getMinutes().toString()).slice(-2);
			const date_add_second  = ('00' + date_add.getSeconds().toString()).slice(-2);
			const date_edit        = new Date(ref.data.getItem.date_edit);
			const date_edit_year   = date_add.getFullYear().toString();
			const date_edit_month  = ('00' + (date_edit.getMonth()+1).toString()).slice(-2);
			const date_edit_day    = ('00' + date_edit.getDate().toString()).slice(-2);
			const date_edit_hour   = ('00' + date_edit.getHours().toString()).slice(-2);
			const date_edit_minute = ('00' + date_edit.getMinutes().toString()).slice(-2);
			const date_edit_second = ('00' + date_edit.getSeconds().toString()).slice(-2);
			const date_now         = new Date();

			this.setState({
				isLoading     : false,
				code          : code,
				name          : name,
				m_code        : m_code,
				m_name        : m_name,
				price         : price,
				price_notax   : price_notax,
				piece_type    : piece_type,
				title         : title,
				text_1        : text_1,
				text_2        : text_2,
				text_3        : text_3,
				text_4        : text_4,
				text_5        : text_5,
				select_1      : select_1,
				select_2      : select_2,
				select_3      : select_3,
				zaiko         : zaiko,
				zaiko_flag    : zaiko_flag,
				flag_1        : flag_1,
				flag_2        : flag_2,
				flag_3        : flag_3,
				flag_4        : flag_4,
				flag_5        : flag_5,
				flag_6        : flag_6,
				flag_7        : flag_7,
				flag_8        : flag_8,
				flag_9        : flag_9,
				en_kbn        : en_kbn,
				date_add      : date_add_year + '年' + date_add_month + '月' + date_add_day + '日' + date_add_hour + '時' + date_add_minute + '分' + date_add_second + '秒',
				date_edit     : date_edit_year + '年' + date_edit_month + '月' + date_edit_day + '日' + date_edit_hour + '時' + date_edit_minute + '分' + date_edit_second + '秒',
				date_now      : date_now.toISOString(),

			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLoading  : false,
				isLockForm : false,
			 });
			return(0);
		}
	}
	async updateSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowUpdate : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				 });
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}
			
			//Formから直接取得する更新項目
			const name        = formObj.name.value;
			const m_code      = formObj.m_code.value;
			const m_name      = formObj.m_name.value;
			const price       = formObj.price.value;
			const price_notax = formObj.price_notax.value;
			const piece_type  = formObj.piece_type.value;
			const title       = formObj.title.value;
			const text_1      = formObj.text_1.value;
			const text_2      = formObj.text_2.value;
			const text_3      = formObj.text_3.value;
			const text_4      = formObj.text_4.value;
			const text_5      = 'null';
			const select_1    = formObj.select_1.value === '' ? 'null' : formObj.select_1.value;
			const select_2    = formObj.select_2.value === '' ? 'null' : formObj.select_2.value;
			const select_3    = 'null';
			const zaiko       = formObj.zaiko.value;

			//Stateから取得する更新項目
			const code        = this.state.code;
			const zaiko_flag  = this.state.zaiko_flag    ? true :false;
			const flag_1      = this.state.flag_1        ? true :false;
			const flag_2      = this.state.flag_2        ? true :false;
			const flag_3      = this.state.flag_3        ? true :false;
			const flag_4      = this.state.flag_4        ? true :false;
			const flag_5      = this.state.flag_5        ? true :false;
			const flag_6      = this.state.flag_6        ? true :false;
			const flag_7      = this.state.flag_7        ? true :false;
			const flag_8      = this.state.flag_8        ? true :false;
			const flag_9      = this.state.flag_9        ? true :false;
			const en_kbn　    = this.state.en_kbn        ? true :false;

			//日付処理
		        const date          = new Date();
		        const isoDate       = date.toISOString();
			
			//商品の更新登録
			const Query = `mutation updateItem($data: ItemInput!) {
				updateItem(input: $data) {
					code
				}
			}`;
			const Data = {
				data : {
					code        : code,
					name        : name,
					m_code      : m_code,
					m_name      : m_name,
					price       : price,
					price_notax : price_notax,
					piece_type  : piece_type,
					title       : title,
					text_1      : text_1,
					text_2      : text_2,
					text_3      : text_3,
					text_4      : text_4,
					text_5      : text_5,
					select_1    : select_1,
					select_2    : select_2,
					select_3    : select_3,
					zaiko       : zaiko,
					zaiko_flag  : zaiko_flag,
					zaiko_memo  : '変更',
					flag_1      : flag_1,
					flag_2      : flag_2,
					flag_3      : flag_3,
					flag_4      : flag_4,
					flag_5      : flag_5,
					flag_6      : flag_6,
					flag_7      : flag_7,
					flag_8      : flag_8,
					flag_9      : flag_9,
					en_kbn      : en_kbn,
					date_edit   : isoDate,
				}
			};
			await API.graphql(graphqlOperation(Query, Data));

			//鑑査ログ
			await AuditLog('item', 'update', code, Data, '商品情報を変更しました。');
		}
		catch(error) {
		
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isSuccess   : true,
			isLockForm  : false,
		});
	}
	async deleteSubmit () {

		//モーダルの消去とバリデーションの有効化
		this.setState({
			isShowDelete : false,
			isValidated  : true,
			isLockForm   : true,
		});

		//更新処理
		try{
			//Formオブジェクトの確認
			const formObj     = this.state.formObj;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Stateから取得する更新項目
			const code      = this.state.code;
			
			//商品の更新登録
			const Query = `mutation deleteItem($data: ItemInput!) {
				deleteItem(input: $data) {
					code
				}
			}`;
			const Data = {
				data : {
					code   : code,
				}
			};
			await API.graphql(graphqlOperation(Query, Data));

			//鑑査ログ
			await AuditLog('item', 'delete', code, Data, '商品情報を削除しました。');
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

		//更新成功
		this.setState({
			isDeleted   : true,
			isLockForm  : false,
		});
	}
	handleClose() {
		this.setState({
			isShowImage  : false,
			isShowUpdate : false,
			isShowDelete : false,
			isSuccess    : false,
			isValidated  : false,
			isLockForm   : false,
			ErrMsg       : '',
		});
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleUpdate(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowUpdate : true,
			formObj      : form,
		});
	}
	handleDelete(event) {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		this.setState({
			isShowDelete : true,
			formObj      : form,
		});
	}
	deleteImage(imageName, event) {
		event.preventDefault();
		event.stopPropagation();
		this.setState({
			isShowImage  : true,
			imageName    : imageName,
		});
	}
	//画像ファイルのアップロード
	async updateImage(imageName, event) {

		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		try{
			//画像データ
			const file = event.target.files[0];
		
			if(file){

				//画面ロックの有効化
				this.setState({
					isLockForm : true,
				});

				//ストレージを定義
				Storage.configure({ AWSS3: { bucket: 'images.mindwave-store.com', region: 'us-east-1', level: 'public' } });

				//ファイルをアップロード
				await Storage.put(imageName, file);

				//現在日付
				const date_now         = new Date();
				
				//更新
				this.setState({
					isSuccess  : true,
					isLockForm : false,
					date_now   : date_now.toISOString(),
				});
			}

		}
		catch(error) {
			//console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}
	}
	//画像ファイルの削除
	async deleteImageSubmit () {

		try{
			//画像ファイル名
			const imageName = this.state.imageName;

			if(imageName){

				//モーダルの消去と画面ロックの有効化
				this.setState({
					isShowImage : false,
					isLockForm  : true,
				});

				//ストレージを定義
				Storage.configure({ AWSS3: { bucket: 'images.mindwave-store.com', region: 'us-east-1', level: 'public' } });

				//ファイルを削除
				await Storage.remove(imageName);

				//現在日付
				const date_now         = new Date();
				
				//更新
				this.setState({
					isSuccess  : true,
					isLockForm : false,
					date_now   : date_now.toISOString(),
					imageName  : '',
				});
			}

		}
		catch(error) {
			//console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
				imageName  : '',
			 });
			return(0);
		}
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			);
		}
		else if(this.state.isNotFound){
			return (
				<NotFound />
			);
		}
		else{
			return (
				<Container style={{ marginTop: 50, marginBottom: 200 }}>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>更新しました。</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleClose}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isDeleted} onHide={()=>{ this.props.history.push('/Item/'); }} centered>
						<Modal.Body>削除しました。</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={()=>{ this.props.history.push('/Item/'); }}>
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowUpdate} onHide={this.handleClose} centered>
						<Modal.Body>
							本当に更新しますか？<br/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.updateSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowDelete} onHide={this.handleClose} centered>
						<Modal.Body>本当に削除しますか？</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.deleteSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isShowImage} onHide={this.handleClose} centered>
						<Modal.Body>本当に削除しますか？</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								いいえ
							</Button>
							<Button variant="primary" onClick={this.deleteImageSubmit}>
								はい
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleUpdate(e)}>
					<Row>
						<Col sm={6}>
							<p><big>商品情報</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ this.handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							商品コード
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="code">
							        <Form.Control name="code" type="text" defaultValue={this.state.code} size="lg" readOnly />
								<Form.Control.Feedback />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							登録日時
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="date_add">
							        <Form.Control name="date_add" type="text" defaultValue={this.state.date_add} size="lg" readOnly />
								<Form.Control.Feedback />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							更新日時
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="date_edit">
							        <Form.Control name="date_edit" type="text" defaultValue={this.state.date_edit} size="lg" readOnly />
								<Form.Control.Feedback />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							商品名　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="name">
							        <Form.Control name="name" type="text" defaultValue={this.state.name} placeholder="商品名" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「商品名」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メーカーコード　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="m_code">
							        <Form.Control name="m_code" type="text" defaultValue={this.state.m_code} placeholder="メーカーコード" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「メーカーコード」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							メーカー名　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="m_name">
							        <Form.Control name="m_name" type="text" defaultValue={this.state.m_name} placeholder="メーカー名" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「メーカー名」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							販売価格　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={3}>
							<Form.Group controlId="price">
							        <Form.Control name="price" type="text" defaultValue={this.state.price} placeholder="販売価格" size="lg" required pattern="\d{1,20}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「販売価格」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<h5 style={{ marginTop: 20 }}>円</h5>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							税抜価格　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={3}>
							<Form.Group controlId="price_notax">
							        <Form.Control name="price_notax" type="text" defaultValue={this.state.price_notax} placeholder="税抜価格" size="lg" required pattern="\d{1,20}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「税抜価格」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<h5 style={{ marginTop: 20 }}>円</h5>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							個数名称　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="piece_type">
							        <Form.Control name="piece_type" type="text" defaultValue={this.state.piece_type} placeholder="個数名称" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「個数名称」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							見出しキャッチ　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="title">
							        <Form.Control name="title" type="text" defaultValue={this.state.title} placeholder="見出しキャッチ" size="lg" required pattern=".{1,255}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「見出しキャッチ」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							商品説明　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="text_1">
							        <Form.Control name="text_1" as="textarea" rows="3" defaultValue={this.state.text_1} placeholder="商品説明" size="lg" required pattern=".{1,1000}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「商品説明」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							カラー説明　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="text_2">
							        <Form.Control name="text_2" as="textarea" rows="3" defaultValue={this.state.text_2} placeholder="カラー説明" size="lg" required pattern=".{1,1000}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「カラー説明」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							サイズ説明　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="text_3">
							        <Form.Control name="text_3" as="textarea" rows="3" defaultValue={this.state.text_3} placeholder="サイズ説明" size="lg" required pattern=".{1,1000}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「サイズ説明」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							付属品その他　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="text_4">
							        <Form.Control name="text_4" as="textarea" rows="3" defaultValue={this.state.text_4} placeholder="付属品その他" size="lg" required pattern=".{1,1000}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「付属品その他」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							色選択
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="select_1">
							        <Form.Control name="select_1" as="textarea" rows="3" defaultValue={this.state.select_1} placeholder="色選択" size="lg" pattern=".{1,1000}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「色選択」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							サイズ選択
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId="select_2">
							        <Form.Control name="select_2" as="textarea" rows="3" defaultValue={this.state.select_2} placeholder="サイズ選択" size="lg" pattern=".{1,1000}" disabled={this.state.isLockForm} />
								<Form.Control.Feedback type="invalid">
									「サイズ選択」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginBottom: 50 }}>
						<Form.Label column sm={3}>
							メイン画像
						</Form.Label>
						<Col sm={4}>
							<Image src={'https://images.mindwave-store.com/public/' + this.state.code + '_main.jpg?' + this.state.date_now} thumbnail />
							<Form.Control type="file" accept='image/*' onChange={(e) => this.updateImage(this.state.code + '_main.jpg', e)} />
						</Col>
						<Col sm={4} style={{ marginTop: 100 }}>
							<Button variant="danger" type="button" block size="lg" onClick={(e) => this.deleteImage(this.state.code + '_main.jpg', e)} disabled={this.state.isLockForm} >この画像を削除する</Button>
						</Col>
					</Row>
					<Row style={{ marginBottom: 50 }}>
						<Form.Label column sm={3}>
							サブ画像1
						</Form.Label>
						<Col sm={4}>
							<Image src={'https://images.mindwave-store.com/public/' + this.state.code + '_sub01.jpg?' + this.state.date_now} thumbnail />
							<Form.Control type="file" accept='image/*' onChange={(e) => this.updateImage(this.state.code + '_sub01.jpg', e)} />
						</Col>
						<Col sm={4} style={{ marginTop: 100 }}>
							<Button variant="danger" type="button" block size="lg" onClick={(e) => this.deleteImage(this.state.code + '_sub01.jpg', e)} disabled={this.state.isLockForm} >この画像を削除する</Button>
						</Col>
					</Row>
					<Row style={{ marginBottom: 50 }}>
						<Form.Label column sm={3}>
							サブ画像2
						</Form.Label>
						<Col sm={4}>
							<Image src={'https://images.mindwave-store.com/public/' + this.state.code + '_sub02.jpg?' + this.state.date_now} thumbnail />
							<Form.Control type="file" accept='image/*' onChange={(e) => this.updateImage(this.state.code + '_sub02.jpg', e)} />
						</Col>
						<Col sm={4} style={{ marginTop: 100 }}>
							<Button variant="danger" type="button" block size="lg" onClick={(e) => this.deleteImage(this.state.code + '_sub02.jpg', e)} disabled={this.state.isLockForm} >この画像を削除する</Button>
						</Col>
					</Row>
					<Row style={{ marginBottom: 50 }}>
						<Form.Label column sm={3}>
							サブ画像3
						</Form.Label>
						<Col sm={4}>
							<Image src={'https://images.mindwave-store.com/public/' + this.state.code + '_sub03.jpg?' + this.state.date_now} thumbnail />
							<Form.Control type="file" accept='image/*' onChange={(e) => this.updateImage(this.state.code + '_sub03.jpg', e)} />
						</Col>
						<Col sm={4} style={{ marginTop: 100 }}>
							<Button variant="danger" type="button" block size="lg" onClick={(e) => this.deleteImage(this.state.code + '_sub03.jpg', e)} disabled={this.state.isLockForm} >この画像を削除する</Button>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							在庫数　<Badge variant="danger">必須</Badge>
						</Form.Label>
						<Col sm={2}>
							<Form.Group controlId="zaiko">
							        <Form.Control name="zaiko" type="text" defaultValue={this.state.zaiko} placeholder="在庫数" size="lg" required pattern="\d{1,20}" disabled={this.state.isLockForm} readOnly={this.state.zaiko_flag}/>
								<Form.Control.Feedback type="invalid">
									「在庫数」を正しく入力してください。
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							在庫数無制限フラグ
						</Form.Label>
						<Col sm={2}>
							<Button name="zaiko_flag" variant={this.state.zaiko_flag ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ zaiko_flag: this.state.zaiko_flag ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.zaiko_flag ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							属性アイコン（NEW）
						</Form.Label>
						<Col sm={2}>
							<Button name="flag_1" variant={this.state.flag_1 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ flag_1: this.state.flag_1 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.flag_1 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							属性アイコン（PICKUP）
						</Form.Label>
						<Col sm={2}>
							<Button name="flag_2" variant={this.state.flag_2 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ flag_2: this.state.flag_2 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.flag_2 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							属性アイコン（SALE）
						</Form.Label>
						<Col sm={2}>
							<Button name="flag_3" variant={this.state.flag_3 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ flag_3: this.state.flag_3 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.flag_3 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							属性アイコン（DM便可）
						</Form.Label>
						<Col sm={2}>
							<Button name="flag_4" variant={this.state.flag_4 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ flag_4: this.state.flag_4 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.flag_4 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							属性アイコン（宅急便）
						</Form.Label>
						<Col sm={2}>
							<Button name="flag_5" variant={this.state.flag_5 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ flag_5: this.state.flag_5 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.flag_5 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							属性アイコン（DM10）
						</Form.Label>
						<Col sm={2}>
							<Button name="flag_6" variant={this.state.flag_6 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ flag_6: this.state.flag_6 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.flag_6 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							属性アイコン（送料無料）
						</Form.Label>
						<Col sm={2}>
							<Button name="flag_8" variant={this.state.flag_8 ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ flag_8: this.state.flag_8 ? false : true }); }} disabled={this.state.isLockForm} >
								{this.state.flag_8 ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							有効フラグ
						</Form.Label>
						<Col sm={2}>
							<Button name="en_kbn" variant={this.state.en_kbn ? 'primary' : 'secondary'} size="lg" block onClick={()=>{ this.setState({ en_kbn: this.state.en_kbn ? false : true }); }} disabled={this.state.isLockForm || this.state.not_email_kbn} >
								{this.state.en_kbn ? '有効' : '無効'}
							</Button>　
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block size="lg" disabled={this.state.isLockForm} >更新する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block size="lg" onClick={()=>{ this.props.history.push('/Item/'); }} disabled={this.state.isLockForm} >戻る</Button>
						</Col>
					</Row>
					</Form>

					<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleDelete(e)}>
					<Row style={{ marginTop: 100 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="danger" type="submit" block size="lg" disabled={this.state.isLockForm} >削除する</Button>
						</Col>
					</Row>
					</Form>

				</Container>
			);
		}
	}
}

export default withRouter(Default);


